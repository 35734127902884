import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Alert, Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'

function AddPhone({ lastPhone, handleAddPhone, uploadingPhone, uploadedPhone, uploadingFailedPhone }) {
   //
   const [phoneDetails, setPhoneDetails] = useState({
      Phone: '',
   })
   useEffect(() => {
      console.log('Phone details on change of its value: ', phoneDetails)
   }, [phoneDetails])

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (/^\d*$/.test(value)) {
         //  setMobileNumber(value)

         // Regular expression to match exactly 10 digits
         const regex = /^\d{10}$/
         if (value.length === 0 || regex.test(value)) {
            setErrorMessage(value.length === 10 ? 'Is Valid Phone / Mobile' : '')
         } else {
            setErrorMessage('Mobile / phone number must be exactly 10 digits.')
         }
      } else {
         setErrorMessage('Only digits are allowed.')
      }

      //   if (validator.isURL(value)) {
      //     setErrorMessage('Is Valid Phone')
      //  } else {
      //     setErrorMessage('Is Not Valid Phone')
      //  }
   }

   function handlePhoneDetails(event) {
      setPhoneDetails({ ...phoneDetails, [event.target.name]: event.target.value })
      validate(event.target.value)
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         handleSubmitPhone(event)
      }
   }

   function handleSubmitPhone(e) {
      e.preventDefault()

      if (errorMessage !== '' || errorMessage === 'Is Valid Phone / Mobile') {
         handleAddPhone(phoneDetails)
      }

      reset()
   }

   function reset() {
      setPhoneDetails({
         Phone: '',
      })
   }

   return (
      <Box>
         <div>
            <Alert icon={false} variant="outlined" severity="success" sx={{ margin: 5, fontSize: 14 }}>
               <div>Mobile number must be 10 digits.</div>
               <div>Phone numbers with dial code numbers must be 10 digits total.</div>
               <div>Do not prefix 0 before the dial code.</div>
            </Alert>
         </div>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handlePhoneDetails}
               value={phoneDetails.Phone}
               name="Phone"
               id="phone"
               label="Phone"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               onKeyDown={handleKeyDown}
            ></TextField>
         </div>
         <br />
         <span
            style={{
               fontWeight: 'bold',
               color: 'red',
            }}
         >
            {errorMessage}
         </span>
         <Box>
            {uploadingPhone && <MyLoaderCircularGradient title={'Listing the phone ...'}></MyLoaderCircularGradient>}
            {uploadedPhone && (
               <Alert variant="outlined" severity="success">
                  <strong>{lastPhone}</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedPhone && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>{lastPhone}</strong> could not be listed.
               </Alert>
            )}
         </Box>
         <div className={myStyles.margin_top_one}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               onClick={(e) => {
                  handleSubmitPhone(e)
               }}
               disabled={errorMessage === '' || errorMessage !== 'Is Valid Phone / Mobile'}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddPhone.propTypes = {
   lastPhone: PropTypes.string.isRequired,
   handleAddPhone: PropTypes.func.isRequired,
   uploadingPhone: PropTypes.bool.isRequired,
   uploadedPhone: PropTypes.bool.isRequired,
   uploadingFailedPhone: PropTypes.bool.isRequired,
}

export default AddPhone
