import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'

function AddNumber({ label, handleAddNumber }) {
   //
   const [numberDetails, setNumberDetails] = useState({
      Number: '',
   })
   useEffect(() => {
      console.log('Number details on change of its value: ', numberDetails)
   }, [numberDetails])

   function handleNumberDetails(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setNumberDetails({ ...numberDetails, Number: newValue })
            : setNumberDetails({ ...numberDetails, Number: '' })
      } else {
         setNumberDetails({ ...numberDetails, Number: '' })
      }
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         handleSubmitNumber(event)
      }
   }

   function handleSubmitNumber(e) {
      e.preventDefault()

      if (numberDetails.Number !== '') {
         handleAddNumber(numberDetails)
      }

      reset()
   }

   function reset() {
      setNumberDetails({
         Number: '',
      })
   }

   return (
      <Box sx={{ mx: 2 }}>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNumberDetails(e)
               }}
               value={numberDetails.Number}
               name="Number"
               id="number"
               label={label}
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               onKeyDown={handleKeyDown}
            ></TextField>
         </div>
         <div className={myStyles.margin_top_one}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               onClick={(e) => {
                  handleSubmitNumber(e)
               }}
               disabled={numberDetails.Number === ''}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddNumber.propTypes = {
   handleAddNumber: PropTypes.func.isRequired,
   label: PropTypes.string.isRequired,
}

export default AddNumber
