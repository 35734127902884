import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle, Box, Divider, Link, List, ListItem, ListItemText, Typography } from '@mui/material'
import moment from 'moment'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'

function pPAllianceManifestoes({ pPAlliance, handlepPAllianceDetails }) {
   console.log('value from props from inside pPAlliance Manifestoes : ', pPAlliance)

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [manifestoesList, setManifestoesList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchManifestoes()
         setFirstLoadStatus('success')
      }
   }, [])

   const fetchManifestoes = async () => {
      try {
         const docRef = doc(db, 'PPAlliances', pPAlliance.id, 'CoreLists', 'Manifestoes-0001')
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            const sortedManifestoes = [...docSnap.data().Manifestoes].sort((a, b) => {
               if (b.DatePublished > a.DatePublished) {
                  return -1
               } else if (b.DatePublished < a.DatePublished) {
                  return 1
               } else {
                  return 0
               }
            })
            setManifestoesList(sortedManifestoes)
         } else {
            setManifestoesList([])
         }
      } catch (error) {
         console.log(error.message)
      }
   }

   return (
      <div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.4,
            }}
         >
            Manifestoes: {'\n'} घोषणापत्र:
         </Typography>
         <Box sx={{ m: 1 }}>
            {manifestoesList && manifestoesList.length > 0 ? (
               <List dense sx={{ py: 0.5 }}>
                  {manifestoesList.map((item, index) => (
                     <Box key={item.Manifesto_id}>
                        <ListItem sx={{ p: 0, ml: 0 }} key={item.Manifesto_id}>
                           <ListItemText
                              sx={{ ml: 1, fontSize: 13, p: 0 }}
                              secondaryTypographyProps={{
                                 fontSize: 12,
                                 color: 'Crimson',
                                 pr: 1,
                                 align: 'left',
                              }}
                              primaryTypographyProps={{ fontSize: 12 }}
                              primary={
                                 <>
                                    <Link
                                       href={`/election/${item.ElectionName}/manifesto/${item.Manifesto_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.Name.toUpperCase()}</strong>
                                    </Link>

                                    {item.NameLocal && (
                                       <>
                                          <br />
                                          <Link
                                             href={`/election/${item.ElectionName}/manifesto/${item.Manifesto_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <strong>{item.NameLocal}</strong>
                                          </Link>
                                       </>
                                    )}
                                    {!item.NameLocal && (
                                       <>
                                          <br />
                                          <Link
                                             href={`/election/${item.ElectionName}/manifesto/${item.Manifesto_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <strong>{item.NameHindi}</strong>
                                          </Link>
                                       </>
                                    )}
                                 </>
                              }
                              secondary={
                                 <>
                                    {item.DateReleased && (
                                       <>{moment(item.DateReleased.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                    <>
                                       By:{' '}
                                       <strong>
                                          {item.IssuedByParty_id && <>{item.IssuedByPartyName}</>}
                                          {item.IssuedByAlliance_id && <>{item.IssuedByAllianceName}</>}
                                       </strong>
                                    </>
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </Box>
                  ))}
               </List>
            ) : (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        Manifestoes to be available soon. <br /> घोषणा पत्र शीघ्र उपलब्ध होंगे |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}
         </Box>
      </div>
   )
}

pPAllianceManifestoes.propTypes = {
   pPAlliance: PropTypes.object.isRequired,
   handlepPAllianceDetails: PropTypes.func.isRequired,
}

export default pPAllianceManifestoes
