import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Transition } from '../../../Utils/MUITheme/MUITheme'

function PresidentPetition() {
   const [sortField, setSortField] = useState('Support')
   function handleSortField(e) {
      e.preventDefault()
      setSortField(e.target.value)
   }

   useEffect(() => {
      if (sortField) {
         // let sortedConstis
         // switch (sortField) {
         //    case 'Name':
         //       sortedConstis = [...constisList].sort((a, b) => {
         //          if (b.Name > a.Name) {
         //             return -1
         //          } else if (b.Name < a.Name) {
         //             return 1
         //          } else {
         //             return 0
         //          }
         //       })
         //       break
         //    case 'ConstituencyNumber':
         //       sortedConstis = [...constisList].sort((a, b) => a.ConstituencyNumber - b.ConstituencyNumber)
         //       break
         // }
         // setConstisList(sortedConstis)
      }
   }, [sortField])

   const [openDialogCreatePetition, setOpenDialogCreatePetition] = React.useState(false)

   const handleOpenDialogCreatePetition = (e) => {
      e.preventDefault()

      setOpenDialogCreatePetition(true)
   }

   const handleCloseDialogCreatePetition = () => {
      setOpenDialogCreatePetition(false)
   }

   return (
      <Box>
         <Box sx={{ mb: 0.5 }}>
            <Button
               onClick={(e) => {
                  handleOpenDialogCreatePetition(e)
               }}
               size="small"
               variant="outlined"
               sx={{ py: 0, px: 1, minWidth: 0, whiteSpace: 'pre-line' }}
            >
               Create a petition / एक याचिका बनाएँ
            </Button>
         </Box>

         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               Petitions: {'\n'} याचिकाएं:
            </Typography>
         </Box>
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               px: 1,
               mt: 0.5,
               '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
            }}
         >
            <Box></Box>
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  px: 0.5,
                  ml: 'auto',
                  border: 1,
                  borderRadius: 1,
                  borderColor: '#888889',
               }}
            >
               <Box sx={{ color: '#795548', fontSize: 15, textDecoration: 'underline' }}>Sort by / क्रमबद्धता:</Box>
               <Box>
                  <FormControl
                     sx={{
                        p: 0,
                     }}
                  >
                     {/* <FormLabel id="demo-controlled-radio-buttons-group">Sort by:</FormLabel> */}
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="SortField"
                        value={sortField}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handleSortField(e)
                        }}
                        sx={{
                           backgroundColor: '#ffffff',
                           borderRadius: 1,
                           py: 0,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 13,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        <FormControlLabel
                           value="Date"
                           control={<Radio size="small" />}
                           label="Date / तिथि"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Supports"
                           control={<Radio size="small" />}
                           label="Supports / समर्थन"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     </RadioGroup>
                  </FormControl>
               </Box>
            </Box>
         </Box>

         <Box sx={{ mt: 10, mx: 10 }}>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
            >
               <AlertTitle sx={{ fontSize: 12 }}>
                  This facility will be available soon. <br /> यह सुविधा शीघ्र ही उपलब्ध होगी|
               </AlertTitle>
            </Alert>
         </Box>
         <Box id="boxModals">
            <Dialog
               open={openDialogCreatePetition}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogCreatePetition}
               aria-describedby="alert-dialog-slide-su"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`एक याचिका बनाएँ ${'\n'} Create a petition`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}>
                     This facility will be available soon. {'\n'}
                     यह सुविधा शीघ्र ही उपलब्ध होगी |
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogCreatePetition}>Close</Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Box>
   )
}

export default PresidentPetition
