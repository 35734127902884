import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { sentenceCase } from '../../Utils/Conversions/CaseConversion'

function AddTextByLanguage({ labelName, handleAddText }) {
   const [selectedLanguage, setSelectedLanguage] = useState('')

   function handleLanguage(e) {
      e.preventDefault()

      setSelectedLanguage(e.target.value)
      console.log('Language: ', selectedLanguage)
   }

   const [textDetails, setTextDetails] = useState({
      Text: '',
   })
   useEffect(() => {
      console.log('Party details on change of its value: ', textDetails)
   }, [textDetails])

   function handleTextDetails(event) {
      setTextDetails({ ...textDetails, [event.target.name]: sentenceCase(event.target.value.trim()) })
   }

   const handleKeyDown = (event) => {
      if (textDetails.Text.length >= 2 && selectedLanguage !== '') {
         if (event.key === 'Enter') {
            handleSubmitText(event)
         }
      }
   }

   function handleSubmitText(e) {
      e.preventDefault()

      if (textDetails.Text.length >= 2 && selectedLanguage !== '') {
         handleAddText(textDetails, selectedLanguage)
         reset()
      } else {
         alert('Please add some text.')
      }
   }

   function reset() {
      setTextDetails({
         Text: '',
      })
   }
   return (
      <Box sx={{ m: 2, width: 400 }}>
         <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <FormControl
               sx={{
                  py: 0,
                  px: 1,
                  borderRadius: 1,
                  backgroundColor: '#F1F1F1',
               }}
            >
               <FormLabel id="demo-controlled-radio-buttons-group">Select language:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="PoliticalAffiliationType"
                  value={selectedLanguage}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handleLanguage(e)
                  }}
                  sx={{
                     backgroundColor: '#F1F1F1',
                     borderRadius: 1,
                     py: 0,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="English"
                     control={<Radio size="small" />}
                     label="English"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="Hindi"
                     control={<Radio size="small" />}
                     label="Hindi"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="Local"
                     control={<Radio size="small" />}
                     label="Local"
                     sx={{ py: 0, fontSize: 15 }}
                  />
               </RadioGroup>
            </FormControl>
         </Box>
         <Box sx={{ mt: 2 }}>
            Selected language: <strong>{selectedLanguage}</strong>
         </Box>
         <Box sx={{ m: 2 }}>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleTextDetails}
                  value={textDetails.Text}
                  name="Text"
                  id="text"
                  label={labelName}
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                  onKeyDown={handleKeyDown}
               ></TextField>
            </div>
            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  margin="dense"
                  sx={{ my: 1 }}
                  onClick={(e) => {
                     handleSubmitText(e)
                  }}
                  disabled={textDetails.Text.length < 2 || selectedLanguage === ''}
               >
                  Submit
               </Button>
            </div>
         </Box>
      </Box>
   )
}

AddTextByLanguage.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleAddText: PropTypes.func.isRequired,
}

export default AddTextByLanguage
