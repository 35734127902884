import React from 'react'
import { Helmet } from 'react-helmet'
import { Alert, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

function LegisMemWorks({ member }) {
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Works of - ${member.Name} - member of ${member.LegislatureName}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               pl: 1,
               whiteSpace: 'pre-line',
            }}
         >
            Works by {member.Name}: {'\n'} {member.NameHindi} द्वारा कार्य:
         </Typography>
         <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 10, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               <strong>Members can show their works here.</strong>
               <br />
               <br />
               <strong>सदस्य यहां अपना कार्य दिखा सकते हैं।</strong>
            </Alert>
         </Box>
         <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 10, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               This facility will be available soon.
               <br />
               <br />
               यह सुविधा शीघ्र ही उपलब्ध होगी।
            </Alert>
         </Box>
      </Box>
   )
}

LegisMemWorks.propTypes = {
   member: PropTypes.object.isRequired,
}

export default LegisMemWorks
