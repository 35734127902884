import { Box, Card, CardContent, CardMedia, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import { Helmet } from 'react-helmet'
import ElectionsGeneral from './ElectionsGeneral'
import ElectionsBye from './ElectionsBye'
import ElectionsSU from './ElectionsSU'
import ElectionsBarCouncil from './ElectionsBarCouncil'

function Elections() {
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [electionsGE, setElectionsGE] = useState('')
   const [electionsBE, setElectionsBE] = useState('')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         console.log('reached first blank use effect')
         fetchElections()
      }
   }, [])

   const fetchElections = async () => {
      try {
         let listBase = []
         const docRef = doc(db, 'BaseLists', 'Elections-India-000001')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            listBase = docSnap.data().Elections
            // console.log('elections list fetched : ', listBase)

            const listBaseGE = listBase.filter((item) => item.ElectionType === 'General Election')
            const listBaseBE = listBase.filter((item) => item.ElectionType === 'Bye-Election')

            console.log('listBaseGE GE : ', listBaseGE)
            console.log('listBaseBE BE : ', listBaseBE)

            const sortedListGE = listBaseGE.sort((a, b) => {
               // bigger date first
               if (moment(a.DateStart.toDate()).isAfter(moment(b.DateStart.toDate()))) {
                  return -1
               } else if (moment(a.DateStart.toDate()).isBefore(moment(b.DateStart.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            const sortedListBE = listBaseBE.sort((a, b) => {
               // bigger date first
               if (moment(a.DateStart.toDate()).isAfter(moment(b.DateStart.toDate()))) {
                  return -1
               } else if (moment(a.DateStart.toDate()).isBefore(moment(b.DateStart.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setElectionsGE(sortedListGE)
            setElectionsBE(sortedListBE)

            console.log('General Elections list sorted: ', sortedListGE)
            console.log('Bye-Elections list sorted: ', sortedListBE)
         } else {
            setElectionsGE([])
            setElectionsBE([])
         }
         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
      }
   }

   const theme = useTheme()
   //
   return (
      <Box sx={{ minHeight: 800, px: 0.5 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Elections in India`}</title>
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               py: 1,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #797580, #545159)',
            }}
         >
            <Box
               sx={{
                  px: 1,
                  py: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <CardContent sx={{ flex: '1 0 auto', p: 0 }}>
                  <div>
                     <Typography
                        component="div"
                        variant="h5"
                        sx={{ fontWeight: 700, fontSize: 22, lineHeight: 1.4 }}
                        color="#FFFFFF"
                     >
                        Elections of India
                     </Typography>

                     <Typography
                        component="div"
                        variant="h5"
                        sx={{ fontWeight: 700, fontSize: 22, lineHeight: 1.4 }}
                        color="#FFFFFF"
                     >
                        भारत के चुनाव
                     </Typography>
                  </div>
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ width: 151, borderRadius: 1, mr: 1 }}
               image="https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
               alt="Live from space album cover"
            />
         </Card>
         <TabContext value={value}>
            <Box>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  //   variant="scrollable"
                  //   scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`General Elections \n आम चुनाव`} {...a11yProps(0)} />
                  <StyledTab1 label={`Bye-Elections \n उप-चुनाव`} {...a11yProps(1)} />
                  <StyledTab1 label={`Student Union Elections \n छात्र संघ चुनाव`} {...a11yProps(2)} />
                  <StyledTab1 label={`Bar Counci Elections \n विधिज्ञ परिषद चुनाव`} {...a11yProps(3)} />
               </Tabs>
            </Box>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={12} sm={12} md={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {electionsGE && <ElectionsGeneral electionsGE={electionsGE} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {electionsBE && <ElectionsBye electionsBE={electionsBE} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           <ElectionsSU />
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           <ElectionsBarCouncil />
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4}>
                  <Box sx={{ backgroundColor: '#F1F1F1', mx: 0.5, height: '95%' }}></Box>
               </Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default Elections
