import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Link,
   Slide,
   Stack,
   Typography,
} from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import HomeTabPanelLegis from './HomeTabPanelLegis'
import HomeTabPanelExecutive from './HomeTabPanelExecutive'
import HomeTabPanelJudiciary from './HomeTabPanelJudiciary'

import Marquee from 'react-fast-marquee'
import moment from 'moment'
import { sxTypoGraphy2 } from '../../Utils/MUITheme/MUITheme'
import { styles4ButtonGreen, styles4ButtonOrange, styles4ButtonRed } from '../../Utils/MUITheme/ButttonThemes'
import PPartiesTop10 from '../PParties/PPartiesTop10/PPartiesTop10'
import { useSelector } from 'react-redux'
import { selectPParties } from '../../redux/reducers/pParties-slice'
import { selectUserProfile } from '../../redux/reducers/users-slice'

// const Item = styled(Paper)(({ theme }) => ({
//    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//    ...theme.typography.body2,
//    padding: theme.spacing(1),
//    textAlign: 'center',
//    color: theme.palette.text.secondary,
//    height: 70,
// }))

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function Home() {
   const userProfile = useSelector(selectUserProfile)

   const [initialState, setInitialState] = useState('idle')
   const [partiesListNational, setPartiesListNational] = useState('')
   const [electionsList, setElectionsList] = useState('')

   const pParties = useSelector(selectPParties).pPartiesList

   let fullName = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast
   }

   useEffect(() => {
      if (initialState === 'idle') {
         //
         fetchElections()
         setInitialState('success')
      }
   }, [])

   // related to countries
   // const countries = useSelector(selectCountries).countriesList
   // const countryId = useSelector(selectCountries).selectedCountryInformation.id

   // related to parties list

   const fetchPartiesListNational = async () => {
      console.log('start fetching parties - 1 - pparties list: ', pParties)
      const pPartiesNational = pParties.filter((party) => party.ECIStatus === 'National')

      const sortedPPartiesNational = pPartiesNational.sort((a, b) => {
         if (b.Name.trim() > a.Name.trim()) {
            return -1
         } else if (b.Name.trim() < a.Name.trim()) {
            return 1
         } else {
            return 0
         }
      })
      setPartiesListNational(sortedPPartiesNational)
      console.log('parties list fetched by National level: ', sortedPPartiesNational)
   }

   useEffect(() => {
      console.log('parties list on change', partiesListNational)
      fetchPartiesListNational()
   }, [pParties])

   const fetchElections = async () => {
      try {
         let electionsListBase = []
         const docRef = doc(db, 'BaseLists', 'Elections-India-000001')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            electionsListBase = docSnap.data().Elections
            console.log('elections list fetched : ', electionsListBase)

            const dateLimit = new Date()
            dateLimit.setMonth(dateLimit.getMonth() - 2)
            console.log('date limit : ', dateLimit)

            const electionsListFiltered = electionsListBase.filter((item) =>
               moment(item.DateStart.toDate()).isAfter(moment(dateLimit)),
            )
            const electionsListFilteredElecType = electionsListFiltered.filter(
               (item) => item.ElectionType === 'General Election',
            )
            // const electionsListFiltered1 = electionsListBase.filter((item) =>
            //    moment(item.DateStart.toDate()).isAfter(moment(dateLimit)),
            // )
            // const electionsListFiltered2 = electionsListBase.filter((item) =>
            //    moment(item.DateStart.toDate()).isBefore(moment(dateLimit)),
            // )

            console.log('elections list filtered : ', electionsListFilteredElecType)
            // console.log('elections list filtered 1 : ', electionsListFiltered1)
            // console.log('elections list filtered 2 : ', electionsListFiltered2)

            const sortedListElections = electionsListFilteredElecType.sort((a, b) => {
               // smaller date first
               if (moment(b.DateStart.toDate()).isAfter(moment(a.DateStart.toDate()))) {
                  return -1
               } else if (moment(b.DateStart.toDate()).isBefore(moment(a.DateStart.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setElectionsList(sortedListElections)

            console.log('Elections list sorted: ', sortedListElections)
         } else {
            setElectionsList([])
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   // for dialog students union

   const [openSU, setOpenSU] = React.useState(false)

   const handleClickOpenSU = () => {
      setOpenSU(true)
   }

   const handleCloseSU = () => {
      setOpenSU(false)
   }

   // for dialog not logged

   const [openNotLogged, setOpenNotLogged] = React.useState(false)

   const handleCloseNotLogged = () => {
      setOpenNotLogged(false)
   }

   // for dialog own party

   const [openOwnParty, setOpenOwnParty] = React.useState(false)

   const handleClickOpenOwnParty = () => {
      setOpenOwnParty(true)
   }

   const handleCloseOwnParty = () => {
      setOpenOwnParty(false)
   }

   // for dialog OnLineProtest

   const [openOnLineProtest, setOpenOnLineProtest] = React.useState(false)

   const handleClickOpenOnLineProtest = () => {
      setOpenOnLineProtest(true)
   }

   const handleCloseOnLineProtest = () => {
      setOpenOnLineProtest(false)
   }

   return (
      <Box id="mainBoxHome">
         <Grid container sx={{ mt: 1, mx: 1 }} disableEqualOverflow>
            <Grid xs={12}>
               <Marquee pauseOnHover="true" speed={50} delay={2}>
                  {electionsList &&
                     electionsList.length > 0 &&
                     electionsList.map((item) => (
                        <Link
                           key={item.Election_id}
                           href={`/election/${item.Name}/${item.Election_id}`}
                           sx={{ textDecoration: 'none !important' }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <Box
                              key={item.Election_id}
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 backgroundColor: '#e5f6fd',
                                 mr: 5,
                                 borderRadius: 1,
                                 px: 1,
                                 py: 0.5,
                                 lineHeight: 1,
                              }}
                           >
                              <Typography sx={{ fontSize: 15, lineHeight: 1.1, color: '#000000' }}>
                                 {item.Name}
                              </Typography>
                              {item.IsSpeculated && (
                                 <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Box
                                       sx={{
                                          fontSize: 14,
                                          lineHeight: 1.1,
                                          fontWeight: 500,
                                          backgroundColor: '#FFD32C',
                                          borderRadius: 1,
                                          p: 0.5,
                                          color: '#000000',
                                       }}
                                    >
                                       <span> Speculated </span>
                                    </Box>
                                    <Box sx={{ fontSize: 14, lineHeight: 1.1, color: '#FF5733 ', fontWeight: 500 }}>
                                       {moment(item.DateStart.toDate()).format('Do MMM YYYY')}
                                       {moment(item.DateStart.toDate()).format('Do MMM YYYY') !==
                                          moment(item.DateFinish.toDate()).format('Do MMM YYYY') && (
                                          <> - {moment(item.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                       )}
                                    </Box>

                                    <Box
                                       sx={{
                                          fontSize: 13,
                                          lineHeight: 1.1,
                                          fontWeight: 600,
                                          backgroundColor: '#FFD32C',
                                          borderRadius: 1,
                                          p: 0.5,
                                          color: '#000000',
                                       }}
                                    >
                                       <span> अनुमानित </span>
                                    </Box>
                                 </Box>
                              )}
                              {!item.IsSpeculated && (
                                 <Typography sx={{ fontSize: 14, lineHeight: 1.1, color: '#d32f2f ', fontWeight: 500 }}>
                                    {moment(item.DateStart.toDate()).format('Do MMM YYYY')}
                                    {moment(item.DateStart.toDate()).format('Do MMM YYYY') !==
                                       moment(item.DateFinish.toDate()).format('Do MMM YYYY') && (
                                       <> - {moment(item.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                 </Typography>
                              )}
                              {item.NameLocal ? (
                                 <Typography sx={{ fontSize: 14, lineHeight: 1.3, color: '#000000' }}>
                                    {item.NameLocal}
                                 </Typography>
                              ) : (
                                 <Typography sx={{ fontSize: 14, lineHeight: 1.3, color: '#000000' }}>
                                    {item.NameHindi}
                                 </Typography>
                              )}
                           </Box>
                        </Link>
                     ))}
               </Marquee>
            </Grid>
         </Grid>

         <Grid container sx={{ mt: 1, mx: 1 }} disableEqualOverflow>
            <Grid xs={12}>
               <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{ alignItems: 'center', justifyContent: 'center', height: '54px' }}
               >
                  {partiesListNational &&
                     partiesListNational.length > 0 &&
                     partiesListNational.map((item) => (
                        <Link
                           key={item.Party_id}
                           href={`/political-party/${item.Name}/${item.Party_id}`}
                           sx={{ textDecoration: 'none !important', px: 0 }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <Box
                              key={item.Party_id}
                              component="img"
                              src={item.Flag}
                              alt={item.Name}
                              sx={{ height: '50px', maxWidth: 150, borderRadius: 1 }}
                           />
                        </Link>
                     ))}
               </Stack>
            </Grid>
         </Grid>

         <Grid container sx={{ mx: 1, backgroundColor: '#000000', p: 0.5 }} disableEqualOverflow>
            <Grid xs={12}>
               <Marquee speed={100}>
                  <Typography sx={{ fontSize: 15, color: '#CFFF04', fontWeight: 500, mr: 5 }}>
                     {`World's first of its kind digital platform - For a transparent democratic interaction between
                     leaders - elected representatives (MP, MLA, Ministers, Corporaters, etc.) - political parties -
                     student unions - government - administration - judiciary - PUBLIC.`}
                  </Typography>
                  <Typography sx={{ fontSize: 15, color: '#FF9933', fontWeight: 500, mr: 5 }}>
                     {`Made In India - Owned By Indians - Run By Indians`}
                  </Typography>
                  <Typography sx={{ fontSize: 14, color: '#CFFF04', fontWeight: 600, mr: 5 }}>
                     विश्व का अपनी तरह का पहला डिजिटल प्लेटफार्म - नेताओं, निर्वाचित प्रतिनिधि (सांसद, विधायक, मंत्री,
                     कॉर्पोरेटर, आदि) - राजनीतिक दल - छात्र संघ - सरकार - प्रशासन - न्यायपालिका - जनता के बीच एक
                     लोकतांत्रिक पारदर्शी पारस्परिक संवाद के लिए|
                  </Typography>
                  <Typography sx={{ fontSize: 14, color: '#FF9933', fontWeight: 600, mr: 5 }}>
                     भारत में निर्मित - भारतीयों के स्वामित्व में - भारतीयों द्वारा संचालित
                  </Typography>
               </Marquee>
            </Grid>
         </Grid>

         <Grid id="home_grid_container_2" container spacing={0.5} sx={{ my: 0.25, mx: 0.5 }} disableEqualOverflow>
            <Grid
               id="home_grid_Container_2_child_1"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 1, sm: 1, md: 1 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     // Love Couple from uiGradient
                     background: 'linear-gradient(to right, #3a6186, #89253e)',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     // borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>
                     Legislative{' - '}
                     <span style={{ fontSize: 15 }}>
                        <strong>विधायिका</strong>
                     </span>
                  </Typography>
               </Box>
               <Box>
                  <HomeTabPanelLegis />
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_2"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 2, sm: 4, md: 3 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     // backgroundColor: '#1565c0',
                     background: 'linear-gradient(to right, #3a6186, #89253e)',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     // borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>
                     Executive{' - '}
                     <span style={{ fontSize: 15 }}>
                        <strong>कार्यपालिका</strong>
                     </span>
                  </Typography>
               </Box>
               <Box>
                  <HomeTabPanelExecutive />
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_3"
               xs={4}
               sm={4}
               md={2}
               sx={{ alignItems: 'center', justifyContent: 'center', mt: 0.5, borderRadius: 1 }}
               order={{ xs: 3, sm: 2, md: 2 }}
            >
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        ...styles4ButtonOrange,
                        width: '100%',
                        fontSize: 15,
                     }}
                  >
                     <Link
                        href={`/constitution/India/lsTDGRF8XHWnR3VjVuB4`}
                        sx={{
                           textDecoration: 'none !important',
                           textTransform: 'none',
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 15,
                           color: '#FFFFFF',
                        }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <strong>संविधान</strong> {'\n'} Constitution
                     </Link>
                  </Button>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        ...styles4ButtonGreen,
                        width: '100%',
                        fontSize: 15,
                     }}
                  >
                     <Link
                        href={`/political-parties/India/lsTDGRF8XHWnR3VjVuB4`}
                        sx={{
                           textDecoration: 'none !important',
                           textTransform: 'none',
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 15,
                           color: '#FFFFFF',
                        }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <strong>राजनीतिक दल</strong> {'\n'} Political Parties
                     </Link>
                  </Button>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        ...styles4ButtonGreen,
                        width: '100%',
                        fontSize: 15,
                     }}
                  >
                     <Link
                        href={`/elections-of-INDIA`}
                        sx={{
                           textDecoration: 'none !important',
                           textTransform: 'none',
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 15,
                           color: '#FFFFFF',
                        }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        <strong>चुनाव</strong> {'\n'} Elections
                     </Link>
                  </Button>
               </Box>

               {/* <Box sx={{ width: '100%', mt: 0.5 }}>
                  <ThemeProvider theme={theme}>
                     <Button
                        variant="contained"
                        size="small"
                        color="ochre"
                        sx={{
                           textTransform: 'none',
                           padding: 1,
                           minWidth: 0,
                           lineHeight: 1.1,
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 12,
                           whiteSpace: 'pre-line',
                        }}
                        onClick={(e) => {
                           handleClickScams(e)
                        }}
                     >
                        घोटाले {'\n'} Scams
                     </Button>
                  </ThemeProvider>
               </Box> */}

               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        ...styles4ButtonGreen,
                        width: '100%',
                        fontSize: 15,
                        fontWeight: 500,
                     }}
                  >
                     <Link
                        href={
                           userProfile && userProfile.NetaProfile
                              ? `/leader/${fullName}/${userProfile.NetaProfile}`
                              : `/my-dashboard`
                        }
                        sx={{
                           textDecoration: 'none !important',
                           textTransform: 'none',
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 15,
                           color: '#FFFFFF',
                        }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        {!userProfile && <>नेता प्रोफाइल बनायें {'\n'} Create Leader Profile</>}
                        {userProfile && !userProfile.NetaProfile && (
                           <>नेता प्रोफाइल बनायें {'\n'} Create Leader Profile</>
                        )}
                        {userProfile && userProfile.NetaProfile && <>नेता प्रोफ़ाइल {'\n'} Leader Profile</>}
                     </Link>
                  </Button>
               </Box>
               {/* {userProfile && userProfile.NetaProfile && (
                  <Box sx={{ width: '100%', mt: 0.5 }}>
                     <Button
                        variant="contained"
                        size="small"
                        sx={{
                           ...styles4ButtonGreen,
                           width: '100%',
                           fontSize: 15,
                           fontWeight: 500,
                        }}
                        onClick={handleClickOpenLeaderProfile}
                     >
                        <Link
                           href={`/my-dashboard`}
                           sx={{
                              textDecoration: 'none !important',
                              textTransform: 'none',
                              width: '100%',
                              fontWeight: 500,
                              fontSize: 15,
                              color: '#FFFFFF',
                           }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           नेता प्रोफ़ाइल प्रबंधित करें {'\n'} Manage Leader Profile
                        </Link>
                     </Button>
                  </Box>
               )} */}
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        ...styles4ButtonRed,
                        width: '100%',
                        fontWeight: 500,
                        fontSize: 15,
                     }}
                     onClick={handleClickOpenSU}
                  >
                     छात्र संघ {'\n'} Student Unions
                  </Button>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        ...styles4ButtonRed,
                        width: '100%',
                     }}
                     onClick={handleClickOpenOwnParty}
                  >
                     अपना दल बनायें {'\n'} Create Own Party
                  </Button>
               </Box>

               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        ...styles4ButtonRed,
                        width: '100%',
                     }}
                     onClick={handleClickOpenOnLineProtest}
                  >
                     ऑनलाइन विरोध शुरू करें {'\n'} Start On-line Protest
                  </Button>
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_4"
               xs={6}
               sm={4}
               md={2}
               order={{ xs: 4, sm: 3, md: 4 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     // backgroundColor: '#191919',
                     background: 'linear-gradient(to right, #3a6186, #89253e)',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 50,
                     mb: 2,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={{ ...sxTypoGraphy2, lineHeight: 1.3 }}>
                     Your Constituencies{'\n'}
                     <span style={{ fontSize: 15 }}>
                        <strong>आपके निर्वाचन क्षेत्र</strong>
                     </span>
                  </Typography>
               </Box>
               <Grid container spacing={1} sx={{ my: 0.5 }} disableEqualOverflow>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#D3D3D3',
                           fontWeight: 500,
                        }}
                     >
                        Parliamentary (संसदीय):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#FFFFFF',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#D3D3D3',
                           fontWeight: 500,
                        }}
                     >
                        State Assembly (राज्य विधायिका):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#FFFFFF',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#D3D3D3',
                           fontWeight: 500,
                        }}
                     >
                        District Level (जिला स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#FFFFFF',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#D3D3D3',
                           fontWeight: 500,
                        }}
                     >
                        Sub-District Level (उप-जिला स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#FFFFFF',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#D3D3D3',
                           fontWeight: 500,
                        }}
                     >
                        Block Level (ब्लॉक स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#FFFFFF',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#D3D3D3',
                           fontWeight: 500,
                        }}
                     >
                        Base Level (मूल स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#FFFFFF',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_5"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 6, sm: 6, md: 6 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     background: 'linear-gradient(to right, #3a6186, #89253e)',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>
                     Judiciary{' - '}
                     <span style={{ fontSize: 15 }}>
                        <strong>न्यायपालिका</strong>
                     </span>
                  </Typography>
               </Box>
               <Box>
                  <HomeTabPanelJudiciary />
               </Box>
            </Grid>

            <Grid
               id="home_Grid_Container_2_child_5"
               xs={12}
               sm={8}
               md={6}
               order={{ xs: 5, sm: 5, md: 5 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     background: 'linear-gradient(to right, #3a6186, #89253e)',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 0.5,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>
                     Public Support -<strong> जन समर्थन </strong>
                  </Typography>
               </Box>
               {/* <Box sx={{ border: 1, borderColor: '#3a6186', borderTop: 0 }}>
                  <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: 14 }}>
                     National Level - राष्ट्रीय स्तर
                  </Typography>
               </Box> */}
               <PPartiesTop10 />
            </Grid>
         </Grid>

         {/* <HomeAccordian /> */}
         {/* <MydbOperations /> */}
         <br />
         <br />
         <br />
         <br />
         <Box id="boxModals">
            <Dialog
               open={openOnLineProtest}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseOnLineProtest}
               aria-describedby="alert-dialog-slide-OwnParty"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`ऑनलाइन विरोध शुरू करें ${'\n'} Satrt On-line Protest`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-description"
                     sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}
                  >
                     This facility will be available soon. {'\n'}
                     यह सुविधा शीघ्र ही उपलब्ध होगी |
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseOnLineProtest}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openOwnParty}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseOwnParty}
               aria-describedby="alert-dialog-slide-OwnParty"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`अपना दल बनायें ${'\n'} Create Own Party`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-description"
                     sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}
                  >
                     This facility will be available soon. {'\n'}
                     यह सुविधा शीघ्र ही उपलब्ध होगी |
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseOwnParty}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openSU}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseSU}
               aria-describedby="alert-dialog-slide-su"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`छात्र संघ ${'\n'} Student Unions`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}>
                     This facility will be available soon. {'\n'}
                     यह सुविधा शीघ्र ही उपलब्ध होगी |
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseSU}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openNotLogged}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseNotLogged}
               aria-describedby="alert-dialog-slide-notLogged"
            >
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}>
                     You are not logged in. {'\n'}
                     आपने लॉग - इन नहीं किया है|
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseNotLogged}>Close</Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Box>
   )
}

export default Home
