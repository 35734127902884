import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, TextField } from '@mui/material'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function AddPPartiesAlliance({
   lastAlliance,
   handleAddAlliance,
   uploadingAlliance,
   uploadedAlliance,
   uploadingFailedAlliance,
}) {
   const [allianceDetails, setAllianceDetails] = useState({
      Name: '',
      NameOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameLocal: '',
      NameLocalOld: [],
      Abbreviation: '',
      Country_id: '',
      CountryName: '',
      CountryNameHindi: '',
      CountryNameLocal: '',
      States: [],
      State_id_s: [],
      IsActive: true,
      FoundedDate: '',
      Parties: [],
      Party_id_s: [],
      OfficePosts: [],
      OfficeBearers: [],
      Committees: [],
      Address: {},
      AddressOld: [],
      Phones: [],
      PhonesOld: [],
      WebLinks: [],
      WebLinksOld: [],
      Emails: [],
      EmailsOld: [],
      Image: '',
      ImageOld: [],
      Flag: '',
      FlagOld: [],
      Logo: '',
      LogoOld: [],
   })

   useEffect(() => {
      console.log('alliance details on change: ', allianceDetails)
   }, [allianceDetails])

   function handleAllianceDetails(e) {
      setAllianceDetails({ ...allianceDetails, [e.target.name]: e.target.value })
      console.log('abbr length: ', allianceDetails.Abbreviation.length, 'name length: ', allianceDetails.Name.length)
   }

   const handleSubmitAlliance = async (e) => {
      e.preventDefault()
      if (allianceDetails.Name.length > 3) {
         console.log('allianceDetails before submit: ', allianceDetails)

         handleAddAlliance(allianceDetails)

         setAllianceDetails({
            Name: '',
            NameOld: [],
            NameHindi: '',
            NameHindiOld: [],
            NameLocal: '',
            NameLocalOld: [],
            Abbreviation: '',
            Country_id: '',
            CountryName: '',
            CountryNameHindi: '',
            CountryNameLocal: '',
            States: '',
            State_id_s: [],
            IsActive: true,
            FoundedDate: '',
            Parties: [],
            Party_id_s: [],
            OfficePosts: [],
            OfficeBearers: [],
            Committees: [],
            Address: {},
            AddressOld: [],
            Phones: [],
            PhonesOld: [],
            WebLinks: [],
            WebLinksOld: [],
            Emails: [],
            EmailsOld: [],
            Image: '',
            ImageOld: [],
            Flag: '',
            FlagOld: [],
            Logo: '',
            LogoOld: [],
         })
      }
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            mx: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': {
               width: '50ch',
               mb: 0.5,
               mt: 0,
               paddingBottom: 0,
               backgroundColor: '#ffffff',
               borderRadius: 1,
            },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAllianceDetails(e)
               }}
               value={allianceDetails.Name}
               name="Name"
               id="name"
               label="Name"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
            ></TextField>
         </div>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAllianceDetails(e)
               }}
               value={allianceDetails.NameHindi}
               name="NameHindi"
               id="nameHindi"
               label="Name in Hindi"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
            ></TextField>
         </div>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAllianceDetails(e)
               }}
               value={allianceDetails.NameLocal}
               name="NameLocal"
               id="nameLocal"
               label="Name in Local Language"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
            ></TextField>
         </div>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAllianceDetails(e)
               }}
               value={allianceDetails.Abbreviation}
               name="Abbreviation"
               id="abbreviation"
               label="Abbreviation"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
            ></TextField>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitAlliance(e)
               }}
               disabled={allianceDetails.Name.length < 4 || allianceDetails.Abbreviation.length < 2}
            >
               Submit
            </Button>
         </div>
         <Box sx={{ mt: 1 }}>
            {uploadingAlliance && (
               <MyLoaderCircularGradient title={'Listing the alliance ...'}></MyLoaderCircularGradient>
            )}
            {uploadedAlliance && (
               <Alert variant="outlined" severity="success">
                  <strong>{lastAlliance}</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedAlliance && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>{lastAlliance}</strong> could not be listed.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

AddPPartiesAlliance.propTypes = {
   lastAlliance: PropTypes.string.isRequired,
   handleAddAlliance: PropTypes.func.isRequired,
   uploadingAlliance: PropTypes.bool.isRequired,
   uploadedAlliance: PropTypes.bool.isRequired,
   uploadingFailedAlliance: PropTypes.bool.isRequired,
}

export default AddPPartiesAlliance
