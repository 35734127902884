import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Box } from '@mui/material'
import { Helmet } from 'react-helmet'

function ElecPromises({ elec }) {
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Promises made during ${elec.Name}`}</title>
            </Helmet>
         </div>
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               color: '#ffffff',
            }}
         >
            <Box>Promises:</Box>
            <Box>वादे:</Box>
         </Box>
         <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 10, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               <strong>
                  You can upload links of online news items and videos of the promises made by top leaders during the
                  election.
               </strong>
               <br />
               <br />
               <strong>
                  आप चुनाव के दौरान शीर्ष नेताओं द्वारा किए गए वादों के वीडियो लिंक और ऑनलाइन समाचारों के लिंक अपलोड कर
                  सकते हैं।
               </strong>
            </Alert>
         </Box>
         <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 10, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               This facility will be available soon.
               <br />
               <br />
               यह सुविधा शीघ्र ही उपलब्ध होगी।
            </Alert>
         </Box>
      </Box>
   )
}

ElecPromises.propTypes = {
   elec: PropTypes.object.isRequired,
}

export default ElecPromises
