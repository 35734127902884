import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { auth, db } from '../../FirebaseConfig'
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { Alert, AlertTitle, Box, Button, TextField, Typography } from '@mui/material'
import { setUser } from '../../redux/reducers/users-slice'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function Login({ isUserLoggedIn, handleIsUserLoggedIn, userProfile }) {
   // area for log in
   const [error, setError] = useState('')
   const dispatch = useDispatch()

   let fullName = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast
   }

   const [loginCreds, setLoginCreds] = useState({ phone: '', password: '' })

   function handleLoginCreds(e) {
      // console.log(e.target.value)
      setLoginCreds({ ...loginCreds, [e.target.name]: e.target.value })
   }

   function handlePhone4Login(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setLoginCreds({ ...loginCreds, [e.target.name]: newValue })
            : setLoginCreds({ ...loginCreds, [e.target.name]: '' })
      } else {
         setLoginCreds({ ...loginCreds, [e.target.name]: '' })
      }
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         handleLogin(event)
      }
   }

   const [phoneIsNotRegistered, setPhoneIsNotRegistered] = useState(false)

   async function handleLogin(e) {
      e.preventDefault()
      setError('')
      setPhoneIsNotRegistered(false)
      if (!isNaN(loginCreds.phone) && loginCreds.phone.toString().length === 10 && loginCreds.password.length > 7) {
         const querryUserProfile = query(collection(db, 'UserProfiles'), where('MobilePhone', '==', loginCreds.phone))
         const querySnapshot = await getDocs(querryUserProfile)

         let email = ''

         querySnapshot.forEach((docSnap) => {
            email = docSnap.data().Email
         })

         if (email !== '') {
            // try to log in the user
            signInWithEmailAndPassword(auth, email, loginCreds.password)
               .then((userCredential) => {
                  handleIsUserLoggedIn('in')
                  dispatch(
                     setUser({
                        id: userCredential.user.uid,
                        email: userCredential.user.email,
                        displayName: userCredential.user.displayName,
                     }),
                  )
                  console.log('login stage -3 : useeffect : ', userCredential)
               })
               .catch((error) => {
                  setError(error.message)
               })
         } else {
            // show that the phone is not registered
            setPhoneIsNotRegistered(true)
         }
      }
   }

   function handlePasswordReset(e) {
      e.preventDefault()
      const email = prompt('Please enter your email')
      if (email) {
         sendPasswordResetEmail(auth, email)
            .then(() => {
               alert('Email sent! Check your inbox for password reset instructions.')
            })
            .catch((error) => {
               setError(error.message)
            })
      } else {
         //
      }
   }
   return (
      <Box sx={{ minHeight: 500 }}>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && isUserLoggedIn === '' && (
            <div>
               <Alert variant="outlined" severity="warning" sx={{ margin: 5 }}>
                  <AlertTitle>... Loading !</AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && isUserLoggedIn === 'in' && (
            <div>
               <Alert
                  variant="outlined"
                  severity="success"
                  sx={{
                     margin: 5,
                     alignItems: 'center',
                     justifyContent: 'center',
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >
                  <AlertTitle>Welcome ... {fullName}!</AlertTitle>
                  You are logged in the site. Enjoy tracking democracy!
               </Alert>
            </div>
         )}
         {navigator.onLine && isUserLoggedIn === 'out' && (
            <Box>
               <Box>
                  <Typography sx={{ fontFamily: 'Poppins', fontSize: 16, fontWeight: 600 }}>Log in</Typography>
               </Box>
               <Box sx={{ m: 2, py: 3, backgroundColor: '#F1F1F1', borderRadius: 2 }}>
                  <Box
                     sx={{
                        mb: 1,
                        mx: 5,
                        pb: 1,
                        // borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiButton-root': { width: '15ch' },
                        '& .MuiTextField-root': { width: '50ch' },
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <Box sx={{ mb: 2 }}>
                        <Typography sx={{ fontFamily: 'Poppins', whiteSpace: 'pre-line', fontSize: 14 }}>
                           (Your mobile number is your user id. {'\n'} आपका मोबाइल नंबर ही आपका यूजर आईडी है|)
                        </Typography>
                     </Box>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handlePhone4Login(e)
                           }}
                           value={loginCreds.phone}
                           name="phone"
                           id="phone"
                           label="Mobile Phone Number"
                           variant="outlined"
                           margin="dense"
                           required
                           fullWidth
                           inputProps={{ maxLength: 10 }}
                           size="small"
                           // helperText="10 digit phone number*."
                           sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           // onChange={(event) => setPassword(event.target.value)}
                           onChange={(e) => {
                              handleLoginCreds(e)
                           }}
                           value={loginCreds.password}
                           name="password"
                           id="password"
                           label="Password"
                           type="password"
                           variant="outlined"
                           margin="dense"
                           align="center"
                           fullWidth
                           required
                           sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                           onKeyDown={handleKeyDown}
                        ></TextField>
                     </div>
                     <Box>
                        {phoneIsNotRegistered && (
                           <Alert variant="outlined" severity="warning">
                              The entered phone number is not registered.
                           </Alert>
                        )}
                        {error && (
                           <Alert variant="outlined" severity="error">
                              The entered password did not match.
                           </Alert>
                        )}
                     </Box>
                     <Box sx={{ mt: 1 }}>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="success"
                           onClick={(e) => {
                              handleLogin(e)
                           }}
                           disabled={
                              isNaN(loginCreds.phone) ||
                              loginCreds.phone.toString().length < 10 ||
                              loginCreds.password.length < 8
                           }
                        >
                           LogIn
                        </Button>

                        {/* {error && <div className={styles.error}>{error}</div>} */}
                     </Box>
                  </Box>

                  <Box
                     sx={{
                        mx: 5,
                        p: 0,
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                     }}
                  >
                     <Button
                        type="submit"
                        align="center"
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                           handlePasswordReset(e)
                        }}
                     >
                        Forgot Password?
                     </Button>
                  </Box>
               </Box>
            </Box>
         )}
      </Box>
   )
}

Login.propTypes = {
   isUserLoggedIn: PropTypes.string.isRequired,
   handleIsUserLoggedIn: PropTypes.func.isRequired,
   userProfile: PropTypes.object.isRequired,
}

export default Login
