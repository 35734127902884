import React, { useEffect, useState } from 'react'

import { Box, Card, CardContent, CardMedia, Grid, Tabs, Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { TabContext } from '@mui/lab'
import SwipeableViews from 'react-swipeable-views'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import PPartiesNational from './PPartiesNational'
import PPartiesState from './PPartiesState'
import PPartiesUnrecognised from './PPartiesUnrecognised'
import PPartiesUnRegistered from './PPartiesUnRegistered'
import PPartiesAlliances from './PPartiesAlliances'

function PParties() {
   const { countryName, countryId } = useParams()
   const docId = 'PoliticalParties-' + countryId
   console.log('parameters from link - countryId: ', countryId, 'Name: ', countryName)
   console.log('docId: ', docId)

   const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()

   const theme = useTheme()
   const [value, setValue] = useState(0)

   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [partiesBase, setPartiesBase] = useState('')
   const [fetchStatus, setFetchStatus] = useState('idle')
   const [alliancesListRoot, setAlliancesListRoot] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchParties(docId)
         fetchAlliances()
      }
   }, [])

   const fetchParties = async (docId) => {
      try {
         // const docRef = doc(db, 'BaseLists', docId)
         // const docSnap = await getDoc(docRef)

         // if (docSnap.exists()) {
         //    setPartiesBase({ ...docSnap.data(), id: docSnap.id })
         // } else {
         //    navigate('/', { replace: true })
         // }

         const q = query(collection(db, 'BaseLists', 'PoliticalParties-India', 'PartiesLists'))
         const querySnapshot = await getDocs(q)

         let pPartiesListFetched = []

         querySnapshot.forEach((doc) => {
            pPartiesListFetched = [...pPartiesListFetched, ...doc.data().Parties]
            console.log('parties from slice - for each: ', doc.data().Parties)
         })

         console.log('parties from slice - after for each: ', pPartiesListFetched)

         const pPartiesList = pPartiesListFetched.sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setPartiesBase(pPartiesList)
         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   const [countryComplete, setCountryComplete] = useState('')

   useEffect(() => {
      if (fetchStatus !== 'idle') {
         const selectedCountryComplete = countries.find((item) => item.id === countryId)
         if (selectedCountryComplete.Name !== countryName) {
            navigate('/', { replace: true })
         }
         setCountryComplete(selectedCountryComplete)
      }
   }, [partiesBase])

   const fetchAlliances = async () => {
      try {
         const list = []

         const docRef = doc(db, 'BaseLists', 'PPAlliancesBase')
         const docSnap = await getDoc(docRef)
         console.log('data: ', docSnap.data().Alliances)
         list.push(...docSnap.data().Alliances)

         setAlliancesListRoot(list)
         console.log('listtt: ', list)
      } catch (err) {
         console.log('error', err)
      }
   }

   const handleAlliancesListRootUpdate = async (newList) => {
      setAlliancesListRoot(newList)
   }

   return (
      <Box sx={{ minHeight: 600, px: 0.5 }}>
         <Card
            sx={{
               my: 1,
               py: 1,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundImage: 'linear-gradient(to right, #403b4a, #d6d6d6)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto', fontWeight: 700 }}>
                  {partiesBase && (
                     <div>
                        <Typography
                           variant="subtitle1"
                           color="#ffebcd"
                           component="div"
                           sx={{ fontWeight: 600, textAlign: 'center', fontFamily: 'Poppins' }}
                        >
                           Political Parties Of
                        </Typography>
                        <Typography
                           component="div"
                           variant="h5"
                           sx={{ fontWeight: 700, textAlign: 'center', fontFamily: 'Poppins' }}
                           color="#FFFFFF"
                        >
                           {countryName}
                        </Typography>
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ width: 151, borderRadius: 1, mr: 1 }}
               image={countryComplete.Flag}
               alt={countryComplete.Name}
            />
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`National Level \n राष्ट्र स्तरीय`} {...a11yProps(0)} />
                  <StyledTab1 label={`State Level \n राज्य स्तरीय`} {...a11yProps(1)} />
                  <StyledTab1 label={`Registered (Un-recognised) \n पंजीकृत (मान्यता-रहित)`} {...a11yProps(2)} />
                  <StyledTab1 label={`Un-Registered \n अ-पंजीकृत`} {...a11yProps(3)} />
                  <StyledTab1 label={`Alliances \n गठबंधन`} {...a11yProps(4)} />
               </Tabs>
            </div>
            <Grid container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid item xs={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                        id="swipeableee"
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesNational props={partiesBase} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesState props={partiesBase} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesUnrecognised props={partiesBase} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesUnRegistered props={partiesBase} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           <PPartiesAlliances
                              alliancesListRoot={alliancesListRoot}
                              handleAlliancesListRootUpdate={handleAlliancesListRootUpdate}
                           />
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid>
               <Grid item xs={4} sx={{ backgroundColor: '#FFFFFF' }}></Grid>
            </Grid>
         </TabContext>
      </Box>
   )
}

export default PParties
