import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Link, Modal, Table, TableCell, TableRow, Typography } from '@mui/material'

import myStyles from './PPartyBasicInfo.module.css'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { v4 } from 'uuid'

// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import EditIcon from '@mui/icons-material/Edit'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
// import dayjs from 'dayjs'
import AddWeblink from '../../../Components/Edits/AddWeblink'
import AddImage from '../../../Components/Edits/AddImage'
import AddAddress from '../../../Components/Edits/AddAddress'
import AddText from '../../../Components/Edits/AddText'
import AddTextAndWeblink from '../../../Components/Edits/AddTextAndWeblink'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import AddECIStatus from './AddECIStatus'
import AddTextRaw from '../../../Components/Edits/AddTextRaw'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'

function PPartyBasicInfo({ pParty, handlePPartyDetails }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // const [partyLevels, setPartyLevels] = useState([])
   // const [eciStatusText, setECIStatusText] = useState('')
   // const [fetchStatus, setFetchStatus] = useState('idle')
   const [sourceSymbol, setSourceSymbol] = useState(pParty.Symbol)
   const [sourceFlag, setSourceFlag] = useState(pParty.Flag)
   const [sourceLogo, setSourceLogo] = useState(pParty.Logo)

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const partyRef = doc(db, 'PoliticalParties', pParty.id)

            await updateDoc(partyRef, {
               Name: textDetails.Text,
               NameOld: arrayUnion({
                  Name: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const baseListRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
            await updateDoc(baseListRef, {
               Parties: arrayRemove({
                  Party_id: pParty.id,
                  Name: pParty.Name,
                  NameHindi: pParty.NameHindi,
                  NameLocal: pParty.NameLocal,
                  Abbreviation: pParty.Abbreviation,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            await updateDoc(baseListRef, {
               Parties: arrayUnion({
                  Party_id: pParty.id,
                  Name: textDetails.Text,
                  NameHindi: pParty.NameHindi,
                  NameLocal: pParty.NameLocal,
                  Abbreviation: pParty.Abbreviation,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            // update the name display
            pParty.Name = textDetails.Text
            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const partyRef = doc(db, 'PoliticalParties', pParty.id)

            await updateDoc(partyRef, {
               NameHindi: textDetails.Text,
               NameHindiOld: arrayUnion({
                  NameHindi: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const baseListRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
            await updateDoc(baseListRef, {
               Parties: arrayRemove({
                  Party_id: pParty.id,
                  Name: pParty.Name,
                  NameHindi: pParty.NameHindi,
                  NameLocal: pParty.NameLocal,
                  Abbreviation: pParty.Abbreviation,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            await updateDoc(baseListRef, {
               Parties: arrayUnion({
                  Party_id: pParty.id,
                  Name: pParty.Name,
                  NameHindi: textDetails.Text,
                  NameLocal: pParty.NameLocal,
                  Abbreviation: pParty.Abbreviation,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            // update the name display
            pParty.NameHindi = textDetails.Text
            // close the modal
            setOpenModalNameHindi(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const partyRef = doc(db, 'PoliticalParties', pParty.id)

            await updateDoc(partyRef, {
               NameLocal: textDetails.Text,
               NameLocalOld: arrayUnion({
                  NameLocal: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const baseListRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
            await updateDoc(baseListRef, {
               Parties: arrayRemove({
                  Party_id: pParty.id,
                  Name: pParty.Name,
                  NameHindi: pParty.NameHindi,
                  NameLocal: pParty.NameLocal,
                  Abbreviation: pParty.Abbreviation,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            await updateDoc(baseListRef, {
               Parties: arrayUnion({
                  Party_id: pParty.id,
                  Name: pParty.Name,
                  NameHindi: pParty.NameHindi,
                  NameLocal: textDetails.Text,
                  Abbreviation: pParty.Abbreviation,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            // update the name display
            pParty.NameLocal = textDetails.Text
            // close the modal
            setOpenModalNameLocal(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Abbreviation modal

   const [openModalAbbreviation, setOpenModalAbbreviation] = useState(false)

   const handleOpenModalAbbreviation = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAbbreviation(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAbbreviation = () => setOpenModalAbbreviation(false)

   const handleAddAbbreviation = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const partyRef = doc(db, 'PoliticalParties', pParty.id)

            await updateDoc(partyRef, {
               Abbreviation: textDetails.Text,
               AbbreviationOld: arrayUnion({
                  Abbreviation: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const baseListRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
            await updateDoc(baseListRef, {
               Parties: arrayRemove({
                  Party_id: pParty.id,
                  Name: pParty.Name,
                  NameHindi: pParty.NameHindi,
                  NameLocal: pParty.NameLocal,
                  Abbreviation: pParty.Abbreviation,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            await updateDoc(baseListRef, {
               Parties: arrayUnion({
                  Party_id: pParty.id,
                  Name: pParty.Name,
                  NameHindi: pParty.NameHindi,
                  NameLocal: pParty.NameLocal,
                  Abbreviation: textDetails.Text,
                  ECIStatus: pParty.ECIStatus,
                  Active: pParty.Active,
                  Flag: pParty.Flag,
                  Symbol: pParty.Symbol,
               }),
            })

            // update the name display
            pParty.Abbreviation = textDetails.Text
            pParty.AbbreviationOld.push({
               Abbreviation: textDetails.Text,
               CrBy: auth.currentUser.uid,
               CrDt: new Date(),
               CrIP: ip.data.ip,
            })
            // close the modal
            setOpenModalAbbreviation(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Other Name modal

   const [openModalNameAlt, setOpenModalNameAlt] = useState(false)

   const handleOpenModalNameAlt = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameAlt(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameAlt = () => setOpenModalNameAlt(false)

   const handleAddNameAlt = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const partyRef = doc(db, 'PoliticalParties', pParty.id)

            await updateDoc(partyRef, {
               NameAlt: arrayUnion(textDetails.Text),
               NameAltOld: arrayUnion({
                  NameAlt: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            // await addDoc(collection(db, `PoliticalParties/${pParty.id}/OldNameAlts`), textDetails)

            // update the nameAlt display
            pParty.NameAlt.push(textDetails.Text)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to ECI Status modal

   const [openModalECIStatus, setOpenModalECIStatus] = useState(false)

   const handleOpenModalECIStatus = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalECIStatus(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalECIStatus = () => setOpenModalECIStatus(false)

   const handleAddECIStatus = async (eciStatusDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (eciStatusDetails.Level === 'State level' && eciStatusDetails.State_ids === '') {
               return
            }
            if (
               eciStatusDetails.ValueRadio.length >= 5 &&
               eciStatusDetails.ECIStatus.length >= 3 &&
               eciStatusDetails.ECIStatusDate !== ''
            ) {
               const detailsForRecord = {
                  ECIStatus: eciStatusDetails.ECIStatus,
                  ECIStatusDate: new Date(eciStatusDetails.ECIStatusDate),
                  State_ids: eciStatusDetails.State_ids,
                  StateNames: eciStatusDetails.StateNames,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }

               const partyRef = doc(db, 'PoliticalParties', pParty.id)

               if (eciStatusDetails.ValueRadio === 'Current status') {
                  ///
                  await updateDoc(partyRef, {
                     ECIStatus: eciStatusDetails.ECIStatus,
                     ECIStatusDate: new Date(eciStatusDetails.ECIStatusDate),
                     State_ids: eciStatusDetails.State_ids,
                     StateNames: eciStatusDetails.StateNames,
                     ECIStatusOld: arrayUnion(detailsForRecord),
                  })

                  // update the base list
                  const baseListRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
                  await updateDoc(baseListRef, {
                     Parties: arrayRemove({
                        Party_id: pParty.id,
                        Name: pParty.Name,
                        NameHindi: pParty.NameHindi,
                        NameLocal: pParty.NameLocal,
                        Abbreviation: pParty.Abbreviation,
                        ECIStatus: pParty.ECIStatus,
                        Active: pParty.Active,
                        Flag: pParty.Flag,
                        Symbol: pParty.Symbol,
                     }),
                  })

                  await updateDoc(baseListRef, {
                     Parties: arrayUnion({
                        Party_id: pParty.id,
                        Name: pParty.Name,
                        NameHindi: pParty.NameHindi,
                        NameLocal: pParty.NameLocal,
                        Abbreviation: pParty.Abbreviation,
                        ECIStatus: eciStatusDetails.ECIStatus,
                        Active: pParty.Active,
                        Flag: pParty.Flag,
                        Symbol: pParty.Symbol,
                     }),
                  })

                  pParty.ECIStatus = eciStatusDetails.ECIStatus
                  pParty.ECIStatusDate = eciStatusDetails.ECIStatusDate
                  pParty.State_ids = eciStatusDetails.State_ids
                  pParty.StateNames = eciStatusDetails.StateNames
               } else {
                  await updateDoc(partyRef, {
                     ECIStatusOld: arrayUnion(detailsForRecord),
                  })
               }

               pParty.ECIStatusOld.push(detailsForRecord)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Headquarter modal

   const [openModalHeadquarter, setOpenModalHeadquarter] = useState(false)

   const handleOpenModalHeadquarter = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalHeadquarter(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalHeadquarter = () => setOpenModalHeadquarter(false)

   const handleAddHeadquarter = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               addressDetails.State_id.length >= 3 &&
               addressDetails.District_id.length >= 3 &&
               addressDetails.PinCode.length >= 4 &&
               addressDetails.Address.length >= 10 &&
               addressDetails.RecordedDate !== '' &&
               addressDetails.ValueRadio !== ''
            ) {
               ///
               addressDetails.CrBy = auth.currentUser.uid
               addressDetails.CrDt = new Date()
               addressDetails.CrIP = ip.data.ip

               const partyRef = doc(db, 'PoliticalParties', pParty.id)

               if (addressDetails.ValueRadio === 'Current address') {
                  ///
                  await updateDoc(partyRef, {
                     Headquarter: addressDetails,
                     HeadquarterOld: arrayUnion(addressDetails),
                  })
                  // update the address display
                  pParty.Headquarter.Address = addressDetails.Address
                  pParty.Headquarter.DistrictName = addressDetails.DistrictName
                  pParty.Headquarter.StateName = addressDetails.StateName
                  pParty.Headquarter.PinCode = addressDetails.PinCode
               } else {
                  await updateDoc(partyRef, {
                     HeadquarterOld: arrayUnion(addressDetails),
                  })
               }

               // close modal
               setOpenModalHeadquarter(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to logo modal
   const [openModalLogo, setOpenModalLogo] = useState(false)

   const handleOpenModalLogo = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalLogo(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalLogo = () => setOpenModalLogo(false)

   const handleAddImageLogo = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `PoliticalParties/${pParty.id}/Party/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then((url) => {
                  //
                  const forRecord = {
                     Logo: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const partyRef = doc(db, 'PoliticalParties', pParty.id)

                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     updateDoc(partyRef, {
                        Logo: url,
                        LogoOld: arrayUnion(forRecord),
                     })

                     setSourceLogo(url)

                     // update the pParty
                     pParty.Logo = url
                  } else {
                     updateDoc(partyRef, {
                        LogoOld: arrayUnion(forRecord),
                     })

                     // update the pParty
                     pParty.LogoOld.push(url)
                  }
               })
            })

            // close modal
            setOpenModalLogo(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to flag modal
   const [openModalFlag, setOpenModalFlag] = useState(false)

   const handleOpenModalFlag = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalFlag(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalFlag = () => setOpenModalFlag(false)

   const handleAddImageFlag = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `PoliticalParties/${pParty.id}/Party/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forRecord = {
                     Flag: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const partyRef = doc(db, 'PoliticalParties', pParty.id)

                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     updateDoc(partyRef, {
                        Flag: url,
                        FlagOld: arrayUnion(forRecord),
                     })

                     // update the base list
                     const baseListRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
                     await updateDoc(baseListRef, {
                        Parties: arrayRemove({
                           Party_id: pParty.id,
                           Name: pParty.Name,
                           NameHindi: pParty.NameHindi,
                           NameLocal: pParty.NameLocal,
                           Abbreviation: pParty.Abbreviation,
                           ECIStatus: pParty.ECIStatus,
                           Active: pParty.Active,
                           Flag: pParty.Flag,
                           Symbol: pParty.Symbol,
                        }),
                     })

                     await updateDoc(baseListRef, {
                        Parties: arrayUnion({
                           Party_id: pParty.id,
                           Name: pParty.Name,
                           NameHindi: pParty.NameHindi,
                           NameLocal: pParty.NameLocal,
                           Abbreviation: pParty.Abbreviation,
                           ECIStatus: pParty.ECIStatus,
                           Active: pParty.Active,
                           Flag: url,
                           Symbol: pParty.Symbol,
                        }),
                     })

                     setSourceFlag(url)

                     // update the pParty
                     pParty.Flag = url
                  } else {
                     updateDoc(partyRef, {
                        FlagOld: arrayUnion(forRecord),
                     })

                     // update the pParty
                     pParty.FlagOld.push(url)
                  }
               })
            })

            // close modal
            setOpenModalFlag(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to symbol modal
   const [openModalSymbol, setOpenModalSymbol] = useState(false)

   const handleOpenModalSymbol = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalSymbol(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalSymbol = () => setOpenModalSymbol(false)

   const handleAddImageSymbol = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `PoliticalParties/${pParty.id}/Party/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forRecord = {
                     Symbol: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const partyRef = doc(db, 'PoliticalParties', pParty.id)

                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     updateDoc(partyRef, {
                        Symbol: url,
                        SymbolOld: arrayUnion(forRecord),
                     })

                     // update the base list
                     const baseListRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
                     await updateDoc(baseListRef, {
                        Parties: arrayRemove({
                           Party_id: pParty.id,
                           Name: pParty.Name,
                           NameHindi: pParty.NameHindi,
                           NameLocal: pParty.NameLocal,
                           Abbreviation: pParty.Abbreviation,
                           ECIStatus: pParty.ECIStatus,
                           Active: pParty.Active,
                           Flag: pParty.Flag,
                           Symbol: pParty.Symbol,
                        }),
                     })

                     await updateDoc(baseListRef, {
                        Parties: arrayUnion({
                           Party_id: pParty.id,
                           Name: pParty.Name,
                           NameHindi: pParty.NameHindi,
                           NameLocal: pParty.NameLocal,
                           Abbreviation: pParty.Abbreviation,
                           ECIStatus: pParty.ECIStatus,
                           Active: pParty.Active,
                           Flag: pParty.Flag,
                           Symbol: url,
                        }),
                     })

                     setSourceSymbol(url)

                     // update the pParty
                     pParty.Symbol = url
                  } else {
                     updateDoc(partyRef, {
                        SymbolOld: arrayUnion(forRecord),
                     })

                     // update the pParty
                     pParty.SymbolOld.push(url)
                  }
               })
            })

            // close modal
            setOpenModalSymbol(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            const partyRef = doc(db, 'PoliticalParties', pParty.id)
            await updateDoc(partyRef, {
               Websites: arrayUnion(weblinkDetails.Weblink),
               WebsitesOld: arrayUnion(weblinkDetails),
            })

            // update the website display
            pParty.Websites.push(weblinkDetails.Weblink)

            // close modal
            setOpenModalWebsite(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to newspaper modal
   const [openModalNewspaper, setOpenModalNewspaper] = useState(false)

   const handleOpenModalNewspaper = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNewspaper(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalNewspaper = () => setOpenModalNewspaper(false)

   const handleAddNewspaper = async (textAndWeblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            // update doc PoliticalParties
            textAndWeblinkDetails.CrBy = auth.currentUser.uid
            textAndWeblinkDetails.CrDt = new Date()
            textAndWeblinkDetails.CrIP = ip.data.ip

            const partyRef = doc(db, 'PoliticalParties', pParty.id)
            updateDoc(partyRef, {
               Newspapers: arrayUnion(textAndWeblinkDetails),
               NewspapersOld: arrayUnion(textAndWeblinkDetails),
            })

            // update newspaper display
            pParty.Newspapers.push(textAndWeblinkDetails)

            // close modal
            // setOpenModalNewspaper(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Thinktank modal

   const [openModalThinktank, setOpenModalThinktank] = useState(false)

   const handleOpenModalThinktank = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalThinktank(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalThinktank = () => setOpenModalThinktank(false)

   const handleAddThinktank = async (textAndWeblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            // update doc PoliticalParties

            textAndWeblinkDetails.CrBy = auth.currentUser.uid
            textAndWeblinkDetails.CrDt = new Date()
            textAndWeblinkDetails.CrIP = ip.data.ip

            const partyRef = doc(db, 'PoliticalParties', pParty.id)
            updateDoc(partyRef, {
               Thinktanks: arrayUnion(textAndWeblinkDetails),
               ThinktanksOld: arrayUnion(textAndWeblinkDetails),
            })

            // update newspaper display
            pParty.Thinktanks.push(textAndWeblinkDetails)

            // close modal
            setOpenModalThinktank(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div>
            <Box
               sx={{
                  display: 'flex',
                  borderRadius: 1,
                  flexDirection: 'column',
                  px: 2,
               }}
            >
               <Table>
                  <tbody>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                              Name:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{pParty.Name}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editName"
                                       onClick={(e) => {
                                          handleOpenModalName(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalName}
                                    onClose={handleCloseModalName}
                                    aria-labelledby="modal-modal-title-name"
                                    aria-describedby="modal-modal-description-name"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-name"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Change name
                                       </Typography>
                                       <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                              Other name:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{pParty.NameAlt.join(', ')}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameAlt"
                                       onClick={(e) => {
                                          handleOpenModalNameAlt(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameAlt}
                                    onClose={handleCloseModalNameAlt}
                                    aria-labelledby="modal-modal-title-othername"
                                    aria-describedby="modal-modal-description-othername"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-othername"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add other name/s
                                       </Typography>
                                       <AddText handleAddText={handleAddNameAlt} labelName="Other name"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                              Name in hindi:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{pParty.NameHindi}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameHindi"
                                       onClick={(e) => {
                                          handleOpenModalNameHindi(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameHindi}
                                    onClose={handleCloseModalNameHindi}
                                    aria-labelledby="modal-modal-title-Hindiname"
                                    aria-describedby="modal-modal-description-Hindiname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-hindiname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in hindi
                                       </Typography>
                                       <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                              Name in local language:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{pParty.NameLocal}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameLocal"
                                       onClick={(e) => {
                                          handleOpenModalNameLocal(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameLocal}
                                    onClose={handleCloseModalNameLocal}
                                    aria-labelledby="modal-modal-title-localname"
                                    aria-describedby="modal-modal-description-localname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-localname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in local language
                                       </Typography>
                                       <AddText
                                          handleAddText={handleAddNameLocal}
                                          labelName="Name (in local language)"
                                       ></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                              Abbreviation:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{pParty.Abbreviation}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editAbbreviation"
                                       onClick={(e) => {
                                          handleOpenModalAbbreviation(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalAbbreviation}
                                    onClose={handleCloseModalAbbreviation}
                                    aria-labelledby="modal-modal-title-abbreviation"
                                    aria-describedby="modal-modal-description-abbreviation"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-abbreviation"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add Abbreviation
                                       </Typography>
                                       <AddTextRaw
                                          handleAddTextRaw={handleAddAbbreviation}
                                          labelName="Abbreviation"
                                       ></AddTextRaw>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                           ECI Status:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 <Box>{pParty.ECIStatus}</Box>
                                 {pParty.ECIStatusDate !== '' && (
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                       <Box
                                          sx={{
                                             mr: 1,
                                             color: 'GrayText',
                                             fontSize: 12,
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'flex-end',
                                          }}
                                       >
                                          Notified:
                                       </Box>
                                       <Box>{moment(pParty.ECIStatusDate.toDate()).format('Do MMMM YYYY')}</Box>
                                    </Box>
                                 )}
                                 {pParty.StateNames && pParty.StateNames.length > 0 && (
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                       <Box
                                          sx={{
                                             mr: 1,
                                             color: 'GrayText',
                                             fontSize: 12,
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'flex-end',
                                          }}
                                       >
                                          States:{' '}
                                       </Box>
                                       <Box>{pParty.StateNames.join(', ')}</Box>
                                    </Box>
                                 )}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editECIStatus"
                                    onClick={(e) => {
                                       handleOpenModalECIStatus(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalECIStatus}
                                 onClose={handleCloseModalECIStatus}
                                 aria-labelledby="modal-modal-title-eciStatus"
                                 aria-describedby="modal-modal-description-eciStatus"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-eciStatus"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add ECI Status
                                    </Typography>
                                    <AddECIStatus
                                       countryId={pParty.Country_id}
                                       handleAddECIStatus={handleAddECIStatus}
                                    ></AddECIStatus>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                           Headquarter:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {pParty.Headquarter && (
                                 <Box>
                                    <div>{pParty.Headquarter.Address}</div>
                                    <span>
                                       {pParty.Headquarter.DistrictName} - {pParty.Headquarter.StateName} -{' '}
                                       {pParty.Headquarter.PinCode}
                                    </span>
                                 </Box>
                              )}

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editHeadquarter"
                                    onClick={(e) => {
                                       handleOpenModalHeadquarter(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalHeadquarter}
                                 onClose={handleCloseModalHeadquarter}
                                 aria-labelledby="modal-modal-title-headquarter"
                                 aria-describedby="modal-modal-description-headquarter"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-headquarter"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Headquarter
                                    </Typography>
                                    <AddAddress
                                       countryId={pParty.Country_id}
                                       handleAddAddress={handleAddHeadquarter}
                                    ></AddAddress>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                           Logo:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>{sourceLogo !== '' ? <img src={sourceLogo} className={myStyles.imgX} /> : null}</Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editLogo"
                                    onClick={(e) => {
                                       handleOpenModalLogo(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalLogo}
                                 onClose={handleCloseModalLogo}
                                 aria-labelledby="modal-modal-title-logo"
                                 aria-describedby="modal-modal-description-logo"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-logo"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add image of logo
                                    </Typography>
                                    <AddImage handleAddImage={handleAddImageLogo}></AddImage>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                              Flag:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                 }}
                              >
                                 <Box>
                                    {sourceFlag !== null ? <img src={sourceFlag} className={myStyles.imgX} /> : null}
                                 </Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editFlag"
                                       onClick={(e) => {
                                          handleOpenModalFlag(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalFlag}
                                    onClose={handleCloseModalFlag}
                                    aria-labelledby="modal-modal-title-flag"
                                    aria-describedby="modal-modal-description-flag"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-flag"
                                          variant="h6"
                                          component="h2"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add image of flag
                                       </Typography>
                                       <AddImage handleAddImage={handleAddImageFlag}></AddImage>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                           Symbol:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {sourceSymbol !== null ? <img src={sourceSymbol} className={myStyles.imgX} /> : null}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editSymbol"
                                    onClick={(e) => {
                                       handleOpenModalSymbol(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalSymbol}
                                 onClose={handleCloseModalSymbol}
                                 aria-labelledby="modal-modal-title-symbol"
                                 aria-describedby="modal-modal-description-symbol"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-symbol"
                                       variant="h6"
                                       component="h6"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add image of symbol
                                    </Typography>
                                    <AddImage handleAddImage={handleAddImageSymbol}></AddImage>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                           Websites:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {pParty.Websites &&
                                    pParty.Websites.length > 0 &&
                                    pParty.Websites.map((item, index) => (
                                       <div key={index}>
                                          {item &&
                                             (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                                <div>
                                                   <a href={item} target="_blank" rel="noopener noreferrer">
                                                      {item}
                                                   </a>
                                                </div>
                                             )}
                                          {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                             <div>
                                                <Link
                                                   underline="hover"
                                                   href={`//${item}`}
                                                   rel="noreferrer"
                                                   target="_blank"
                                                >
                                                   {item}
                                                </Link>
                                             </div>
                                          )}
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editWebsite"
                                    onClick={(e) => {
                                       handleOpenModalWebsite(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalWebsite}
                                 onClose={handleCloseModalWebsite}
                                 aria-labelledby="modal-modal-title-website"
                                 aria-describedby="modal-modal-description-website"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-website"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add a website
                                    </Typography>
                                    <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                           Newspapers:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {pParty.Newspapers &&
                                    pParty.Newspapers.length > 0 &&
                                    pParty.Newspapers.map((item, index) => (
                                       <div key={index}>
                                          {item.Weblink &&
                                             (item.Weblink.indexOf('http://') === 0 ||
                                                item.Weblink.indexOf('https://') === 0) && (
                                                <div>
                                                   <a
                                                      href={item.Weblink}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className={myStyles.hover}
                                                   >
                                                      {item.Text}
                                                   </a>
                                                </div>
                                             )}
                                          {item.Weblink &&
                                             item.Weblink.indexOf('http://') !== 0 &&
                                             item.Weblink.indexOf('https://') !== 0 && (
                                                <div>
                                                   <Link
                                                      href={`//${item.Weblink}`}
                                                      underline="hover"
                                                      rel="noreferrer"
                                                      target="_blank"
                                                   >
                                                      {item.Text}
                                                   </Link>
                                                </div>
                                             )}
                                          {!item.Weblink && <div>{item.Text}</div>}
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editNewspaper"
                                    onClick={(e) => {
                                       handleOpenModalNewspaper(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalNewspaper}
                                 onClose={handleCloseModalNewspaper}
                                 aria-labelledby="modal-modal-title-newspaper"
                                 aria-describedby="modal-modal-description-newspaper"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-newspaper"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add a newspaper
                                    </Typography>
                                    <AddTextAndWeblink
                                       handleAddTextAndWeblink={handleAddNewspaper}
                                       labelName="Name"
                                    ></AddTextAndWeblink>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} align="right" variant="head">
                           Thinktank:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {pParty.Thinktanks &&
                                    pParty.Thinktanks.length > 0 &&
                                    pParty.Thinktanks.map((item, index) => (
                                       <div key={index}>
                                          {item.Weblink &&
                                             (item.Weblink.indexOf('http://') === 0 ||
                                                item.Weblink.indexOf('https://') === 0) && (
                                                <div>
                                                   <a
                                                      href={item.Weblink}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className={myStyles.hover}
                                                   >
                                                      {item.Text}
                                                   </a>
                                                </div>
                                             )}
                                          {item.Weblink &&
                                             item.Weblink.indexOf('http://') !== 0 &&
                                             item.Weblink.indexOf('https://') !== 0 && (
                                                <div>
                                                   <Link
                                                      href={`//${item.Weblink}`}
                                                      underline="hover"
                                                      rel="noreferrer"
                                                      target="_blank"
                                                   >
                                                      {item.Text}
                                                   </Link>
                                                </div>
                                             )}
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editThinktank"
                                    onClick={(e) => {
                                       handleOpenModalThinktank(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalThinktank}
                                 onClose={handleCloseModalThinktank}
                                 aria-labelledby="modal-modal-title-thinkTank"
                                 aria-describedby="modal-modal-description-thinkTank"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-thinkTank"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add a thinkTank
                                    </Typography>
                                    <AddTextAndWeblink
                                       handleAddTextAndWeblink={handleAddThinktank}
                                       labelName="Name"
                                    ></AddTextAndWeblink>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  </tbody>
               </Table>
            </Box>
         </div>
      </LocalizationProvider>
   )
}

PPartyBasicInfo.propTypes = {
   pParty: PropTypes.object.isRequired,
   handlePPartyDetails: PropTypes.func.isRequired,
}

export default PPartyBasicInfo
