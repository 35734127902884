import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemText,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Typography,
} from '@mui/material'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {
   addDoc,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import { selectUsers, setUserProfile } from '../../redux/reducers/users-slice'
import { useDispatch, useSelector } from 'react-redux'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { useNavigate } from 'react-router-dom'
import { selectPParties } from '../../redux/reducers/pParties-slice'

function CreateNetaProfile({ isUserLoggedIn, userProfile }) {
   let fullName = ''
   let fullNameHindi = ''
   let fullNameLocal = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast
      if (userProfile.NameHindiFirst) {
         fullNameHindi = userProfile.NameHindiMiddle
            ? userProfile.NameHindiFirst + ' ' + userProfile.NameHindiMiddle + ' ' + userProfile.NameHindiLast
            : userProfile.NameHindiFirst + ' ' + userProfile.NameHindiLast
      }
      if (userProfile.NameLocalFirst) {
         fullNameLocal = userProfile.NameLocalMiddle
            ? userProfile.NameLocalFirst + ' ' + userProfile.NameLocalMiddle + ' ' + userProfile.NameLocalLast
            : userProfile.NameLocalFirst + ' ' + userProfile.NameLocalLast
      }
   }

   const user = useSelector(selectUsers)
   const ip = user.ip
   const pParties = useSelector(selectPParties)

   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [thisUser, setThisUser] = useState('')
   const [netaProfile, setNetaProfile] = useState('')
   const [netaConstituencies, setNetaConstituencies] = useState('')
   const [partiesList, setPartiesList] = useState([])
   console.log(thisUser)

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         if (isUserLoggedIn && userProfile) {
            if (navigator.onLine) {
               setThisUser(userProfile.User_id)
               fetchCountry()
               fetchLegisCentral()
               fetchNetaProfile()

               setPartiesList(pParties.pPartiesList)
               setNetaDetails({
                  Candidate_id: '',
                  Name: fullName,
                  NameOld: [fullName],
                  NameHindi: fullNameHindi,
                  NameHindiOld: [fullNameHindi],
                  NameLocal: fullNameLocal,
                  NameLocalOld: [fullNameLocal],
                  NameOthers: [],
                  NameOthersOld: [],
                  NameOthersHindi: [],
                  NameOthersHindiOld: [],
                  NameOthersLocal: [],
                  NameOthersLocalOld: [],
                  Gender: userProfile.Gender,
                  GenderOld: [],
                  DoB: userProfile.DoB,
                  DoBOld: [],
                  Caste: '',
                  CasteOld: [],
                  Religion: '',
                  ReligionOld: [],
                  ReservationCat: '',
                  ReservationCatOld: [],
                  Address: [],
                  AddressOld: [],
                  AddressOffice: [],
                  AddressOfficeOld: [],
                  Party: '',
                  PartiesOld: [],
                  Image: {
                     Image: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrBy: auth.currentUser.uid,
                     CrIP: ip.data.ip,
                  },
                  Phones: [],
                  PhonesOld: [],
                  WebLinks: [],
                  WebLinksOld: [],
                  Emails: [],
                  EmailsOld: [],
                  Constituencies: [],
                  Helpers: [],
                  IsActive: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
                  User_id: userProfile.User_id,
                  UserProfile_id: userProfile.id,
                  IsBlocked: false,
                  IsSuspended: false,
                  IsSubscriptionExpired: false,
                  Parking: 'Owned',
               })

               setSelectedConstisCentral([])
               setSelectedConstisState([])

               setFirstLoadStatus('success')
            }
         }
      }
   }, [])

   // for Neta Profile

   const fetchNetaProfile = async () => {
      try {
         // fetch neta profile
         console.log('fetchNetaProfile - stage 1', userProfile, 'id: ', userProfile.id)
         const querryProfile = query(collection(db, 'NetaProfiles'), where('UserProfile_id', '==', userProfile.id))
         const querySnapshot = await getDocs(querryProfile)
         console.log('fetchNetaProfile - stage 2', querySnapshot)
         if (querySnapshot) {
            let itemFetched = ''

            console.log('fetchNetaProfile - stage 3')
            querySnapshot.forEach((docSnap) => {
               itemFetched = { id: docSnap.id, ...docSnap.data() }
            })
            console.log('fetchNetaProfile: itemFetched: ', itemFetched)
            setNetaProfile(itemFetched)

            // fetch core list subcollection of neta profile for attached constituencies
            const docRefNetaProfileSubCollectionConstituencies = doc(
               db,
               'NetaProfiles',
               itemFetched.id,
               'CoreLists',
               'Constituencies-001',
            )
            const docSnapNetaProfileSubCollectionConstituencies = await getDoc(
               docRefNetaProfileSubCollectionConstituencies,
            )
            if (docSnapNetaProfileSubCollectionConstituencies.exists()) {
               setNetaConstituencies(docSnapNetaProfileSubCollectionConstituencies.data().Constituencies)
               console.log('Constituencies: ', docSnapNetaProfileSubCollectionConstituencies.data().Constituencies)
            } else {
               setNetaConstituencies([])
            }
         } else {
            console.log('fetchNetaProfile - no query snapshot')
         }
      } catch (error) {
         console.log('error fetching neta profile: ', error)
      }
   }

   useEffect(() => {
      if (netaConstituencies && netaConstituencies.length > 0) {
         // set list of attached constis at central level
         const listCentral = netaConstituencies.filter(
            (item) => item.Strata === 'Central' && item.ConstituencyType === 'Public' && item.IsActive === true,
         )
         const sortedListCentral = [...listCentral].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setAttachedConstisCentral(sortedListCentral)
         // set list of attache constis at state level
         const listState = netaConstituencies.filter(
            (item) => item.Strata === 'State' && item.ConstituencyType === 'Public' && item.IsActive === true,
         )
         const sortedListState = [...listState].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setAttachedConstisState(sortedListState)
      } else {
         setAttachedConstisCentral([])
         setAttachedConstisState([])
      }
   }, [netaConstituencies])

   // for country and stateslist

   const [country, setCountry] = useState('')
   const [statesList, setStatesList] = useState('')

   const fetchCountry = async () => {
      try {
         const docRef = doc(db, 'Countries', 'lsTDGRF8XHWnR3VjVuB4')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            setCountry(docSnap.data())
            console.log('data as such: ', docSnap.data())

            const sortedListStates = [...docSnap.data().States].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setStatesList(sortedListStates)
            console.log('sortedListStates: ', sortedListStates)
         } else {
            // docSnap.data() will be undefined in this case
            console.log('Country - No such document!')
         }
      } catch (error) {
         //
      }
   }

   console.log('country: ', country)

   // for constituencies of central legislature

   const [legisCentral, setLegisCentral] = useState('')

   const fetchLegisCentral = async () => {
      try {
         const querryUser = query(
            collection(db, 'Legislatures'),
            where('Type', '==', 'Legislative'),
            where('Strata', '==', 'Central'),
            where('HasConstituencies', '==', 'true'),
         )
         const querySnapshot = await getDocs(querryUser)
         let legisFetched = ''
         querySnapshot.forEach((docSnap) => {
            legisFetched = { id: docSnap.id, ...docSnap.data() }
         })
         console.log('central legis fetched in create neta: ', legisFetched)
         setLegisCentral(legisFetched)
      } catch (error) {
         console.error('error fetching user profile', error)
      }
   }

   const [constisListCentral, setConstisListCentral] = useState('')

   useEffect(() => {
      if (legisCentral !== '') {
         //
         const sortedList = [...legisCentral.Constituencies].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setConstisListCentral(sortedList)
      }
   }, [legisCentral])

   console.log('constisListCentral: ', constisListCentral)

   // for constis list central filtered by state id

   const [selectedState, setSelectedState] = useState('')

   const handleStateChange = (e) => {
      if (userProfile.NetaProfile !== '') {
         if (e.target.value !== '') {
            e.preventDefault()
            setSelectedState(e.target.value)
         } else {
            setConstisListCentralFiltered([])
         }
      }
   }

   const [constisListCentralFitered, setConstisListCentralFiltered] = useState('')

   useEffect(() => {
      if (selectedState !== '') {
         //
         const listFiltered = constisListCentral.filter((item) => item.State_id === selectedState)

         setConstisListCentralFiltered(listFiltered)
      }
   }, [selectedState])

   // for state legis on selected state
   const [selectedState2, setSelectedState2] = useState('')

   const handleStateChange2 = (e) => {
      e.preventDefault()
      if (userProfile.NetaProfile !== '') {
         if (e.target.value !== '') {
            setSelectedState2(e.target.value)
            fetchLegisState(e.target.value)
         } else {
            setConstisListState([])
         }
      }
   }

   const [legisState, setLegisState] = useState('')

   const fetchLegisState = async (stateId) => {
      try {
         const querryUser = query(
            collection(db, 'Legislatures'),
            where('State_id', '==', stateId),
            where('Type', '==', 'Legislative'),
            where('Strata', '==', 'State'),
            where('HasConstituencies', '==', 'true'),
         )
         const querySnapshot = await getDocs(querryUser)
         let legisFetched = ''
         querySnapshot.forEach((docSnap) => {
            legisFetched = { id: docSnap.id, ...docSnap.data() }
         })
         console.log('state legis fetched in create neta: ', legisFetched)
         setLegisState(legisFetched)
      } catch (error) {
         console.error('error fetching user profile', error)
      }
   }

   const [constisListState, setConstisListState] = useState('')

   useEffect(() => {
      if (legisState !== '') {
         //
         const sortedList = [...legisState.Constituencies].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setConstisListState(sortedList)
      }
   }, [legisState])

   console.log('constisListState: ', constisListState)

   // for declaring as leader

   const [selectedPoliticalAffiliationType, setSelectedPoliticalAffiliationType] = useState('')
   const [enablePartiesList, setEnablePartiesList] = useState(false)
   const [selectedPartyId, setSelectedPartyId] = useState('')

   const [netaDetails, setNetaDetails] = useState('')

   function handlePoliticalAffiliationType(e) {
      e.preventDefault()

      setSelectedPoliticalAffiliationType(e.target.value)
      console.log('selected type value: ', e.target.value)

      setSelectedPartyId('')
      setEnablePartiesList(false)
      setNetaDetails({
         ...netaDetails,
         Party: {
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
         },
      })

      if (e.target.value === 'PoliticalParty') {
         setEnablePartiesList(true)
      } else {
         setEnablePartiesList(false)
         setSelectedPartyId('')
         setNetaDetails({
            ...netaDetails,
            Party: {
               Party_id: '',
               PartyName: 'Independent',
               PartyNameHindi: 'निर्दलीय',
               PartyNameLocal: '',
               PartyAbbreviation: '',
               PartyFlag: '',
               PartySymbol: '',
            },
         })
      }
   }

   function handleChangeParty(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedPartyId(e.target.value)

         const party = partiesList.find((item) => item.Party_id === e.target.value)
         console.log('party flag', party.Flag)
         console.log('party name', party.Name)
         setNetaDetails({
            ...netaDetails,
            Party: {
               Party_id: party.Party_id,
               PartyName: party.Name,
               PartyNameHindi: party.NameHindi,
               PartyNameLocal: party.NameLocal,
               PartyAbbreviation: party.Abbreviation,
               PartyFlag: party.Flag,
               PartySymbol: party.Symbol,
            },
         })
      } else {
         setSelectedPartyId('')
         setNetaDetails({
            ...netaDetails,
            Party: {
               Party_id: '',
               PartyName: '',
               PartyNameHindi: '',
               PartyNameLocal: '',
               PartyAbbreviation: '',
               PartyFlag: '',
               PartySymbol: '',
            },
         })
      }
   }

   const handleCreateNetaProfile = async () => {
      if (thisUser === auth.currentUser.uid && !userProfile.NetaProfile) {
         console.log('List as leader - stage 1')
         if (
            selectedPoliticalAffiliationType === 'Independent' ||
            (selectedPoliticalAffiliationType === 'PoliticalParty' && selectedPartyId !== '')
         ) {
            console.log('List as leader - stage 2')
            try {
               // Re-check if neta profile for this user exists
               const querryProfile = query(collection(db, 'NetaProfiles'), where('User_id', '==', userProfile.id))
               const querySnapshot = await getDocs(querryProfile)
               if (querySnapshot.exists) {
                  return
               } else {
                  // 1. Create a leader profile doc

                  const docRef = await addDoc(collection(db, 'NetaProfiles'), netaDetails)
                  // record.id = docRef.id
                  console.log('List as leader - stage 3')

                  // 2. Update the user profile with new neta profile doc id
                  const docRefUser = doc(db, 'UserProfiles', userProfile.id)
                  await updateDoc(docRefUser, {
                     NetaProfile: docRef.id,
                     NetaProfileActive: true,
                  })

                  // 3. update the local state
                  handleUserProfile(userProfile.User_id)
                  fetchNetaProfile()
               }
            } catch (error) {
               console.log('error creating Neta profile: ', error)
            }
         }
         console.log('List as leader - stage L')
      }
   }

   const handleUserProfile = async (userId) => {
      try {
         const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', userId))
         const querySnapshot = await getDocs(querryUser)
         let userFetched = ''
         querySnapshot.forEach((docSnap) => {
            userFetched = { id: docSnap.id, ...docSnap.data() }
         })
         console.log('user profile from bio js: ', userFetched)
         dispatch(setUserProfile(userFetched))
      } catch (error) {
         console.error('error fetching user profile', error)
      }
   }

   // for attaching central consti

   const [attachedConstisCentral, setAttachedConstisCentral] = useState('')
   const [selectedConstisCentral, setSelectedConstisCentral] = useState('')

   const handleSelectConstiCentral = async (e, item) => {
      e.preventDefault()

      if (
         auth.currentUser &&
         thisUser === auth.currentUser.uid &&
         userProfile.NetaProfile !== '' &&
         userProfile.NetaProfileActive &&
         netaProfile !== '' &&
         attachedConstisCentral !== ''
      ) {
         try {
            // tally with already selected constis at central level
            console.log('handleSelectConstiCentral - Stage 1')
            let presentItemInSelectedList = ''
            if (selectedConstisCentral && selectedConstisCentral.length > 0) {
               const xxx = selectedConstisCentral.find(
                  (itemSelected) => itemSelected.Constituency_id === item.Constituency_id,
               )
               console.log('handleSelectConstiCentral - Stage 1 - xxx', xxx)
               if (xxx) {
                  presentItemInSelectedList = xxx
               }
            }
            // tally with already attached constis at central level
            let presentItemInAttachedList = ''
            if (attachedConstisCentral && attachedConstisCentral.length > 0) {
               const yyy = attachedConstisCentral.find(
                  (itemAttached) => itemAttached.Constituency_id === item.Constituency_id,
               )
               console.log('handleSelectConstiCentral - Stage 1 - yyy', yyy)
               if (yyy) {
                  presentItemInAttachedList = yyy
               }
            }
            // finally if both draw a blank, add the consti to selected list
            if (presentItemInSelectedList === '' && presentItemInAttachedList === '') {
               const list = [...selectedConstisCentral]
               list.push(item)
               setSelectedConstisCentral(list)
               console.log('handleSelectConstiCentral - Stage 1 - list', list)
            } else {
               console.log('handleSelectConstiCentral - Stage 1 - list blank')
            }
         } catch (error) {
            alert('Error selecting consti Central')
            console.log('Error selecting consti Central: ', error)
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleAttachConstiCentral = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         try {
            // 1. Create object of Neta for Constituency and object of Constituency for Neta profile
            const netaObj = {
               NetaProfile_id: netaProfile.id,
               Candidate_id: '',
               Party: netaProfile.Party,
               Name: fullName,
               NameHindi: fullNameHindi,
               NameLocal: fullNameLocal,
               Image: netaProfile.Image.Image,
               DoB: netaProfile.DoB,
               Gender: netaProfile.Gender,
               Caste: netaProfile.Caste,
               Religion: netaProfile.Religion,
               IsActive: true,
               DateAttached: new Date(),
               DateDetached: '',
               CoreListId: 'Netas-000001',
               CrBy: auth.currentUser.uid,
               CrIP: ip.data.ip,
               ModBy: '',
               ModIP: '',
            }

            console.log('Central netaObj: ', netaObj)

            const constiObj = {
               ...item,
               Strata: 'Central',
               ConstituencyType: 'Public',
               IsActive: true,
               DateAttached: new Date(),
               DateDetached: '',
               CoreListId: 'Constituencies-001',
               CrBy: auth.currentUser.uid,
               CrIP: ip.data.ip,
               ModBy: '',
               ModIP: '',
            }
            console.log('Central constiObj: ', constiObj)

            // 2. fetch references of subcolections in Neta Profile and Constituency
            const docRefNetaProfilesSubCollection = doc(
               db,
               'NetaProfiles',
               userProfile.NetaProfile,
               'CoreLists',
               'Constituencies-001',
            )
            const docRefConstituenciesSubCollection = doc(
               db,
               'LegisConstituencies',
               item.Constituency_id,
               'CoreLists',
               'Netas-000001',
            )

            const docSnapNetaProfilesSubCollection = await getDoc(docRefNetaProfilesSubCollection)
            const docSnapConstituenciesSubCollection = await getDoc(docRefConstituenciesSubCollection)

            // 3. Check if the user is already attached and active in this constituency.
            // To do so, first see if the subcollection inside Neta Profile exists.
            if (docSnapNetaProfilesSubCollection.exists()) {
               // 3.1 means the user has at some point of time attached himsef to some constituency
               // So, now, find if the selected constituency is present and active
               const list = docSnapNetaProfilesSubCollection
                  .data()
                  .Constituencies.filter(
                     (thisItem) => thisItem.Constituency_id === item.Constituency_id && thisItem.IsActive === true,
                  )

               if (list && list.length > 0) {
                  // 3.1.1 it means such item is present. Simply ignore and come out
                  console.log('3.1.1')
                  return
               } else {
                  // 3.1.2 it means such item is not present.
                  // So proceed to attach

                  // 3.1.2.1 attach constituency object to neta profile
                  // remember, the subcollection already exists
                  console.log('3.1.2.1')
                  await updateDoc(docRefNetaProfilesSubCollection, {
                     Constituencies: arrayUnion(constiObj),
                  })

                  // 3.1.2.2 attach neta object to constituency profile
                  if (docSnapConstituenciesSubCollection.exists()) {
                     console.log('3.1.2.2 - update start')
                     await updateDoc(docRefConstituenciesSubCollection, {
                        Netas: arrayUnion(netaObj),
                     })
                     console.log('3.1.2.2 - update end')
                  } else {
                     console.log('3.1.2.2 - set start')
                     setDoc(docRefConstituenciesSubCollection, { Netas: [netaObj] }, { merge: true })
                     console.log('3.1.2.2 - set end')
                  }
               }
            } else {
               // 3.2  means the user is not attached to any constituency at all
               // so proceed to attach

               // 3.2.1 attach constituency object to neta profile
               console.log('3.2.1 - set start')
               setDoc(docRefNetaProfilesSubCollection, { Constituencies: [constiObj] }, { merge: true })
               console.log('3.2.1 - set end')

               // 3.2.2 attach neta object to constituency
               if (docSnapConstituenciesSubCollection.exists()) {
                  console.log('3.2.2 - update start')
                  await updateDoc(docRefConstituenciesSubCollection, {
                     Netas: arrayUnion(netaObj),
                  })
                  console.log('3.2.2 - update end')
               } else {
                  console.log('3.2.2 - set start')
                  setDoc(docRefConstituenciesSubCollection, { Netas: [netaObj] }, { merge: true })
                  console.log('3.2.2 - set end')
               }
            }

            // 3. Update local state
            const list = [...attachedConstisCentral]
            list.push(constiObj)
            setAttachedConstisCentral(list)

            const listSelectedConstis = selectedConstisCentral.filter(
               (thisItem) => thisItem.Constituency_id !== item.Constituency_id,
            )
            setSelectedConstisCentral(listSelectedConstis)
         } catch (error) {
            alert('Error attaching consti central')
            console.log('Error attaching consti central', error)
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // for attaching state constis
   const [attachedConstisState, setAttachedConstisState] = useState('')
   const [selectedConstisState, setSelectedConstisState] = useState('')

   const handleSelectConstiState = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid && userProfile.NetaProfile !== '') {
         try {
            // tally with already selected constis at State level
            let presentItemInSelectedList = ''
            if (selectedConstisState && selectedConstisState.length > 0) {
               const xxx = selectedConstisState.find(
                  (itemSelected) => itemSelected.Constituency_id === item.Constituency_id,
               )
               if (xxx) {
                  presentItemInSelectedList = xxx
               }
            }
            // tally with already attached constis at State level
            let presentItemInAttachedList = ''
            if (attachedConstisState && attachedConstisState.length > 0) {
               const yyy = attachedConstisState.find(
                  (itemAttached) => itemAttached.Constituency_id === item.Constituency_id,
               )
               if (yyy) {
                  presentItemInAttachedList = yyy
               }
            }
            // finally if both draw a blank, add the consti to selected list
            if (presentItemInSelectedList === '' && presentItemInAttachedList === '') {
               const list = [...selectedConstisState]
               list.push(item)
               setSelectedConstisState(list)
            }
         } catch (error) {
            alert('Error selecting consti State')
            console.log('Error selecting consti State: ', error)
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleAttachConstiState = async (e, item) => {
      e.preventDefault()
      if (
         auth.currentUser &&
         thisUser === auth.currentUser.uid &&
         userProfile.NetaProfile !== '' &&
         userProfile.NetaProfileActive &&
         netaProfile !== '' &&
         attachedConstisState !== ''
      ) {
         try {
            // 1. Create object of Neta for Constituency and object of Constituency for Neta profile
            const netaObj = {
               NetaProfile_id: netaProfile.id,
               Candidate_id: '',
               Party: netaProfile.Party,
               Name: fullName,
               NameHindi: fullNameHindi,
               NameLocal: fullNameLocal,
               Image: netaProfile.Image.Image,
               DoB: netaProfile.DoB,
               IsActive: true,
               DateAttached: new Date(),
               DateDetached: '',
               CoreListId: 'Netas-000001',
               CrBy: auth.currentUser.uid,
               CrIP: ip.data.ip,
               ModBy: '',
               ModIP: '',
            }

            console.log('State netaObj: ', netaObj)

            const constiObj = {
               ...item,
               Strata: 'State',
               ConstituencyType: 'Public',
               IsActive: true,
               DateAttached: new Date(),
               DateDetached: '',
               CoreListId: 'Constituencies-001',
               CrBy: auth.currentUser.uid,
               CrIP: ip.data.ip,
               ModBy: '',
               ModIP: '',
            }
            console.log('State constiObj: ', constiObj)

            // 2. fetch references of subcolections in Neta Profile and Constituency
            const docRefNetaProfilesSubCollection = doc(
               db,
               'NetaProfiles',
               userProfile.NetaProfile,
               'CoreLists',
               'Constituencies-001',
            )
            const docRefConstituenciesSubCollection = doc(
               db,
               'LegisConstituencies',
               item.Constituency_id,
               'CoreLists',
               'Netas-000001',
            )

            const docSnapNetaProfilesSubCollection = await getDoc(docRefNetaProfilesSubCollection)
            const docSnapConstituenciesSubCollection = await getDoc(docRefConstituenciesSubCollection)

            // 3. Check if the user is already attached and active in this constituency.
            // To do so, first see if the subcollection inside Neta Profile exists.
            if (docSnapNetaProfilesSubCollection.exists()) {
               // 3.1 means the user has at some point of time attached himsef to some constituency
               // So, now, find if the selected constituency is present and active
               const list = docSnapNetaProfilesSubCollection
                  .data()
                  .Constituencies.filter(
                     (thisItem) => thisItem.Constituency_id === item.Constituency_id && thisItem.IsActive === true,
                  )

               if (list && list.length > 0) {
                  // 3.1.1 it means such item is present. Simply ignore and come out
                  console.log('State - 3.1.1')
                  return
               } else {
                  // 3.1.2 it means such item is not present.
                  // So proceed to attach

                  // 3.1.2.1 attach constituency object to neta profile
                  // remember, the subcollection already exists
                  console.log('State - 3.1.2.1')
                  await updateDoc(docRefNetaProfilesSubCollection, {
                     Constituencies: arrayUnion(constiObj),
                  })

                  // 3.1.2.2 attach neta object to constituency profile
                  if (docSnapConstituenciesSubCollection.exists()) {
                     console.log('State - 3.1.2.2 - update start')
                     await updateDoc(docRefConstituenciesSubCollection, {
                        Netas: arrayUnion(netaObj),
                     })
                     console.log('State - 3.1.2.2 - update end')
                  } else {
                     console.log('State - 3.1.2.2 - set start')
                     setDoc(docRefConstituenciesSubCollection, { Netas: [netaObj] }, { merge: true })
                     console.log('State - 3.1.2.2 - set end')
                  }
               }
            } else {
               // 3.2  means the user is not attached to any constituency at all
               // so proceed to attach

               // 3.2.1 attach constituency object to neta profile
               console.log('State - 3.2.1 - set start')
               setDoc(docRefNetaProfilesSubCollection, { Constituencies: [constiObj] }, { merge: true })
               console.log('State - 3.2.1 - set end')

               // 3.2.2 attach neta object to constituency
               if (docSnapConstituenciesSubCollection.exists()) {
                  console.log('State - 3.2.2 - update start')
                  await updateDoc(docRefConstituenciesSubCollection, {
                     Netas: arrayUnion(netaObj),
                  })
                  console.log('State - 3.2.2 - update end')
               } else {
                  console.log('State - 3.2.2 - set start')
                  setDoc(docRefConstituenciesSubCollection, { Netas: [netaObj] }, { merge: true })
                  console.log('State - 3.2.2 - set end')
               }
            }

            // 3. Update local state
            const list = [...attachedConstisState]
            list.push(constiObj)
            setAttachedConstisState(list)

            const listSelectedConstis = selectedConstisState.filter(
               (thisItem) => thisItem.Constituency_id !== item.Constituency_id,
            )
            setSelectedConstisState(listSelectedConstis)
         } catch (error) {
            alert('Error attaching consti State')
            console.log('Error attaching consti State', error)
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ minHeight: 500 }}>
            {!navigator.onLine && (
               <div>
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle>
                        You are offline !
                        <CloudOffIcon sx={{ ml: 5 }} />
                     </AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === '' && (
               <div>
                  <Alert variant="outlined" severity="warning" sx={{ margin: 5 }}>
                     <AlertTitle>... Loading !</AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === 'out' && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{
                        margin: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >
                     <AlertTitle>Please log in! {'\n'} कृपया लॉगिन करें!</AlertTitle>
                     You are not logged in the site. {'\n'} आप साइट पर लॉग इन नहीं हैं.
                  </Alert>
               </Box>
            )}
            {navigator.onLine && isUserLoggedIn === 'in' && (
               <Box sx={{ m: 1 }}>
                  <Box sx={{ fontWeight: 600, textAlign: 'center', mb: 1 }}>Welcome ... {fullName}!</Box>
                  <Box>
                     {auth.currentUser && userProfile && !userProfile.NetaProfile && (
                        <Box>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'space-between',
                                 backgroundColor: '#F1F1F1',
                                 py: 0.5,
                                 px: 2,
                                 m: 2,
                                 borderRadius: 2,
                              }}
                           >
                              <Box sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                                 Currently you do not hold a leader profile. {'\n'} वर्तमान में आपका कोई नेता प्रोफ़ाइल
                                 नहीं है|
                              </Box>
                              <Box></Box>
                           </Box>
                           <Box
                              sx={{
                                 m: 2,
                                 borderRadius: 2,
                                 border: 1,
                                 overflow: 'hidden',
                                 backgroundColor: '#F1F1F1',
                                 borderColor: '#3a6186',
                                 '& .MuiButton-root': { width: '25ch', mt: 1 },
                                 '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
                                 // '& .MuiInputBase-root': { mb: 2 },
                                 '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box
                                 sx={{
                                    backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                                    color: '#FFFFFF',
                                    whiteSpace: 'pre-line',
                                    mb: 1,
                                 }}
                              >
                                 <Typography
                                    sx={{
                                       backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                                       color: '#FFFFFF',
                                       whiteSpace: 'pre-line',
                                    }}
                                 >
                                    To create a leader profile, select a Political Affiliation Type: {'\n'} नेता
                                    प्रोफ़ाइल बनाने के लिए राजनीतिक संबद्धता प्रकार चुनें:
                                 </Typography>
                              </Box>
                              <FormControl
                                 sx={{
                                    py: 0,
                                    px: 1,
                                 }}
                              >
                                 {/* <FormLabel id="demo-controlled-radio-buttons-group">
                                    To register as aleader, select a Political Affiliation Type:
                                 </FormLabel> */}
                                 <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="PoliticalAffiliationType"
                                    value={selectedPoliticalAffiliationType}
                                    // style={{ display: 'flex', gap: '2rem' }}
                                    onChange={(e) => {
                                       handlePoliticalAffiliationType(e)
                                    }}
                                    sx={{
                                       borderRadius: 1,
                                       py: 0,
                                       '& .MuiSvgIcon-root': {
                                          fontSize: 15,
                                       },
                                       '& .MuiButtonBase-root': {
                                          py: 0,
                                       },
                                       '& MuiFormControlLabel-label': {
                                          fontSize: 13,
                                          lineHeight: 16,
                                          py: 0,
                                       },
                                    }}
                                 >
                                    <FormControlLabel
                                       value="Independent"
                                       control={<Radio size="small" />}
                                       label="Independent"
                                       sx={{ py: 0, fontSize: 15 }}
                                    />
                                    <FormControlLabel
                                       value="PoliticalParty"
                                       control={<Radio size="small" />}
                                       label="Political Party"
                                       sx={{ py: 0, fontSize: 15 }}
                                    />
                                 </RadioGroup>
                              </FormControl>
                              <FormControl sx={{ m: 1 }} size="small" disabled={!enablePartiesList}>
                                 <InputLabel>Party</InputLabel>
                                 <Select
                                    value={selectedPartyId}
                                    label="Party"
                                    onChange={(e) => {
                                       handleChangeParty(e)
                                    }}
                                    sx={{ backgroundColor: '#ffffff' }}
                                 >
                                    <MenuItem value="">
                                       <em>None</em>
                                    </MenuItem>
                                    {partiesList &&
                                       partiesList.map((item) => (
                                          <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                                             {item.Name} - <strong> {item.Abbreviation}</strong>
                                          </MenuItem>
                                       ))}
                                 </Select>
                              </FormControl>
                              <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', m: 1 }}>
                                 <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ whiteSpace: 'pre-line', lineHeight: 1.3, px: 1, py: 0.5, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleCreateNetaProfile(e)
                                    }}
                                    disabled={
                                       selectedPoliticalAffiliationType === '' ||
                                       (selectedPoliticalAffiliationType === 'PoliticalParty' && selectedPartyId === '')
                                    }
                                 >
                                    Create Leader Profile {'\n'} लीडर प्रोफ़ाइल बनाएं
                                 </Button>
                              </Box>
                           </Box>
                        </Box>
                     )}
                     {auth.currentUser && userProfile && userProfile.NetaProfile && !userProfile.NetaProfileActive && (
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              backgroundColor: '#F1F1F1',
                              py: 0.5,
                              px: 2,
                              m: 2,
                              borderRadius: 2,
                           }}
                        >
                           <Box sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                              Currently you hold a leader profile but it is not active. {'\n'} वर्तमान में आपके पास नेता
                              प्रोफ़ाइल है लेकिन यह सक्रिय नहीं है|
                           </Box>
                           <Box>
                              <Button variant="contained" sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}>
                                 Activate {'\n'} सक्रिय करें
                              </Button>
                           </Box>
                        </Box>
                     )}
                     {userProfile && userProfile.NetaProfile && userProfile.NetaProfileActive && (
                        <Box
                           sx={{
                              m: 2,
                              borderRadius: 2,
                              overflow: 'Hidden',
                              border: 1,
                              borderColor: '#3a6186',
                           }}
                        >
                           <Box
                              sx={{
                                 // backgroundColor: '#F1F1F1',
                                 p: 0.5,
                                 color: '#3a6186',
                                 display: 'flex',
                                 flexDirection: 'row',
                                 fontWeight: 600,
                                 whiteSpace: 'pre-line',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box sx={{ mr: 2 }}>Your profile as leader: {'\n'} नेता के रूप में आपकी प्रोफ़ाइल:</Box>
                              <Box>
                                 <Link
                                    href={`/leader/${fullName}/${userProfile.NetaProfile}`}
                                    sx={{
                                       textDecoration: 'none !important',
                                    }}
                                    target="_blank"
                                    rel="noopener"
                                 >
                                    https://trackdemocracy.com/leader/{fullName}/{userProfile.NetaProfile}
                                 </Link>
                              </Box>
                           </Box>
                           <Box
                              sx={{
                                 whiteSpace: 'pre-line',
                                 // backgroundColor: '#F1F1F1',
                                 p: 0.5,
                                 mt: 0.5,
                                 color: 'Green',
                              }}
                           >
                              Please proceed to attach constituencies (as many as you want) where you want to be a
                              leader: {'\n'} कृपया उन निर्वाचन क्षेत्रों (जितने चाहें) को संलग्न करें जहां आप नेता बनना
                              चाहते हैं:
                           </Box>
                        </Box>
                     )}

                     <Box
                        sx={{
                           m: 2,
                           borderRadius: 2,
                           border: 1,
                           overflow: 'hidden',
                           backgroundColor: '#F1F1F1',
                           borderColor: '#3a6186',
                        }}
                     >
                        <Typography
                           sx={{
                              backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                              color: '#FFFFFF',
                              whiteSpace: 'pre-line',
                           }}
                        >
                           Parliamentary Constituencies: {'\n'} लोकसभा निर्वाचन क्षेत्र:
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', m: 1, justifyContent: 'space-between' }}>
                           <Box sx={{ width: '49%' }}>
                              <FormControl sx={{ m: 1, width: '80%' }} size="small">
                                 <InputLabel>Choose State / UT</InputLabel>
                                 <Select
                                    id="xxx"
                                    value={selectedState}
                                    label="Choose State / UT"
                                    onChange={(e) => {
                                       handleStateChange(e)
                                    }}
                                    sx={{ padding: 0, backgroundColor: '#ffffff' }}
                                    disabled={
                                       !auth.currentUser ||
                                       (auth.currentUser && !userProfile.NetaProfile) ||
                                       (auth.currentUser &&
                                          userProfile.NetaProfile &&
                                          !userProfile.NetaProfileActive) ||
                                       (auth.currentUser &&
                                          userProfile.NetaProfile &&
                                          userProfile.NetaProfileActive &&
                                          !netaProfile) ||
                                       (auth.currentUser &&
                                          userProfile.NetaProfile &&
                                          userProfile.NetaProfileActive &&
                                          netaProfile &&
                                          !attachedConstisCentral)
                                    }
                                 >
                                    <MenuItem value="">
                                       <em>None</em>
                                    </MenuItem>
                                    {statesList &&
                                       statesList.length > 0 &&
                                       statesList.map((item) => (
                                          <MenuItem key={item.State_id} value={item.State_id}>
                                             {item.Name}
                                          </MenuItem>
                                       ))}
                                 </Select>
                              </FormControl>
                              {selectedState !== '' &&
                                 constisListCentralFitered &&
                                 constisListCentralFitered.length > 0 && (
                                    <Box
                                       sx={{ border: 0.5, borderColor: '#3a6186', overflow: 'hidden', borderRadius: 1 }}
                                    >
                                       <Box
                                          sx={{
                                             minHeight: 250,
                                             maxHeight: 250,
                                             //   backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
                                             backgroundColor: '#FFFFFF',
                                             overflowY: 'auto',
                                             scrollbarWidth: 'thin',
                                             borderRadius: 1,
                                             '&::-webkit-scrollbar': {
                                                width: '0.4em',
                                             },
                                             '&::-webkit-scrollbar-track': {
                                                background: '#f1f1f1',
                                             },
                                             '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#888',
                                             },
                                             '&::-webkit-scrollbar-thumb:hover': {
                                                background: '#555',
                                             },
                                          }}
                                       >
                                          <List dense>
                                             {constisListCentralFitered.map((item, index) => (
                                                <Box key={item.Constituency_id}>
                                                   <ListItem
                                                      key={item.Constituency_id}
                                                      sx={{ px: 0.5, py: 0 }}
                                                      secondaryAction={
                                                         <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={(e) => {
                                                               handleSelectConstiCentral(e, item)
                                                            }}
                                                         >
                                                            <DoubleArrowIcon />
                                                         </IconButton>
                                                      }
                                                   >
                                                      <ListItemText
                                                         sx={{ m: 0.5 }}
                                                         primaryTypographyProps={{ fontSize: 12 }}
                                                         secondaryTypographyProps={{
                                                            align: 'left',
                                                            fontSize: 12,
                                                            whiteSpace: 'pre-line',
                                                         }}
                                                         primary={`${item.Name} (${item.ConstituencyNumber})`}
                                                         secondary={`${item.NameLocal} \n ${item.NameHindi}`}
                                                      />
                                                   </ListItem>
                                                   <Divider />
                                                </Box>
                                             ))}
                                          </List>
                                       </Box>
                                    </Box>
                                 )}
                           </Box>
                           <Box sx={{ width: '49%' }}>
                              <Box sx={{ textAlign: 'center', fontWeight: 600, mb: 1 }}>Selected constituencies:</Box>
                              {selectedConstisCentral && selectedConstisCentral.length > 0 && (
                                 <Box sx={{ border: 0.5, borderColor: '#3a6186', overflow: 'hidden', borderRadius: 1 }}>
                                    <Box
                                       sx={{
                                          maxHeight: 250,
                                          mb: 0.5,
                                          backgroundColor: '#FFFFFF',
                                          overflowY: 'auto',
                                          scrollbarWidth: 'thin',
                                          borderRadius: 1,
                                          '&::-webkit-scrollbar': {
                                             width: '0.4em',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                             background: '#f1f1f1',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                             backgroundColor: '#888',
                                          },
                                          '&::-webkit-scrollbar-thumb:hover': {
                                             background: '#555',
                                          },
                                       }}
                                    >
                                       <List dense>
                                          {selectedConstisCentral.map((item, index) => (
                                             <Box key={item.Constituency_id}>
                                                <ListItem
                                                   key={item.Constituency_id}
                                                   sx={{ px: 0.5, py: 0 }}
                                                   secondaryAction={
                                                      <Button
                                                         variant="outlined"
                                                         aria-label="delete"
                                                         endIcon={<AttachFileIcon />}
                                                         onClick={(e) => {
                                                            handleAttachConstiCentral(e, item)
                                                         }}
                                                      >
                                                         Attach
                                                      </Button>
                                                   }
                                                >
                                                   <ListItemText
                                                      sx={{ m: 0.5 }}
                                                      primaryTypographyProps={{ fontSize: 12 }}
                                                      secondaryTypographyProps={{
                                                         align: 'left',
                                                         fontSize: 12,
                                                         whiteSpace: 'pre-line',
                                                      }}
                                                      primary={`${item.Name} (${item.ConstituencyNumber})`}
                                                      secondary={`${item.NameLocal} \n ${item.NameHindi}`}
                                                   />
                                                </ListItem>
                                                <Divider />
                                             </Box>
                                          ))}
                                       </List>
                                    </Box>
                                 </Box>
                              )}
                              {!selectedConstisCentral ||
                                 (selectedConstisCentral.length < 1 && (
                                    <Alert variant="outlined" severity="warning" sx={{ backgroundColor: '#FFFFFF' }}>
                                       No new constituencies selected.
                                    </Alert>
                                 ))}
                              <Box sx={{ textAlign: 'center', fontWeight: 600, my: 1 }}>Attached constituencies:</Box>
                              {attachedConstisCentral && attachedConstisCentral.length > 0 && (
                                 <Box sx={{ border: 0.5, borderColor: '#3a6186', overflow: 'hidden', borderRadius: 1 }}>
                                    <Box
                                       sx={{
                                          maxHeight: 250,
                                          mb: 0.5,
                                          backgroundColor: '#FFFFFF',
                                          overflowY: 'auto',
                                          scrollbarWidth: 'thin',
                                          borderRadius: 1,
                                          '&::-webkit-scrollbar': {
                                             width: '0.4em',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                             background: '#f1f1f1',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                             backgroundColor: '#888',
                                          },
                                          '&::-webkit-scrollbar-thumb:hover': {
                                             background: '#555',
                                          },
                                       }}
                                    >
                                       <List dense>
                                          {attachedConstisCentral.map((item, index) => (
                                             <Box key={item.Constituency_id}>
                                                <ListItem key={item.Constituency_id} sx={{ px: 0.5, py: 0 }}>
                                                   <ListItemText
                                                      sx={{ m: 0.5 }}
                                                      // primaryTypographyProps={{ fontSize: 12 }}
                                                      secondaryTypographyProps={{
                                                         align: 'left',
                                                         whiteSpace: 'pre-line',
                                                      }}
                                                      primary={
                                                         <Link
                                                            href={`/constituency/${item.Name}/${item.Constituency_id}`}
                                                            sx={{ textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            <strong>{item.Name}</strong> ({item.ConstituencyNumber})
                                                         </Link>
                                                      }
                                                      secondary={
                                                         <>
                                                            <>{item.NameLocal && <>{item.NameLocal}</>}</>
                                                            <>{item.NameHindi && <>{item.NameHindi}</>}</>
                                                            <>State: {item.StateName}</>
                                                         </>
                                                      }
                                                   />
                                                </ListItem>
                                                <Divider />
                                             </Box>
                                          ))}
                                       </List>
                                    </Box>
                                 </Box>
                              )}
                              {!attachedConstisCentral ||
                                 (attachedConstisCentral.length < 1 && (
                                    <Alert variant="outlined" severity="warning" sx={{ backgroundColor: '#FFFFFF' }}>
                                       You have not attached yourself to any constituency at this level.
                                    </Alert>
                                 ))}
                           </Box>
                        </Box>
                     </Box>
                     <Box
                        sx={{
                           m: 2,
                           borderRadius: 2,
                           border: 1,
                           overflow: 'hidden',
                           backgroundColor: '#F1F1F1',
                           borderColor: '#3a6186',
                        }}
                     >
                        <Typography
                           sx={{
                              backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                              color: '#FFFFFF',
                              whiteSpace: 'pre-line',
                           }}
                        >
                           Legislative Assembly Constituencies: {'\n'} विधान सभा निर्वाचन क्षेत्र:
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', m: 1, justifyContent: 'space-between' }}>
                           <Box sx={{ width: '49%' }}>
                              <FormControl sx={{ m: 1, width: '80%' }} size="small">
                                 <InputLabel>Choose State / UT</InputLabel>
                                 <Select
                                    id="xxx"
                                    value={selectedState2}
                                    label="Choose State / UT"
                                    onChange={(e) => {
                                       handleStateChange2(e)
                                    }}
                                    sx={{ padding: 0, backgroundColor: '#ffffff' }}
                                    disabled={
                                       !auth.currentUser ||
                                       (auth.currentUser && !userProfile.NetaProfile) ||
                                       (auth.currentUser &&
                                          userProfile.NetaProfile &&
                                          !userProfile.NetaProfileActive) ||
                                       (auth.currentUser &&
                                          userProfile.NetaProfile &&
                                          userProfile.NetaProfileActive &&
                                          !netaProfile) ||
                                       (auth.currentUser &&
                                          userProfile.NetaProfile &&
                                          userProfile.NetaProfileActive &&
                                          netaProfile &&
                                          !attachedConstisState)
                                    }
                                 >
                                    <MenuItem value="">
                                       <em>None</em>
                                    </MenuItem>
                                    {statesList &&
                                       statesList.length > 0 &&
                                       statesList.map((item) => (
                                          <MenuItem key={item.State_id} value={item.State_id}>
                                             {item.Name}
                                          </MenuItem>
                                       ))}
                                 </Select>
                              </FormControl>
                              {selectedState2 !== '' && constisListState && constisListState.length > 0 && (
                                 <Box sx={{ border: 0.5, borderColor: '#3a6186', overflow: 'hidden', borderRadius: 1 }}>
                                    <Box
                                       sx={{
                                          minHeight: 250,
                                          maxHeight: 250,
                                          //    backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
                                          backgroundColor: '#FFFFFF',
                                          overflowY: 'auto',
                                          scrollbarWidth: 'thin',
                                          borderRadius: 1,
                                          '&::-webkit-scrollbar': {
                                             width: '0.4em',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                             background: '#f1f1f1',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                             backgroundColor: '#888',
                                          },
                                          '&::-webkit-scrollbar-thumb:hover': {
                                             background: '#555',
                                          },
                                       }}
                                    >
                                       <List dense>
                                          {constisListState.map((item, index) => (
                                             <Box key={item.Constituency_id}>
                                                <ListItem
                                                   key={item.Constituency_id}
                                                   sx={{ px: 0.5, py: 0 }}
                                                   secondaryAction={
                                                      <IconButton
                                                         edge="end"
                                                         aria-label="delete"
                                                         onClick={(e) => {
                                                            handleSelectConstiState(e, item)
                                                         }}
                                                      >
                                                         <DoubleArrowIcon />
                                                      </IconButton>
                                                   }
                                                >
                                                   <ListItemText
                                                      sx={{ m: 0.5 }}
                                                      primaryTypographyProps={{ fontSize: 12 }}
                                                      secondaryTypographyProps={{
                                                         align: 'left',
                                                         fontSize: 12,
                                                         whiteSpace: 'pre-line',
                                                      }}
                                                      primary={`${item.Name} (${item.ConstituencyNumber})`}
                                                      secondary={`${item.NameLocal} \n ${item.NameHindi}`}
                                                   />
                                                </ListItem>
                                                <Divider />
                                             </Box>
                                          ))}
                                       </List>
                                    </Box>
                                 </Box>
                              )}
                           </Box>
                           <Box sx={{ width: '49%' }}>
                              <Box sx={{ textAlign: 'center', fontWeight: 600, mb: 1 }}>Selected constituencies:</Box>
                              {selectedConstisState && selectedConstisState.length > 0 && (
                                 <Box sx={{ border: 0.5, borderColor: '#3a6186', overflow: 'hidden', borderRadius: 1 }}>
                                    <Box
                                       sx={{
                                          maxHeight: 250,
                                          mb: 0.5,
                                          //    backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
                                          backgroundColor: '#FFFFFF',
                                          overflowY: 'auto',
                                          scrollbarWidth: 'thin',
                                          borderRadius: 1,
                                          '&::-webkit-scrollbar': {
                                             width: '0.4em',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                             background: '#f1f1f1',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                             backgroundColor: '#888',
                                          },
                                          '&::-webkit-scrollbar-thumb:hover': {
                                             background: '#555',
                                          },
                                       }}
                                    >
                                       <List dense>
                                          {selectedConstisState.map((item, index) => (
                                             <Box key={item.Constituency_id}>
                                                <ListItem
                                                   key={item.Constituency_id}
                                                   sx={{ px: 0.5, py: 0 }}
                                                   secondaryAction={
                                                      <Button
                                                         variant="outlined"
                                                         aria-label="delete"
                                                         endIcon={<AttachFileIcon />}
                                                         onClick={(e) => {
                                                            handleAttachConstiState(e, item)
                                                         }}
                                                      >
                                                         Attach
                                                      </Button>
                                                   }
                                                >
                                                   <ListItemText
                                                      sx={{ m: 0.5 }}
                                                      primaryTypographyProps={{ fontSize: 12 }}
                                                      secondaryTypographyProps={{
                                                         align: 'left',
                                                         fontSize: 12,
                                                         whiteSpace: 'pre-line',
                                                      }}
                                                      primary={`${item.Name} (${item.ConstituencyNumber})`}
                                                      secondary={`${item.NameLocal} \n ${item.NameHindi}`}
                                                   />
                                                </ListItem>
                                                <Divider />
                                             </Box>
                                          ))}
                                       </List>
                                    </Box>
                                 </Box>
                              )}
                              {!selectedConstisState ||
                                 (selectedConstisState.length < 1 && (
                                    <Alert variant="outlined" severity="warning" sx={{ backgroundColor: '#FFFFFF' }}>
                                       No new constituencies selected.
                                    </Alert>
                                 ))}
                              <Box sx={{ textAlign: 'center', fontWeight: 600, my: 1 }}>Attached constituencies:</Box>
                              {attachedConstisState && attachedConstisState.length > 0 && (
                                 <Box sx={{ border: 0.5, borderColor: '#3a6186', overflow: 'hidden', borderRadius: 1 }}>
                                    <Box
                                       sx={{
                                          maxHeight: 250,
                                          mb: 0.5,
                                          //    backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
                                          backgroundColor: '#FFFFFF',
                                          overflowY: 'auto',
                                          scrollbarWidth: 'thin',
                                          borderRadius: 1,
                                          '&::-webkit-scrollbar': {
                                             width: '0.4em',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                             background: '#f1f1f1',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                             backgroundColor: '#888',
                                          },
                                          '&::-webkit-scrollbar-thumb:hover': {
                                             background: '#555',
                                          },
                                       }}
                                    >
                                       <List dense>
                                          {attachedConstisState.map((item, index) => (
                                             <Box key={item.Constituency_id}>
                                                <ListItem
                                                   key={item.Constituency_id}
                                                   sx={{ px: 0.5, py: 0 }}
                                                   //    secondaryAction={<DoubleArrowIcon />}
                                                >
                                                   <ListItemText
                                                      sx={{ m: 0.5 }}
                                                      // primaryTypographyProps={{ fontSize: 12 }}
                                                      secondaryTypographyProps={{
                                                         align: 'left',
                                                         fontSize: 12,
                                                         whiteSpace: 'pre-line',
                                                      }}
                                                      primary={
                                                         <Link
                                                            href={`/constituency/${item.Name}/${item.Constituency_id}`}
                                                            sx={{ mr: 1, textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            <strong>{item.Name}</strong> ({item.ConstituencyNumber})
                                                         </Link>
                                                      }
                                                      secondary={
                                                         <>
                                                            <>{item.NameLocal && <>{item.NameLocal} </>}</>
                                                            <>{item.NameHindi && <>{item.NameHindi}</>}</>
                                                            <>
                                                               {' '}
                                                               <br />
                                                               State: {item.StateName}
                                                            </>
                                                         </>
                                                      }
                                                   />
                                                </ListItem>
                                                <Divider />
                                             </Box>
                                          ))}
                                       </List>
                                    </Box>
                                 </Box>
                              )}
                              {!attachedConstisState ||
                                 (attachedConstisState.length < 1 && (
                                    <Alert variant="outlined" severity="warning" sx={{ backgroundColor: '#FFFFFF' }}>
                                       You have not attached yourself to any constituency at this level.
                                    </Alert>
                                 ))}
                           </Box>
                        </Box>
                     </Box>
                  </Box>
               </Box>
            )}
         </Box>
      </LocalizationProvider>
   )
}

CreateNetaProfile.propTypes = {
   isUserLoggedIn: PropTypes.string.isRequired,
   userProfile: PropTypes.object.isRequired,
}

export default CreateNetaProfile
