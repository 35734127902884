import { createTheme, styled } from '@mui/material/styles'

import { Avatar, Slide } from '@mui/material'
import React from 'react'

export const theme = createTheme({
   palette: {
      background: {
         paper: '#fff',
      },
      text: {
         primary: '#173A5E',
         secondary: '#46505A',
      },
      action: {
         active: '#001E3C',
      },
      ochre: {
         main: '#E3D026',
         light: '#E9DB5D',
         dark: '#A29415',
         contrastText: '#242105',
      },
   },
})

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
   width: 50,
   height: 50,
   border: `0px solid ${theme.palette.background.paper}`,
}))

export const BigAvatar = styled(Avatar)(({ theme }) => ({
   width: 150,
   height: 150,
   border: `0px solid ${theme.palette.background.paper}`,
}))

function stringToColor(string) {
   let hash = 0
   let i

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
   }

   let color = '#'

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
   }
   /* eslint-enable no-bitwise */

   return color
}

export function stringAvatar(name) {
   return {
      sx: {
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar50(name) {
   return {
      sx: {
         width: 50,
         height: 50,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar50Single(name) {
   return {
      sx: {
         width: 50,
         height: 50,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
   }
}

export function stringAvatar80(name) {
   return {
      sx: {
         width: 80,
         height: 80,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
         // padding: 0.25,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar80Single(name) {
   return {
      sx: {
         width: 80,
         height: 80,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
   }
}

export function stringAvatar80Multiple(name) {
   return {
      sx: {
         width: 80,
         height: 80,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}${name.split(' ')[2][0]}${name.split(' ')[3][0]}${name.split(' ')[4][0]}${name.split(' ')[5][0]}`,
   }
}

export function stringAvatar150(name) {
   return {
      sx: {
         width: 150,
         height: 150,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar150Single(name) {
   return {
      sx: {
         width: 150,
         height: 150,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
   }
}

// style for modal
export const styleModalBox = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   bgcolor: '#ffffff',
   boxShadow: 24,
   borderRadius: 2,
   pt: 0,
   pb: 1,

   scrollbarWidth: 'thin',
   '&::-webkit-scrollbar': {
      width: '0.4em',
   },
   '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: 5,
      // margin: 5,
   },
   '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#000000',
      borderRadius: 5,
   },
   '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
   },

   maxHeight: '90%',
   overflowY: 'auto',
}

export const styleModalTypography = {
   fontSize: '14px',
   color: '#ffffff',
   backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
   mb: 2,
   mt: 2,
   py: 0.5,
   px: 1,
   whiteSpace: 'pre-line',
}

export const sxTypoGraphy2 = {
   fontWeight: 500,
   textAlign: 'center',
   fontSize: '18px',
   whiteSpace: 'pre-line',
   color: '#FFFFFF',
   borderRadius: 1,
   fontFamily: ['Poppins'],
}

export const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})
