import { Alert, AlertTitle, Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { sentenceCase } from '../../Utils/Conversions/CaseConversion'

function AddName({ handleAddName }) {
   const [nameDetails, setTextDetails] = useState({
      NameFirst: '',
      NameMiddle: '',
      NameLast: '',
   })
   const [nameError, setNameError] = useState(false)

   useEffect(() => {
      console.log('Name details on change of its value: ', nameDetails)
   }, [nameDetails])

   function handleNameDetails(event) {
      setTextDetails({ ...nameDetails, [event.target.name]: event.target.value })
   }

   function handleSubmitName(e) {
      e.preventDefault()

      if (nameDetails.NameFirst.length < 2 || nameDetails.NameLast.length < 2) {
         setNameError(true)
         return
      } else {
         setNameError(false)
         nameDetails.NameFirst = sentenceCase(nameDetails.NameFirst.trim())
         nameDetails.NameMiddle = sentenceCase(nameDetails.NameMiddle.trim())
         nameDetails.NameLast = sentenceCase(nameDetails.NameLast.trim())
      }
      handleAddName(nameDetails)

      reset()
   }

   function reset() {
      setTextDetails({
         NameFirst: '',
         NameMiddle: '',
         NameLast: '',
      })
   }

   return (
      <Box sx={{ mx: 2 }}>
         <Alert variant="outlined" severity="warning" sx={{ mb: 2 }}>
            Please use your official name strictly.
            <br />
            कृपया अपने आधिकारिक नाम का ही उपयोग करें|
         </Alert>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNameDetails(e)
               }}
               value={nameDetails.NameFirst}
               name="NameFirst"
               id="nameFirst"
               label="First Name"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               autoComplete="off"
               autoFocus={true}
               sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
               helperText="First name must be at least two letters*."
            ></TextField>
         </div>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNameDetails(e)
               }}
               value={nameDetails.NameMiddle}
               name="NameMiddle"
               id="nameMiddle"
               label="Middle Name"
               variant="outlined"
               margin="dense"
               fullWidth
               sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNameDetails(e)
               }}
               value={nameDetails.NameLast}
               name="NameLast"
               id="nameLast"
               label="Last Name"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
               helperText="Last name must be at least two letters*."
            ></TextField>
         </div>
         {nameError && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ margin: 5 }}>
                  <AlertTitle>Errors!</AlertTitle>
                  Please complete the form properly!
               </Alert>
            </Box>
         )}
         <Box sx={{ mt: 1 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               onClick={(e) => {
                  handleSubmitName(e)
               }}
               disabled={nameDetails.NameFirst.length < 2 || nameDetails.NameLast.length < 2}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

AddName.propTypes = {
   handleAddName: PropTypes.func.isRequired,
}

export default AddName
