export function dynamicSort(properties) {
   return function (a, b) {
      for (let i = 0; i < properties.length; i++) {
         const prop = properties[i]
         if (a[prop] > b[prop]) return -1
         if (a[prop] < b[prop]) return 1
      }
      return 0
   }
}

export function addMonthsToDate(date, months) {
   const newDate = new Date(date) // Create a new Date instance to avoid mutating the original date
   newDate.setMonth(newDate.getMonth() + months)
   return newDate
}
