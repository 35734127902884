import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   currentUser: null,
   ip: null,
   userProfile: null,
}

export const usersSlice = createSlice({
   name: 'users',
   initialState,
   reducers: {
      setUser: (users, action) => {
         users.currentUser = action.payload
      },
      setIP: (users, action) => {
         users.ip = action.payload
      },
      setUserProfile: (users, action) => {
         users.userProfile = action.payload
      },
   },
})

export const { setUser, setIP, setUserProfile } = usersSlice.actions

export const selectUsers = (state) => state.users
export const selectUserProfile = (state) => state.users.userProfile

export default usersSlice.reducer
