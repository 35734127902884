import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUserProfile } from '../../../redux/reducers/users-slice'
import { Alert, AlertTitle, Box, FormControl, InputLabel, Link, MenuItem, Select, Typography } from '@mui/material'

import CloudOffIcon from '@mui/icons-material/CloudOff'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'

function CreateNeta() {
   const userProfile = useSelector(selectUserProfile)

   let fullName = ''
   let fullNameHindi = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast

      fullNameHindi = userProfile.NameHindiMiddle
         ? userProfile.NameHindiFirst + ' ' + userProfile.NameHindiMiddle + ' ' + userProfile.NameHindiLast
         : userProfile.NameHindiFirst + ' ' + userProfile.NameHindiLast
   }
   console.log('full name: ', fullName)

   const [initialState, setInitialState] = useState('idle')
   useEffect(() => {
      if (initialState === 'idle') {
         try {
            fetchCountry()
            setInitialState('success')
         } catch (error) {
            //
         }
      }
   }, [])

   const [individualCountry, setIndividualCountry] = useState('')

   const fetchCountry = async () => {
      try {
         const docRef = doc(db, 'Countries', 'lsTDGRF8XHWnR3VjVuB4')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            setIndividualCountry(docSnap.data())

            const sortedListStates = [docSnap.data().States].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setStatesList(sortedListStates)
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document!')
         }
      } catch (error) {
         //
      }
   }

   console.log(individualCountry)

   const [statesList, setStatesList] = useState('')

   const [selectedState, setSelectedState] = useState('')
   const handleStateChange = (e) => {
      e.preventDefault()
      setSelectedState(e.target.value)
   }

   const [individualState, setIndividualState] = useState('')

   useEffect(() => {
      if (selectedState) {
         getIndividualState()
      }
   }, [selectedState])

   const getIndividualState = async () => {
      try {
         const docRef = doc(db, 'States', selectedState)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            setIndividualState(docSnap.data())
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document!')
         }
      } catch (error) {
         //
      }
   }

   const [stateLegisList, setStateLegisList] = useState('')
   console.log(stateLegisList)

   useEffect(() => {
      if (individualState) {
         try {
            const list = individualState.Legislatures
            const listLegislative = list.filter((item) => item.Type === 'Legislative')

            const sortedListLegis = [...listLegislative].sort((a, b) => {
               return parseInt(a.Priority) - parseInt(b.Priority)
            })
            setStateLegisList(sortedListLegis)
         } catch (error) {
            //
         }
      } else {
         setStateLegisList('')
      }
   }, [individualState])

   return (
      <Box sx={{ minHeight: 500 }}>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && !userProfile && (
            <div>
               <Alert variant="outlined" severity="warning" sx={{ margin: 5, whiteSpace: 'pre-line' }}>
                  <AlertTitle>You are not logged in! {'\n'} आपने लॉग - इन नहीं किया है!</AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && userProfile && userProfile.NetaProfile && (
            <div>
               <Alert variant="outlined" severity="warning" sx={{ margin: 5, whiteSpace: 'pre-line' }}>
                  <AlertTitle>
                     You already have a leader profile. {'\n'} आपके पास पहले से ही एक नेता प्रोफ़ाइल है|
                     <br />
                     <Link
                        href={`/leader/${userProfile.NetaProfile}`}
                        sx={{
                           textDecoration: 'none !important',
                           textTransform: 'none',
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 15,
                           color: '#FFFFFF',
                        }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        {fullNameHindi} का नेता प्रोफाइल {'\n'} Leader Profile of {fullName}
                     </Link>
                  </AlertTitle>
               </Alert>
            </div>
         )}

         {navigator.onLine && userProfile && !userProfile.NetaProfile && (
            <Box>
               <Typography sx={{ mt: 2, fontWeight: 600 }}>Welcome {fullName}</Typography>
               <Typography>You are about to craete a neta profile ...</Typography>
               <Box sx={{ m: 2, whiteSpace: 'pre-line' }}>
                  Please select at least one constituency at any level: {'\n'} कृपया किसी भी स्तर पर कम से कम एक
                  निर्वाचन क्षेत्र का चयन करें:
               </Box>
               <Box>
                  <FormControl sx={{ m: 1, width: '80%', backgroundColor: '#ffffff' }} size="small">
                     <InputLabel>Choose State / UT</InputLabel>
                     <Select
                        id="xxx"
                        value={selectedState}
                        label="Choose State / UT"
                        onChange={(e) => {
                           handleStateChange(e)
                        }}
                        sx={{ padding: 0 }}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {statesList &&
                           statesList.length > 0 &&
                           statesList.map((item) => (
                              <MenuItem key={item.State_id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Box>
               <Box
                  sx={{
                     m: 2,
                     borderRadius: 2,
                     border: 1,
                     overflow: 'hidden',
                     backgroundColor: '#F1F1F1',
                     borderColor: '#3a6186',
                  }}
               >
                  <Typography
                     sx={{
                        backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                        color: '#FFFFFF',
                        whiteSpace: 'pre-line',
                     }}
                  >
                     Parliamentary Constituencies: {'\n'} लोकसभा निर्वाचन क्षेत्र:
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', m: 1, justifyContent: 'space-between' }}>
                     <Box sx={{ width: '49%', backgroundColor: 'Red', height: 20 }}></Box>
                     <Box sx={{ width: '49%', backgroundColor: 'Green', height: 20 }}></Box>
                  </Box>
               </Box>
               <Box
                  sx={{
                     m: 2,
                     borderRadius: 2,
                     border: 1,
                     overflow: 'hidden',
                     backgroundColor: '#F1F1F1',
                     borderColor: '#3a6186',
                  }}
               >
                  <Typography
                     sx={{
                        backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                        color: '#FFFFFF',
                        whiteSpace: 'pre-line',
                     }}
                  >
                     Legislative Assembly Constituencies: {'\n'} विधान सभा निर्वाचन क्षेत्र:
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', m: 1, justifyContent: 'space-between' }}>
                     <Box sx={{ width: '49%', backgroundColor: 'Red', height: 20 }}></Box>
                     <Box sx={{ width: '49%', backgroundColor: 'Green', height: 20 }}></Box>
                  </Box>
               </Box>
            </Box>
         )}
      </Box>
   )
}

export default CreateNeta
