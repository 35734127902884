import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Button,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import AddLegisMem from './AddLegisMem'
import { auth, db } from '../../../FirebaseConfig'
import { arrayRemove, arrayUnion, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CloudOffIcon from '@mui/icons-material/CloudOff'

import styles from './LegisMems.module.css'
import { Helmet } from 'react-helmet'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import moment from 'moment'
import AddToDateAndReason from '../../../Components/Edits/AddToDateAndReason'
import HighLighter from '../../../Utils/Functions/Highlighter'

function LegisMems({ legis }) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip
   const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadToDate] = useState('idle')

   const [houseNumbersList, setHouseNumbersList] = useState([])
   const [selectedHouseNumber, setSelectedHouseNumber] = useState('')
   const [memListCore, setMemListCore] = useState('')
   const [memListCoreCount, setMemListCoreCount] = useState('')
   const [memListFiltered, setMemListFiltered] = useState('')
   const [memListFilteredHeading, setMemListFilteredHeading] = useState('')
   const [memListHeading, setMemListHeading] = useState('')
   const [memListRenderPresent, setMemListRenderPresent] = useState('')
   const [memListRenderPresentCount, setMemListRenderPresentCount] = useState(0)
   const [memListRenderPast, setMemListRenderPast] = useState('')
   const [memListRenderPastCount, setMemListRenderPastCount] = useState(0)
   const [uploadingMemListCore, setUploadingMemListCore] = useState('')

   const [stateFull, setStateFull] = useState('')
   const [districtsList, setDistrictsList] = useState([])
   const [statesList, setStatesList] = useState([])
   const [selectedState, setSelectedState] = useState({ id: '', actionable: false })
   const [selectedStateName, setSelectedStateName] = useState('')
   const [selectedConstituency, setSelectedConstituency] = useState({ id: '', actionable: false })
   const [constisListRoot, setConstisListRoot] = useState([])
   const [constisList, setConstisList] = useState([])
   const [partiesList, setPartiesList] = useState('')
   const [selectedParty, setSelectedParty] = useState({ name: '', actionable: false })
   // const [lastMember, setLastMember] = useState('')
   // const [uploading, setUploading] = useState(false)
   // const [uploaded, setUploaded] = useState(false)
   // const [uploadingFailed, setUploadingFailed] = useState(false)

   const focusRef = useRef(null)

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         console.log('Headings: ', memListHeading, ', ', memListFilteredHeading)
         try {
            console.log('legis:', legis)
            const sortedConstisList = [...legis.Constituencies].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setConstisListRoot(sortedConstisList)
            setConstisList(sortedConstisList)
            if (legis.HouseNumbers && legis.HouseNumbers.length > 0) {
               setUploadingMemListCore(true)
               const sortedHouseNumbers = legis.HouseNumbers.sort((a, b) => b - a)
               setHouseNumbersList(sortedHouseNumbers)
               console.log('sortedHouseNumbers: ', sortedHouseNumbers)
            } else {
               setUploadingMemListCore(false)
            }

            //
            switch (legis.Strata) {
               case 'Central':
                  console.log('legis mems - on load - case central')
                  fetchStatesList()
                  break
               case 'State':
                  console.log('legis mems - on load - case state')
                  fetchStateFull(legis.State_id)
                  setSelectedState({ id: legis.State_id, actionable: false })
                  break
               case 'Division':
                  //
                  setSelectedState({ id: legis.State_id, actionable: false })
                  break
               case 'District':
                  //
                  setSelectedState({ id: legis.State_id, actionable: false })
                  break
               default:
            }
         } catch (error) {
            console.log(error)
         }

         setFirstLoadToDate('success')
      }
   }, [])

   useEffect(() => {
      if (houseNumbersList && houseNumbersList.length > 0) {
         setSelectedHouseNumber(houseNumbersList[0])
         console.log('house number at start: ', houseNumbersList[0])
      }
   }, [houseNumbersList])

   const handleChangeHouseNumber = async (e) => {
      e.preventDefault()
      setSelectedHouseNumber(e.target.value)
      setUploadingMemListCore(true)
      console.log('Legis members - handle change HouseNumber')
   }

   useEffect(() => {
      if (selectedHouseNumber && selectedHouseNumber !== '') {
         // this condition is sure to be ...
         if (legis.Strata === 'Central') {
            setSelectedState({ id: '', actionable: false })
         }

         setSelectedConstituency({ id: '', actionable: false })
         setSelectedParty({ name: '', actionable: false })
         setSearchText({ text: '', actionable: false })

         const coreListId = 'MemCoreList-' + selectedHouseNumber
         fetchMemListCore(coreListId)
         console.log('coreListId: ', coreListId)
      }
   }, [selectedHouseNumber])

   const fetchMemListCore = async (coreListId) => {
      try {
         const docRef = doc(db, 'Legislatures', legis.id, 'MemCoreLists', coreListId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            const sortedList = [...docSnap.data().Members].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setMemListCore(sortedList)
            setMemListCoreCount(sortedList.length)
            setMemListFiltered(sortedList)
            // setMemListFilteredCount(sortedList.length)
            const heading = 'House number: ' + selectedHouseNumber
            setMemListFilteredHeading(heading)
            setMemListHeading('')
            const listPresent = sortedList.filter(
               (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
            )
            const listPast = sortedList.filter(
               (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
            )

            setMemListRenderPresent(listPresent)
            setMemListRenderPresentCount(listPresent.length)
            setMemListRenderPast(listPast)
            setMemListRenderPastCount(listPast.length)

            // get the list of all parties that are associated with elected members
            const parties = []
            sortedList.forEach((member) => {
               const partyObj = {
                  Party_id: member.Party_id,
                  PartyName: member.PartyName,
                  PartyNameHindi: member.PartyNameHindi,
                  PartyNameLocal: member.PartyNameLocal,
                  PartyAbbreviation: member.PartyAbbreviation,
                  PartyFlag: member.PartyFlag,
               }
               parties.push(partyObj)
            })
            // now create a list unique items in parties list
            // in the following code, we can have multiple conditions
            const partiesUnique = parties.filter(
               (ele, ind) => ind === parties.findIndex((elem) => elem.Party_id === ele.Party_id),
            )
            const sortedParties = partiesUnique.sort((a, b) => {
               if (b.PartyName.trim() > a.PartyName.trim()) {
                  return -1
               } else if (b.PartyName.trim() < a.PartyName.trim()) {
                  return 1
               } else {
                  return 0
               }
            })

            setPartiesList(sortedParties)
         }
         setUploadingMemListCore(false)
      } catch (err) {
         console.log('error', err)
         setUploadingMemListCore(false)
      }
   }

   // related to form filling and submission

   const [openModalAddMember, setOpenModalAddMember] = useState(false)

   const handleOpenModalAddMember = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAddMember(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalAddMember = () => setOpenModalAddMember(false)

   const handleAddMember = async (constiDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            ///
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this member?')) {
               try {
                  console.log('item : ', item)
                  // get ref of the subCollection 'MemListCores' inside the legislature
                  const coreListId = 'MemListCore-' + item.HouseNumber
                  const docRefLegisSubCollectionMemListCores = doc(
                     db,
                     'Legislatures',
                     item.Legislature_id,
                     'MemListCores',
                     coreListId,
                  )
                  // get ref of the subcollection 'MemListCore' inside the constituency
                  const docRefConstiSubCollectionMemListCore = doc(
                     db,
                     'LegisConstituencies',
                     item.Constituency_id,
                     'CoreLists',
                     'MemListCore',
                  )

                  // Delete the main doc in collection LegisMembers
                  await deleteDoc(doc(db, 'LegisMembers', item.LegisMem_id))

                  await updateDoc(docRefLegisSubCollectionMemListCores, {
                     Members: arrayRemove(item),
                     CandidateIdsList: arrayRemove(item.Candidate_id),
                  })

                  await updateDoc(docRefConstiSubCollectionMemListCore, {
                     Members: arrayRemove(item),
                  })
                  setMemListCore(memListCore.filter((thisItem) => thisItem.LegisMem_id !== item.LegisMem_id))
                  setMemListCoreCount(memListCoreCount - 1)
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // for states

   const fetchStateFull = async (stateId) => {
      try {
         const docRef = doc(db, 'States', stateId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setStateFull({ ...docSnap.data(), id: docSnap.id })
            setSelectedStateName(docSnap.data().Name)
         }
      } catch (err) {
         console.log('error: ', err)
      }
   }

   useEffect(() => {
      console.log('reached the use effect of stateFull outside if condition')
      if (stateFull !== '') {
         fetchDistrictsListByState()
      }
   }, [stateFull])

   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === legis.Country_id)
         console.log('selectedCountryComplete: ', selectedCountryComplete)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   function handleChangeState(e) {
      e.preventDefault()
      setSelectedState({ id: e.target.value, actionable: true })

      // make the downline search parameters empty
      setSelectedConstituency({ id: '', actionable: false })
      setSelectedParty({ name: '', actionable: false })
      setSearchText({ text: '', actionable: false })
   }

   const handleRemoveState = async (e) => {
      e.preventDefault()
      setSelectedState({ id: '', actionable: true })

      // make the downline search parameters empty
      setSelectedConstituency({ id: '', actionable: false })
      setSelectedParty({ name: '', actionable: false })
      setSearchText({ text: '', actionable: false })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (selectedState.id !== '' && selectedState.actionable === true) {
               // this condition is reached when a state is chosen

               // create the different members list according to state
               const memListByState = memListCore.filter((item) => item.State_id === selectedState.id)

               setMemListFiltered(memListByState)
               // setMemListFilteredCount(memListByState.length)

               const present = statesList.find((item) => item.State_id === selectedState.id)
               setSelectedStateName(present.Name)
               const heading = 'House Number:  ' + selectedHouseNumber + '   >   State / UT:  ' + present.Name
               setMemListFilteredHeading(heading)
               setMemListHeading('')

               const listPresent = memListByState.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = memListByState.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)

               // set the constituencies list for dropdown according to selected state
               const list = constisListRoot.filter((item) => item.State_id === selectedState.id)
               setConstisList(list)
            } else if (selectedState.id === '' && selectedState.actionable === true) {
               // this condition is reached when a chosen state is removed by selecting 'all'
               // here the lists need to be set according to selected state or the state of the legislature
               setMemListFiltered(memListCore)
               // setMemListFilteredCount(memListCore.length)
               setSelectedStateName('')
               const heading = 'House number: ' + selectedHouseNumber + '   >   '
               setMemListFilteredHeading(heading)
               setMemListHeading('')

               const listPresent = memListCore.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = memListCore.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)

               // set the constituencies list for dropdown according to all states
               setConstisList(constisListRoot)
            } else if (selectedState.id === '' && selectedState.actionable === false) {
               // this condition is reached when selectedState is altered by an outsider parameter selection
               // i.e. selectedHouseNumber
               // what to do?
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [selectedState])

   // related to Districts

   const fetchDistrictsListByState = async () => {
      try {
         const sortedList = [...stateFull.Districts].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setDistrictsList(sortedList)

         console.log('sorted districts list fetched by state ', sortedList)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }

   useEffect(() => {
      if (districtsList && districtsList.length > 0) {
         console.log('districts list fetched by state ', districtsList)
      }
   }, [districtsList])

   // related to constituency dropdown

   function handleChangeConsti(e) {
      e.preventDefault()
      setSelectedConstituency({ id: e.target.value, actionable: true })

      // make the downline search parameters empty
      setSelectedParty({ name: '', actionable: false })
      setSearchText({ text: '', actionable: false })
   }

   const handleRemoveConstituency = async (e) => {
      e.preventDefault()
      setSelectedConstituency({ id: '', actionable: true })

      // make the downline search parameters empty
      setSelectedParty({ name: '', actionable: false })
      setSearchText({ text: '', actionable: false })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (selectedConstituency.id !== '' && selectedConstituency.actionable === true) {
               // this condition is reached when a constituency is chosen
               const newList = memListCore.filter((item) => item.Constituency_id === selectedConstituency.id)

               const present = constisList.find((item) => item.Constituency_id === selectedConstituency.id)

               let heading = ''
               if (selectedState.id !== '' && legis.Strata === 'Central') {
                  heading =
                     'House Number:  ' +
                     selectedHouseNumber +
                     '   >   Sate / UT:  ' +
                     selectedStateName +
                     '   >   Constituency:  ' +
                     present.Name
               } else {
                  heading = 'House Number:  ' + selectedHouseNumber + '   >   Constituency:  ' + present.Name
               }
               setMemListHeading(heading)

               const listPresent = newList.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = newList.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)
            } else if (selectedConstituency.id === '' && selectedConstituency.actionable === true) {
               // this condition is reached when a chosen state is removed by selecting 'all'
               // here the lists need to be set according to memListFiltered

               // memListFiltered was not altered above
               setMemListHeading('')

               const listPresent = memListFiltered.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = memListFiltered.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)
            } else if (selectedConstituency.id === '' && selectedConstituency.actionable === false) {
               // this condition is reached when selectedState is altered by an outsider parameter selection
               // i.e. selectedHouseNumber or selectedState
               // what to do?
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [selectedConstituency])

   // related to Party dropdown

   function handleChangeParty(e) {
      e.preventDefault()
      setSelectedParty({ name: e.target.value, actionable: true })

      // make the following search parameters empty
      setSelectedConstituency({ id: '', actionable: false })
      setSearchText({ text: '', actionable: false })
   }

   const handleRemoveParty = async (e) => {
      e.preventDefault()
      setSelectedParty({ name: '', actionable: true })

      // make the following search parameters empty
      setSelectedConstituency({ id: '', actionable: false })
      setSearchText({ text: '', actionable: false })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (selectedParty.name !== '' && selectedParty.actionable === true) {
               // this condition is reached when a political party is chosen

               const newList = memListFiltered.filter((item) => item.PartyName === selectedParty.name)

               let heading = ''
               if (selectedState.id !== '' && legis.Strata === 'Central') {
                  heading =
                     'House Number:  ' +
                     selectedHouseNumber +
                     '   >   Sate / UT:  ' +
                     selectedStateName +
                     '   >   Party:  ' +
                     selectedParty.name
               } else {
                  heading = 'House Number:  ' + selectedHouseNumber + '   >   Party:  ' + selectedParty.name
               }
               setMemListHeading(heading)

               const listPresent = newList.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = newList.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)
            } else if (selectedParty.name === '' && selectedParty.actionable === true) {
               // this condition is reached when a chosen political party is removed by selecting 'all'
               // here the lists need to be set according to memListFiltered

               // memListFiltered was not altered above
               setMemListHeading('')

               const listPresent = memListFiltered.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = memListFiltered.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)
            } else if (selectedParty.name === '' && selectedParty.actionable === false) {
               // this condition is reached when selectedParty is altered by an outsider parameter selection
               // i.e. selectedHouseNumber or selectedState
               // what to do?
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [selectedParty])

   // for search by name
   const [searchText, setSearchText] = useState('')

   // const filteredPersons = leaders.filter((person) => {
   //    return (
   //       person.name.toLowerCase().includes(searchText.toLowerCase()) ||
   //       person.email.toLowerCase().includes(searchText.toLowerCase())
   //    )
   // })

   const handleChangeSearchText = (e) => {
      setSearchText({ text: e.target.value, actionable: true })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (searchText.text !== '' && searchText.text.length > 1 && searchText.actionable === true) {
               // this condition is reached when a text is entered is chosen
               const newList = memListCore.filter((item) =>
                  item.Name.toLowerCase().includes(searchText.text.toLowerCase()),
               )

               const heading =
                  'House Number:  ' + selectedHouseNumber + '   >   Search text:  ' + searchText.text + '   >   '
               setMemListHeading(heading)

               const listPresent = newList.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = newList.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)
            } else if ((searchText.text === '' || searchText.text.length < 2) && searchText.actionable === true) {
               // this condition is reached when the text is removed
               // here the lists need to be set according to memListFiltered

               // memListFiltered was not altered above
               setMemListHeading('')

               const listPresent = memListFiltered.filter(
                  (item) => item.ToDate === '' || item.ToDate.ToDateReason === 'Term Completed / कार्यकाल पूर्ण',
               )
               const listPast = memListFiltered.filter(
                  (item) => item.ToDate !== '' && item.ToDate.ToDateReason !== 'Term Completed / कार्यकाल पूर्ण',
               )
               setMemListRenderPresent(listPresent)
               setMemListRenderPresentCount(listPresent.length)
               setMemListRenderPast(listPast)
               setMemListRenderPastCount(listPast.length)
            } else if (searchText.text === '' && searchText.actionable === false) {
               // this condition is reached when the text is altered by an outsider parameter selection
               // i.e. selectedHouseNumber or selectedState
               // what to do?
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [searchText])

   // toDate of Legislature

   const [openModalToDateOfLegislature, setOpenModalToDateOfLegislature] = useState(false)

   const handleOpenModalToDateOfLegislature = async (e) => {
      e.preventDefault()

      setOpenModalToDateOfLegislature(true)
   }

   const handleCloseModalToDateOfLegislature = () => setOpenModalToDateOfLegislature(false)

   const handleToDateOfLegislature = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (dateDetails.ToDate !== '' && dateDetails.ToDateReason !== '') {
               try {
                  // 1. get the House - old and new value
                  const list = legis.Houses
                  const filteredHouse = list.find((houseItem) => houseItem.HouseNumber === selectedHouseNumber)
                  const houseObj = {
                     HouseNumber: filteredHouse.HouseNumber,
                     FromDate: filteredHouse.FromDate,
                     ToDate: dateDetails.ToDate,
                     ToDateReason: dateDetails.ToDateReason,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  // 2. get refernece of main doc of Legislature and update the legisature for Houses with new values
                  const docRefLegislature = doc(db, 'Legislatures', legis.id)

                  await updateDoc(docRefLegislature, {
                     Houses: arrayRemove(filteredHouse),
                  })

                  await updateDoc(docRefLegislature, {
                     Houses: arrayUnion(houseObj),
                     HousesOld: arrayUnion(houseObj),
                  })

                  // 3. get the memcorelist inside legislature
                  const memCoreListId = 'MemCoreList-' + selectedHouseNumber
                  const docRefLegisSubCollectionMemCoreLists = doc(
                     db,
                     'Legislatures',
                     legis.id,
                     'MemCoreLists',
                     memCoreListId,
                  )
                  const docSnapLegisSubCollectionMemCoreLists = await getDoc(docRefLegisSubCollectionMemCoreLists)

                  if (docSnapLegisSubCollectionMemCoreLists.exists()) {
                     //
                     // 3.1 get list of all present members
                     const memListNew = docSnapLegisSubCollectionMemCoreLists.data().Members
                     const memListForUpdate = memListNew.filter((thisItem) => thisItem.ToDate === '')

                     // 3.2 update every member
                     memListForUpdate.forEach(async (memberItem) => {
                        // 3.2.1 Create a new member object with new values
                        const memberObj = {
                           ...memberItem,
                           ToDate: dateDetails.ToDate,
                           ToDateReason: dateDetails.ToDateReason,
                        }

                        // 3.2.2 update the memcorelist with new values
                        await updateDoc(docRefLegisSubCollectionMemCoreLists, {
                           Members: arrayRemove(memberItem),
                        })

                        await updateDoc(docRefLegisSubCollectionMemCoreLists, {
                           Members: arrayUnion(memberObj),
                        })

                        // 3.2.3 Get the main doc of legis member and update
                        const docRefLegisMem = doc(db, 'LegisMembers', memberItem.LegisMem_id)
                        await updateDoc(docRefLegisMem, {
                           ToDate: dateDetails.ToDate,
                           ToDateReason: dateDetails.ToDateReason,
                           ToDateOld: arrayUnion({
                              ToDate: dateDetails.ToDate,
                              ToDateReason: dateDetails.ToDateReason,
                              CrBy: auth.currentUser.uid,
                              CrDt: new Date(),
                              CrIP: ip.data.ip,
                           }),
                        })

                        // 3.2.4 Update the memcorelist inside reated constituency
                        const docRefConstiSubCollectionMemCoreList = doc(
                           db,
                           'LegisConstituencies',
                           memberItem.Constituency_id,
                           'CoreLists',
                           'MemCoreList',
                        )

                        await updateDoc(docRefConstiSubCollectionMemCoreList, {
                           Members: arrayRemove(memberItem),
                        })
                        await updateDoc(docRefConstiSubCollectionMemCoreList, {
                           Members: arrayUnion(memberObj),
                        })
                     })
                  }

                  console.log('houses: ', list, 'fiteredHouse: ', filteredHouse, 'objHouse: ', houseObj)

                  // manage the local state ...
                  // while updating the local state from here, the date is causing error
                  // hence, re fetching the data

                  if (legis.Strata === 'Central') {
                     setSelectedState({ id: '', actionable: false })
                  }

                  setSelectedConstituency({ id: '', actionable: false })
                  setSelectedParty({ name: '', actionable: false })
                  setSearchText({ text: '', actionable: false })

                  const coreListId = 'MemCoreList-' + selectedHouseNumber
                  fetchMemListCore(coreListId)

                  // close modal
                  setOpenModalToDateOfLegislature(false)
               } catch (error) {
                  alert(`Error adding legislature house's toDate: `, error.message)
                  console.log(`Error adding legislature house's toDate: `, error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // toDate of members

   const [openModalToDateOfMember, setOpenModalToDateOfMember] = useState(false)
   const [selectedMember, setSelectedMember] = useState('')

   const handleOpenModalToDateOfMember = async (e, item) => {
      e.preventDefault()

      setSelectedMember(item)
      console.log('selected member for toDate: ', item)

      setOpenModalToDateOfMember(true)
   }

   const handleCloseModalToDateOfMember = () => setOpenModalToDateOfMember(false)

   const handleToDateOfMember = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (dateDetails.ToDate !== '' && dateDetails.ToDateReason !== '') {
               const newRecord = {
                  ...selectedMember,
                  ToDate: { ToDate: dateDetails.ToDate, ToDateReason: dateDetails.ToDateReason },
               }

               try {
                  console.log('value to add: ', newRecord)

                  // get refernece of main doc of legis member
                  const docRefLegisMember = doc(db, 'LegisMembers', selectedMember.LegisMem_id)

                  // get ref of the subCollection 'MemCoreLists' inside the legislature
                  const memCoreListId = 'MemCoreList-' + selectedMember.HouseNumber
                  const docRefLegisSubCollectionMemCoreLists = doc(
                     db,
                     'Legislatures',
                     legis.id,
                     'MemCoreLists',
                     memCoreListId,
                  )

                  // get ref of the subcollection 'CoreLists / MemCoreList' inside the constituency
                  const docRefConstiSubCollectionMemCoreList = doc(
                     db,
                     'LegisConstituencies',
                     selectedMember.Constituency_id,
                     'CoreLists',
                     'MemCoreList',
                  )

                  // 1. update the main Legis Member doc
                  await updateDoc(docRefLegisMember, {
                     ToDate: { ToDate: dateDetails.ToDate, ToDateReason: dateDetails.ToDateReason },
                     ToDateOld: arrayUnion({
                        ToDate: dateDetails.ToDate,
                        ToDateReason: dateDetails.ToDateReason,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  // 2. update the member corelist inside Legislature
                  await updateDoc(docRefLegisSubCollectionMemCoreLists, {
                     Members: arrayRemove(selectedMember),
                  })
                  await updateDoc(docRefLegisSubCollectionMemCoreLists, {
                     Members: arrayUnion(newRecord),
                  })

                  // 3. update subcollection 'MemCoreList' inside constituencies
                  await updateDoc(docRefConstiSubCollectionMemCoreList, {
                     Members: arrayRemove(selectedMember),
                  })
                  await updateDoc(docRefConstiSubCollectionMemCoreList, {
                     Members: arrayUnion(newRecord),
                  })

                  // manage the local state ...
                  // while updating the local state from here, the date is causing error
                  // hence, re fetching the data
                  if (legis.Strata === 'Central') {
                     setSelectedState({ id: '', actionable: false })
                  }

                  setSelectedConstituency({ id: '', actionable: false })
                  setSelectedParty({ name: '', actionable: false })
                  setSearchText({ text: '', actionable: false })

                  const coreListId = 'MemCoreList-' + selectedHouseNumber
                  fetchMemListCore(coreListId)

                  // close modal
                  setOpenModalToDateOfMember(false)
               } catch (error) {
                  alert(`Error adding member's toDate: `, error.message)
                  console.log(`Error adding member's toDate: `, error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handlePastCount = (e) => {
      e.preventDefault()

      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   return (
      <Box sx={{ px: 1, minHeight: 600, mb: 10 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Elected members of ${legis.Name}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.3,
               fontFamily: 'Poppins',
            }}
         >
            Elected Members: {'\n'} निर्वाचित सदस्य:
         </Typography>
         <Grid id="home_grid_container_Filter" container spacing={0} sx={{ my: 0.25 }} disableEqualOverflow>
            {(isDataEditor || isSuper) && (
               <Grid
                  id="home_grid_Container_Filter_AddMember"
                  xs={6}
                  sm={6}
                  md={4}
                  order={{ xs: 1, sm: 1, md: 1 }}
                  sx={{
                     backgroundColor: '#F1F1F1',
                     p: 1,
                     mt: 0.5,
                     border: 2,
                     borderColor: '#ffffff',
                  }}
               >
                  <Button
                     onClick={(e) => {
                        handleOpenModalAddMember(e)
                     }}
                     size="small"
                     variant="outlined"
                     sx={{ textTransform: 'none', py: 0, px: 1, minWidth: 0, mr: 1 }}
                  >
                     Add
                  </Button>

                  <Button
                     onClick={(e) => {
                        handleOpenModalToDateOfLegislature(e)
                     }}
                     size="small"
                     variant="outlined"
                     sx={{ textTransform: 'none', py: 0, px: 1, minWidth: 0, mr: 1 }}
                  >
                     House Term Completion
                  </Button>
               </Grid>
            )}
            <Grid
               id="home_grid_Container_Filter_House"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 2, sm: 2, md: 2 }}
               sx={{
                  // backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  backgroundColor: '#F1F1F1',
                  p: 1,
                  mt: 0.5,
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <FormControl sx={{ width: '100%' }} size="small">
                  <InputLabel>House Number / सदन संख्या</InputLabel>
                  <Select
                     value={selectedHouseNumber}
                     label="House Number / सदन संख्या"
                     onChange={(e) => {
                        handleChangeHouseNumber(e)
                     }}
                     MenuProps={{
                        sx: {
                           '&& .Mui-selected': {
                              color: '#FFFFFF',
                              background: '#1565c0',
                           },
                        },
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     {houseNumbersList &&
                        houseNumbersList.map((item) => (
                           <MenuItem key={item} value={item}>
                              {item}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Grid>
            {legis.Strata === 'Central' && (
               <Grid
                  id="home_grid_Container_Fiter_state"
                  xs={6}
                  sm={6}
                  md={4}
                  order={{ xs: 3, sm: 3, md: 3 }}
                  sx={{
                     backgroundColor: '#F1F1F1',
                     p: 1,
                     mt: 0.5,
                     display: 'flex',
                     flexDirection: 'row',
                     border: 2,
                     borderColor: '#ffffff',
                  }}
               >
                  <FormControl sx={{ width: '100%', backgroundColor: '#F1F1F1' }} size="small">
                     <InputLabel>State / राज्य</InputLabel>
                     <Select
                        value={selectedState.id}
                        label="State / राज्य"
                        onChange={handleChangeState}
                        MenuProps={{
                           sx: {
                              '&& .Mui-selected': {
                                 color: '#FFFFFF',
                                 background: '#1565c0',
                              },
                           },
                        }}
                        sx={{ backgroundColor: '#ffffff' }}
                     >
                        <MenuItem value="">
                           <em>All</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.State_id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
                  <IconButton
                     edge="end"
                     aria-label="delete"
                     onClick={(e) => {
                        handleRemoveState(e)
                     }}
                     sx={{ height: '25px', width: '25px' }}
                  >
                     <DeleteIcon fontSize="small" />
                  </IconButton>
               </Grid>
            )}
            <Grid
               id="home_grid_Container_Filter_party"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 5, sm: 5, md: 5 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  p: 1,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <FormControl sx={{ width: '100%', backgroundColor: '#F1F1F1' }} size="small">
                  <InputLabel>Party / दल</InputLabel>
                  <Select
                     value={selectedParty.name}
                     label="Constituency / निर्वाचन क्षेत्र"
                     onChange={handleChangeParty}
                     MenuProps={{
                        sx: {
                           '&& .Mui-selected': {
                              color: '#FFFFFF',
                              background: '#1565c0',
                           },
                        },
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>All</em>
                     </MenuItem>
                     {partiesList &&
                        partiesList.map((item) => (
                           <MenuItem key={item.PartyName} value={item.PartyName}>
                              {item.PartyName} {item.PartyAbbreviation && `(${item.PartyAbbreviation})`}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                     handleRemoveParty(e)
                  }}
                  sx={{ height: '25px', width: '25px' }}
               >
                  <DeleteIcon fontSize="small" />
               </IconButton>
            </Grid>
            <Grid
               id="home_grid_Container_filter_constituency"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 4, sm: 4, md: 4 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  p: 1,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <FormControl sx={{ width: '100%', backgroundColor: '#F1F1F1' }} size="small">
                  <InputLabel>Constituency / निर्वाचन क्षेत्र</InputLabel>
                  <Select
                     value={selectedConstituency.id}
                     label="Constituency / निर्वाचन क्षेत्र"
                     onChange={handleChangeConsti}
                     MenuProps={{
                        sx: {
                           '&& .Mui-selected': {
                              color: '#FFFFFF',
                              background: '#1565c0',
                           },
                        },
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>All</em>
                     </MenuItem>
                     {constisList &&
                        constisList.map((item) => (
                           <MenuItem key={item.Constituency_id} value={item.Constituency_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                     handleRemoveConstituency(e)
                  }}
                  sx={{ height: '25px', width: '25px' }}
               >
                  <DeleteIcon fontSize="small" />
               </IconButton>
            </Grid>
            <Grid
               id="home_grid_Container_filter_searchText"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 6, sm: 6, md: 6 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  p: 1,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <TextField
                  id="SearchName"
                  label="Search Name"
                  value={searchText.text}
                  onChange={(e) => {
                     handleChangeSearchText(e)
                  }}
                  onBlur={(event) => event}
                  size="small"
                  sx={{ backgroundColor: '#FFFFFF', width: '100%' }}
               />
            </Grid>
         </Grid>
         {!navigator.onLine && (
            <div>
               <Alert variant="outlined" severity="error" sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box>
               <Box>
                  {uploadingMemListCore && (
                     <MyLoaderCircularGradient title={'Fetching the list of members...'}></MyLoaderCircularGradient>
                  )}
               </Box>
               <Box
                  sx={{
                     backgroundColor: '#d3d3d3',
                     px: 1,
                     py: 0.5,
                     mb: 1,
                     whiteSpace: 'pre-wrap',
                     lineHeight: 1.2,
                     fontFamily: 'Poppins',
                     display: 'flex',
                     flexDirection: 'row',
                  }}
               >
                  {memListHeading || memListFilteredHeading}
                  <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, ml: 'auto', px: 1, mr: 1, color: '#4caf50' }}>
                     {memListRenderPresentCount}
                  </Box>
                  <Box
                     sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, color: '#ef5350', cursor: 'pointer' }}
                     onClick={(e) => {
                        handlePastCount(e)
                     }}
                  >
                     {memListRenderPastCount}
                  </Box>
               </Box>
               <Box>
                  {!uploadingMemListCore &&
                     (!memListRenderPresent || (memListRenderPresent && memListRenderPresent.length < 1)) && (
                        <Alert variant="Outlined" severity="Warning">
                           No elected member to show.
                        </Alert>
                     )}
                  {!uploadingMemListCore && memListRenderPresent && memListRenderPresent.length > 0 && (
                     <List dense>
                        {memListRenderPresent.map((item, index) => (
                           <div key={item.LegisMem_id}>
                              <ListItem
                                 alignItems="flex-start"
                                 sx={{ pl: 0, ml: 0 }}
                                 key={item.LegisMem_id}
                                 secondaryAction={
                                    <Box sx={{ ml: 'auto' }}>
                                       {isSuper && (
                                          <IconButton
                                             edge="end"
                                             aria-label="delete"
                                             onClick={(e) => {
                                                handleDelete(e, item)
                                             }}
                                             sx={{ mr: 1, height: '25px', width: '25px' }}
                                          >
                                             <DeleteIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 }
                              >
                                 <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography
                                       component="span"
                                       variant="body2"
                                       sx={{ color: 'text.primary', display: 'inline', fontWeight: 600, mb: 1 }}
                                    >
                                       {index + 1}.
                                    </Typography>
                                    <Avatar alt={item.Name} src={item.Image} />
                                 </ListItemAvatar>
                                 <ListItemText
                                    secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                    primary={
                                       <>
                                          <Link
                                             underline="hover"
                                             href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <HighLighter
                                                fullText={item.Name.toUpperCase()}
                                                highlightText={searchText.text}
                                             />
                                          </Link>{' '}
                                          .{' '}
                                          {item.NameLocal && (
                                             <Link
                                                href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                {item.NameLocal}
                                             </Link>
                                          )}{' '}
                                          .{' '}
                                          <Link
                                             underline="none"
                                             href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                             color="warning"
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             {item.NameHindi}
                                             {'  '}
                                          </Link>
                                       </>
                                    }
                                    secondary={
                                       <>
                                          <>
                                             <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                             >
                                                Constituency:
                                             </Typography>
                                             <Link
                                                href={`/constituency/${item.ConstituencyName}/${item.Constituency_id}`}
                                                sx={{ m: 1, textDecoration: 'none !important' }}
                                                rel="noreferrer"
                                                target="_blank"
                                             >
                                                <strong>{item.ConstituencyName}</strong> ({item.ConstituencyNumber}){' '}
                                                {item.ConstituencyNameLocal && <>{item.ConstituencyNameLocal}</>}{' '}
                                                {item.ConstituencyNameHindi}
                                             </Link>
                                          </>
                                          <>
                                             <br />
                                             <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                             >
                                                Seat:
                                             </Typography>
                                             {` ${item.SeatType}`}
                                          </>
                                          {legis.Strata === 'Central' && (
                                             <>
                                                <br />
                                                <Typography
                                                   component="span"
                                                   variant="body2"
                                                   sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                                >
                                                   State / राज्य:
                                                </Typography>
                                                {item.StateName}
                                             </>
                                          )}
                                          <>
                                             <br />
                                             {item.PartyFlag && (
                                                <Link
                                                   href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <img src={item.PartyFlag} className={styles.imgX2} />
                                                </Link>
                                             )}
                                             {item.PartyName !== 'Independent' && (
                                                <Link
                                                   href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                   target="_blank"
                                                   rel="noopener"
                                                   sx={{ textDecoration: 'none !important' }}
                                                >
                                                   {item.PartyName}
                                                </Link>
                                             )}
                                             {item.PartyName === 'Independent' && <>{item.PartyName}</>}
                                          </>
                                          <>
                                             <br />
                                             <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                             >
                                                Tenure:
                                             </Typography>
                                             {moment(item.FromDate.toDate()).format('Do MMMM YYYY')} to
                                             {item.ToDate !== ''
                                                ? ` ${moment(item.ToDate.ToDate.toDate()).format('Do MMMM YYYY')}`
                                                : ' till date'}
                                          </>
                                          {(isDataEditor || isSuper) && (
                                             <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                onClick={(e) => {
                                                   handleOpenModalToDateOfMember(e, item)
                                                }}
                                                sx={{ ml: 1, height: '15px', width: '15px' }}
                                             >
                                                <EditIcon sx={{ height: '15px', width: '15px' }} />
                                             </IconButton>
                                          )}
                                       </>
                                    }
                                 />
                              </ListItem>

                              <Divider sx={{ width: '80%' }} />
                           </div>
                        ))}
                     </List>
                  )}
               </Box>
               <Box>
                  <Box
                     sx={{
                        p: 1,
                        mt: 0.5,
                        color: '#ffffff',
                        backgroundImage: 'linear-gradient(to right, #ff9800, #ff9800, #ffffff)',
                        fontWeight: 700,
                     }}
                     ref={focusRef}
                  >
                     Premature tenure end / समयपूर्व कार्यकाल अंत:
                  </Box>
                  <Box>
                     {!uploadingMemListCore &&
                        (!memListRenderPast || (memListRenderPast && memListRenderPast.length < 1)) && (
                           <Alert variant="Outlined" severity="Warning">
                              No elected member to show.
                           </Alert>
                        )}
                     {!uploadingMemListCore && memListRenderPast && memListRenderPast.length > 0 && (
                        <List dense>
                           {memListRenderPast.map((item, index) => (
                              <div key={item.LegisMem_id}>
                                 <ListItem
                                    alignItems="flex-start"
                                    sx={{ pl: 0, ml: 0 }}
                                    key={item.LegisMem_id}
                                    secondaryAction={
                                       <Box sx={{ ml: 'auto' }}>
                                          {isSuper && (
                                             <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) => {
                                                   handleDelete(e, item)
                                                }}
                                                sx={{ mr: 1, height: '25px', width: '25px' }}
                                             >
                                                <DeleteIcon fontSize="small" />
                                             </IconButton>
                                          )}
                                       </Box>
                                    }
                                 >
                                    <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                       <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{ color: 'text.primary', display: 'inline', fontWeight: 600, mb: 1 }}
                                       >
                                          {index + 1}.
                                       </Typography>
                                       <Avatar alt={item.Name} src={item.Image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       primary={
                                          <>
                                             <Link
                                                underline="hover"
                                                href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                {item.Name.toUpperCase()}
                                             </Link>{' '}
                                             .{' '}
                                             {item.NameLocal && (
                                                <Link
                                                   href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   {item.NameLocal}
                                                </Link>
                                             )}{' '}
                                             .{' '}
                                             <Link
                                                underline="none"
                                                href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                                color="warning"
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                {item.NameHindi}
                                                {'  '}
                                             </Link>
                                          </>
                                       }
                                       secondary={
                                          <>
                                             <>
                                                <Typography
                                                   component="span"
                                                   variant="body2"
                                                   sx={{ color: 'text.primary', display: 'inline' }}
                                                >
                                                   Constituency:
                                                </Typography>
                                                <Link
                                                   href={`/constituency/${item.ConstituencyName}/${item.Constituency_id}`}
                                                   sx={{ m: 1, textDecoration: 'none !important' }}
                                                   rel="noreferrer"
                                                   target="_blank"
                                                >
                                                   <strong>{item.ConstituencyName}</strong> ({item.ConstituencyNumber}){' '}
                                                   {item.ConstituencyNameLocal && <>{item.ConstituencyNameLocal}</>}{' '}
                                                   {item.ConstituencyNameHindi}
                                                </Link>
                                             </>
                                             <>
                                                <br />
                                                <Typography
                                                   component="span"
                                                   variant="body2"
                                                   sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                                >
                                                   Seat:
                                                </Typography>
                                                {` ${item.SeatType}`}
                                             </>
                                             {legis.Strata === 'Central' && (
                                                <>
                                                   <br />
                                                   <Typography
                                                      component="span"
                                                      variant="body2"
                                                      sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                                   >
                                                      State / राज्य:
                                                   </Typography>
                                                   {item.StateName}
                                                </>
                                             )}
                                             <>
                                                <br />
                                                {item.PartyFlag && (
                                                   <Link
                                                      href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                      target="_blank"
                                                      rel="noopener"
                                                   >
                                                      <img src={item.PartyFlag} className={styles.imgX2} />
                                                   </Link>
                                                )}
                                                {item.PartyName !== 'Independent' && (
                                                   <Link
                                                      href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                      target="_blank"
                                                      rel="noopener"
                                                      sx={{ textDecoration: 'none !important' }}
                                                   >
                                                      {item.PartyName}
                                                   </Link>
                                                )}
                                                {item.PartyName === 'Independent' && <>{item.PartyName}</>}
                                             </>
                                             <>
                                                <br />
                                                <Typography
                                                   component="span"
                                                   variant="body2"
                                                   sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                                >
                                                   Tenure:
                                                </Typography>
                                                {moment(item.FromDate.toDate()).format('Do MMMM YYYY')} to
                                                {item.ToDate !== ''
                                                   ? ` ${moment(item.ToDate.ToDate.toDate()).format('Do MMMM YYYY')}`
                                                   : ' till date'}
                                             </>
                                             {(isDataEditor || isSuper) && (
                                                <IconButton
                                                   edge="end"
                                                   aria-label="edit"
                                                   onClick={(e) => {
                                                      handleOpenModalToDateOfMember(e, item)
                                                   }}
                                                   sx={{ ml: 1, height: '15px', width: '15px' }}
                                                >
                                                   <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                </IconButton>
                                             )}
                                             <>
                                                <br />
                                                <Typography
                                                   component="span"
                                                   variant="body2"
                                                   sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                                >
                                                   Reason:
                                                </Typography>
                                                {item.ToDate.ToDateReason}
                                             </>
                                          </>
                                       }
                                    />
                                 </ListItem>

                                 <Divider sx={{ width: '80%' }} />
                              </div>
                           ))}
                        </List>
                     )}
                  </Box>
               </Box>
            </Box>
         )}

         <Box id="boxModals">
            <Modal
               open={openModalAddMember}
               onClose={handleCloseModalAddMember}
               aria-labelledby="modal-modal-title-addMember"
               aria-describedby="modal-modal-description-addMember"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addMember"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a constituency
                  </Typography>
                  <AddLegisMem
                     // lastMember={lastMember}
                     // uploading={uploading}
                     // uploaded={uploaded}
                     // uploadingFailed={uploadingFailed}
                     handleAddMember={handleAddMember}
                  ></AddLegisMem>
               </Box>
            </Modal>
            <Modal
               open={openModalToDateOfMember}
               onClose={handleCloseModalToDateOfMember}
               aria-labelledby="modal-modal-title-toDateOfMember"
               aria-describedby="modal-modal-description-toDateOfMember"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-toDateOfMember"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     {selectedMember !== '' &&
                        `Update tenure completion date of \n ${selectedMember.Name.toUpperCase()}`}
                  </Typography>
                  <AddToDateAndReason
                     labelName="Date:"
                     purpose="member"
                     handleAddDate={handleToDateOfMember}
                  ></AddToDateAndReason>
               </Box>
            </Modal>
            <Modal
               open={openModalToDateOfLegislature}
               onClose={handleCloseModalToDateOfLegislature}
               aria-labelledby="modal-modal-title-toDateOfLegislature"
               aria-describedby="modal-modal-description-toDateOfLegislature"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-toDateOfLegislature"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Update term completion date of {'\n'} House number {selectedHouseNumber} of {'\n'}
                     {legis.Name.toUpperCase()}
                  </Typography>
                  <AddToDateAndReason
                     labelName="Date:"
                     purpose="legislature"
                     handleAddDate={handleToDateOfLegislature}
                  ></AddToDateAndReason>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

LegisMems.propTypes = {
   legis: PropTypes.object.isRequired,
}

export default LegisMems
