import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
   Accordion,
   AccordionActions,
   AccordionDetails,
   AccordionSummary,
   Alert,
   AlertTitle,
   Box,
   Button,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { doc, getDoc } from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import moment from 'moment'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function Subscriptions({ isUserLoggedIn, userProfile }) {
   let fullName = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast
   }
   console.log('full name: ', fullName)

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [thisUser, setThisUser] = useState('')
   const [expanded, setExpanded] = useState(false)
   const [subscriptionsGeneral, setSubscriptionsGeneral] = useState([])
   const [subscriptionsLeader, setSubscriptionsLeader] = useState([])

   const handleChangeAccordianExpand = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
   }

   const headList = [
      { Name: `General Use Subscription \n सामान्य उपयोग सब्सक्रिप्शन`, Code: 'SG', id: 5 },
      { Name: `Leader Profile Subscription \n नेता प्रोफ़ाइल सब्सक्रिप्शन`, Code: 'SL', id: 10 },
   ]

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         if (userProfile) {
            if (navigator.onLine) {
               setThisUser(userProfile.User_id)

               fetchLists()

               const item = headList[0]
               setExpanded(item.id)
               setFirstLoadStatus('success')
            }
         }
      }
   }, [])

   const fetchLists = async () => {
      const docRefSubCollSG = doc(db, 'UserProfiles', userProfile.id, 'CoreLists', 'Subscriptions-General')
      const docRefSubCollSL = doc(db, 'UserProfiles', userProfile.id, 'CoreLists', 'Subscriptions-Leader')
      const docSnapSG = await getDoc(docRefSubCollSG)
      const docSnapSL = await getDoc(docRefSubCollSL)

      setSubscriptionsGeneral([...docSnapSG.data().Subscriptions].sort((a, b) => a.StartDate - b.StartDate))
      setSubscriptionsLeader([...docSnapSL.data().Subscriptions].sort((a, b) => a.StartDate - b.StartDate))
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ minHeight: 500 }}>
            {!navigator.onLine && (
               <div>
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle>
                        You are offline !
                        <CloudOffIcon sx={{ ml: 5 }} />
                     </AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === '' && (
               <div>
                  <Alert variant="outlined" severity="warning" sx={{ margin: 5 }}>
                     <AlertTitle>... Loading !</AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === 'out' && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{
                        margin: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >
                     <AlertTitle>Please log in! {'\n'} कृपया लॉगिन करें!</AlertTitle>
                     You are not logged in the site. {'\n'} आप साइट पर लॉग इन नहीं हैं.
                  </Alert>
               </Box>
            )}
            {navigator.onLine && isUserLoggedIn === 'in' && (
               <Box sx={{ m: 1 }}>
                  <Box sx={{ fontWeight: 600, textAlign: 'center', mb: 1 }}>Welcome ... {fullName}!</Box>
                  {headList &&
                     headList.length > 0 &&
                     headList.map((item, index) => (
                        <Accordion
                           key={item.id}
                           expanded={expanded === item.id}
                           onChange={handleChangeAccordianExpand(item.id)}
                           defaultExpanded={index === 0}
                           sx={{
                              mt: 0.5,
                              '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                              '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                           }}
                        >
                           <AccordionSummary
                              expandIcon={<ArrowDropDownIcon style={{ color: '#FFFFFF' }} />}
                              aria-controls={`${item.PhaseNum}-content`}
                              id={`${item.PhaseNum}-header`}
                              sx={{
                                 backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                                 color: '#FFFFFF',
                                 whiteSpace: 'pre-line',
                              }}
                           >
                              <strong>{item.Name}</strong>
                           </AccordionSummary>
                           <AccordionDetails>
                              {item.Code === 'SG' && (
                                 <Box>
                                    {subscriptionsGeneral &&
                                       subscriptionsGeneral.length > 0 &&
                                       subscriptionsGeneral.map((item, index) => (
                                          <Box key={index} sx={{ backgroundColor: '#F1F1F1', p: 1 }}>
                                             <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ mr: 2, color: '#3a6186' }}>Start Date:</Box>
                                                <Box>
                                                   {item.StartDate &&
                                                      moment(item.StartDate.toDate()).format('Do MMM YYYY')}
                                                </Box>
                                             </Box>
                                             <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ mr: 2, color: '#3a6186' }}>End date: </Box>
                                                <Box>
                                                   {item.EndDate && moment(item.EndDate.toDate()).format('Do MMM YYYY')}
                                                </Box>
                                             </Box>
                                             <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ mr: 2, color: '#3a6186' }}>Amount: </Box>
                                                <Box>{item.Amount}</Box>
                                             </Box>
                                          </Box>
                                       ))}
                                 </Box>
                              )}
                              {item.Code === 'SL' && (
                                 <Box>
                                    {subscriptionsLeader &&
                                       subscriptionsLeader.length > 0 &&
                                       subscriptionsLeader.map((item, index) => (
                                          <Box key={index} sx={{ backgroundColor: '#F1F1F1', p: 1 }}>
                                             <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ mr: 2, color: '#3a6186' }}>Start Date:</Box>
                                                <Box>
                                                   {item.StartDate &&
                                                      moment(item.StartDate.toDate()).format('Do MMM YYYY')}
                                                </Box>
                                             </Box>
                                             <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ mr: 2, color: '#3a6186' }}>End date: </Box>
                                                <Box>
                                                   {item.EndDate && moment(item.EndDate.toDate()).format('Do MMM YYYY')}
                                                </Box>
                                             </Box>
                                             <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ mr: 2, color: '#3a6186' }}>Amount: </Box>
                                                <Box>{item.Amount}</Box>
                                             </Box>
                                          </Box>
                                       ))}
                                 </Box>
                              )}
                           </AccordionDetails>
                           <AccordionActions>
                              {thisUser === auth.currentUser.uid && (
                                 <>
                                    {item.Code === 'SG' && (
                                       <Button
                                          // onClick={(e) => {
                                          //    handleOpenModalAddPhase(e)
                                          // }}
                                          size="small"
                                          sx={{ py: 0, px: 1, minWidth: 0 }}
                                       >
                                          Renew Subscription
                                       </Button>
                                    )}
                                    {item.Code === 'SL' && (
                                       <Button
                                          // onClick={(e) => {
                                          //    handleOpenModalAddPhase(e)
                                          // }}
                                          size="small"
                                          sx={{ py: 0, px: 1, minWidth: 0 }}
                                       >
                                          Renew Subscription
                                       </Button>
                                    )}
                                 </>
                              )}
                           </AccordionActions>
                        </Accordion>
                     ))}
               </Box>
            )}
         </Box>
      </LocalizationProvider>
   )
}

Subscriptions.propTypes = {
   isUserLoggedIn: PropTypes.string.isRequired,
   userProfile: PropTypes.object.isRequired,
}

export default Subscriptions
