import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const ReadMore = ({ children }) => {
   const text = children
   const [isReadMore, setIsReadMore] = useState(true)
   const [textAltered, setTextAltered] = useState('')
   const toggleReadMore = () => {
      console.log('ho gaya')
      setIsReadMore(!isReadMore)
   }

   useEffect(() => {
      if (isReadMore) {
         setTextAltered(text.slice(0, 80))
      } else {
         setTextAltered(text)
      }
   }, [isReadMore])

   return (
      <Box sx={{ display: 'inline', width: '90%' }}>
         {textAltered}
         <span onClick={toggleReadMore} className="read-or-hide" style={{ color: 'green', cursor: 'pointer' }}>
            {isReadMore ? ' ...read more' : ' show less'}
         </span>
      </Box>
   )
}

ReadMore.propTypes = {
   children: PropTypes.string.isRequired,
}

export const ReadMore2 = ({ text }) => {
   const [isReadMore, setIsReadMore] = useState(true)
   const toggleReadMore = () => {
      setIsReadMore(!isReadMore)
   }
   return (
      <Box sx={{ display: 'inline', width: '90%' }}>
         {isReadMore ? text.slice(0, 75) : text}
         <span onClick={toggleReadMore} className="read-or-hide" style={{ color: 'green', cursor: 'pointer' }}>
            {isReadMore ? '...read more' : ' show less'}
         </span>
      </Box>
   )
}

ReadMore2.propTypes = {
   text: PropTypes.string.isRequired,
}

export default ReadMore
