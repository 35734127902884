import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function AddCandiStatus({
   selectedCandidate,
   uploadingStatus,
   uploadingFailedStatus,
   uploadedStatus,
   handleAddStatus,
}) {
   const [statusDetails, setStatusDetails] = useState({
      Announced: selectedCandidate.Announced,
      AnnouncedDate: selectedCandidate.AnnouncedDate,
      AnnounceCancelled: selectedCandidate.AnnounceCancelled,
      AnnounceCancelledDate: selectedCandidate.AnnounceCancelledDate,
      Filed: selectedCandidate.Filed,
      FiledDate: selectedCandidate.FiledDate,
      Accepted: selectedCandidate.Accepted,
      AcceptedDate: selectedCandidate.AcceptedDate,
      Rejected: selectedCandidate.Rejected,
      RejectedDate: selectedCandidate.RejectedDate,
      Withdrawn: selectedCandidate.Withdrawn,
      WithdrawnDate: selectedCandidate.WithdrawnDate,
   })

   const [actionIsValid, setActionIsValid] = useState(false)
   const [selectedStatus, setSelectedStatus] = useState('')
   const [date, setDate] = useState('')

   useEffect(() => {
      console.log('status details on change: ', statusDetails)
      if (
         statusDetails.Announced === selectedCandidate.Announced &&
         statusDetails.AnnounceCancelled === selectedCandidate.AnnounceCancelled &&
         statusDetails.Filed === selectedCandidate.Filed &&
         statusDetails.Accepted === selectedCandidate.Accepted &&
         statusDetails.Rejected === selectedCandidate.Rejected &&
         statusDetails.Withdrawn === selectedCandidate.Withdrawn
      ) {
         setActionIsValid(false)
      } else {
         setActionIsValid(true)
      }
   }, [statusDetails])

   function handleChangeStatus(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedStatus(e.target.value)
         switch (e.target.value) {
            case 'Announced':
               setStatusDetails({
                  ...statusDetails,
                  Announced: 'Announced',
               })
               return
            case 'AnnounceCancelled':
               setStatusDetails({
                  ...statusDetails,
                  AnnounceCancelled: 'AnnounceCancelled',
               })
               return
            case 'Filed':
               setStatusDetails({
                  ...statusDetails,
                  Filed: 'Filed',
                  Accepted: '',
                  Rejected: '',
                  Withdrawn: '',
               })
               return
            case 'Accepted':
               setStatusDetails({
                  ...statusDetails,
                  Accepted: 'Accepted',
                  Rejected: '',
                  Withdrawn: '',
               })
               return
            case 'Rejected':
               setStatusDetails({
                  ...statusDetails,
                  Accepted: '',
                  Rejected: 'Rejected',
                  Withdrawn: '',
               })
               return
            case 'Withdrawn':
               setStatusDetails({
                  ...statusDetails,
                  Accepted: 'Accepted',
                  Rejected: '',
                  Withdrawn: 'Withdrawn',
               })
         }
      } else {
         setSelectedStatus('')
      }
   }

   useEffect(() => {
      if (date !== '') {
         switch (selectedStatus) {
            case 'Announced':
               setStatusDetails({
                  ...statusDetails,
                  AnnouncedDate: date,
               })
               return
            case 'AnnounceCancelled':
               setStatusDetails({
                  ...statusDetails,
                  AnnounceCancelledDate: date,
               })
               return
            case 'Filed':
               setStatusDetails({
                  ...statusDetails,
                  FiledDate: date,
               })
               return
            case 'Accepted':
               setStatusDetails({
                  ...statusDetails,
                  AcceptedDate: date,
               })
               return
            case 'Rejected':
               setStatusDetails({
                  ...statusDetails,
                  RejectedDate: date,
               })
               return
            case 'Withdrawn':
               setStatusDetails({
                  ...statusDetails,
                  WithdrawnDate: date,
               })
               return
            default:
               setDate('')
         }
      } else {
         setStatusDetails({
            ...statusDetails,
            AnnouncedDate: '',
            AnnounceCancelledDate: '',
            FiledDate: '',
            AcceptedDate: '',
            RejectedDate: '',
            WithdrawnDate: '',
         })
      }
   }, [date])

   const handleSubmitStatus = async (e) => {
      e.preventDefault()

      if (actionIsValid) {
         console.log('status Details before submit: ', statusDetails)

         handleAddStatus(statusDetails, actionIsValid)
      }
   }
   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Box>
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                     value={selectedStatus}
                     label="Party"
                     onChange={(e) => {
                        handleChangeStatus(e)
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     <MenuItem value="Announced">Announced by Party</MenuItem>
                     <MenuItem value="AnnounceCancelled">Announcement cancelled by Party</MenuItem>
                     <MenuItem value="Filed">Filed</MenuItem>
                     <MenuItem value="Accepted">Accepted</MenuItem>
                     <MenuItem value="Rejected">Rejected</MenuItem>
                     <MenuItem value="Withdrawn">Withdrawn</MenuItem>
                  </Select>
               </FormControl>
            </Box>
            <Box>
               <DatePicker
                  label="Date"
                  name="Date"
                  value={date != null ? dayjs(date) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDate(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </Box>
            <Box>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitStatus(e)
                  }}
                  disabled={!actionIsValid}
               >
                  Submit
               </Button>
            </Box>
            <Box>
               {uploadingStatus && <MyLoaderCircularGradient title={'Uploading status ...'}></MyLoaderCircularGradient>}
               {uploadedStatus && (
                  <Alert variant="outlined" severity="success">
                     Status updated successfully.
                  </Alert>
               )}
               {uploadingFailedStatus && (
                  <Alert variant="outlined" severity="error">
                     Error occured! Status could not be updated.
                  </Alert>
               )}
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddCandiStatus.propTypes = {
   selectedCandidate: PropTypes.object.isRequired,
   handleAddStatus: PropTypes.func.isRequired,
   uploadingStatus: PropTypes.bool.isRequired,
   uploadedStatus: PropTypes.bool.isRequired,
   uploadingFailedStatus: PropTypes.bool.isRequired,
}

export default AddCandiStatus
