import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import styles from './MyLoaderCircularGradient.module.css'
import PropTypes from 'prop-types'

function MyLoaderCircularGradient(props) {
   return (
      <Box
         sx={{
            border: 1,
            borderColor: 'Blue',
            my: 1,
            mx: 10,
            p: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <div className={styles.loading}>{props.title} </div>
         <div>
            <svg width={0} height={0}>
               <defs>
                  <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                     <stop offset="0%" stopColor="#e01cd5" />
                     <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
               </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
         </div>
      </Box>
   )
}

MyLoaderCircularGradient.propTypes = {
   title: PropTypes.string.isRequired,
}

export default MyLoaderCircularGradient
