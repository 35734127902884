import {
   Alert,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Divider,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Modal,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPParties } from '../../../redux/reducers/pParties-slice'
import { styleModalBox, styleModalTypography, theme, Transition } from '../../../Utils/MUITheme/MUITheme'
import SupportPPartyNational from './SupportPPartyNational'
import { auth, db } from '../../../FirebaseConfig'
import { selectUserProfile, selectUsers, setUserProfile } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import moment from 'moment'
import {
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   increment,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'

function PPartiesTop10() {
   const user = useSelector(selectUsers)
   const ip = user.ip
   const dispatch = useDispatch()

   const navigate = useNavigate()
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      if (user.currentUser !== null) {
         console.log('ElecCandis - found user state not-null')
         setThisUser(user.currentUser.id)
      } else {
         console.log('ElecCandis - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const userProfile = useSelector(selectUserProfile)
   console.log('user profile: ', userProfile)

   const [initialState, setInitialState] = useState('idle')
   const [partiesList, setPartiesList] = useState([])
   const [alreadySupported, setAlreadySupported] = useState('')
   const [lastSupportDate, setLastSupportDate] = useState('')
   const [presentDate, setPresentDate] = useState('')

   const pParties = useSelector(selectPParties).pPartiesList

   useEffect(() => {
      if (initialState === 'idle') {
         console.log('empty use state')
         const presentDate = new Date()
         // const monthYear = presentDate.getMonth() + '-' + presentDate.getFullYear()
         setPresentDate(presentDate)

         setInitialState('success')
      }
   }, [])

   useEffect(() => {
      if (userProfile) {
         console.log('userProfile use state')
         getLastSupport()
      }
   }, [userProfile])

   // related to last support

   const getLastSupport = async () => {
      console.log(
         'getLastSupport : enetered, userProfile: ',
         userProfile,
         ' last party: ',
         userProfile.LS_Party_National,
         ' keys: ',
         Object.keys(userProfile.LS_Party_National),
      )
      if (userProfile) {
         if (userProfile.LS_Party_National && Object.keys(userProfile.LS_Party_National).length !== 0) {
            const lastSupportedDate = new Date(userProfile.LS_Party_National.CrDt.toDate())
            setLastSupportDate(lastSupportedDate)
            const presentDate = new Date()

            if (
               presentDate.getMonth() === lastSupportedDate.getMonth() &&
               presentDate.getFullYear() === lastSupportedDate.getFullYear()
            ) {
               setAlreadySupported(true)
            } else {
               setAlreadySupported(false)
            }
         } else {
            setLastSupportDate('')
            setAlreadySupported(false)
         }
      } else {
         console.log('Blast')
         setLastSupportDate('')
         setAlreadySupported('')
      }
   }

   useEffect(() => {
      if (lastSupportDate !== '') {
         console.log('lastSupportDate: ', lastSupportDate)
      }
   }, [lastSupportDate])

   // related to parties list

   useEffect(() => {
      if (pParties && pParties.length > 0) {
         setPartiesList(pParties)
         getSupportedParties()
      }
   }, [pParties])

   // online support

   const [openModalSupport, setOpenModalSupport] = useState(false)
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleOpenModalSupport = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('reached stage prior to open modal for support - you are logged in.')

         if (alreadySupported) {
            setOpenModalSupport(false)
            setOpenDialogAlreadySupported(true)
         } else {
            setUploading(false)
            setUploaded(false)
            setUploadingFailed(false)

            setOpenModalSupport(true)
         }
      } else {
         console.log('reached stage prior to open modal for support - You are NOT logged in.')
         handleOpenDialogNotLoggedIn()
      }
   }

   const handleCloseModalSupport = () => setOpenModalSupport(false)

   const handleAddSupport = async (onlineSupportDetails) => {
      console.log('reached adding stage of vote.')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('reached adding stage of vote - you are logged in.')

         if (onlineSupportDetails.Party_id !== '') {
            setUploading(true)

            try {
               if (alreadySupported) {
                  // this condition was already checked befor opening the support modal. So reaching this stage means fraud.
                  navigate('/', { replace: true })
               } else {
                  // 1. update user profile
                  const docRefUserProfile = doc(db, 'UserProfiles', userProfile.id)
                  await updateDoc(docRefUserProfile, {
                     LS_Party_National: {
                        Party_id: onlineSupportDetails.Party_id,
                        PartyName: onlineSupportDetails.PartyName,
                        PartyNameHindi: onlineSupportDetails.PartyNameHindi,
                        PartyNameLocal: onlineSupportDetails.PartyNameLocal,
                        PartyFlag: onlineSupportDetails.PartyFlag,

                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  })

                  // 2. update user profile sub collection
                  // To do later: make the following doc id dynamic
                  const docRefUserProfileSubColl = doc(db, 'UserProfiles', userProfile.id, 'CoreLists', 'Supports-0001')
                  const docSnapUserProfileSubColl = await getDoc(docRefUserProfileSubColl)

                  if (docSnapUserProfileSubColl.exists()) {
                     // means at least one record has been inserted erliear for this subcollection
                     // update subcollection 'CoreLists' of legislature with new values
                     await updateDoc(docRefUserProfileSubColl, {
                        Supports: arrayUnion({
                           Party_id: onlineSupportDetails.Party_id,
                           PartyName: onlineSupportDetails.PartyName,
                           PartyNameHindi: onlineSupportDetails.PartyNameHindi,
                           PartyNameLocal: onlineSupportDetails.PartyNameLocal,
                           PartyFlag: onlineSupportDetails.PartyFlag,
                           Level: 'National',
                           Type: 'PartySupport',

                           CrDt: new Date(),
                           CrIP: ip.data.ip,
                        }),
                     })
                  } else {
                     // means no record has been inserted for this subcollection
                     // create the subcollection CoreLists with new values
                     setDoc(
                        docRefUserProfileSubColl,
                        {
                           Supports: [
                              {
                                 Party_id: onlineSupportDetails.Party_id,
                                 PartyName: onlineSupportDetails.PartyName,
                                 PartyNameHindi: onlineSupportDetails.PartyNameHindi,
                                 PartyNameLocal: onlineSupportDetails.PartyNameLocal,
                                 PartyFlag: onlineSupportDetails.PartyFlag,
                                 Level: 'National',
                                 Type: 'PartySupport',

                                 CrDt: new Date(),
                                 CrIP: ip.data.ip,
                              },
                           ],
                        },
                        { merge: true },
                     )
                  }
                  // }

                  // 3. maitain records of all parties support count on monthly basis

                  const today = new Date()
                  const month = String(today.getMonth() + 1).padStart(2, '0')
                  const year = today.getFullYear()

                  const docId = year + '-' + month

                  const slab = 10000
                  const docRefMain = doc(db, 'PP_OnlineSupports', docId)
                  const docSnapMain = await getDoc(docRefMain)

                  if (docSnapMain.exists()) {
                     // update the main doc for counts
                     await updateDoc(docRefMain, {
                        [onlineSupportDetails.Party_id]: increment(1),
                        zzTotalCount: increment(1),
                     })
                     // update the subcollection of records
                     const count = docSnapMain.data().zzTotalCount

                     const quotient = Math.floor(count / slab)
                     const num = quotient + 1
                     const numStr = String(num).padStart(5, '0')

                     const docRefSubColl = doc(db, 'PP_OnlineSupports', docId, 'Records', numStr)
                     const docSnapSubColl = await getDoc(docRefSubColl)

                     if (docSnapSubColl.exists()) {
                        await updateDoc(docRefSubColl, {
                           Records: arrayUnion({
                              Party_id: onlineSupportDetails.Party_id,
                              PartyName: onlineSupportDetails.PartyName,
                              CrBy: auth.currentUser.uid,
                              CrDt: new Date(),
                              CrIP: ip.data.ip,
                           }),
                        })
                     } else {
                        try {
                           setDoc(
                              docRefSubColl,
                              {
                                 Records: [
                                    {
                                       Party_id: onlineSupportDetails.Party_id,
                                       PartyName: onlineSupportDetails.PartyName,
                                       CrBy: auth.currentUser.uid,
                                       CrDt: new Date(),
                                       CrIP: ip.data.ip,
                                    },
                                 ],
                              },
                              { merge: true },
                           )
                        } catch (error) {
                           alert('Error adding support core list: Please try again')
                           console.log('Error adding support core list: ', error)

                           handleCloseModalSupport()
                           return
                        }
                     }
                  } else {
                     try {
                        setDoc(docRefMain, { [onlineSupportDetails.Party_id]: 1, zzTotalCount: 1 }, { merge: true })
                        const docRefSubColl = doc(db, 'PP_OnlineSupports', docId, 'Records', '00001')
                        setDoc(
                           docRefSubColl,
                           {
                              Records: [
                                 {
                                    Party_id: onlineSupportDetails.Party_id,
                                    PartyName: onlineSupportDetails.PartyName,
                                    CrBy: auth.currentUser.uid,
                                    CrDt: new Date(),
                                    CrIP: ip.data.ip,
                                 },
                              ],
                           },
                           { merge: true },
                        )
                     } catch (error) {
                        alert('Error adding support: Please try again')
                        console.log('Error adding support: ', error)

                        handleCloseModalSupport()
                        return
                     }
                  }

                  // 4. do something to create or update the record with parties also.
                  // To do later. We will summarise data and send to individual parties after the end of month.

                  // 5. set the state of userProfile afresh
                  const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', auth.currentUser.uid))
                  const querySnapshot = await getDocs(querryUser)
                  let userFetched = ''
                  querySnapshot.forEach((docSnap) => {
                     userFetched = { id: docSnap.id, ...docSnap.data() }
                  })
                  dispatch(setUserProfile(userFetched))

                  handleCloseModalSupport()
                  handleOpenDialogThanks()
                  getSupportedParties()

                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               }
            } catch (error) {
               alert(`Error adding support: `, error.message)
               console.log(`Error adding support: `, error)
               setUploading(false)
               setUploaded(false)
               setUploadingFailed(true)
            }
         } else {
            alert('Please select a political party to proceed. \n कृपया आगे बढ़ने के लिए एक राजनीतिक दल का चयन करें।')
         }
      } else {
         console.log('reached adding stage of support - You are NOT logged in.')
         // this condition was already checked befor opening the add modal. So reaching this stage means fraud.
         navigate('/', { replace: true })
      }
   }

   const handleCancelSupport = async () => {
      setOpenModalSupport(false)
   }

   // related to Top 10 supported parties of the month

   const [supportedParties, setSupportedParties] = useState('')

   const getSupportedParties = async () => {
      //
      const today = new Date()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const year = today.getFullYear()

      const docId = year + '-' + month

      const docRefMain = doc(db, 'PP_OnlineSupports', docId)
      const docSnapMain = await getDoc(docRefMain)

      if (docSnapMain.exists()) {
         //
         const dataRaw = docSnapMain.data()
         console.log('data Raw: ', dataRaw)

         const dataNormalized = Object.keys(dataRaw).map((key) => ({
            Party_id: key,
            SupportCount: dataRaw[key],
         }))
         console.log('dataNormalized: ', dataNormalized)

         const dataNormalizedFilterZ = dataNormalized.filter((item) => item.Party_id !== 'zzTotalCount')
         console.log('data Normalized filter z: ', dataNormalizedFilterZ)

         // find largest elements
         let scoreArray = dataNormalizedFilterZ.map((item) => item.SupportCount)
         console.log('scoreArray: ', scoreArray)
         // const largestElements = scoreArray.reduce((acc, curr) => {
         //    if (acc.length < 2 || curr > acc[acc.length - 1]) {
         //       acc.push(curr)
         //       acc.sort((a, b) => b - a).slice(0, 2)
         //    }
         //    return acc
         // }, [])
         const largestElements = []
         for (let i = 0; i < 3; i++) {
            const max = Math.max(...scoreArray)
            largestElements.push(max)
            scoreArray = scoreArray.filter((num) => num !== max)
         }
         console.log('largestElements: ', largestElements)

         const largestElementsUnique = largestElements.reduce((acc, obj) => {
            if (!acc.includes(obj)) {
               acc.push(obj)
            }
            return acc
         }, [])
         const firstScore = largestElementsUnique[0]
         const secondScore = largestElementsUnique[1]
         const thirdScore = largestElementsUnique[2]
         console.log('largestElementsUnique: ', largestElementsUnique)
         console.log('firstScore: ', firstScore, 'secondScore: ', secondScore, 'thirdScore: ', thirdScore)
         // const dataSortedBySupportCount = dataNormalizedFilterZ.sort((a, b) => b.SupportCount - a.SupportCount)
         // console.log('dataSorted: ', dataSortedBySupportCount)

         const finalList = []
         dataNormalizedFilterZ.forEach((item) => {
            const party = pParties.find((plst) => plst.Party_id === item.Party_id)
            finalList.push({
               Party_id: party.Party_id,
               Name: party.Name,
               NameHindi: party.NameHindi,
               NameLocal: party.NameLocal,
               Flag: party.Flag,
               SupportCount: item.SupportCount,
               FirstScore: firstScore,
               SecondScore: secondScore,
               ThirdScore: thirdScore,
            })
         })

         const sortedData = finalList.sort((a, b) => {
            const af = a.SupportCount
            const bf = b.SupportCount
            const as = a.Name.trim()
            const bs = b.Name.trim()

            // If first value is same
            if (af === bf) {
               return as < bs ? -1 : as > bs ? 1 : 0
            } else {
               return af > bf ? -1 : 1
            }
         })

         setSupportedParties(sortedData)
      } else {
         //
         setSupportedParties([])
      }
   }

   useEffect(() => {
      console.log('top 10 parties list: ', supportedParties)
   }, [supportedParties])

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   const handleOpenDialogNotLoggedIn = async () => {
      setOpenDialogNotLoggedIn(true)
   }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // already supported

   const [openDialogAlreadySupported, setOpenDialogAlreadySupported] = useState(false)

   const handleCloseDialogAlreadySupported = () => setOpenDialogAlreadySupported(false)

   // thanks for support

   const [openDialogThanks, setOpenDialogThanks] = useState(false)

   const handleOpenDialogThanks = async () => {
      setOpenDialogThanks(true)
   }

   const handleCloseDialogThanks = () => setOpenDialogThanks(false)

   return (
      <Box sx={{ alignItems: 'left' }}>
         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
            <Button size="small" sx={{ textTransform: 'none', py: 0, minWidth: 0, px: 1 }}>
               {moment(presentDate).format('MMM YYYY')}
            </Button>
            <Box
               sx={{
                  mx: 1,
                  textAlign: 'center',
                  fontSize: 12,
                  color: 'Crimson',
               }}
            >
               Support your favourite party for this month. <br /> इस महीने के लिए अपनी पसंदीदा पार्टी का समर्थन करें|
            </Box>
            <Button
               variant="contained"
               size="large"
               sx={{ textTransform: 'none', py: 0, minWidth: 0, px: 1 }}
               onClick={(e) => {
                  handleOpenModalSupport(e)
               }}
               endIcon={<HowToVoteIcon />}
            >
               Support - समर्थन करें
            </Button>
         </Box>
         <Divider />
         <Box
            sx={{
               minHeight: 250,
               maxHeight: 250,
               mb: 0.5,
               backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
               overflowY: 'auto',
               scrollbarWidth: 'thin',
               borderRadius: 1,
               '&::-webkit-scrollbar': {
                  width: '0.4em',
               },
               '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
               },
               '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
               },
               '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555',
               },
            }}
         >
            {/* {supportedParties && supportedParties.length > 0 && (
               <Box
                  sx={{
                     mx: 1,
                     textAlign: 'center',
                     fontSize: 12,
                     color: 'Crimson',
                  }}
               >
                  Support your favourite party for this month. <br /> इस महीने के लिए अपनी पसंदीदा पार्टी का समर्थन
                  करें|
               </Box>
            )} */}
            {supportedParties && supportedParties.length > 0 && (
               <List dense>
                  {supportedParties.map((item, index) => (
                     <Box key={item.Party_id}>
                        <ListItem
                           key={item.Party_id}
                           sx={{ px: 0.5, py: 0 }}
                           secondaryAction={
                              <Typography
                                 edge="end"
                                 aria-label="delete"
                                 sx={{
                                    backgroundColor:
                                       item.SupportCount === item.FirstScore
                                          ? theme.palette.success.light
                                          : item.SupportCount === item.SecondScore
                                            ? theme.palette.warning.main
                                            : item.SupportCount === item.ThirdScore
                                              ? theme.palette.primary.light
                                              : theme.palette.error.main,

                                    // lineHeight: 1.2,
                                    fontSize: 14,
                                    // fontWeight: 500,
                                    color: '#FFFFFF',
                                    px: 1,
                                    borderRadius: 10,
                                    // border: 1,
                                    minWidth: 50,
                                 }}
                              >
                                 {item.SupportCount.toLocaleString('en-IN')}
                              </Typography>
                           }
                        >
                           <ListItemIcon sx={{ minWidth: '50px !important' }}>
                              {item.Flag && (
                                 <Box
                                    key={item.Party_id}
                                    component="img"
                                    src={item.Flag}
                                    alt={item.Name}
                                    sx={{ height: '25px' }}
                                 />
                              )}
                           </ListItemIcon>
                           <ListItemText
                              sx={{ m: 0.5 }}
                              primaryTypographyProps={{ fontSize: 12 }}
                              secondaryTypographyProps={{ align: 'left', fontSize: 12 }}
                              primary={item.Name}
                              secondary={item.NameHindi}
                           />
                        </ListItem>
                        <Divider />
                     </Box>
                  ))}
               </List>
            )}
            {!supportedParties ||
               (supportedParties.length === 0 && (
                  <Alert severity="success" sx={{ margin: 1, mt: 5, justifyContent: 'center', alignItems: 'center' }}>
                     <strong>
                        Support your favourite party for this month.
                        <br />
                        <br />
                        इस महीने के लिए अपनी पसंदीदा पार्टी का समर्थन करें|
                     </strong>
                  </Alert>
               ))}
         </Box>
         <Box id="boxModals">
            <Modal
               open={openModalSupport}
               onClose={handleCloseModalSupport}
               aria-labelledby="modal-modal-title-support"
               aria-describedby="modal-modal-description-support"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-support"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Support a political party for the month of{' '}
                     <strong>{moment(presentDate).format('MMM YYYY')}</strong>. {'\n'}{' '}
                     <strong>{moment(presentDate).format('MMM YYYY')}</strong> के लिए एक राजनीतिक दल का समर्थन करें|
                  </Typography>
                  <SupportPPartyNational
                     partiesList={partiesList}
                     uploading={uploading}
                     uploadingFailed={uploadingFailed}
                     uploaded={uploaded}
                     handleAddSupport={handleAddSupport}
                     handleCancelSupport={handleCancelSupport}
                  ></SupportPPartyNational>
               </Box>
            </Modal>
            <Dialog
               open={openDialogNotLoggedIn}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogNotLoggedIn}
               aria-describedby="alert-dialog-slide-notLoggedIn"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-notLoggedIn"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     You are not logged-in.
                     {'\n'}
                     आपने लॉग - इन नहीं किया है।
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogThanks}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogThanks}
               aria-describedby="alert-dialog-slide-Thanks"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-su"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  ></DialogContentText>
                  <Typography sx={{ color: '#4caf50' }}>
                     Thanks for showing support to your favourite political party.
                  </Typography>
                  <br />
                  <br />
                  <Typography sx={{ color: '#4caf50' }}>
                     अपने पसंदीदा राजनीतिक दल को समर्थन दिखाने के लिए धन्यवाद।
                  </Typography>
                  <br />
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogThanks}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogAlreadySupported}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAlreadySupported}
               aria-describedby="alert-dialog-slide-alreadySupported"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-alreadySupported"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     {userProfile &&
                        userProfile.LS_Party_National &&
                        Object.keys(userProfile.LS_Party_National).length !== 0 && (
                           <>
                              You have already supported a political party on{' '}
                              <strong>
                                 {moment(userProfile.LS_Party_National.CrDt.toDate()).format('Do MMM YYYY')}
                              </strong>
                              . <br />
                              <span style={{ color: 'Green' }}>
                                 Please feel free to support you favourite party <strong>next month</strong>.
                              </span>
                              <br />
                              <br />
                              आपने{' '}
                              <strong>
                                 {moment(userProfile.LS_Party_National.CrDt.toDate()).format('Do MMM YYYY')}
                              </strong>{' '}
                              को पहले ही एक राजनीतिक पार्टी का समर्थन किया है।
                              <br />
                              <span style={{ color: 'Green' }}>
                                 कृपया <strong>अगले महीने</strong> अपनी पसंदीदा पार्टी का समर्थन करने में संकोच न करें।
                              </span>
                           </>
                        )}
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogAlreadySupported}>Close</Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Box>
   )
}

export default PPartiesTop10
