import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import React from 'react'

function MinistryOB() {
   return (
      <Box>
         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               Office bearers: {'\n'} पदाधिकारी:
            </Typography>
         </Box>
         <Box sx={{ mt: 10, mx: 10 }}>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
            >
               <AlertTitle sx={{ fontSize: 12 }}>
                  This service will be available soon. <br /> यह सेवा शीघ्र ही उपलब्ध होगी|
               </AlertTitle>
            </Alert>
         </Box>
      </Box>
   )
}

export default MinistryOB
