import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Link, Modal, Table, TableCell, TableRow, Typography } from '@mui/material'

import myStyles from './LegisMemBasicInfo.module.css'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { v4 } from 'uuid'
// import moment from 'moment'

// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import EditIcon from '@mui/icons-material/Edit'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'

import AddWeblink from '../../../Components/Edits/AddWeblink'
import AddImage from '../../../Components/Edits/AddImage'
import AddText from '../../../Components/Edits/AddText'
import AddEmail from '../../../Components/Edits/AddEmail'

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import AddPhone from '../../../Components/Edits/AddPhone'
import AddDate from '../../../Components/Edits/AddDate'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { Helmet } from 'react-helmet'
import moment from 'moment'

function LegisMemBasicInfo({ member }) {
   //

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [record, setRecord] = useState('')

   const [sourceImage, setSourceImage] = useState(member.Image)
   const [subCollectionDocId, setSubCollectionDocId] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setRecord({
            LegisMem_id: member.id,
            Candidate_id: member.Candidate_id,
            NameAsCandidate: member.NameAsCandidate,
            Name: member.Name,
            NameHindi: member.NameHindi,
            NameLocal: member.NameLocal,
            Age: member.Age,
            DoB: member.DoB,
            Gender: member.Gender,
            Constituency_id: member.Constituency_id,
            ConstituencyName: member.ConstituencyName,
            ConstituencyNameHindi: member.ConstituencyNameHindi,
            ConstituencyNameLocal: member.ConstituencyNameLocal,
            ConstituencyNumber: member.ConstituencyNumber,
            SeatType: member.SeatType,
            Image: member.Image,
            Party_id: member.PartyId,
            PartyName: member.PartyName,
            PartyNameHindi: member.PartyNameHindi,
            PartyNameLocal: member.PartyNameLocal,
            PartyAbbreviation: member.PartyAbbreviation,
            PartyFlag: member.PartyFlag,
            Alliances: member.Alliances,
            AlliancesNow: member.AlliancesNow,
            Votes: member.Votes,
            Election_id: member.Election_id,
            ElectionName: member.ElectionName,
            ElectionNameHindi: member.ElectionNameHindi,
            ElectionNameLocal: member.ElectionNameLocal,
            Legislature_id: member.Legislature_id,
            HouseNumber: member.HouseNumber,
            FromDate: member.FromDate,
            ToDate: member.ToDate,
            Oath: member.Oath,
            Resignation: member.Resignation,
            Disqualification: member.Disqualification,
            TermCompletionDate: member.TermCompletionDate,
            Crux_id: member.Crux_id,
            NetaProfile_id: member.NetaProfile_id,
         })

         setSubCollectionDocId('MemCoreList-' + member.HouseNumber)

         setFirstLoadStatus('success')

         console.log('LegisMems - first use effect blank')
      }
   }, [])

   useEffect(() => {
      if (record !== '') {
         console.log('record', record)
      }
   }, [record])

   useEffect(() => {
      if (record !== '') {
         console.log('MemCoreList', subCollectionDocId)
      }
   }, [subCollectionDocId])

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const memberRef = doc(db, 'LegisMembers', member.id)
            const memberCoreRef = doc(db, 'LegisMembers', member.Legislature_id, 'MemCoreLists', subCollectionDocId)

            await updateDoc(memberRef, {
               Name: textDetails.Text,
               NameOld: arrayUnion({
                  Name: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Name: textDetails.Text }

            await updateDoc(memberCoreRef, {
               Members: arrayRemove(record),
            })

            await updateDoc(memberCoreRef, {
               Members: arrayUnion(recordNew),
            })

            // update the name display
            member.Name = textDetails.Text
            setRecord({ ...record, Name: textDetails.Text })
            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const memberRef = doc(db, 'LegisMembers', member.id)
            const memberCoreRef = doc(db, 'LegisMembers', member.Legislature_id, 'MemCoreLists', subCollectionDocId)

            await updateDoc(memberRef, {
               NameHindi: textDetails.Text,
               NameHindiOld: arrayUnion({
                  NameHindi: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameHindi: textDetails.Text }

            await updateDoc(memberCoreRef, {
               Members: arrayRemove(record),
            })

            await updateDoc(memberCoreRef, {
               Members: arrayUnion(recordNew),
            })

            // update the name display
            member.NameHindi = textDetails.Text
            setRecord({ ...record, NameHindi: textDetails.Text })
            // close the modal
            setOpenModalNameHindi(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const memberRef = doc(db, 'LegisMembers', member.id)
            const memberCoreRef = doc(db, 'LegisMembers', member.Legislature_id, 'MemCoreLists', subCollectionDocId)

            await updateDoc(memberRef, {
               NameLocal: textDetails.Text,
               NameLocalOld: arrayUnion({
                  NameLocal: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameLocal: textDetails.Text }

            await updateDoc(memberCoreRef, {
               Members: arrayRemove(record),
            })

            await updateDoc(memberCoreRef, {
               Members: arrayUnion(recordNew),
            })

            // update the name display
            member.NameLocal = textDetails.Text
            setRecord({ ...record, NameLocal: textDetails.Text })
            // close the modal
            setOpenModalNameLocal(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to DoB modal

   const [openModalDoB, setOpenModalDoB] = useState(false)

   const handleOpenModalDoB = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalDoB(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalDoB = () => setOpenModalDoB(false)

   const handleAddDoB = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (dateDetails.Date !== '') {
               const dobForOld = {
                  DoB: dateDetails.Date,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }
               const memberRef = doc(db, 'LegisMembers', member.id)
               const memberCoreRef = doc(db, 'LegisMembers', member.Legislature_id, 'MemCoreLists', subCollectionDocId)

               await updateDoc(memberRef, {
                  DoB: dateDetails.Date,
                  DoBOld: arrayUnion(dobForOld),
               })

               const recordNew = { ...record, DoB: dateDetails.Date }

               await updateDoc(memberCoreRef, {
                  Members: arrayRemove(record),
               })

               await updateDoc(memberCoreRef, {
                  Members: arrayUnion(recordNew),
               })

               // update the name display
               member.DoB = dateDetails.Date
               setRecord({ ...record, Dob: dateDetails.Date })

               member.DoBOld.push(dobForOld)
               setOpenModalDoB(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Address modal

   // const [openModalAddress, setOpenModalAddress] = useState(false)

   // const handleOpenModalAddress = (e) => {
   //    e.preventDefault()
   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper) {
   //          setOpenModalAddress(true)
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }
   // const handleCloseModalAddress = () => setOpenModalAddress(false)

   // const handleAddAddress = async (addressDetails) => {
   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper) {
   //          if (
   //             addressDetails.State_id.length >= 3 &&
   //             addressDetails.Address.length >= 10 &&
   //             addressDetails.ValueRadio !== ''
   //          ) {
   //             ///
   //             addressDetails.CrBy = auth.currentUser.uid
   //             addressDetails.CrDt = new Date()
   //             addressDetails.CrIP = ip.data.ip
   //             console.log('stage 4 submit')

   //             addressDetails.Address = sentenceCase(addressDetails.Address)
   //             console.log('stage 5 submit')

   //             const memberRef = doc(db, 'LegisMembers', member.id)

   //             if (addressDetails.ValueRadio === 'Current address') {
   //                ///
   //                await updateDoc(candiRef, {
   //                   Address: addressDetails,
   //                   AddressOld: arrayUnion(addressDetails),
   //                })
   //                console.log('stage 6 submit')
   //                // update the address display
   //                candidate.Address.Address = addressDetails.Address
   //                candidate.Address.DistrictName = addressDetails.DistrictName
   //                candidate.Address.StateName = addressDetails.StateName
   //                candidate.Address.PinCode = addressDetails.PinCode
   //                console.log('stage 7 submit')
   //             } else {
   //                await updateDoc(candiRef, {
   //                   AddressOld: arrayUnion(addressDetails),
   //                })
   //                console.log('stage 8 submit')
   //             }

   //             // close modal
   //             setOpenModalAddress(false)
   //          }
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //

            const imageRef = ref(dbStorage, `LegisMembers/${member.Legislature_id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     Image: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const recordNew = { ...record, Image: url }

                  const memberRef = doc(db, 'LegisMembers', member.id)
                  const memberCoreRef = doc(
                     db,
                     'LegisMembers',
                     member.Legislature_id,
                     'MemCoreLists',
                     subCollectionDocId,
                  )
                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     await updateDoc(memberRef, {
                        Image: url,
                        ImageOld: arrayUnion(forOld),
                     })

                     await updateDoc(memberCoreRef, {
                        Members: arrayRemove(record),
                     })

                     await updateDoc(memberCoreRef, {
                        Members: arrayUnion(recordNew),
                     })

                     setRecord({ ...record, Image: url })
                     setSourceImage(url)
                  } else {
                     await updateDoc(memberRef, {
                        ImageOld: arrayUnion(forOld),
                     })
                  }
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Phone modal
   const [openModalPhone, setOpenModalPhone] = useState(false)

   const handleOpenModalPhone = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalPhone(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalPhone = () => setOpenModalPhone(false)

   const [lastPhone, setLastPhone] = useState('')
   const [uploadingPhone, setUploadingPhone] = useState(false)
   const [uploadedPhone, setUploadedPhone] = useState(false)
   const [uploadingFailedPhone, setUploadingFailedPhone] = useState(false)

   const handleAddPhone = async (phoneDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            phoneDetails.CrBy = auth.currentUser.uid
            phoneDetails.CrDt = new Date()
            phoneDetails.CrIP = ip.data.ip

            setLastPhone(phoneDetails.Phone.toString())
            setUploadingPhone(true)
            try {
               const memberRef = doc(db, 'LegisMembers', member.id)
               await updateDoc(memberRef, {
                  Phones: arrayUnion(phoneDetails.Phone),
                  PhonesOld: arrayUnion(phoneDetails),
               })

               // update the website display
               member.Phones.push(phoneDetails.Phone)

               // close modal
               // setOpenModalPhone(false)

               setUploadingPhone(false)
               setUploadedPhone(true)
               setUploadingFailedPhone(false)
            } catch (error) {
               alert('Error adding Phone: ', error.message)
               console.log('Error adding Phone', error)
               setUploadingPhone(false)
               setUploadedPhone(false)
               setUploadingFailedPhone(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            const memberRef = doc(db, 'LegisMembers', member.id)
            await updateDoc(memberRef, {
               WebLinks: arrayUnion(weblinkDetails.Weblink),
               WebLinksOld: arrayUnion(weblinkDetails),
            })

            // update the website display
            member.WebLinks.push(weblinkDetails.Weblink)

            // close modal
            // setOpenModalWebsite(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Email modal
   const [openModalEmail, setOpenModalEmail] = useState(false)

   const handleOpenModalEmail = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalEmail(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalEmail = () => setOpenModalEmail(false)

   const handleAddEmail = async (emailDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            emailDetails.CrBy = auth.currentUser.uid
            emailDetails.CrDt = new Date()
            emailDetails.CrIP = ip.data.ip

            const memberRef = doc(db, 'LegisMembers', member.id)
            await updateDoc(memberRef, {
               Emails: arrayUnion(emailDetails.Email),
               EmailsOld: arrayUnion(emailDetails),
            })

            // update the website display
            member.Emails.push(emailDetails.Email)

            // close modal
            setOpenModalEmail(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // const [foundIcon, setFoundIcon] = useState('')

   // function smSubstring(name) {
   //    if (name.substring(0, 25) === 'https://www.facebook.com/') {
   //       setFoundIcon('FacebookIcon')
   //    } else if (name.substring(0, 26) === 'https://www.instagram.com/') {
   //       setFoundIcon('InstagramIcon')
   //    } else if (name.substring(0, 25) === 'https://www.linkedin.com/') {
   //       setFoundIcon('LinkedInIcon')
   //    } else if (name.substring(0, 14) === 'https://x.com/') {
   //       setFoundIcon('XIcon')
   //    } else {
   //       setFoundIcon('')
   //    }
   // }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div>
            <div>
               <Helmet>
                  <title>{`Track Democracy - ${member.Name} - member of ${member.LegislatureName}`}</title>
               </Helmet>
            </div>
            <Box
               sx={{
                  display: 'flex',
                  borderRadius: 1,
                  flexDirection: 'column',
                  px: 2,
               }}
            >
               <Table sx={{ width: '95%' }}>
                  <tbody>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Body:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>
                                 {member.LegislatureName && (
                                    <Link
                                       href={`/legislature/${member.LegislatureName}/${member.Legislature_id}`}
                                       // sx={{ textDecoration: 'none !important', px: 0 }}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {member.LegislatureNameLocal
                                          ? `${member.LegislatureNameLocal} - ${member.LegislatureName}`
                                          : `${member.LegislatureName} - ${member.LegislatureNameHindi}`}
                                    </Link>
                                 )}
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           House number:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>{member.HouseNumber}</Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Constituency:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>
                                 {member.ConstituencyName && (
                                    <Link
                                       href={`/constituency/${member.ConstituencyName}/${member.Constituency_id}`}
                                       sx={{ mr: 1, textDecoration: 'none !important' }}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {member.ConstituencyNameLocal
                                          ? `${member.ConstituencyNameLocal} (${member.ConstituencyNumber}) - ${member.ConstituencyName} - ${member.ConstituencyNameHindi}`
                                          : `${member.ConstituencyName} (${member.ConstituencyNumber}) - ${member.ConstituencyNameHindi}`}
                                    </Link>
                                 )}
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Seat:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>{member.SeatType}</Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Election:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                              }}
                           >
                              <Link
                                 href={`/election/${member.ElectionName}/${member.Election_id}`}
                                 rel="noreferrer"
                                 target="_blank"
                              >
                                 {member.ElectionName}
                              </Link>
                              {member.ElectionNameLocal ? (
                                 <Link
                                    href={`/election/${member.ElectionName}/${member.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {member.ElectionNameLocal}
                                 </Link>
                              ) : (
                                 <Link
                                    href={`/election/${member.ElectionName}/${member.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {member.ElectionNameHindi}
                                 </Link>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{member.Name}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editName"
                                       onClick={(e) => {
                                          handleOpenModalName(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalName}
                                    onClose={handleCloseModalName}
                                    aria-labelledby="modal-modal-title-name"
                                    aria-describedby="modal-modal-description-name"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-name"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Change name
                                       </Typography>
                                       <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name hindi:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{member.NameHindi}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameHindi"
                                       onClick={(e) => {
                                          handleOpenModalNameHindi(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameHindi}
                                    onClose={handleCloseModalNameHindi}
                                    aria-labelledby="modal-modal-title-Hindiname"
                                    aria-describedby="modal-modal-description-Hindiname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-hindiname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in hindi
                                       </Typography>
                                       <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name local:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{member.NameLocal}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameLocal"
                                       onClick={(e) => {
                                          handleOpenModalNameLocal(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameLocal}
                                    onClose={handleCloseModalNameLocal}
                                    aria-labelledby="modal-modal-title-localname"
                                    aria-describedby="modal-modal-description-localname"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-localname"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add name in local language
                                       </Typography>
                                       <AddText
                                          handleAddText={handleAddNameLocal}
                                          labelName="Name (in local language)"
                                       ></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Tenure {'\n'} कार्यकाल:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {member.FromDate && member.FromDate !== '' && (
                                 <>
                                    <Box sx={{ mr: 1 }}>
                                       <>{moment(member.FromDate.toDate()).format('Do MMM YYYY')}</>
                                    </Box>{' '}
                                    -{' '}
                                 </>
                              )}
                              {member.ToDate && member.ToDate !== '' && (
                                 <Box sx={{ ml: 1 }}>
                                    <>{moment(member.ToDate.toDate()).format('Do MMM YYYY')}</>
                                 </Box>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Birth date {'\n'} जन्म तिथि:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {member.DoB !== '' && (
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                       <Box
                                          sx={{
                                             mr: 1,
                                             color: 'GrayText',
                                             fontSize: 12,
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'flex-end',
                                          }}
                                       >
                                          Date of Birth:
                                       </Box>
                                       <Box>
                                          {member.DoB && member.DoB !== '' && (
                                             <>{moment(member.DoB.toDate()).format('Do MMMM YYYY')}</>
                                          )}
                                       </Box>
                                    </Box>
                                 )}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editDoB"
                                    onClick={(e) => {
                                       handleOpenModalDoB(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalDoB}
                                 onClose={handleCloseModalDoB}
                                 aria-labelledby="modal-modal-title-dob"
                                 aria-describedby="modal-modal-description-dob"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-dob"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Date Of Birth
                                    </Typography>
                                    <AddDate
                                       labelName="Date of Birth / जन्म तिथि"
                                       handleAddDate={handleAddDoB}
                                    ></AddDate>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Age at election {'\n'} चुनाव समय आयु:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>{member.Age}</Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Image:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                 }}
                              >
                                 <Box>
                                    {sourceImage !== '' ? <img src={sourceImage} className={myStyles.imgX} /> : null}
                                 </Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editImage"
                                       onClick={(e) => {
                                          handleOpenModalImage(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalImage}
                                    onClose={handleCloseModalImage}
                                    aria-labelledby="modal-modal-title-logo"
                                    aria-describedby="modal-modal-description-logo"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-logo"
                                          variant="h6"
                                          component="h2"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add image of member
                                       </Typography>
                                       <AddImage handleAddImage={handleAddImage}></AddImage>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Party:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 pt: 0.5,
                              }}
                           >
                              {member.PartyFlag && (
                                 <Link
                                    href={`/political-party/${member.PartyName}/${member.Party_id}`}
                                    sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    <Box sx={{ mr: 1 }}>
                                       <img src={member.PartyFlag} className={myStyles.imgX} />
                                    </Box>
                                 </Link>
                              )}
                              {member.PartyName === 'Independent' ? (
                                 <Box
                                    sx={{
                                       whiteSpace: 'pre-line',
                                    }}
                                 >
                                    {member.PartyName} {'\n'} {member.PartyNameHindi} {'\n'} {member.PartyNameLocal}
                                 </Box>
                              ) : (
                                 <Link
                                    href={`/political-party/${member.PartyName}/${member.Party_id}`}
                                    sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    <Box
                                       sx={{
                                          whiteSpace: 'pre-line',
                                       }}
                                    >
                                       {member.PartyName} {'\n'} {member.PartyNameHindi} {'\n'} {member.PartyNameLocal}
                                    </Box>
                                 </Link>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     {/* <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Alliances {'\n'} गठबंधन:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.Alliances.map((itemAl, index) => (
                                    <>
                                       {index + 1}.
                                       <Link
                                          key={itemAl.Alliance_id}
                                          href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                          sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                          rel="noreferrer"
                                          target="_blank"
                                       >
                                          {itemAl.Abbreviation}
                                       </Link>
                                    </>
                                 ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAddress"
                                    onClick={(e) => {
                                       handleOpenModalAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow> */}
                     {/* <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Address {'\n'} पता:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {member.Address && (
                                 <Box>
                                    <div>{member.Address.Address}</div>
                                    <span>
                                       {member.Address.DistrictName} - {member.Address.StateName} -{' '}
                                       {member.Address.PinCode}
                                    </span>
                                 </Box>
                              )}

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAddress"
                                    onClick={(e) => {
                                       handleOpenModalAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalAddress}
                                 onClose={handleCloseModalAddress}
                                 aria-labelledby="modal-modal-title-headquarter"
                                 aria-describedby="modal-modal-description-headquarter"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-headquarter"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Address
                                    </Typography>
                                    <AddAddress
                                       countryId={candidate.Country_id}
                                       handleAddAddress={handleAddAddress}
                                    ></AddAddress>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow> */}
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Phones {'\n'} दूरभाष:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {member.Phones &&
                                    member.Phones.length > 0 &&
                                    member.Phones.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editPhone"
                                    onClick={(e) => {
                                       handleOpenModalPhone(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalPhone}
                                 onClose={handleCloseModalPhone}
                                 aria-labelledby="modal-modal-title-phones"
                                 aria-describedby="modal-modal-description-phones"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-phones"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Phones:
                                    </Typography>
                                    <AddPhone
                                       lastPhone={lastPhone}
                                       handleAddPhone={handleAddPhone}
                                       uploadingPhone={uploadingPhone}
                                       uploadedPhone={uploadedPhone}
                                       uploadingFailedPhone={uploadingFailedPhone}
                                    ></AddPhone>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Web Links {'\n'} वेब लिंक:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>
                                 {member.WebLinks &&
                                    member.WebLinks.length > 0 &&
                                    member.WebLinks.map((item, index) => (
                                       <div key={index}>
                                          {item &&
                                             (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                                <Link
                                                   underline="hover"
                                                   href={item}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                   {item.substring(0, 25) === 'https://www.facebook.com/' && (
                                                      <FacebookIcon />
                                                   )}
                                                   {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                                      <InstagramIcon />
                                                   )}
                                                   {item.substring(0, 25) === 'https://www.linkedin.com/' && (
                                                      <LinkedInIcon />
                                                   )}
                                                   {item.substring(0, 14) === 'https://x.com/' && <XIcon />}
                                                </Link>
                                             )}
                                          {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                             <Link
                                                underline="hover"
                                                href={`//${item}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                             >
                                                {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                                   <FacebookIcon />
                                                ) : item.substring(0, 26) === 'https://www.instagram.com/' ? (
                                                   <InstagramIcon />
                                                ) : item.substring(0, 25) === 'https://www.linkedin.com/' ? (
                                                   <LinkedInIcon />
                                                ) : item.substring(0, 14) === 'https://x.com/' ? (
                                                   <XIcon sx={{ color: '#000000' }} />
                                                ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                                   <YouTubeIcon />
                                                ) : (
                                                   item
                                                )}
                                             </Link>
                                          )}
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editWebsite"
                                    onClick={(e) => {
                                       handleOpenModalWebsite(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalWebsite}
                                 onClose={handleCloseModalWebsite}
                                 aria-labelledby="modal-modal-title-website"
                                 aria-describedby="modal-modal-description-website"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-website"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add a web link
                                    </Typography>
                                    <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Emails {'\n'} ईमेल:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {member.Emails &&
                                    member.Emails.length > 0 &&
                                    member.Emails.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editEmail"
                                    onClick={(e) => {
                                       handleOpenModalEmail(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalEmail}
                                 onClose={handleCloseModalEmail}
                                 aria-labelledby="modal-modal-title-email"
                                 aria-describedby="modal-modal-description-email"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-email"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add an email
                                    </Typography>
                                    <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  </tbody>
               </Table>
            </Box>
         </div>
      </LocalizationProvider>
   )
}

LegisMemBasicInfo.propTypes = {
   member: PropTypes.object.isRequired,
}

export default LegisMemBasicInfo
