import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { collection, doc, deleteDoc, addDoc, query, getDocs } from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import axios from 'axios'

const initialState = {
   pPartiesList: [],
   status: 'idle',
}

export const pPartiesSlice = createSlice({
   name: 'pParties',
   initialState,
   reducers: {
      setPPartiesList: (state, action) => {
         state.pPartiesList = action.payload
      },
      resetPParties: () => {
         return initialState
      },
   },

   extraReducers(builder) {
      builder
         .addCase(fetchPParties.pending, (state, action) => {
            console.log('loading')
            state.status = 'loading'
         })
         .addCase(fetchPParties.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.pPartiesList = action.payload
         })
         .addCase(fetchPParties.rejected, (state, action) => {
            state.status = 'failed'
            console.log(action.error.message)
         })

         .addCase(erasePParty.fulfilled, (state, action) => {
            state.pPartiesList = state.pPartiesList.filter((pParty) => pParty.id !== action.payload)
            console.log('deleted')
         })
         .addCase(erasePParty.rejected, (state, action) => {
            state.status = 'failed'
            console.log(action.error.message)
         })
         .addCase(addPParty.fulfilled, (state, action) => {
            state.pPartiesList.push(action.payload)
         })
         .addCase(addPParty.rejected, (state, action) => {
            state.status = 'failed'
            console.log(action.error.message)
         })
   },
})

export const { resetPParties, setPPartiesList } = pPartiesSlice.actions

export const selectPParties = (state) => state.pParties

export default pPartiesSlice.reducer

export const fetchPParties = createAsyncThunk('pParties/fetchPParties', async () => {
   const q = query(collection(db, 'BaseLists', 'PoliticalParties-India', 'PartiesLists'))
   const querySnapshot = await getDocs(q)

   let pPartiesListFetched = []

   querySnapshot.forEach((doc) => {
      pPartiesListFetched = [...pPartiesListFetched, ...doc.data().Parties]
      console.log('parties from slice - for each: ', doc.data().Parties)
   })

   console.log('parties from slice - after for each: ', pPartiesListFetched)

   const pPartiesList = pPartiesListFetched.sort((a, b) => {
      if (b.Name.trim() > a.Name.trim()) {
         return -1
      } else if (b.Name.trim() < a.Name.trim()) {
         return 1
      } else {
         return 0
      }
   })
   console.log('parties from slice - after sorting: ', pPartiesListFetched)
   return pPartiesList
})

export const erasePParty = createAsyncThunk('pParties/erasePParty', async (payload) => {
   await deleteDoc(doc(db, 'PoliticalParties', payload))
   return payload
})

export const addPParty = createAsyncThunk('pParties/addPParty', async (payload) => {
   const newPParty = payload
   newPParty.CrBy = auth.currentUser.uid
   newPParty.CrDt = new Date()
   const ip = await axios.get('https://ipapi.co/json')
   newPParty.CrIP = ip.data.ip
   console.log('payload: ', payload)
   console.log('newPParty: ', newPParty)
   const docRef = await addDoc(collection(db, 'PoliticalParties'), newPParty)
   newPParty.id = docRef.id
   return newPParty
})
