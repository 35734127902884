import { Avatar, Box, Divider, Link, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function HomeCentralLegis({ props }) {
   const individualCountry = props

   const [initialState, setInitialState] = useState('idle')
   const [legisList, setLegisList] = useState('')

   useEffect(() => {
      if (initialState === 'idle') {
         const list = individualCountry.Legislatures
         const listLegislative = list.filter((item) => item.Type === 'Legislative')

         const sortedListLegis = [...listLegislative].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegisList(sortedListLegis)

         setInitialState('success')
      }
   }, [])

   return (
      <Box sx={{ p: 0.5 }}>
         <List dense>
            {legisList &&
               legisList.length > 0 &&
               legisList.map((item, index) => (
                  <Link
                     key={item.Legislature_id}
                     href={`/legislature/${item.Name}/${item.Legislature_id}`}
                     sx={{ textDecoration: 'none !important', px: 0 }}
                     rel="noreferrer"
                     target="_blank"
                  >
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemAvatar>
                           <Avatar alt="Travis Howard" src={item.Image} />
                        </ListItemAvatar>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                           primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                           primary={item.Name}
                           secondary={item.NameHindi}
                        />
                     </ListItem>
                     <Divider />
                  </Link>
               ))}
         </List>
      </Box>
   )
}

HomeCentralLegis.propTypes = {
   props: PropTypes.object.isRequired,
}

export default HomeCentralLegis
