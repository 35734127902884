import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { auth, db } from '../../FirebaseConfig'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   FormControlLabel,
   FormHelperText,
   FormLabel,
   IconButton,
   InputAdornment,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemText,
   OutlinedInput,
   Radio,
   RadioGroup,
   TextField,
   Typography,
} from '@mui/material'
import { selectUsers, setUser, setUserProfile } from '../../redux/reducers/users-slice'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import validator from 'validator'
import { Transition } from '../../Utils/MUITheme/MUITheme'
import { sentenceCase } from '../../Utils/Conversions/CaseConversion'
import { addMonthsToDate } from '../../Utils/Functions/Functions'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function Signup({ isUserLoggedIn, handleIsUserLoggedIn, userProfile }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   let fullName = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast
   }

   const dispatch = useDispatch()

   const [mode, setMode] = useState('email')
   const [error, setError] = useState('')

   useEffect(() => {
      setMode('email')
   }, [])

   // area for sign up

   const [signupCreds, setSignupCreds] = useState({
      nameFirst: '',
      nameMiddle: '',
      nameLast: '',
      displayName: '',
      gender: '',
      doB: '',
      phone: '',
      email: '',
      password: '',
   })
   const [showPassword, setShowPassword] = useState(false)
   const [passwordConfirm, setPasswordConfirm] = useState('')
   const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
   const [confirmation, setConfirmation] = useState('')

   function handleSignupCreds(e) {
      // console.log(e.target.value)
      setSignupCreds({ ...signupCreds, [e.target.name]: sentenceCase(e.target.value).trim() })
   }

   function handleEmail(e) {
      // console.log(e.target.value)
      setSignupCreds({ ...signupCreds, [e.target.name]: e.target.value.trim() })
   }

   const handleGender = (e) => {
      e.preventDefault()
      setSignupCreds({ ...signupCreds, gender: e.target.value })
   }

   const age17 = dayjs().subtract(17, 'year')
   const age100 = dayjs().subtract(100, 'year')

   const [doB, setDoB] = useState('')
   useEffect(() => {
      if (doB) {
         setSignupCreds({ ...signupCreds, doB: doB.$d })
      }
   }, [doB])

   const handleClickShowPassword = () => setShowPassword((show) => !show)

   const handleMouseDownPassword = (event) => {
      event.preventDefault()
   }

   const handleMouseUpPassword = (event) => {
      event.preventDefault()
   }

   // sign up area - password checking
   const [passwordStrength, setPasswordStrength] = useState('NEUTRAL')
   // const [Icon, setIcon] = useState('ErrorOutlineOutlined')
   // const [iconColors, setIconColors] = useState([])
   const [hasUC, setHasUC] = useState(false)
   const [hasLC, setHasLC] = useState(false)
   const [hasNumber, setHasNumber] = useState(false)
   const [hasSpecialC, setHasSpecialC] = useState(false)
   const [has8C, setHas8C] = useState(false)

   const atLeastMinimumLength = (password) => /(?=.{8,})/.test(password)
   const atLeastOneUppercaseLetter = (password) => /(?=.*?[A-Z])/.test(password)
   const atLeastOneLowercaseLetter = (password) => /(?=.*?[a-z])/.test(password)
   const atLeastOneNumber = (password) => /(?=.*?[0-9])/.test(password)
   const atLeastOneSpecialChar = (password) => /(?=.*?[#?!@$ _%^&*-])/.test(password)

   function handlePassword(e) {
      //
      setSignupCreds({ ...signupCreds, password: e.target.value })
      const password = e.target.value
      if (!password || password === '' || password.length === 0) {
         setPasswordStrength('NEUTRAL')
         setHasUC(false)
         setHasLC(false)
         setHasNumber(false)
         setHasSpecialC(false)
         setHas8C(false)
      } else {
         let points = 0
         if (atLeastOneUppercaseLetter(password)) {
            points += 1
            setHasUC(true)
         } else {
            setHasUC(false)
         }
         if (atLeastOneLowercaseLetter(password)) {
            points += 1
            setHasLC(true)
         } else {
            setHasLC(false)
         }
         if (atLeastOneNumber(password)) {
            points += 1
            setHasNumber(true)
         } else {
            setHasNumber(false)
         }
         if (atLeastOneSpecialChar(password)) {
            points += 1
            setHasSpecialC(true)
         } else {
            setHasSpecialC(false)
         }
         if (atLeastMinimumLength(password)) {
            points += 1
            setHas8C(true)
         } else {
            setHas8C(false)
         }

         if (points >= 5) {
            setPasswordStrength('STRONG')
         }
         if (points === 3 || points === 4) {
            setPasswordStrength('MEDIUM')
         }
         if (points === 1 || points === 2) {
            setPasswordStrength('WEAK')
         }
      }
   }

   // useEffect(() => {
   //    console.log('passwordStrength on change: ', passwordStrength)
   //    const colors = {
   //       NEUTRAL: 'hsla(0, 0%, 88%, 1)',
   //       WEAK: 'hsla(353, 100%, 38%, 1)',
   //       MEDIUM: 'hsla(40, 71%, 51%, 1)',
   //       STRONG: 'hsla(134, 73%, 30%, 1)',
   //    }
   //    switch (passwordStrength) {
   //       case 'NEUTRAL':
   //          setIcon(ErrorOutlineOutlined)
   //          setIconColors([colors.NEUTRAL, colors.NEUTRAL, colors.NEUTRAL, colors.NEUTRAL])
   //          break
   //       case 'WEAK':
   //          setIcon(ErrorOutlineOutlined)
   //          setIconColors([colors.WEAK, colors.WEAK, colors.NEUTRAL, colors.NEUTRAL])
   //          break
   //       case 'MEDIUM':
   //          setIcon(ErrorOutlineOutlined)
   //          setIconColors([colors.MEDIUM, colors.MEDIUM, colors.MEDIUM, colors.NEUTRAL])
   //          break
   //       case 'STRONG':
   //          setIcon(CheckOutlined)
   //          setIconColors([colors.STRONG, colors.STRONG, colors.STRONG, colors.STRONG])
   //          break
   //    }
   // }, [passwordStrength])

   const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show)

   const handleMouseDownPasswordConfirm = (event) => {
      event.preventDefault()
   }

   const handleMouseUpPasswordConfirm = (event) => {
      event.preventDefault()
   }

   function handlePasswordConfirm(e) {
      //
      setPasswordConfirm(e.target.value)
      if (e.target.value !== '') {
         if (e.target.value === signupCreds.password) {
            //
            setConfirmation(true)
         } else {
            //
            setConfirmation(false)
         }
      } else {
         setConfirmation('')
      }
   }

   function handlePhone4Signup(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setSignupCreds({ ...signupCreds, [e.target.name]: newValue })
            : setSignupCreds({ ...signupCreds, [e.target.name]: '' })
      } else {
         setSignupCreds({ ...signupCreds, [e.target.name]: '' })
      }
   }

   useEffect(() => {
      console.log('signupCreds on change: ', signupCreds)
   }, [signupCreds])

   const [signupError, setSignupError] = useState(false)
   const [openLegal, setOpenLegal] = useState(false)

   function handleSignup(e) {
      e.preventDefault()
      console.log('details at submit', signupCreds)
      if (
         signupCreds.nameFirst.length > 1 &&
         signupCreds.nameLast.length > 1 &&
         signupCreds.doB !== '' &&
         signupCreds.gender !== '' &&
         !isNaN(signupCreds.phone) &&
         signupCreds.phone.toString().length === 10 &&
         validator.isEmail(signupCreds.email) &&
         passwordStrength === 'STRONG' &&
         confirmation === true
      ) {
         setOpenLegal(true)
         setSignupError(false)
      } else {
         //
         setOpenLegal(false)
         setSignupError(true)
      }
   }

   // for legal condition dialog

   const handleRejectLegal = () => {
      setSignupCreds({
         nameFirst: '',
         nameMiddle: '',
         nameLast: '',
         displayName: '',
         gender: '',
         doB: '',
         phone: '',
         email: '',
         password: '',
      })
      setOpenLegal(false)
   }

   const handleSubmitLegal = async () => {
      const querryUserProfile = query(collection(db, 'UserProfiles'), where('MobilePhone', '==', signupCreds.phone))
      const querySnapshot = await getDocs(querryUserProfile)

      if (querySnapshot.exists) {
         // throw exception
         alert(
            `Phone number ${signupCreds.phone} is already held by a user of this site. If its you, please dont create multiple accounts.`,
         )
      } else {
         //
         const querryUserProfile2 = query(collection(db, 'UserProfiles'), where('Email', '==', signupCreds.email))
         const querySnapshot2 = await getDocs(querryUserProfile2)
         if (querySnapshot2.exists) {
            // throw exception
            alert(
               `Email id ${signupCreds.email} is already held by a user of this site. If its you, please dont create multiple accounts.`,
            )
         } else {
            //
            const nameComplete = signupCreds.nameFirst + ' ' + signupCreds.nameMiddle + ' ' + signupCreds.nameLast
            setSignupCreds({ ...signupCreds, displayName: nameComplete })
         }

         setOpenLegal(false)
      }
   }

   useEffect(() => {
      //
      if (signupCreds.displayName !== '') {
         setError('')
         createUserWithEmailAndPassword(auth, signupCreds.email, signupCreds.password, signupCreds.displayName)
            .then(async (userCredential) => {
               handleIsUserLoggedIn('in')
               console.log('userCredential: ', userCredential)
               console.log('userCredential -> user: ', userCredential.user)
               dispatch(
                  setUser({
                     id: userCredential.user.uid,
                     email: userCredential.user.email,
                     displayName: userCredential.user.displayName,
                  }),
               )

               const newUserProfile = {
                  User_id: userCredential.user.uid,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,

                  NameFirst: signupCreds.nameFirst,
                  NameMiddle: signupCreds.nameMiddle,
                  NameLast: signupCreds.nameLast,

                  NamesOld: [
                     {
                        NameFirst: signupCreds.nameFirst,
                        NameMiddle: signupCreds.nameMiddle,
                        NameLast: signupCreds.nameLast,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ],

                  NameHindiFirst: '',
                  NameHindiMiddle: '',
                  NameHindiLast: '',
                  NamesHindiOld: [],

                  NameLocalFirst: '',
                  NameLocalMiddle: '',
                  NameLocalLast: '',
                  NamesLocalOld: [],

                  MobilePhone: signupCreds.phone,
                  MobilePhonesOld: [
                     {
                        Phone: signupCreds.phone,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ],
                  DoB: signupCreds.doB,
                  DoBsOld: [
                     {
                        DoB: signupCreds.doB,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ],
                  Gender: signupCreds.gender,
                  GenderOld: [
                     {
                        Gender: signupCreds.gender,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ],
                  Image: '',
                  ImagesOld: [],

                  Email: signupCreds.email,
                  EmailsOld: [{ Email: signupCreds.email, CrDt: new Date(), CrIP: ip.data.ip }],

                  NetaProfile: '',
                  NetaProfileActive: false,
                  NetaConstituencies: [],
                  CandidateProfiles: [],
                  CandidateProfilesHelpers: [],
                  LegisMemProfiles: [],
                  LegisMemProfilesHelpers: [],

                  AtCentralMinistries: true,
                  AtCentralExecutive: true,
                  AtCentralConstituency: '',
                  AtCentral: '',

                  AtState: '',
                  AtStateConstituency: '',
                  AtDivision: '',
                  AtDivisionConstituency: '',
                  AtDistrict: '',
                  AtDistrictConstituency: '',
                  AtSubDivision: '',
                  AtSubDivisionConstituency: '',
                  AtBlock: '',
                  AtBlockConstituency: '',
                  AtVillage: '',
                  AtVillageConstituency: '',
                  AtMunicipality: '',
                  AtMunicipalityConstituency: '',

                  LR_Center: '',
                  LR_CenterConstituency: '',
                  LR_State: '',
                  LR_StateConstituency: '',
                  LR_Division: '',
                  LR_DivisionConstituency: '',
                  LR_District: '',
                  LR_DistrictConstituency: '',
                  LR_SubDivision: '',
                  LR_SubDivisionConstituency: '',
                  LR_Block: '',
                  LR_BlockConstituency: '',
                  LR_Village: '',
                  LR_VillageConstituency: '',
                  LR_Municipality: '',
                  LR_MunicipalityConstituency: '',

                  LP_Center: '',
                  LP_CenterConstituency: '',
                  LP_State: '',
                  LP_StateConstituency: '',
                  LP_Division: '',
                  LP_DivisionConstituency: '',
                  LP_District: '',
                  LP_DistrictConstituency: '',
                  LP_SubDivision: '',
                  LP_SubDivisionConstituency: '',
                  LP_Block: '',
                  LP_BlockConstituency: '',
                  LP_Village: '',
                  LP_VillageConstituency: '',
                  LP_Municipality: '',
                  LP_MunicipalityConstituency: '',

                  LR_Candi_Center: '',
                  LR_Candi_CenterConstituency: '',
                  LR_Candi_State: '',
                  LR_Candi_StateConstituency: '',
                  LR_Candi_Division: '',
                  LR_Candi_DivisionConstituency: '',
                  LR_Candi_District: '',
                  LR_Candi_DistrictConstituency: '',
                  LR_Candi_SubDivision: '',
                  LR_Candi_SubDivisionConstituency: '',
                  LR_Candi_Block: '',
                  LR_Candi_BlockConstituency: '',
                  LR_Candi_Village: '',
                  LR_Candi_VillageConstituency: '',
                  LR_Candi_Municipality: '',
                  LR_Candi_MunicipalityConstituency: '',

                  LS_Candi_Center: '',
                  LS_Candi_CenterConstituency: '',
                  LS_Candi_State: '',
                  LS_Candi_StateConstituency: '',
                  LS_Candi_Division: '',
                  LS_Candi_DivisionConstituency: '',
                  LS_Candi_District: '',
                  LS_Candi_DistrictConstituency: '',
                  LS_Candi_SubDivision: '',
                  LS_Candi_SubDivisionConstituency: '',
                  LS_Candi_Block: '',
                  LS_Candi_BlockConstituency: '',
                  LS_Candi_Village: '',
                  LS_Candi_VillageConstituency: '',
                  LS_Candi_Municipality: '',
                  LS_Candi_MunicipalityConstituency: '',

                  LS_Party_National: '',

                  SubscriptionGeneral: {
                     StartDate: new Date(),
                     EndDate: addMonthsToDate(new Date(), 2),
                     Duration: 2,
                     Rate: 365,
                     Amount: 0,
                     Transaction_id: '',
                     PaymentDate: new Date(),
                     CrIP: ip.data.ip,
                  },

                  SubscriptionLeader: {
                     StartDate: new Date(),
                     EndDate: addMonthsToDate(new Date(), 2),
                     Duration: 2,
                     Rate: 1500,
                     Amount: 0,
                     Transaction_id: '',
                     PaymentDate: new Date(),
                     CrIP: ip.data.ip,
                  },
               }
               console.log('new item: ', newUserProfile)
               const docRef = await addDoc(collection(db, 'UserProfiles'), newUserProfile)
               newUserProfile.id = docRef.id

               const docRefSubCollSG = doc(db, 'UserProfiles', docRef.id, 'CoreLists', 'Subscriptions-General')
               const docRefSubCollSL = doc(db, 'UserProfiles', docRef.id, 'CoreLists', 'Subscriptions-Leader')

               setDoc(
                  docRefSubCollSG,
                  {
                     Subscriptions: [
                        {
                           StartDate: new Date(),
                           EndDate: addMonthsToDate(new Date(), 2),
                           Duration: 2,
                           Rate: 365,
                           Amount: 0,
                           Transaction_id: '',
                           PaymentDate: new Date(),
                           CrIP: ip.data.ip,
                        },
                     ],
                  },
                  { merge: true },
               )

               setDoc(
                  docRefSubCollSL,
                  {
                     Subscriptions: [
                        {
                           StartDate: new Date(),
                           EndDate: addMonthsToDate(new Date(), 2),
                           Duration: 2,
                           Rate: 1500,
                           Amount: 0,
                           Transaction_id: '',
                           PaymentDate: new Date(),
                           CrIP: ip.data.ip,
                        },
                     ],
                  },
                  { merge: true },
               )

               dispatch(setUserProfile(newUserProfile))
               setSignupCreds({
                  nameFirst: '',
                  nameMiddle: '',
                  nameLast: '',
                  displayName: '',
                  gender: '',
                  doB: '',
                  phone: '',
                  email: '',
                  password: '',
               })
            })
            .catch((error) => {
               setError(error.message)
            })
      }
   }, [signupCreds.displayName])

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ minHeight: 500 }}>
            {!navigator.onLine && (
               <div>
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle>
                        You are offline !
                        <CloudOffIcon sx={{ ml: 5 }} />
                     </AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === '' && (
               <div>
                  <Alert variant="outlined" severity="warning" sx={{ margin: 5 }}>
                     <AlertTitle>... Loading !</AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === 'in' && (
               <div>
                  <Alert
                     variant="outlined"
                     severity="success"
                     sx={{
                        margin: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >
                     <AlertTitle>Welcome ... {fullName}!</AlertTitle>
                     You are logged in the site. Enjoy tracking democracy!
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === 'out' && (
               <Box>
                  <Box>
                     <Typography sx={{ fontFamily: 'Poppins', fontSize: 16, fontWeight: 600 }}>Sign up</Typography>
                  </Box>
                  {mode === 'email' && (
                     <Box
                        sx={{
                           my: 2,
                           mx: 5,
                           py: 1,
                           backgroundColor: '#F1F1F1',
                           display: 'flex',
                           flexDirection: 'column',
                           '& .MuiButton-root': { width: '15ch' },
                           '& .MuiTextField-root': { width: '50ch' },
                           justifyContent: 'center',
                           alignItems: 'center',
                        }}
                     >
                        <Alert variant="outlined" severity="warning">
                           Please register with your official name and birth date strictly.
                           <br />
                           कृपया अपने आधिकारिक नाम और जन्मतिथि के साथ ही पंजीकरण करें।
                        </Alert>
                        <div>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleSignupCreds(e)
                              }}
                              value={signupCreds.nameFirst}
                              name="nameFirst"
                              id="nameFirst"
                              label="First Name"
                              variant="outlined"
                              margin="dense"
                              required
                              fullWidth
                              sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                              helperText="First name must be at least two letters*."
                           ></TextField>
                        </div>
                        <div>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleSignupCreds(e)
                              }}
                              value={signupCreds.nameMiddle}
                              name="nameMiddle"
                              id="nameMiddle"
                              label="Middle Name"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                           ></TextField>
                        </div>
                        <div>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleSignupCreds(e)
                              }}
                              value={signupCreds.nameLast}
                              name="nameLast"
                              id="nameLast"
                              label="Last Name"
                              variant="outlined"
                              margin="dense"
                              required
                              fullWidth
                              sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                              helperText="Last name must be at least two letters*."
                           ></TextField>
                        </div>
                        <div>
                           <FormControl
                              size="small"
                              required
                              sx={{
                                 py: 0,
                                 px: 1,
                                 border: 1,
                                 borderColor: '#D6D6D6',
                                 borderRadius: 1,
                                 mb: 1,
                                 mt: 0.5,
                              }}
                           >
                              <FormLabel id="demo-controlled-radio-buttons-group">Gender:</FormLabel>
                              <RadioGroup
                                 row
                                 aria-labelledby="demo-controlled-radio-buttons-group"
                                 name="gender"
                                 value={signupCreds.gender}
                                 // style={{ display: 'flex', gap: '2rem' }}
                                 onChange={(e) => {
                                    handleGender(e)
                                 }}
                                 sx={{
                                    backgroundColor: '#ffffff',
                                    borderRadius: 1,
                                    py: 0,
                                    '& .MuiSvgIcon-root': {
                                       fontSize: 15,
                                    },
                                    '& .MuiButtonBase-root': {
                                       py: 0,
                                    },
                                    '& MuiFormControlLabel-label': {
                                       fontSize: 13,
                                       lineHeight: 16,
                                       py: 0,
                                    },
                                 }}
                              >
                                 <FormControlLabel
                                    value="Male"
                                    control={<Radio size="small" />}
                                    label="Male"
                                    sx={{ py: 0, fontSize: 15 }}
                                 />
                                 <FormControlLabel
                                    value="Female"
                                    control={<Radio size="small" />}
                                    label="Female"
                                    sx={{ py: 0, fontSize: 15 }}
                                 />
                                 <FormControlLabel
                                    value="Transgender"
                                    control={<Radio size="small" />}
                                    label="Transgender"
                                    sx={{ py: 0, fontSize: 15 }}
                                 />
                              </RadioGroup>
                           </FormControl>
                        </div>
                        <Box>
                           <DatePicker
                              label="Date of Birth"
                              name="doB"
                              required
                              value={signupCreds.doB != null ? dayjs(signupCreds.doB) : null}
                              format="DD-MMM-YYYY"
                              onChange={(newValue) => setDoB(newValue)}
                              views={['year', 'month', 'day']}
                              minDate={age100}
                              maxDate={age17}
                              sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1, mt: 1 }}
                           />
                        </Box>
                        <div>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handlePhone4Signup(e)
                              }}
                              value={signupCreds.phone}
                              name="phone"
                              id="phone"
                              label="Mobile Phone Number"
                              variant="outlined"
                              margin="dense"
                              required
                              fullWidth
                              inputProps={{ maxLength: 10 }}
                              size="small"
                              sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                              helperText="10 digit mobile phone number*."
                           ></TextField>
                        </div>
                        <div>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleEmail(e)
                              }}
                              value={signupCreds.email}
                              name="email"
                              id="email"
                              label="Email"
                              type="email"
                              variant="outlined"
                              margin="dense"
                              required
                              fullWidth
                              sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                              helperText="Email is required for password recovery and reset*."
                           ></TextField>
                        </div>
                        {/* <div>
                                 <TextField
                                    onBlur={(event) => event}
                                    // onChange={(event) => setPassword(event.target.value)}
                                    onChange={(e) => {
                                       handleSignupCreds(e)
                                    }}
                                    value={signupCreds.password}
                                    name="password"
                                    id="password"
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    margin="dense"
                                    align="center"
                                    fullWidth
                                    required
                                 ></TextField>
                              </div> */}
                        <Box>
                           <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                              <InputLabel htmlFor="password">Password</InputLabel>
                              <OutlinedInput
                                 id="password"
                                 type={showPassword ? 'text' : 'password'}
                                 sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          onMouseUp={handleMouseUpPassword}
                                          edge="end"
                                       >
                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                    </InputAdornment>
                                 }
                                 label="Password"
                                 autoComplete="current-password"
                                 onChange={(e) => {
                                    handlePassword(e)
                                 }}
                                 value={signupCreds.password}
                              />
                           </FormControl>
                           {/* <Box
                                    sx={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                       gap: 5,
                                       mt: 0,
                                       mx: 4,
                                    }}
                                 >
                                    {iconColors &&
                                       iconColors.length > 0 &&
                                       iconColors.map((color, index) => (
                                          <Box
                                             key={index}
                                             flex={1}
                                             sx={{ height: 5, borderRadius: 5, backgroundColor: color }}
                                          ></Box>
                                       ))}
                                    <Icon htmlColor={iconColors[0]} />
                                    <Typography sx={{ color: iconColors[0], fontSize: 12 }}>
                                       {passwordStrength}
                                    </Typography>
                                 </Box> */}
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'flex-start',
                                 gap: 1,
                                 mt: 0,
                                 width: '50ch',
                              }}
                           >
                              <Typography
                                 sx={{
                                    color:
                                       passwordStrength === 'STRONG'
                                          ? 'hsla(134, 73%, 30%, 1)'
                                          : 'hsla(353, 100%, 38%, 1)',
                                    fontSize: 14,
                                    fontWeight: 500,
                                 }}
                              >
                                 Minimum required:
                              </Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: 14, fontWeight: 500 }}>
                                 <span
                                    style={{
                                       color: hasUC ? 'hsla(134, 73%, 30%, 1)' : 'hsla(353, 100%, 38%, 1)',
                                    }}
                                 >
                                    1. One upper case letter
                                 </span>
                                 <span
                                    style={{
                                       color: hasLC ? 'hsla(134, 73%, 30%, 1)' : 'hsla(353, 100%, 38%, 1)',
                                    }}
                                 >
                                    2. One lower case letter
                                 </span>
                                 <span
                                    style={{
                                       color: hasSpecialC ? 'hsla(134, 73%, 30%, 1)' : 'hsla(353, 100%, 38%, 1)',
                                    }}
                                 >
                                    3. One special character (#, _, ?, !, @, $, %, ^, &, *, -)
                                 </span>
                                 <span
                                    style={{
                                       color: hasNumber ? 'hsla(134, 73%, 30%, 1)' : 'hsla(353, 100%, 38%, 1)',
                                    }}
                                 >
                                    4. One number
                                 </span>
                                 <span
                                    style={{
                                       color: has8C ? 'hsla(134, 73%, 30%, 1)' : 'hsla(353, 100%, 38%, 1)',
                                    }}
                                 >
                                    5. At leaset 8 characters
                                 </span>
                              </Box>
                              {/* <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'flex-end',
                                          gap: 1,
                                       }}
                                    >
                                       <Icon htmlColor={iconColors[0]} />
                                       <Typography sx={{ color: iconColors[0], fontSize: 12 }}>
                                          {passwordStrength}
                                       </Typography>
                                    </Box> */}
                           </Box>
                           {/* <Box
                                    sx={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       justifyContent: 'flex-start',
                                       gap: 5,
                                       margin: '0 0 15px',
                                    }}
                                 >
                                    <Icon htmlColor={iconColors[0]} />
                                    <Typography sx={{ color: iconColors[0] }}>{passwordStrength}</Typography>
                                 </Box> */}
                        </Box>
                        <Box>
                           <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                              <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                              <OutlinedInput
                                 id="confirmPassword"
                                 type={showPasswordConfirm ? 'text' : 'password'}
                                 sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPasswordConfirm}
                                          onMouseDown={handleMouseDownPasswordConfirm}
                                          onMouseUp={handleMouseUpPasswordConfirm}
                                          edge="end"
                                       >
                                          {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                    </InputAdornment>
                                 }
                                 label="Confirm Password"
                                 autoComplete="current-password"
                                 onChange={(e) => {
                                    handlePasswordConfirm(e)
                                 }}
                                 value={passwordConfirm}
                              />
                              {confirmation === true && (
                                 <FormHelperText
                                    sx={{ color: 'hsla(134, 73%, 30%, 1)' }}
                                    id="outlined-weight-helper-text"
                                 >
                                    The passwords match.
                                 </FormHelperText>
                              )}
                              {confirmation === false && (
                                 <FormHelperText
                                    sx={{ color: 'hsla(353, 100%, 38%, 1)' }}
                                    id="outlined-weight-helper-text"
                                 >
                                    The passwords do not match.
                                 </FormHelperText>
                              )}
                           </FormControl>
                        </Box>
                        {signupError && (
                           <Box>
                              <Alert variant="outlined" severity="error" sx={{ margin: 5 }}>
                                 <AlertTitle>Errors!</AlertTitle>
                                 Please complete the form properly!
                              </Alert>
                           </Box>
                        )}
                        <Box sx={{ mt: 1 }}>
                           <Button
                              type="submit"
                              align="center"
                              variant="contained"
                              color="primary"
                              onClick={(e) => {
                                 handleSignup(e)
                              }}
                              disabled={
                                 signupCreds.nameFirst.length < 2 ||
                                 signupCreds.nameLast.length < 2 ||
                                 signupCreds.doB === '' ||
                                 signupCreds.gender === '' ||
                                 isNaN(signupCreds.phone) ||
                                 signupCreds.phone.toString().length < 10 ||
                                 !validator.isEmail(signupCreds.email) ||
                                 passwordStrength !== 'STRONG' ||
                                 confirmation !== true
                              }
                           >
                              Sign Up
                           </Button>

                           {error && (
                              <Alert variant="outlined" severity="error" sx={{ margin: 5 }}>
                                 <AlertTitle>Errors!</AlertTitle>
                                 {error}
                              </Alert>
                           )}
                        </Box>
                     </Box>
                  )}
                  {mode === 'phone' && (
                     <Box>
                        <PhoneInput
                           country={'in'}
                           onlyCountries={['in']}
                           // value={this.state.phone}
                           // onChange={(phone) => this.setState({ phone })}
                        />
                     </Box>
                  )}
               </Box>
            )}
            <Box id="BoxModals">
               <Dialog
                  open={openLegal}
                  TransitionComponent={Transition}
                  keepMounted
                  // onClose={handleSubmitLegal}
                  aria-describedby="alert-dialog-slide-su"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`शपथ   Oath`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}>
                        <List sx={{ color: '#000000' }}>
                           <ListItem sx={{ p: 0 }}>
                              <ListItemText
                                 sx={{ my: 0 }}
                                 primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                 primary={
                                    <Typography component="span">
                                       <strong>1...</strong> I declare that I have entered factually, officially and
                                       legally correct information. {'\n'}
                                       मैं घोषणा करता हूँ कि मैंने तथ्यात्मक, आधिकारिक और कानूनी रूप से सही जानकारी
                                       पंजीकृत की है। {'\n'}
                                       <strong>2...</strong> I declare that I am creating an account on this site for
                                       the first time. {'\n'}
                                       मैं घोषणा करता हूँ कि मैं इस साइट पर पहली बार पंजीकरण कर रहा हूँ रहा हूँ। {'\n'}
                                       <strong>3...</strong> I declare that I do not hold any other account on this
                                       site. {'\n'}
                                       मैं घोषणा करता हूं कि इस साइट पर मेरा कोई अन्य पंजीकरण / खाता नहीं है।
                                       <strong>4...</strong> I declare that I have read
                                       <Link href={`/terms`} rel="noreferrer" target="_blank">
                                          the terms of use
                                       </Link>{' '}
                                       of this site and agrre to it. {'\n'}
                                       मैं घोषणा करता हूँ कि मैंने इस साइट के
                                       <Link href={`/terms`} rel="noreferrer" target="_blank">
                                          उपयोग की शर्तों{' '}
                                       </Link>{' '}
                                       को पढ़ लिया है और मैं उनसे सहमत हूँ।
                                    </Typography>
                                 }
                              />
                           </ListItem>
                           <ListItem sx={{ p: 0 }}>
                              <ListItemText
                                 sx={{ my: 0 }}
                                 primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                 primary={
                                    <Typography component="span">
                                       <strong>2...</strong> I take the moral and legal responsibility of the
                                       information entered. {'\n'}
                                       मैं पंजीकृत की गई जानकारी की नैतिक और कानूनी जिम्मेदारी लेता हूं|
                                    </Typography>
                                 }
                              />
                           </ListItem>
                        </List>
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                     <Button
                        variant="outlined"
                        onClick={handleSubmitLegal}
                        color="success"
                        sx={{ whiteSpace: 'pre-line' }}
                     >
                        Accept and Submit {'\n'} स्वीकार करें और प्रस्तुत करें
                     </Button>
                     <Button
                        variant="outlined"
                        onClick={handleRejectLegal}
                        color="error"
                        sx={{ whiteSpace: 'pre-line' }}
                     >
                        Cancel {'\n'} निरस्त करें
                     </Button>
                  </DialogActions>
               </Dialog>
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

Signup.propTypes = {
   isUserLoggedIn: PropTypes.string.isRequired,
   handleIsUserLoggedIn: PropTypes.func.isRequired,
   userProfile: PropTypes.object.isRequired,
}

export default Signup
