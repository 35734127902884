export const styles4ButtonGreen = {
   '&.MuiButton-contained': {
      textTransform: 'none',
      padding: 1,
      minWidth: 0,
      lineHeight: 1.1,
      fontFamily: ['Poppins'],
      whiteSpace: 'pre-line',
      color: '#FFFFFF',
      backgroundColor: '#2e7d32',
      '&:hover': {
         backgroundColor: '#1b5e20',
      },
   },
   '&.MuiButton-outlined': {
      color: '#4caf50',
      borderColor: '#4caf50',
      '&:hover': {
         color: '#2e7d32',
         borderColor: '#2e7d32',
      },
   },
}

export const styles4ButtonRed = {
   '&.MuiButton-contained': {
      textTransform: 'none',
      padding: 1,
      minWidth: 0,
      lineHeight: 1.1,
      fontFamily: ['Poppins'],
      whiteSpace: 'pre-line',
      color: '#FFFFFF',
      backgroundColor: '#d32f2f',
      '&:hover': {
         backgroundColor: '#c62828',
      },
   },
   '&.MuiButton-outlined': {
      color: '#ef5350',
      borderColor: '#ef5350',
      '&:hover': {
         color: '#d32f2f',
         borderColor: '#d32f2f',
      },
   },
}

export const styles4ButtonBlue = {
   '&.MuiButton-contained': {
      textTransform: 'none',
      padding: 1,
      minWidth: 0,
      lineHeight: 1.1,
      fontFamily: ['Poppins'],
      whiteSpace: 'pre-line',
      color: '#FFFFFF',
      backgroundColor: '#03a9f4',
      '&:hover': {
         backgroundColor: '#0288d1',
      },
   },
   '&.MuiButton-outlined': {
      color: '#03a9f4',
      borderColor: '#03a9f4',
      '&:hover': {
         color: '#0288d1',
         borderColor: '#0288d1',
      },
   },
}

export const styles4ButtonOrange = {
   '&.MuiButton-contained': {
      textTransform: 'none',
      padding: 1,
      minWidth: 0,
      lineHeight: 1.1,
      fontFamily: ['Poppins'],
      whiteSpace: 'pre-line',
      color: '#FFFFFF',
      backgroundColor: '#ed6c02',
      '&:hover': {
         backgroundColor: '#e65100',
      },
   },
   '&.MuiButton-outlined': {
      color: '#ff9800',
      borderColor: '#ff9800',
      '&:hover': {
         color: '#ed6c02',
         borderColor: '#ed6c02',
      },
   },
}
