import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../FirebaseConfig'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Divider,
   FormControl,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Select,
} from '@mui/material'
import PropTypes from 'prop-types'

function HomeStateLegis({ props }) {
   const individualCountry = props

   const [initialState, setInitialState] = useState('idle')
   const [legisList, setLegisList] = useState('')
   const [statesList, setStatesList] = useState('')
   const [selectedState, setSelectedState] = useState('')

   useEffect(() => {
      if (initialState === 'idle') {
         const sortedListStates = [...individualCountry.States].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setStatesList(sortedListStates)
         setInitialState('success')
      }
   }, [])

   const handleStateChange = (e) => {
      e.preventDefault()
      setSelectedState(e.target.value)
   }

   const [individualState, setIndividualState] = useState('')

   useEffect(() => {
      if (selectedState) {
         getIndividualState()
      }
   }, [selectedState])

   const getIndividualState = async () => {
      const docRef = doc(db, 'States', selectedState)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualState(docSnap.data())
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualState) {
         const list = individualState.Legislatures
         const listLegislative = list.filter((item) => item.Type === 'Legislative')

         const sortedListLegis = [...listLegislative].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegisList(sortedListLegis)
      } else {
         setLegisList('')
      }
   }, [individualState])

   return (
      <Box sx={{ p: 0.5 }}>
         <FormControl sx={{ m: 1, width: '80%', backgroundColor: '#ffffff' }} size="small">
            <InputLabel>Choose State / UT</InputLabel>
            <Select
               id="xxx"
               value={selectedState}
               label="Choose State / UT"
               onChange={(e) => {
                  handleStateChange(e)
               }}
               sx={{ padding: 0 }}
            >
               <MenuItem value="">
                  <em>None</em>
               </MenuItem>
               {statesList &&
                  statesList.length > 0 &&
                  statesList.map((item) => (
                     <MenuItem key={item.State_id} value={item.State_id}>
                        {item.Name}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>

         {legisList && legisList.length < 1 && (
            <div>
               <Alert variant="outlined" severity="warning" sx={{ margin: 1 }}>
                  <AlertTitle sx={{ fontSize: 12 }}>
                     No legislative body at this level. <br /> इस स्तर पर कोई विधायिका नहीं है |
                  </AlertTitle>
               </Alert>
            </div>
         )}

         <List dense>
            {legisList &&
               legisList.length > 0 &&
               legisList.map((item, index) => (
                  <Link
                     key={item.Legislature_id}
                     href={`/legislature/${item.Name}/${item.Legislature_id}`}
                     sx={{ textDecoration: 'none !important', px: 0 }}
                     rel="noreferrer"
                     target="_blank"
                  >
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemAvatar>
                           <Avatar alt="Travis Howard" src={item.Image} />
                        </ListItemAvatar>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                           primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                           primary={item.Name}
                           secondary={`${item.NameHindi} \n ${item.NameLocal}`}
                        />
                     </ListItem>
                     <Divider />
                  </Link>
               ))}
         </List>
      </Box>
   )
}

HomeStateLegis.propTypes = {
   props: PropTypes.object.isRequired,
}

export default HomeStateLegis
