/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import styles from './LegisBasicInfo.module.css'
import { Alert, AlertTitle, Box, IconButton, Modal, Typography } from '@mui/material'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import AddText from '../../../Components/Edits/AddText'

import EditIcon from '@mui/icons-material/Edit'
import CloudOffIcon from '@mui/icons-material/CloudOff'

import AddImage from '../../../Components/Edits/AddImage'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { Helmet } from 'react-helmet'

function LegisBasicInfo({ props }) {
   const legis = props
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [sourceImage, setSourceImage] = useState(legis.Image)
   const [sourceFlag, setSourceFlag] = useState(legis.Flag)
   const [sourceSymbol, setSourceSymbol] = useState(legis.Symbol)

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const legisRef = doc(db, 'Legislatures', legis.id)

            await updateDoc(legisRef, {
               Name: textDetails.Text,
               NameOld: arrayUnion({
                  Name: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const oldDetail = {
               Legislature_id: legis.id,
               Name: legis.Name,
               NameHindi: legis.NameHindi,
               NameLocal: legis.NameLocal,
               NameAlt: legis.NameAlt,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }

            const newDetail = {
               Legislature_id: legis.id,
               Name: textDetails.Text,
               NameHindi: legis.NameHindi,
               NameLocal: legis.NameLocal,
               NameAlt: legis.NameAlt,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }

            const docRefCentral = doc(db, 'Countries', legis.Country_id)
            let docRefState = ''
            if (legis.State_id !== '' && legis.strata === 'State') {
               docRefState = doc(db, 'States', legis.State_id)
            }
            let docRefDivision = ''
            if (legis.Division_id !== '' && legis.strata === 'Division') {
               docRefDivision = doc(db, 'Divisions', legis.Division_id)
            }
            let docRefDistrict = ''
            if (legis.District_id !== '' && legis.strata === 'District') {
               docRefDistrict = doc(db, 'Districts', legis.District_id)
            }
            let docRefSubDistrict = ''
            if (legis.SubDistrict_id !== '' && legis.strata === 'SubDistrict') {
               docRefSubDistrict = doc(db, 'SubDistricts', legis.SubDistrict_id)
            }
            let docRefBlock = ''
            if (legis.Block_id !== '' && legis.strata === 'Block') {
               docRefBlock = doc(db, 'Blocks', legis.Block_id)
            }
            let docRefVillage = ''
            if (legis.Village_id !== '' && legis.strata === 'Village') {
               docRefVillage = doc(db, 'Villages', legis.Village_id)
            }

            switch (legis.Strata) {
               case 'Central':
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'State':
                  await updateDoc(docRefState, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefState, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Division':
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'District':
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'SubDistrict':
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Block':
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Village':
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
            }

            // update the name display
            legis.Name = textDetails.Text
            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const legisRef = doc(db, 'Legislatures', legis.id)

            await updateDoc(legisRef, {
               NameHindi: textDetails.Text,
               NameHindiOld: arrayUnion({
                  NameHindi: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const oldDetail = {
               Legislature_id: legis.id,
               Name: legis.Name,
               NameHindi: legis.NameHindi,
               NameLocal: legis.NameLocal,
               NameAlt: legis.NameAlt,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }

            const newDetail = {
               Legislature_id: legis.id,
               Name: legis.Name,
               NameHindi: textDetails.Text,
               NameLocal: legis.NameLocal,
               NameAlt: legis.NameAlt,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }
            const docRefCentral = doc(db, 'Countries', legis.Country_id)
            let docRefState = ''
            if (legis.State_id !== '' && legis.strata === 'State') {
               docRefState = doc(db, 'States', legis.State_id)
            }
            let docRefDivision = ''
            if (legis.Division_id !== '' && legis.strata === 'Division') {
               docRefDivision = doc(db, 'Divisions', legis.Division_id)
            }
            let docRefDistrict = ''
            if (legis.District_id !== '' && legis.strata === 'District') {
               docRefDistrict = doc(db, 'Districts', legis.District_id)
            }
            let docRefSubDistrict = ''
            if (legis.SubDistrict_id !== '' && legis.strata === 'SubDistrict') {
               docRefSubDistrict = doc(db, 'SubDistricts', legis.SubDistrict_id)
            }
            let docRefBlock = ''
            if (legis.Block_id !== '' && legis.strata === 'Block') {
               docRefBlock = doc(db, 'Blocks', legis.Block_id)
            }
            let docRefVillage = ''
            if (legis.Village_id !== '' && legis.strata === 'Village') {
               docRefVillage = doc(db, 'Villages', legis.Village_id)
            }

            switch (legis.Strata) {
               case 'Central':
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'State':
                  await updateDoc(docRefState, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefState, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Division':
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'District':
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'SubDistrict':
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Block':
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Village':
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
            }

            // update the name display
            legis.NameHindi = textDetails.Text
            // close the modal
            setOpenModalNameHindi(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const legisRef = doc(db, 'Legislatures', legis.id)

            await updateDoc(legisRef, {
               NameLocal: textDetails.Text,
               NameLocalOld: arrayUnion({
                  NameLocal: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const oldDetail = {
               Legislature_id: legis.id,
               Name: legis.Name,
               NameHindi: legis.NameHindi,
               NameLocal: legis.NameLocal,
               NameAlt: legis.NameAlt,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }

            const newDetail = {
               Legislature_id: legis.id,
               Name: legis.Name,
               NameHindi: legis.NameHindi,
               NameLocal: textDetails.Text,
               NameAlt: legis.NameAlt,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }

            const docRefCentral = doc(db, 'Countries', legis.Country_id)
            let docRefState = ''
            if (legis.State_id !== '' && legis.strata === 'State') {
               docRefState = doc(db, 'States', legis.State_id)
            }
            let docRefDivision = ''
            if (legis.Division_id !== '' && legis.strata === 'Division') {
               docRefDivision = doc(db, 'Divisions', legis.Division_id)
            }
            let docRefDistrict = ''
            if (legis.District_id !== '' && legis.strata === 'District') {
               docRefDistrict = doc(db, 'Districts', legis.District_id)
            }
            let docRefSubDistrict = ''
            if (legis.SubDistrict_id !== '' && legis.strata === 'SubDistrict') {
               docRefSubDistrict = doc(db, 'SubDistricts', legis.SubDistrict_id)
            }
            let docRefBlock = ''
            if (legis.Block_id !== '' && legis.strata === 'Block') {
               docRefBlock = doc(db, 'Blocks', legis.Block_id)
            }
            let docRefVillage = ''
            if (legis.Village_id !== '' && legis.strata === 'Village') {
               docRefVillage = doc(db, 'Villages', legis.Village_id)
            }

            switch (legis.Strata) {
               case 'Central':
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'State':
                  await updateDoc(docRefState, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefState, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Division':
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'District':
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'SubDistrict':
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Block':
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Village':
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
            }

            // update the name display
            legis.NameLocal = textDetails.Text
            // close the modal
            setOpenModalNameLocal(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Other Name modal

   const [openModalNameAlt, setOpenModalNameAlt] = useState(false)

   const handleOpenModalNameAlt = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameAlt(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalNameAlt = () => setOpenModalNameAlt(false)

   const handleAddNameAlt = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const legisRef = doc(db, 'Legislatures', legis.id)

            await updateDoc(legisRef, {
               NameAlt: textDetails.Text,
               NameAltOld: arrayUnion({
                  NameAlt: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const oldDetail = {
               Legislature_id: legis.id,
               Name: legis.Name,
               NameHindi: legis.NameHindi,
               NameLocal: legis.NameLocal,
               NameAlt: legis.NameAlt,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }

            const newDetail = {
               Legislature_id: legis.id,
               Name: legis.Name,
               NameHindi: legis.NameHindi,
               NameLocal: legis.NameLocal,
               NameAlt: textDetails.Text,
               Strata: legis.Strata,
               Nature: legis.Nature,
               Type: legis.Type,
               HasHouses: legis.HasHouses,
               HasConstituencies: legis.HasConstituencies,
               Image: legis.Image,
               MinistryLegis_id: legis.MinistryLegis_id,
               MinistryLegisName: legis.MinistryLegisName,
               Priority: legis.Priority,
            }

            const docRefCentral = doc(db, 'Countries', legis.Country_id)
            let docRefState = ''
            if (legis.State_id !== '' && legis.strata === 'State') {
               docRefState = doc(db, 'States', legis.State_id)
            }
            let docRefDivision = ''
            if (legis.Division_id !== '' && legis.strata === 'Division') {
               docRefDivision = doc(db, 'Divisions', legis.Division_id)
            }
            let docRefDistrict = ''
            if (legis.District_id !== '' && legis.strata === 'District') {
               docRefDistrict = doc(db, 'Districts', legis.District_id)
            }
            let docRefSubDistrict = ''
            if (legis.SubDistrict_id !== '' && legis.strata === 'SubDistrict') {
               docRefSubDistrict = doc(db, 'SubDistricts', legis.SubDistrict_id)
            }
            let docRefBlock = ''
            if (legis.Block_id !== '' && legis.strata === 'Block') {
               docRefBlock = doc(db, 'Blocks', legis.Block_id)
            }
            let docRefVillage = ''
            if (legis.Village_id !== '' && legis.strata === 'Village') {
               docRefVillage = doc(db, 'Villages', legis.Village_id)
            }

            switch (legis.Strata) {
               case 'Central':
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'State':
                  await updateDoc(docRefState, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefState, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Division':
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'District':
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'SubDistrict':
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefSubDistrict, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Block':
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefBlock, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
               case 'Village':
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayRemove(oldDetail),
                  })
                  await updateDoc(docRefVillage, {
                     Legislatures: arrayUnion(newDetail),
                  })
                  break
            }

            // update the nameAlt display
            legis.NameAlt = textDetails.Text
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Nominating Authorities modal

   const [openModalNominatingAuthorities, setOpenModalNominatingAuthorities] = useState(false)

   const handleOpenModalNominatingAuthorities = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNominatingAuthorities(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalNominatingAuthorities = () => setOpenModalNominatingAuthorities(false)

   const handleAddNominatingAuthorities = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const legisRef = doc(db, 'Legislatures', legis.id)

            await updateDoc(legisRef, {
               NominatingAuthorities: textDetails.Text,
               NominatingAuthoritiesOld: arrayUnion({
                  NominatingAuthorities: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            // update the nameAlt display
            legis.NominatingAuthorities = textDetails.Text
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal

   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `Legislatures/${legis.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const record = {
                     Image: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  const legisRef = doc(db, 'Legislatures', legis.id)

                  if (imageDetails.ValueRadio === 'Current image') {
                     updateDoc(legisRef, {
                        Image: url,
                        ImagesOld: arrayUnion(record),
                     })

                     const oldDetail = {
                        Legislature_id: legis.id,
                        Name: legis.Name,
                        NameHindi: legis.NameHindi,
                        NameLocal: legis.NameLocal,
                        NameAlt: legis.NameAlt,
                        Strata: legis.Strata,
                        Nature: legis.Nature,
                        Type: legis.Type,
                        HasHouses: legis.HasHouses,
                        HasConstituencies: legis.HasConstituencies,
                        Image: legis.Image,
                        MinistryLegis_id: legis.MinistryLegis_id,
                        MinistryLegisName: legis.MinistryLegisName,
                        Priority: legis.Priority,
                     }

                     const newDetail = {
                        Legislature_id: legis.id,
                        Name: legis.Name,
                        NameHindi: legis.NameHindi,
                        NameLocal: legis.NameLocal,
                        NameAlt: legis.NameAlt,
                        Strata: legis.Strata,
                        Nature: legis.Nature,
                        Type: legis.Type,
                        HasHouses: legis.HasHouses,
                        HasConstituencies: legis.HasConstituencies,
                        Image: url,
                        MinistryLegis_id: legis.MinistryLegis_id,
                        MinistryLegisName: legis.MinistryLegisName,
                        Priority: legis.Priority,
                     }

                     switch (legis.Strata) {
                        case 'Central':
                           const docRefCentral = doc(db, 'Countries', legis.Country_id)
                           await updateDoc(docRefCentral, {
                              Legislatures: arrayRemove(oldDetail),
                           })
                           await updateDoc(docRefCentral, {
                              Legislatures: arrayUnion(newDetail),
                           })
                           break
                        case 'State':
                           const docRefState = doc(db, 'States', legis.State_id)
                           await updateDoc(docRefState, {
                              Legislatures: arrayRemove(oldDetail),
                           })
                           await updateDoc(docRefState, {
                              Legislatures: arrayUnion(newDetail),
                           })
                           break
                        case 'Division':
                           const docRefDivision = doc(db, 'Divisions', legis.Division_id)
                           await updateDoc(docRefDivision, {
                              Legislatures: arrayRemove(oldDetail),
                           })
                           await updateDoc(docRefDivision, {
                              Legislatures: arrayUnion(newDetail),
                           })
                           break
                        case 'District':
                           const docRefDistrict = doc(db, 'Districts', legis.District_id)
                           await updateDoc(docRefDistrict, {
                              Legislatures: arrayRemove(oldDetail),
                           })
                           await updateDoc(docRefDistrict, {
                              Legislatures: arrayUnion(newDetail),
                           })
                           break
                        case 'SubDistrict':
                           const docRefSubDistrict = doc(db, 'SubDistricts', legis.SubDistrict_id)
                           await updateDoc(docRefSubDistrict, {
                              Legislatures: arrayRemove(oldDetail),
                           })
                           await updateDoc(docRefSubDistrict, {
                              Legislatures: arrayUnion(newDetail),
                           })
                           break
                        case 'Block':
                           const docRefBlock = doc(db, 'Blocks', legis.Block_id)
                           await updateDoc(docRefBlock, {
                              Legislatures: arrayRemove(oldDetail),
                           })
                           await updateDoc(docRefBlock, {
                              Legislatures: arrayUnion(newDetail),
                           })
                           break
                        case 'Village':
                           const docRefVillage = doc(db, 'Villages', legis.Village_id)
                           await updateDoc(docRefVillage, {
                              Legislatures: arrayRemove(oldDetail),
                           })
                           await updateDoc(docRefVillage, {
                              Legislatures: arrayUnion(newDetail),
                           })
                           break
                     }

                     setSourceImage(url)
                  } else {
                     updateDoc(legisRef, {
                        ImagesOld: arrayUnion(record),
                     })
                  }
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to flag modal

   const [openModalFlag, setOpenModalFlag] = useState(false)

   const handleOpenModalFlag = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalFlag(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalFlag = () => setOpenModalFlag(false)

   const handleAddFlag = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `Legislatures/${legis.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const record = {
                     Flag: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  const legisRef = doc(db, 'Legislatures', legis.id)

                  if (imageDetails.ValueRadio === 'Current image') {
                     updateDoc(legisRef, {
                        Flag: url,
                        FlagOld: arrayUnion(record),
                     })

                     setSourceFlag(url)
                  } else {
                     updateDoc(legisRef, {
                        FlagOld: arrayUnion(record),
                     })
                  }
               })
            })

            // close modal
            setOpenModalFlag(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to symbol modal

   const [openModalSymbol, setOpenModalSymbol] = useState(false)

   const handleOpenModalSymbol = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalSymbol(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalSymbol = () => setOpenModalSymbol(false)

   const handleAddSymbol = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `Legislatures/${legis.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const record = {
                     Symbol: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  const legisRef = doc(db, 'Legislatures', legis.id)

                  if (imageDetails.ValueRadio === 'Current image') {
                     updateDoc(legisRef, {
                        Symbol: url,
                        SymbolOld: arrayUnion(record),
                     })

                     setSourceSymbol(url)
                  } else {
                     updateDoc(legisRef, {
                        SymbolOld: arrayUnion(record),
                     })
                  }
               })
            })

            // close modal
            setOpenModalSymbol(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <>
         <div>
            <Helmet>
               <title>{`Track Democracy - ${legis.Name}`}</title>
            </Helmet>
         </div>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box
               sx={{
                  display: 'flex',
                  borderRadius: 1,
                  flexDirection: 'column',
                  px: 2,
               }}
               id="vBox"
            >
               <Table>
                  <tbody>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell
                              sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                              className={styles.cell_style_left}
                              variant="head"
                           >
                              Name:
                           </TableCell>
                           <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{legis.Name}</Box>
                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleOpenModalName(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalName}
                                    onClose={handleCloseModalName}
                                    aria-labelledby="modal-modal-title-name"
                                    aria-describedby="modal-modal-description-name"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-name"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Change name
                                       </Typography>
                                       <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell
                              sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                              className={styles.cell_style_left}
                              variant="head"
                           >
                              {(isDataEditor || isSuper) && <div>Name in hindi:</div>}
                           </TableCell>
                           <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{legis.NameHindi}</Box>
                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleOpenModalNameHindi(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameHindi}
                                    onClose={handleCloseModalNameHindi}
                                    aria-labelledby="modal-modal-title-nameHindi"
                                    aria-describedby="modal-modal-description-nameHindi"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-nameHindi"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Change name in Hindi
                                       </Typography>
                                       <AddText handleAddText={handleAddNameHindi} labelName="Name in Hindi"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell
                              sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                              className={styles.cell_style_left}
                              variant="head"
                           >
                              {(isDataEditor || isSuper) && <div>Name in local language:</div>}
                           </TableCell>
                           <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{legis.NameLocal}</Box>
                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleOpenModalNameLocal(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameLocal}
                                    onClose={handleCloseModalNameLocal}
                                    aria-labelledby="modal-modal-title-nameLocal"
                                    aria-describedby="modal-modal-description-nameLocal"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-nameLocal"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Change name in local language
                                       </Typography>
                                       <AddText
                                          handleAddText={handleAddNameLocal}
                                          labelName="Name in local language"
                                       ></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow className={styles.row_style}>
                           <TableCell
                              sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                              className={styles.cell_style_left}
                              variant="head"
                           >
                              Other name:
                           </TableCell>
                           <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{legis.NameAlt}</Box>
                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleOpenModalNameAlt(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalNameAlt}
                                    onClose={handleCloseModalNameAlt}
                                    aria-labelledby="modal-modal-title-othername"
                                    aria-describedby="modal-modal-description-othername"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-othername"
                                          variant="h6"
                                          component="h6"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add other name/s
                                       </Typography>
                                       <AddText handleAddText={handleAddNameAlt} labelName="Other name"></AddText>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Strata:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.Strata}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Location:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.CountryName && <>{legis.CountryName}</>}
                           {legis.StateName && <> - {legis.StateName}</>}
                           {legis.DivisionName && <> - {legis.DivisionName}</>}
                           {legis.DistrictName && <> - {legis.DistrictName}</>}
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow>
                           <TableCell
                              sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                              className={styles.cell_style_left}
                              variant="head"
                           >
                              Image:
                           </TableCell>
                           <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>
                                    {sourceImage !== '' ? <img src={sourceImage} className={styles.imgX} /> : null}
                                 </Box>
                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleOpenModalImage(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}

                                 <Modal
                                    open={openModalImage}
                                    onClose={handleCloseModalImage}
                                    aria-labelledby="modal-modal-title-image"
                                    aria-describedby="modal-modal-description-image"
                                    disableScrollLock
                                 >
                                    <Box sx={styleModalBox}>
                                       <Typography
                                          id="modal-modal-title-image"
                                          variant="h6"
                                          component="h2"
                                          align="center"
                                          sx={styleModalTypography}
                                       >
                                          Add image of image
                                       </Typography>
                                       <AddImage handleAddImage={handleAddImage}></AddImage>
                                    </Box>
                                 </Modal>
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Flag:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 pt: 0.5,
                              }}
                           >
                              {sourceFlag && <img src={sourceFlag} className={styles.imgX} />}
                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(e) => {
                                       handleOpenModalFlag(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalFlag}
                                 onClose={handleCloseModalFlag}
                                 aria-labelledby="modal-modal-title-flag"
                                 aria-describedby="modal-modal-description-flag"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-flag"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add image of Flag
                                    </Typography>
                                    <AddImage handleAddImage={handleAddFlag}></AddImage>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Symbol:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 pt: 0.5,
                              }}
                           >
                              {sourceSymbol && <img src={sourceSymbol} className={styles.imgX} />}
                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(e) => {
                                       handleOpenModalSymbol(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalSymbol}
                                 onClose={handleCloseModalSymbol}
                                 aria-labelledby="modal-modal-title-symbol"
                                 aria-describedby="modal-modal-description-symbol"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-symbol"
                                       variant="h6"
                                       component="h2"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add image of Symbol
                                    </Typography>
                                    <AddImage handleAddImage={handleAddSymbol}></AddImage>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Nature:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.Nature}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Duration:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.Duration}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Connected legislature / body:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.MinistryLegis_id}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Elective seats:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.ElectiveSeats}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Nominated seats:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.NominatedSeats}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Nominating authorities:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>{legis.NominatingAuthorities}</Box>
                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(e) => {
                                       handleOpenModalNominatingAuthorities(e)
                                    }}
                                    sx={{
                                       ml: 'auto',
                                       height: '25px',
                                       width: '25px',
                                    }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalNominatingAuthorities}
                                 onClose={handleCloseModalNominatingAuthorities}
                                 aria-labelledby="modal-modal-title-NominatingAuthorities"
                                 aria-describedby="modal-modal-description-NominatingAuthorities"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-NominatingAuthorities"
                                       variant="h6"
                                       component="h6"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add nominating authority
                                    </Typography>
                                    <AddText
                                       handleAddText={handleAddNominatingAuthorities}
                                       labelName="Nominating Authorities"
                                    ></AddText>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={styles.cell_style_left}
                           variant="head"
                        >
                           Other name:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={styles.cell_style_right}>
                           {legis.NameAlt}
                        </TableCell>
                     </TableRow>
                  </tbody>
               </Table>
            </Box>
         )}
      </>
   )
}

LegisBasicInfo.propTypes = {
   props: PropTypes.object.isRequired,
}

export default LegisBasicInfo
