import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

function AddWinner({ handleAddWinner }) {
   const [winnerDetails, setWinnerDetails] = useState({
      Winner: '',
   })

   const handleChangeRadio = (e) => {
      e.preventDefault()
      setWinnerDetails({
         ...winnerDetails,
         Winner: e.target.value,
      })
   }

   function handleWinnerSubmit(e) {
      e.preventDefault()

      handleAddWinner(winnerDetails)

      reset()
   }

   function reset() {
      setWinnerDetails({
         Winner: '',
      })
   }
   return (
      <Box sx={{ px: 2, m: 2 }}>
         <FormControl sx={{ border: 1, borderRadius: 1, borderColor: 'text.disabled', px: 2, mt: 1 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">Is this candidate a winner?</FormLabel>
            <RadioGroup
               row
               aria-labelledby="demo-row-radio-buttons-group-label"
               name="row-radio-buttons-group"
               value={winnerDetails.Winner}
               onChange={(e) => {
                  handleChangeRadio(e)
               }}
            >
               <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
               <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
            </RadioGroup>
         </FormControl>
         <Box sx={{ textAlign: 'center', my: 1 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleWinnerSubmit(e)
               }}
               disabled={winnerDetails.Winner === ''}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

AddWinner.propTypes = {
   handleAddWinner: PropTypes.func.isRequired,
}

export default AddWinner
