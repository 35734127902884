import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Link } from '@mui/material'
import { Helmet } from 'react-helmet'

function ConstituencyBasicInfo({ constituency }) {
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - ${constituency.Name}(${constituency.ConstituencyNumber}) - Constituency of ${constituency.LegislatureName}`}</title>
            </Helmet>
         </div>
         <Box>
            Constituency of{' '}
            <Link
               key={constituency.Legislature_id}
               href={`/legislature/${constituency.LegislatureName}/${constituency.Legislature_id}`}
               sx={{ textDecoration: 'none !important', px: 0 }}
               rel="noreferrer"
               target="_blank"
            >
               {constituency.LegislatureName}
            </Link>
         </Box>
         <Box>
            <Link
               key={constituency.Legislature_id}
               href={`/legislature/${constituency.LegislatureName}/${constituency.Legislature_id}`}
               sx={{ textDecoration: 'none !important', px: 0 }}
               rel="noreferrer"
               target="_blank"
            >
               {constituency.LegislatureNameHindi}
            </Link>{' '}
            का निर्वाचन क्षेत्र
         </Box>
         <Alert
            variant="outlined"
            severity="success"
            sx={{ mx: 5, mt: 5, justifyContent: 'center', alignItems: 'center' }}
         >
            Basic information of this constituency will be available soon.
            <br />
            इस निर्वाचन क्षेत्र की मूलभूत सूचनाएँ जल्द ही उपलब्ध होगी।
         </Alert>
      </Box>
   )
}

ConstituencyBasicInfo.propTypes = {
   constituency: PropTypes.object.isRequired,
}

export default ConstituencyBasicInfo
