import React, { useEffect, useState } from 'react'

import {
   Alert,
   Box,
   Button,
   FormControl,
   FormControlLabel,
   FormLabel,
   Radio,
   RadioGroup,
   TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PropTypes from 'prop-types'

import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import styles from './LegisElecs.module.css'

function AddLegisElec({ legisId, lastElec, uploading, uploaded, uploadingFailed, handleAddElec }) {
   /// /
   /// /
   const [elecDetails, setElecDetails] = useState({
      Legislature_id: legisId,
      Name: '',
      NameOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameLocal: '',
      NameLocalOld: [],
      NameAlt: '',
      NameAltOld: [],
      DateStart: '',
      DateStartOld: [],
      DateFinish: '',
      DateFinishOld: [],
      DateCounting: '',
      DateCountingOld: [],
      DateCompletion: '',
      DateCompletionOld: [],
      Phases: [],
      Constituencies: [],
      Candidates: [],
      Manifestoes: [],
      IsSpeculated: false,
      ResultsDeclared: false,
      IsDeleted: false,
      IsDeletedRecord: {},
      IsCancelled: false,
      IsCancelledRecord: {},
      Description: [],
      CandidatesCoreLists: [],
      CandidatesCount: 0,
      HouseNumber: '',
      ElectionType: '',
   })

   useEffect(() => {
      console.log('elec details on change: ', elecDetails)
   }, [elecDetails])

   function handleElecDetails(e) {
      setElecDetails({ ...elecDetails, [e.target.name]: e.target.value })
   }

   const [dateStart, setDateStart] = useState('')
   useEffect(() => {
      if (dateStart) {
         setElecDetails({ ...elecDetails, DateStart: dateStart.$d })
      }
   }, [dateStart])

   const [dateFinish, setDateFinish] = useState('')
   useEffect(() => {
      if (dateFinish) {
         setElecDetails({ ...elecDetails, DateFinish: dateFinish.$d })
      }
   }, [dateFinish])

   const [dateCounting, setDateCounting] = useState('')
   useEffect(() => {
      if (dateCounting) {
         setElecDetails({ ...elecDetails, DateCounting: dateCounting.$d })
      }
   }, [dateCounting])

   const [dateCompletion, setDateCompletion] = useState('')
   useEffect(() => {
      if (dateCompletion) {
         setElecDetails({ ...elecDetails, DateCompletion: dateCompletion.$d })
      }
   }, [dateCompletion])

   function handleHouseNumber(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setElecDetails({ ...elecDetails, [e.target.name]: newValue })
            : setElecDetails({ ...elecDetails, [e.target.name]: '' })
      } else {
         setElecDetails({ ...elecDetails, [e.target.name]: '' })
      }
   }

   const handleSubmitElec = async (e) => {
      e.preventDefault()
      if (
         elecDetails.Name.length >= 3 &&
         elecDetails.DateStart !== '' &&
         elecDetails.DateFinish !== '' &&
         elecDetails.HouseNumber !== ''
      ) {
         console.log('elecDetails before submit: ', elecDetails)
         handleAddElec(elecDetails)
         setElecDetails({
            Legislature_id: legisId,
            Name: '',
            NameOld: [],
            NameHindi: '',
            NameHindiOld: [],
            NameLocal: '',
            NameLocalOld: [],
            NameAlt: '',
            NameAltOld: [],
            DateStart: '',
            DateStartOld: [],
            DateFinish: '',
            DateFinishOld: [],
            DateCounting: '',
            DateCountingOld: [],
            DateCompletion: '',
            DateCompletionOld: [],
            Phases: [],
            Constituencies: [],
            Candidates: [],
            Manifestoes: [],
            IsSpeculated: false,
            ResultsDeclared: false,
            IsDeleted: false,
            IsDeletedRecord: {},
            IsCancelled: false,
            IsCancelledRecord: {},
            Description: [],
            CandidatesCoreLists: [],
            CandidatesCount: 0,
            HouseNumber: '',
            ElectionType: '',
         })
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 1 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleHouseNumber(e)
                  }}
                  value={elecDetails.HouseNumber}
                  name="HouseNumber"
                  id="age"
                  label="HouseNumber"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <FormControl>
                  <FormLabel id="controlled-radioButtons-type">Election Type</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="controlled-radioButtons-type"
                     name="ElectionType"
                     value={elecDetails.ElectionType}
                     onChange={(e) => {
                        handleElecDetails(e)
                     }}
                     sx={{ backgroundColor: '#ffffff', borderRadius: 1, px: 1, mb: 2 }}
                  >
                     <FormControlLabel
                        value="General Election"
                        control={<Radio size="small" />}
                        label="General Election"
                     />
                     <FormControlLabel value="Bye-Election" control={<Radio size="small" />} label="Bye-Election" />
                  </RadioGroup>
               </FormControl>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleElecDetails(e)
                  }}
                  value={elecDetails.Name}
                  name="Name"
                  id="name"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleElecDetails(e)
                  }}
                  value={elecDetails.NameHindi}
                  name="NameHindi"
                  id="nameHindi"
                  label="Name in Hindi"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleElecDetails(e)
                  }}
                  value={elecDetails.NameLocal}
                  name="NameLocal"
                  id="nameLocal"
                  label="Name in Local Language"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <DatePicker
                  label="Start Date"
                  name="DateStart"
                  value={elecDetails.DateStart != null ? dayjs(elecDetails.DateStart) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateStart(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Finish Date"
                  name="DateFinish"
                  value={elecDetails.DateFinish != null ? dayjs(elecDetails.DateFinish) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateFinish(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Counting Date"
                  name="DateCounting"
                  value={elecDetails.DateCounting != null ? dayjs(elecDetails.DateCounting) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateCounting(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Completion Date"
                  name="DateCompletion"
                  value={elecDetails.DateCompletion != null ? dayjs(elecDetails.DateCompletion) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateCompletion(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Is speculated?</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="IsSpeculated"
                     value={elecDetails.IsSpeculated}
                     onChange={(e) => {
                        handleElecDetails(e)
                     }}
                     sx={{ backgroundColor: '#ffffff', borderRadius: 1, px: 1, mb: 2 }}
                  >
                     <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                     <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                  </RadioGroup>
               </FormControl>
            </div>
            <div className={styles.alertDiv}>
               {uploading && <MyLoaderCircularGradient title={'Listing the election ...'}></MyLoaderCircularGradient>}
               {uploaded && (
                  <Alert variant="outlined" severity="success">
                     <strong>{lastElec}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailed && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>{lastElec}</strong> could not be listed.
                  </Alert>
               )}
            </div>
            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitElec(e)
                  }}
                  disabled={
                     elecDetails.Name.length < 3 ||
                     elecDetails.DateStart === '' ||
                     elecDetails.DateFinish === '' ||
                     elecDetails.HouseNumber === '' ||
                     elecDetails.ElectionType === ''
                  }
               >
                  Submit
               </Button>
            </div>
         </Box>
      </LocalizationProvider>
   )
}

AddLegisElec.propTypes = {
   legisId: PropTypes.string.isRequired,
   lastElec: PropTypes.string.isRequired,
   handleAddElec: PropTypes.func.isRequired,
   uploading: PropTypes.bool.isRequired,
   uploaded: PropTypes.bool.isRequired,
   uploadingFailed: PropTypes.bool.isRequired,
}

export default AddLegisElec
