import React from 'react'
import PropTypes from 'prop-types'

function PPAllianceMeetings() {
   return <div></div>
}

PPAllianceMeetings.propTypes = {
   pPAlliance: PropTypes.object.isRequired,
}

export default PPAllianceMeetings
