import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import vaishnaviP from '../../../Images/Vaishnavi.jpg'
import deveshP from '../../../Images/Devesh.jpg'
import rajatP from '../../../Images/Rajat.jpg'

function About() {
   const heads4Options = [
      {
         startEnglish: 'Providing the public ',
         endEnglish: 'with options and opportunities \n',
         startHindi: 'जनता को ',
         endHindi: 'विकल्प और अवसर उपलब्ध कराना',
      },
      {
         startEnglish: 'Providing the leaders ',
         endEnglish: '(elected and aspiring), with options and opportunities \n',
         startHindi: 'नेताओं ',
         endHindi: '(निर्वाचित एवं आकांक्षी) को विकल्प और अवसर उपलब्ध कराना',
      },
      {
         startEnglish: 'Providing the political parties ',
         endEnglish: 'with options and opportunities \n',
         startHindi: 'राजनीतिक दलों को ',
         endHindi: 'विकल्प और अवसर उपलब्ध कराना',
      },
   ]
   const useOptions4Public = [
      'to know about the governing system of the country. \n देश की शासन प्रणाली के बारे में जानने के लिए।',
      'to know about the legislatures, constituencies, ministries and executive set-up. \n विधानमंडलों, निर्वाचन क्षेत्रों, मंत्रालयों और कार्यपालिका व्यवस्था के बारे में जानने के लिए।',
      'to know about the provisions and schemes of various ministries and departments. \n विभिन्न मंत्रालयों और विभागों के प्रावधानों और योजनाओं के बारे में जानने के लिए।',
      'to know about the leaders, elected members and other members of the executive. \n नेताओं, निर्वाचित सदस्यों और कार्यकारिणी के अन्य सदस्यों के बारे में जानने के लिए।',
      'to contribute information about the leaders, elected members and other members of the executive. \n नेताओं, निर्वाचित सदस्यों और कार्यकारिणी के अन्य सदस्यों के बारे में जानकारी प्रदान करने के लिए।',
      'to make direct requests to the leaders, ministers and other members of the executive. \n नेताओं, मंत्रियों और कार्यकारिणी के अन्य सदस्यों से सीधे अनुरोध करने के लिए।',
      'to raise mass petitions to the leaders, ministers and other members of the executive. \n नेताओं, मंत्रियों और कार्यकारिणी के अन्य सदस्यों के समक्ष सामूहिक याचिकाएँ प्रस्तावित और प्रेषण करने के लिए|',
      'to express support to their leaders / election candidates / parties of choice. \n अपने नेताओं / चुनाव उम्मीदवारों / पसंदीदा पार्टियों के प्रति समर्थन व्यक्त करने के लिए।',
      'to rate the parties, leaders, elected members and election candidates. \n पार्टियों, नेताओं, निर्वाचित सदस्यों और चुनाव उम्मीदवारों को रेटिंग देने के लिए|',
      'etc. \n वगैरह',
   ]
   const useOptions4Leaders = [
      'to display / showcase their commitment to the public. \n जनता के प्रति अपनी प्रतिबद्धता प्रदर्शित करने के लिये।',
      'to showcase their credentials, work, etc. by craeting a leader profile. \n एक नेता प्रोफ़ाइल बनाकर अपनी प्रमाणिकताएँ, कार्य आदि का प्रदर्शन करने के लिये।',
      'to make on-line office for the public to submit requests. \n जनता के लिए अनुरोध प्रस्तुत करने हेतु ऑनलाइन कार्यालय बनाने के लिये।',
      'to address problems of more of the public without the hassle of physical reach. \n भौतिक पहुंच की असुविधा के बिना अधिक जनता की समस्याओं का समाधान करने के लिये।',
      'etc. \n वगैरह',
   ]

   const useOptions4Parties = [
      'to display the hierarchichal structure of the party. \n पार्टी की पदानुक्रमिक संरचना प्रदर्शित करने के लिये।',
      'to display office bearers to the root level. \n पदाधिकारियों को मूल स्तर तक प्रदर्शित करने के लिये।',
      'to accept membership requests on line. \n ऑनलाइन सदस्यता अनुरोध स्वीकार करने के लिये।',
      'to accept donations on line. \n ऑनलाइन दान स्वीकार करने के लिये।',
      'to accept public grievances and requests. \n सार्वजनिक शिकायतों और अनुरोधों को स्वीकार करने के लिये।',
      'etc. \n वगैरह',
   ]

   const principles = [
      {
         primary: 'Genuinity',
         secondary: `A person posting any content on this site should be genuine and traceable. \n इस साइट पर कोई भी सामग्री पोस्ट करने वाला व्यक्ति वास्तविक और पता लगाने योग्य होना चाहिए|`,
      },
      {
         primary: 'Transparency',
         secondary: `Transparency is and shall be the key to any democracy's functioning. \n पारदर्शिता किसी भी लोकतंत्र की कार्यपद्धति का मूल है और रहना चाहिए|`,
      },
      {
         primary: 'Accountability',
         secondary: `A person posting any content on this site shall take the moral and legal responsibility of the contents posted by him / her. \n इस साइट पर कोई भी सामग्री पोस्ट करने वाला व्यक्ति अपने द्वारा पोस्ट की गई सामग्री की नैतिक और कानूनी जिम्मेदारी लेगा।|`,
      },
      {
         primary: 'Mutual Interaction',
         secondary: `There must be a scope and facility for iteraction between the public and the elected representatives / leaders.\n जनता और निर्वाचित प्रतिनिधियों/नेताओं के बीच संवाद की सम्भावना और सुविधा होनी चाहिए।`,
      },
   ]
   return (
      <Box sx={{ minHeight: 800, m: 1, pb: 10 }}>
         <Box
            sx={{
               p: 2,
               fontWeight: 800,
               fontSize: 18,
               textAlign: 'center',
            }}
         >
            ABOUT
         </Box>
         <Box sx={{ p: 2 }}>
            Welcome to <strong>trackdemocracy.com</strong>. We are happy you want to know something more about this
            site.
            <br />
            आपका स्वागत है। हमें खुशी है कि आप इस साइट के बारे में कुछ और जानना चाहते हैं
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>trackdemocracy.com</strong> is a product of the minds and conscience concerned with the democratic
            build up, aimed at:
            <br />
            <strong>trackdemocracy.com</strong> लोकतांत्रिक निर्माण से संबंधित मन और विवेक का एक उत्पाद है, जिसका
            उद्देश्य है:
            <List sx={{ ml: 4 }}>
               {heads4Options.map((value, index) => (
                  <Fragment key={value}>
                     <ListItem sx={{ p: 0, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600 }}>
                                    {value.startEnglish}
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value.endEnglish}
                                 </Typography>
                                 <Typography component="span" sx={{ fontWeight: 600 }}>
                                    {value.startHindi}
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value.endHindi}
                                 </Typography>
                              </>
                           }
                           secondary={
                              <List sx={{ ml: 4 }}>
                                 {value.startEnglish === 'Providing the public ' && (
                                    <>
                                       {useOptions4Public.map((value, index) => (
                                          <Fragment key={value}>
                                             <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                                                <ListItemText
                                                   sx={{ my: 0 }}
                                                   primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                                   secondaryTypographyProps={{
                                                      whiteSpace: 'pre-line',
                                                      color: 'text.primary',
                                                      display: 'inline',
                                                      textAlign: 'left',
                                                   }}
                                                   primary={
                                                      <>
                                                         <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                                            {index + 1}.
                                                         </Typography>
                                                         <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                                            {value}
                                                         </Typography>
                                                      </>
                                                   }
                                                />
                                             </ListItem>
                                          </Fragment>
                                       ))}
                                    </>
                                 )}
                                 {value.startEnglish === 'Providing the leaders ' && (
                                    <>
                                       {useOptions4Leaders.map((value, index) => (
                                          <Fragment key={value}>
                                             <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                                                <ListItemText
                                                   sx={{ my: 0 }}
                                                   primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                                   secondaryTypographyProps={{
                                                      whiteSpace: 'pre-line',
                                                      color: 'text.primary',
                                                      display: 'inline',
                                                      textAlign: 'left',
                                                   }}
                                                   primary={
                                                      <>
                                                         <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                                            {index + 1}.
                                                         </Typography>
                                                         <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                                            {value}
                                                         </Typography>
                                                      </>
                                                   }
                                                />
                                             </ListItem>
                                          </Fragment>
                                       ))}
                                    </>
                                 )}
                                 {value.startEnglish === 'Providing the political parties ' && (
                                    <>
                                       {useOptions4Parties.map((value, index) => (
                                          <Fragment key={value}>
                                             <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                                                <ListItemText
                                                   sx={{ my: 0 }}
                                                   primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                                   secondaryTypographyProps={{
                                                      whiteSpace: 'pre-line',
                                                      color: 'text.primary',
                                                      display: 'inline',
                                                      textAlign: 'left',
                                                   }}
                                                   primary={
                                                      <>
                                                         <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                                            {index + 1}.
                                                         </Typography>
                                                         <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                                            {value}
                                                         </Typography>
                                                      </>
                                                   }
                                                />
                                             </ListItem>
                                          </Fragment>
                                       ))}
                                    </>
                                 )}
                              </List>
                           }
                        />
                     </ListItem>
                  </Fragment>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Principles followed:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {principles.map((value) => (
                  <>
                     <ListItem key={value.primary} sx={{ p: 0, ml: 4, whiteSpace: 'pre-line', mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ fontWeight: 800, whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={`${value.primary}`}
                           secondary={`${value.secondary}`}
                        />
                     </ListItem>
                  </>
               ))}
               {/* <ListItem disablePadding>
                  <ListItemText
                     primaryTypographyProps={{ fontWeight: 600, whiteSpace: 'pre-line', mt: 1, }}
                     primary="Genuinity"
                     secondary={
                        <Typography
                           sx={{
                              textAlign: 'left',
                              whiteSpace: 'pre-line',
                           }}
                        >
                           A person posting any content on this site should be genuine and traceable. {'\n'}
                           इस साइट पर कोई भी सामग्री पोस्ट करने वाला व्यक्ति वास्तविक और पता लगाने योग्य होना चाहिए|
                           In a world where most of the social media is replete with fake accounts,{' '}
                           <Box component="span" sx={{ fontWeight: 600 }}>
                              trackdemocracy.com
                           </Box>{' '}
                           adopts measures to ensure genuinity of the people posting contents on the site. {'\n'}
                           While the entire contents of the site are available for free to browse, contents can be
                           posted on the site by the paid members only, one of the measures to ensure genuinity.
                        </Typography>
                     }
                  />
               </ListItem> */}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Management:</strong>
            <br />
            <List sx={{ bgcolor: 'background.paper' }}>
               <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                     <Avatar sx={{ width: 70, height: 70, mx: 2 }} alt="Devesh Kumar Singh" src={deveshP} />
                  </ListItemAvatar>
                  <ListItemText
                     primaryTypographyProps={{ fontWeight: 600, whiteSpace: 'pre-line', fontSize: 14 }}
                     primary={`Devesh Kumar Singh \n देवेश कुमार सिंह`}
                     secondary={
                        <Typography
                           component="span"
                           variant="body2"
                           sx={{ color: 'text.primary', display: 'inline', textAlign: 'left' }}
                        >
                           Vision & Concept
                        </Typography>
                     }
                  />
               </ListItem>
               {/* <Divider variant="inset" component="li" /> */}
               <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                     <Avatar sx={{ width: 70, height: 70, mx: 2 }} alt="Vaishnavi Singh" src={vaishnaviP} />
                  </ListItemAvatar>
                  <ListItemText
                     primaryTypographyProps={{ fontWeight: 600, whiteSpace: 'pre-line', fontSize: 14 }}
                     primary={`Vimla Singh ( Vaishnavi Singh) \n विमला सिंह ( वैष्णवी सिंह)`}
                     secondary={
                        <Typography
                           component="span"
                           variant="body2"
                           sx={{ color: 'text.primary', display: 'inline', textAlign: 'left' }}
                        >
                           Country Operations Head
                        </Typography>
                     }
                  />
               </ListItem>
               {/* <Divider variant="inset" component="li" /> */}
               <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                     <Avatar sx={{ width: 70, height: 70, mx: 2 }} alt="Rajat Singh Bhanwal" src={rajatP} />
                  </ListItemAvatar>
                  <ListItemText
                     primaryTypographyProps={{ fontWeight: 600, whiteSpace: 'pre-line', fontSize: 14 }}
                     primary={`Rajat Singh Bhanwal \n रजत सिंह भनवाल`}
                     secondary={
                        <Typography component="span" variant="body2" sx={{ color: 'text.primary', display: 'inline' }}>
                           Data & Advertisement
                        </Typography>
                     }
                  />
               </ListItem>
            </List>
         </Box>
      </Box>
   )
}

export default About
