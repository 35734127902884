import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'

function AddAllianceParty({
   partiesList,
   lastAllianceParty,
   handleAddAllianceParties,
   uploadingAllianceParties,
   uploadedAllianceParties,
   uploadingFailedAllianceParties,
}) {
   /// /

   const [alliancePartyDetails, setAlliancePartyDetails] = useState({
      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyAbbreviation: '',
      PartyFlag: '',
      PartySymbol: '',
      PartyECIStatus: '',
   })

   useEffect(() => {
      console.log('alliance party details on change: ', alliancePartyDetails)
   }, [alliancePartyDetails])

   const [selectedPartyId, setSelectedPartyId] = useState('')

   function handleChangeParty(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedPartyId(e.target.value)

         const party = partiesList.find((item) => item.Party_id === e.target.value)
         console.log('party flag', party.Flag)
         console.log('party name', party.Name)
         setAlliancePartyDetails({
            Party_id: party.Party_id,
            PartyName: party.Name,
            PartyNameHindi: party.NameHindi,
            PartyNameLocal: party.NameLocal,
            PartyAbbreviation: party.Abbreviation,
            PartyFlag: party.Flag,
            PartySymbol: party.Symbol ? party.Symbol : '',
            PartyECIStatus: party.ECIStatus,
         })
      } else {
         setSelectedPartyId('')
         setAlliancePartyDetails({
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
            PartyECIStatus: '',
         })
      }
   }

   const handleSubmitAllianceParty = async (e) => {
      e.preventDefault()
      if (alliancePartyDetails.PartyName.length > 3) {
         console.log('alliancePartyDetails before submit: ', alliancePartyDetails)

         handleAddAllianceParties(alliancePartyDetails)

         setAlliancePartyDetails({
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
            PartyECIStatus: '',
         })

         setSelectedPartyId('')
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel>Party</InputLabel>
                  <Select
                     value={selectedPartyId}
                     label="Party"
                     onChange={(e) => {
                        handleChangeParty(e)
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {partiesList &&
                        partiesList.map((item) => (
                           <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                              {item.Name} - <strong> {item.Abbreviation}</strong>
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Box>

            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitAllianceParty(e)
                  }}
                  disabled={alliancePartyDetails.PartyName < 2}
               >
                  Submit
               </Button>
            </div>
            <Box sx={{ mt: 2 }}>
               {uploadingAllianceParties && (
                  <MyLoaderCircularGradient title={'Listing the party for alliance ...'}></MyLoaderCircularGradient>
               )}
               {uploadedAllianceParties && (
                  <Alert variant="outlined" severity="success">
                     <strong>{lastAllianceParty}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailedAllianceParties && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>{lastAllianceParty}</strong> could not be listed.
                  </Alert>
               )}
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddAllianceParty.propTypes = {
   partiesList: PropTypes.object.isRequired,
   lastAllianceParty: PropTypes.string.isRequired,
   handleAddAllianceParties: PropTypes.func.isRequired,
   uploadingAllianceParties: PropTypes.bool.isRequired,
   uploadedAllianceParties: PropTypes.bool.isRequired,
   uploadingFailedAllianceParties: PropTypes.bool.isRequired,
}

export default AddAllianceParty
