import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'

function PPartyCampaigns({ pParty, handlePPartyDetails }) {
   console.log('value from props from inside PParty Campaigns : ', pParty)
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Campaigns by -${pParty.Name}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.4,
            }}
         >
            Party Campaigns: {'\n'} पार्टी अभियान:
         </Typography>
         <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 10, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               This facility will be available soon.
               <br />
               <br />
               यह सुविधा शीघ्र ही उपलब्ध होगी।
            </Alert>
         </Box>
      </Box>
   )
}

PPartyCampaigns.propTypes = {
   pParty: PropTypes.object.isRequired,
   handlePPartyDetails: PropTypes.func.isRequired,
}
export default PPartyCampaigns
