import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CropSquareIcon from '@mui/icons-material/CropSquare'

import myStyles from './GoDownStageWise.module.css'
import { Box, Stack } from '@mui/material'

const GoDownStageWise = () => {
   //
   const style = {
      height: 20,
      width: 40,
      color: '#FFFFFF',
      borderRadius: 0.5,
      display: 'flex',
      flexDirection: 'row',
      fontSize: 'small',
      alignItems: 'center',
      justifyContent: 'space-around',
      px: 0.5,
   }

   const [stage, setStage] = useState(0)
   const [showBottomBtn, setShowBottomBtn] = useState(false)

   useEffect(() => {
      //   if (document.body.scrollHeight > 1100) {
      //      setShowBottomBtn(true)
      //   } else {
      //      setShowBottomBtn(false)
      //   }
      window.addEventListener('scroll', () => {
         if (window.scrollY > 400) {
            setShowBottomBtn(true)
         } else {
            setShowBottomBtn(false)
         }

         if (window.scrollY < document.body.scrollHeight / 5) {
            setStage(0)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 2) {
            setStage(20)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 3) {
            setStage(40)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 4) {
            setStage(60)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 5) {
            setStage(80)
         }
      })
   }, [])

   const goToBottom00 = () => {
      //   setStage(0)
      window.scrollTo({
         top: 0,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goToBottom20 = () => {
      //   setStage(20)
      window.scrollTo({
         top: document.body.scrollHeight / 5 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goToBottom40 = () => {
      //   setStage(40)
      window.scrollTo({
         top: (document.body.scrollHeight / 5) * 2 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goToBottom60 = () => {
      //   setStage(60)
      window.scrollTo({
         top: (document.body.scrollHeight / 5) * 3 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goToBottom80 = () => {
      //   setStage(80)
      window.scrollTo({
         top: (document.body.scrollHeight / 5) * 4 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   //    const goToBottom100 = () => {
   //       //   setStage(100)
   //       window.scrollTo({
   //          top: (document.body.scrollHeight / 5) * 5,
   //          left: 0,
   //          behavior: 'smooth',
   //       })
   //    }

   return (
      <div className={myStyles.top_to_btm}>
         {' '}
         {showBottomBtn && (
            <Stack spacing={0.25} className={`${myStyles.icon_position} ${myStyles.icon_style}`} sx={{ p: 0.5 }}>
               <Box
                  sx={{
                     color: '#FFFFFF',
                     fontSize: 'small',
                     width: 40,
                     backgroundColor: 'darkgray',
                     textAlign: 'center',
                     borderRadius: 0.5,
                     px: 0.5,
                  }}
               >
                  Page Parts
               </Box>
               <Box sx={{ ...style, backgroundColor: '#6fbf72' }} onClick={goToBottom00}>
                  1{' '}
                  {stage === 0 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 0 && <ArrowDropUpIcon sx={{ p: 0, minWidth: 0 }} />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#6fbf72' }} onClick={goToBottom20}>
                  2{stage < 20 && <ArrowDropDownIcon />}
                  {stage === 20 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 20 && <ArrowDropUpIcon />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#4caf50' }} onClick={goToBottom40}>
                  3{stage < 40 && <ArrowDropDownIcon />}
                  {stage === 40 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 40 && <ArrowDropUpIcon />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#57975a' }} onClick={goToBottom60}>
                  4{stage < 60 && <ArrowDropDownIcon sx={{ p: 0, minWidth: 0 }} />}
                  {stage === 60 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 60 && <ArrowDropUpIcon sx={{ p: 0, minWidth: 0 }} />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#2e7d32' }} onClick={goToBottom80}>
                  5{stage < 80 && <ArrowDropDownIcon />}
                  {stage === 80 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 80 && <ArrowDropUpIcon />}
               </Box>
               {/* <Box sx={{ ...style, backgroundColor: '#246428' }} onClick={goToBottom100}>
                  100%
                  {stage < 100 && <ArrowDropDownIcon />}
                  {stage === 100 && <StopIcon />}
                  {stage > 100 && <ArrowDropUpIcon />}
               </Box> */}
            </Stack>
         )}
      </div>
   )
}

export default GoDownStageWise
