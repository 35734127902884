import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Box,
   Button,
   FormControl,
   FormControlLabel,
   IconButton,
   Link,
   Modal,
   Radio,
   RadioGroup,
   Table,
   TableCell,
   TableRow,
   Typography,
} from '@mui/material'

import myStyles from './NetaBasicInfo.module.css'
import { arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { v4 } from 'uuid'
import moment from 'moment'

// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import EditIcon from '@mui/icons-material/Edit'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'

import AddWeblink from '../../../Components/Edits/AddWeblink'
import AddImage from '../../../Components/Edits/AddImage'
import AddAddress from '../../../Components/Edits/AddAddress'
import AddEmail from '../../../Components/Edits/AddEmail'

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import AddPhone from '../../../Components/Edits/AddPhone'
import { sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { Helmet } from 'react-helmet'
import AddDate from '../../../Components/Edits/AddDate'
import AddText from '../../../Components/Edits/AddText'
import AddPParty from '../../../Components/Edits/AddPParty'
import AddTextByLanguage from '../../../Components/Edits/AddTextByLanguage'

function NetaBasicInfo({ neta, netaConstituencies }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setFirstLoadStatus('success')

         console.log('Neat basic info - first use effect blank')
      }
   }, [])

   const [sourceImage, setSourceImage] = useState(neta.Image.Image)

   // View

   const [selectedView, setSelectedView] = useState('Public View')

   function handleView(e) {
      e.preventDefault()

      setSelectedView(e.target.value)
   }

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               // 1. Update the main Neta Profie for the new name
               const docRefNeta = doc(db, 'NetaProfiles', neta.id)

               await updateDoc(docRefNeta, {
                  Name: textDetails.Text,
                  NameOld: arrayUnion({
                     Name: textDetails.Text,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }),
               })

               // 2. update all the neta objects in respective constituencies where the neta is attached
               // For this, get the list of attached constituencies. This is already in the form of netaConstituencies
               if (netaConstituencies && netaConstituencies.length > 0) {
                  // 3.1 Act for each of the constituencies in this list.
                  netaConstituencies.forEach(async (item) => {
                     // 3.1.1 get reference of the Netas subcollection of the respective Constituency
                     const docRefConstituenciesSubCollectionNetas = doc(
                        db,
                        'LegisConstituencies',
                        item.Constituency_id,
                        'CoreLists',
                        item.CoreListId,
                     )

                     // 3.1.2 get the documnet by this reference
                     const docSnapConstituenciesSubCollectionNetas = await getDoc(
                        docRefConstituenciesSubCollectionNetas,
                     )

                     // 3.1.3 check if the document exists
                     if (docSnapConstituenciesSubCollectionNetas.exists()) {
                        // this docsnap is sure to exist, but still ...
                        // this condition is checked for extra safety.
                        // Now ...

                        // 3.1.3.1 get the list of neta objects related to this neta ...
                        const list = docSnapConstituenciesSubCollectionNetas
                           .data()
                           .Netas.filter((thisItem) => thisItem.NetaProfile_id === neta.id)

                        // 3.1.3.2 Act for each of these objects ...
                        list.forEach(async (fetchedNetaObj) => {
                           // 3.1.3.2.1 create an instance of the neta object
                           const netaObj = fetchedNetaObj

                           // 3.1.3.2.1 create an altered instance with new name of the neta object
                           const netaObjNew = {
                              ...netaObj,
                              Name: textDetails.Text,
                           }

                           // 3.1.3.2.1 Update the Neta sub collection of the constituency
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayRemove(netaObj),
                           })
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayUnion(netaObjNew),
                           })
                        })
                     }
                  })
               }

               // update the name display
               neta.Name = textDetails.Text
               // close the modal
               setOpenModalName(false)
            } catch (error) {
               console.log('Error adding name: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               // 1. Update the main Neta Profie for the new name hindi
               const docRefNeta = doc(db, 'NetaProfiles', neta.id)

               await updateDoc(docRefNeta, {
                  NameHindi: textDetails.Text,
                  NameHindiOld: arrayUnion({
                     NameHindi: textDetails.Text,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }),
               })

               // 2. update all the neta objects in respective constituencies where the neta is attached
               // For this, get the list of attached constituencies. This is already in the form of netaConstituencies
               if (netaConstituencies && netaConstituencies.length > 0) {
                  // 3.1 Act for each of the constituencies in this list.
                  netaConstituencies.forEach(async (item) => {
                     // 3.1.1 get reference of the Netas subcollection of the respective Constituency
                     const docRefConstituenciesSubCollectionNetas = doc(
                        db,
                        'LegisConstituencies',
                        item.Constituency_id,
                        'CoreLists',
                        item.CoreListId,
                     )

                     // 3.1.2 get the documnet by this reference
                     const docSnapConstituenciesSubCollectionNetas = await getDoc(
                        docRefConstituenciesSubCollectionNetas,
                     )

                     // 3.1.3 check if the document exists
                     if (docSnapConstituenciesSubCollectionNetas.exists()) {
                        // this docsnap is sure to exist, but still ...
                        // this condition is checked for extra safety.
                        // Now ...

                        // 3.1.3.1 get the list of neta objects related to this neta ...
                        const list = docSnapConstituenciesSubCollectionNetas
                           .data()
                           .Netas.filter((thisItem) => thisItem.NetaProfile_id === neta.id)

                        // 3.1.3.2 Act for each of these objects ...
                        list.forEach(async (fetchedNetaObj) => {
                           // 3.1.3.2.1 create an instance of the neta object
                           const netaObj = fetchedNetaObj

                           // 3.1.3.2.1 create an altered instance with new name hindi of the neta object
                           const netaObjNew = {
                              ...netaObj,
                              NameHindi: textDetails.Text,
                           }

                           // 3.1.3.2.1 Update the Neta sub collection of the constituency
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayRemove(netaObj),
                           })
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayUnion(netaObjNew),
                           })
                        })
                     }
                  })
               }

               // update the name hindi display
               neta.NameHindi = textDetails.Text
               // close the modal
               setOpenModalNameHindi(false)
            } catch (error) {
               console.log('Error adding name hindi: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               // 1. Update the main Neta Profie for the new name local
               const docRefNeta = doc(db, 'NetaProfiles', neta.id)

               await updateDoc(docRefNeta, {
                  NameLocal: textDetails.Text,
                  NameLocalOld: arrayUnion({
                     NameLocal: textDetails.Text,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }),
               })

               // 2. update all the neta objects in respective constituencies where the neta is attached
               // For this, get the list of attached constituencies. This is already in the form of netaConstituencies
               if (netaConstituencies && netaConstituencies.length > 0) {
                  // 3.1 Act for each of the constituencies in this list.
                  netaConstituencies.forEach(async (item) => {
                     // 3.1.1 get reference of the Netas subcollection of the respective Constituency
                     const docRefConstituenciesSubCollectionNetas = doc(
                        db,
                        'LegisConstituencies',
                        item.Constituency_id,
                        'CoreLists',
                        item.CoreListId,
                     )

                     // 3.1.2 get the documnet by this reference
                     const docSnapConstituenciesSubCollectionNetas = await getDoc(
                        docRefConstituenciesSubCollectionNetas,
                     )

                     // 3.1.3 check if the document exists
                     if (docSnapConstituenciesSubCollectionNetas.exists()) {
                        // this docsnap is sure to exist, but still ...
                        // this condition is checked for extra safety.
                        // Now ...

                        // 3.1.3.1 get the list of neta objects related to this neta ...
                        const list = docSnapConstituenciesSubCollectionNetas
                           .data()
                           .Netas.filter((thisItem) => thisItem.NetaProfile_id === neta.id)

                        // 3.1.3.2 Act for each of these objects ...
                        list.forEach(async (fetchedNetaObj) => {
                           // 3.1.3.2.1 create an instance of the neta object
                           const netaObj = fetchedNetaObj

                           // 3.1.3.2.1 create an altered instance with new name local of the neta object
                           const netaObjNew = {
                              ...netaObj,
                              NameLocal: textDetails.Text,
                           }

                           // 3.1.3.2.1 Update the Neta sub collection of the constituency
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayRemove(netaObj),
                           })
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayUnion(netaObjNew),
                           })
                        })
                     }
                  })
               }
               // update the name local display
               neta.NameLocal = textDetails.Text
               // close the modal
               setOpenModalNameLocal(false)
            } catch (error) {
               console.log('Error adding name local: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Name modal

   const [openModalNameOthers, setOpenModalNameOthers] = useState(false)

   const handleOpenModalNameOthers = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalNameOthers(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalNameOthers = () => setOpenModalNameOthers(false)

   const [lastName, setLastName] = useState('')
   const [uploadingName, setUploadingName] = useState(false)
   const [uploadedName, setUploadedName] = useState(false)
   const [uploadingFailedName, setUploadingFailedName] = useState(false)

   const handleAddNameOthers = async (textDetails, selectedLanguage) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            if (textDetails.Text !== '' && textDetails.Text.length > 1 && selectedLanguage !== '') {
               try {
                  setUploadingName(true)
                  // 1. Update the main Neta Profie for the new name
                  const docRefNeta = doc(db, 'NetaProfiles', neta.id)

                  switch (selectedLanguage) {
                     case 'English':
                        await updateDoc(docRefNeta, {
                           NameOthers: arrayUnion(textDetails.Text),
                           NameOthersOld: arrayUnion({
                              Name: textDetails.Text,
                              CrBy: auth.currentUser.uid,
                              CrDt: new Date(),
                              CrIP: ip.data.ip,
                           }),
                        })
                        // update the local state
                        neta.NameOthers.push(textDetails.Text)

                        break
                     case 'Hindi':
                        await updateDoc(docRefNeta, {
                           NameOthersHindi: arrayUnion(textDetails.Text),
                           NameOthersHindiOld: arrayUnion({
                              Name: textDetails.Text,
                              CrBy: auth.currentUser.uid,
                              CrDt: new Date(),
                              CrIP: ip.data.ip,
                           }),
                        })
                        // update the local state
                        neta.NameOthersHindi.push(textDetails.Text)
                        break
                     case 'Local':
                        await updateDoc(docRefNeta, {
                           NameOthersLocal: arrayUnion(textDetails.Text),
                           NameOthersLocalOld: arrayUnion({
                              Name: textDetails.Text,
                              CrBy: auth.currentUser.uid,
                              CrDt: new Date(),
                              CrIP: ip.data.ip,
                           }),
                        })
                        // update the local state
                        neta.NameOthersLocal.push(textDetails.Text)
                        break
                  }

                  setLastName(textDetails.Name)

                  setUploadingName(false)
                  setUploadedName(true)
                  setUploadingFailedName(false)
                  // close the modal
                  // setOpenModalNameOthers(false)
               } catch (error) {
                  alert('Error adding other names: ', error.message)
                  console.log('Error adding other names: ', error)
                  setUploadingName(false)
                  setUploadedName(false)
                  setUploadingFailedName(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to DoB modal

   const [openModalDoB, setOpenModalDoB] = useState(false)

   const handleOpenModalDoB = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalDoB(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalDoB = () => setOpenModalDoB(false)

   const handleAddDoB = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               // 1. Update the main Neta Profie for the new DoB
               const docRefNeta = doc(db, 'NetaProfiles', neta.id)

               await updateDoc(docRefNeta, {
                  DoB: dateDetails.Date,
                  DoBOld: arrayUnion({
                     DoB: dateDetails.Date,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }),
               })

               // 2. update all the neta objects in respective constituencies where the neta is attached
               // For this, get the list of attached constituencies. This is already in the form of netaConstituencies
               if (netaConstituencies && netaConstituencies.length > 0) {
                  // 3.1 Act for each of the constituencies in this list.
                  netaConstituencies.forEach(async (item) => {
                     // 3.1.1 get reference of the Netas subcollection of the respective Constituency
                     const docRefConstituenciesSubCollectionNetas = doc(
                        db,
                        'LegisConstituencies',
                        item.Constituency_id,
                        'CoreLists',
                        item.CoreListId,
                     )

                     // 3.1.2 get the documnet by this reference
                     const docSnapConstituenciesSubCollectionNetas = await getDoc(
                        docRefConstituenciesSubCollectionNetas,
                     )

                     // 3.1.3 check if the document exists
                     if (docSnapConstituenciesSubCollectionNetas.exists()) {
                        // this docsnap is sure to exist, but still ...
                        // this condition is checked for extra safety.
                        // Now ...

                        // 3.1.3.1 get the list of neta objects related to this neta ...
                        const list = docSnapConstituenciesSubCollectionNetas
                           .data()
                           .Netas.filter((thisItem) => thisItem.NetaProfile_id === neta.id)

                        // 3.1.3.2 Act for each of these objects ...
                        list.forEach(async (fetchedNetaObj) => {
                           // 3.1.3.2.1 create an instance of the neta object
                           const netaObj = fetchedNetaObj

                           // 3.1.3.2.1 create an altered instance with new DoB of the neta object
                           const netaObjNew = {
                              ...netaObj,
                              DoB: dateDetails.Date,
                           }

                           // 3.1.3.2.1 Update the Neta sub collection of the constituency
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayRemove(netaObj),
                           })
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayUnion(netaObjNew),
                           })
                        })
                     }
                  })
               }

               // update the DoB display
               neta.DoB = dateDetails.Date
               // close the modal
               setOpenModalDoB(false)
            } catch (error) {
               console.log('Error adding DoB: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Party modal

   const [openModalParty, setOpenModalParty] = useState(false)

   const handleOpenModalParty = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalParty(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalParty = () => setOpenModalParty(false)

   const handleAddParty = async (partyDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               partyDetails.CrBy = auth.currentUser.uid
               partyDetails.CrDt = new Date()
               partyDetails.CrIP = ip.data.ip
               // 1. Update the main Neta Profie for the new Party
               const docRefNeta = doc(db, 'NetaProfiles', neta.id)

               await updateDoc(docRefNeta, {
                  Party: partyDetails,
                  PartiesOld: arrayUnion({
                     partyDetails,
                  }),
               })

               // 2. update all the neta objects in respective constituencies where the neta is attached
               // For this, get the list of attached constituencies. This is already in the form of netaConstituencies
               if (netaConstituencies && netaConstituencies.length > 0) {
                  // 3.1 Act for each of the constituencies in this list.
                  netaConstituencies.forEach(async (item) => {
                     // 3.1.1 get reference of the Netas subcollection of the respective Constituency
                     const docRefConstituenciesSubCollectionNetas = doc(
                        db,
                        'LegisConstituencies',
                        item.Constituency_id,
                        'CoreLists',
                        item.CoreListId,
                     )

                     // 3.1.2 get the documnet by this reference
                     const docSnapConstituenciesSubCollectionNetas = await getDoc(
                        docRefConstituenciesSubCollectionNetas,
                     )

                     // 3.1.3 check if the document exists
                     if (docSnapConstituenciesSubCollectionNetas.exists()) {
                        // this docsnap is sure to exist, but still ...
                        // this condition is checked for extra safety.
                        // Now ...

                        // 3.1.3.1 get the list of neta objects related to this neta ...
                        const list = docSnapConstituenciesSubCollectionNetas
                           .data()
                           .Netas.filter((thisItem) => thisItem.NetaProfile_id === neta.id)

                        // 3.1.3.2 Act for each of these objects ...
                        list.forEach(async (fetchedNetaObj) => {
                           // 3.1.3.2.1 create an instance of the neta object
                           const netaObj = fetchedNetaObj

                           // 3.1.3.2.1 create an altered instance with new Party of the neta object
                           const netaObjNew = {
                              ...netaObj,
                              Party: partyDetails,
                           }

                           // 3.1.3.2.1 Update the Neta sub collection of the constituency
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayRemove(netaObj),
                           })
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayUnion(netaObjNew),
                           })
                        })
                     }
                  })
               }

               // update the Party display
               neta.Party = partyDetails
               // close the modal
               setOpenModalParty(false)
            } catch (error) {
               console.log('Error adding Party: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Address modal

   const [openModalAddress, setOpenModalAddress] = useState(false)

   const handleOpenModalAddress = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalAddress(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAddress = () => setOpenModalAddress(false)

   const handleAddAddress = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               if (
                  addressDetails.State_id.length >= 3 &&
                  addressDetails.District_id.length >= 3 &&
                  addressDetails.Address.length >= 10 &&
                  addressDetails.ValueRadio !== ''
               ) {
                  ///
                  addressDetails.CrBy = auth.currentUser.uid
                  addressDetails.CrDt = new Date()
                  addressDetails.CrIP = ip.data.ip
                  console.log('stage 4 submit')

                  addressDetails.Address = sentenceCase(addressDetails.Address)
                  console.log('stage 5 submit')

                  const netaRef = doc(db, 'NetaProfiles', neta.id)

                  if (addressDetails.ValueRadio === 'Current address') {
                     ///
                     await updateDoc(netaRef, {
                        Address: arrayUnion(addressDetails),
                     })
                     console.log('stage 6 submit')
                     // update the address display
                     neta.Address.push(addressDetails)
                     console.log('stage 7 submit')
                  } else {
                     await updateDoc(netaRef, {
                        AddressOld: arrayUnion(addressDetails),
                     })
                     console.log('stage 8 submit')
                  }

                  // close modal
                  setOpenModalAddress(false)
               }
            } catch (error) {
               console.log('Error adding Address: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Office Address modal

   const [openModalOfficeAddress, setOpenModalOfficeAddress] = useState(false)

   const handleOpenModalOfficeAddress = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalOfficeAddress(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalOfficeAddress = () => setOpenModalOfficeAddress(false)

   const handleAddOfficeAddress = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               if (
                  addressDetails.State_id.length >= 3 &&
                  addressDetails.District_id.length >= 3 &&
                  addressDetails.Address.length >= 10 &&
                  addressDetails.ValueRadio !== ''
               ) {
                  ///
                  addressDetails.CrBy = auth.currentUser.uid
                  addressDetails.CrDt = new Date()
                  addressDetails.CrIP = ip.data.ip
                  console.log('stage 4 submit')

                  addressDetails.Address = sentenceCase(addressDetails.Address)
                  console.log('stage 5 submit')

                  const netaRef = doc(db, 'NetaProfiles', neta.id)

                  if (addressDetails.ValueRadio === 'Current address') {
                     ///
                     await updateDoc(netaRef, {
                        OfficeAddress: arrayUnion(addressDetails),
                     })
                     console.log('stage 6 submit')
                     // update the address display
                     neta.OfficeAddress.push(addressDetails)
                     console.log('stage 7 submit')
                  } else {
                     await updateDoc(netaRef, {
                        OfficeAddressOld: arrayUnion(addressDetails),
                     })
                     console.log('stage 8 submit')
                  }

                  // close modal
                  setOpenModalAddress(false)
               }
            } catch (error) {
               console.log('Error adding office Address: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               //
               const imageRef = ref(dbStorage, `Netas/${neta.id}/${imageDetails.image.name + v4()}`)
               await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
                  getDownloadURL(snapshot.ref).then(async (url) => {
                     // 1. Update the main Neta Profie for the new image
                     const docRefNeta = doc(db, 'NetaProfiles', neta.id)

                     await updateDoc(docRefNeta, {
                        Image: {
                           Image: url,
                           CrBy: auth.currentUser.uid,
                           CrDt: new Date(),
                           CrIP: ip.data.ip,
                        },
                     })

                     // 2. Update the Images subcollection of Neta Profile
                     const docRefNetaSubCollectionImage = doc(db, 'NetaProfiles', neta.id, 'CoreLists', 'Images-001')
                     const docSnapNetaSubCollectionImage = await getDoc(docRefNetaSubCollectionImage)

                     if (docSnapNetaSubCollectionImage.exists()) {
                        console.log('Image - update start')
                        await updateDoc(docRefNetaSubCollectionImage, {
                           Images: arrayUnion({
                              Image: url,
                              CrBy: auth.currentUser.uid,
                              CrDt: new Date(),
                              CrIP: ip.data.ip,
                           }),
                        })
                        console.log('Image - update end')
                     } else {
                        console.log('Image - set start')
                        setDoc(
                           docRefNetaSubCollectionImage,
                           {
                              Images: [
                                 {
                                    Image: url,
                                    CrBy: auth.currentUser.uid,
                                    CrDt: new Date(),
                                    CrIP: ip.data.ip,
                                 },
                              ],
                           },
                           { merge: true },
                        )
                        console.log('Image - set end')
                     }

                     // 3. update all the neta objects in respective constituencies where the neta is attached
                     // For this, get the list of attached constituencies. This is already in the form of netaConstituencies
                     if (netaConstituencies && netaConstituencies.length > 0) {
                        // 3.1 Act for each of the constituencies in this list.
                        netaConstituencies.forEach(async (item) => {
                           // 3.1.1 get reference of the Netas subcollection of the respective Constituency
                           const docRefConstituenciesSubCollectionNetas = doc(
                              db,
                              'LegisConstituencies',
                              item.Constituency_id,
                              'CoreLists',
                              item.CoreListId,
                           )

                           // 3.1.2 get the documnet by this reference
                           const docSnapConstituenciesSubCollectionNetas = await getDoc(
                              docRefConstituenciesSubCollectionNetas,
                           )

                           // 3.1.3 check if the document exists
                           if (docSnapConstituenciesSubCollectionNetas.exists()) {
                              // this docsnap is sure to exist, but still ...
                              // this condition is checked for extra safety.
                              // Now ...

                              // 3.1.3.1 get the list of neta objects related to this neta ...
                              const list = docSnapConstituenciesSubCollectionNetas
                                 .data()
                                 .Netas.filter((thisItem) => thisItem.NetaProfile_id === neta.id)

                              // 3.1.3.2 Act for each of these objects ...
                              list.forEach(async (fetchedNetaObj) => {
                                 // 3.1.3.2.1 create an instance of the neta object
                                 const netaObj = fetchedNetaObj

                                 // 3.1.3.2.1 create an altered instance with new image of the neta object
                                 const netaObjNew = {
                                    ...netaObj,
                                    Image: {
                                       Image: url,
                                       CrBy: auth.currentUser.uid,
                                       CrDt: new Date(),
                                       CrIP: ip.data.ip,
                                    },
                                 }

                                 // 3.1.3.2.1 Update the Neta sub collection of the constituency
                                 await updateDoc(docRefConstituenciesSubCollectionNetas, {
                                    Netas: arrayRemove(netaObj),
                                 })
                                 await updateDoc(docRefConstituenciesSubCollectionNetas, {
                                    Netas: arrayUnion(netaObjNew),
                                 })
                              })
                           }
                        })
                     }

                     setSourceImage(url)
                  })
               })

               // close modal
               setOpenModalImage(false)
            } catch (error) {
               console.log('Error adding Image: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Phone modal
   const [openModalPhone, setOpenModalPhone] = useState(false)

   const handleOpenModalPhone = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalPhone(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalPhone = () => setOpenModalPhone(false)

   const [lastPhone, setLastPhone] = useState('')
   const [uploadingPhone, setUploadingPhone] = useState(false)
   const [uploadedPhone, setUploadedPhone] = useState(false)
   const [uploadingFailedPhone, setUploadingFailedPhone] = useState(false)

   const handleAddPhone = async (phoneDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            phoneDetails.CrBy = auth.currentUser.uid
            phoneDetails.CrDt = new Date()
            phoneDetails.CrIP = ip.data.ip

            setLastPhone(phoneDetails.Phone.toString())
            setUploadingPhone(true)
            try {
               const partyRef = doc(db, 'NetaProfiles', neta.id)
               await updateDoc(partyRef, {
                  Phones: arrayUnion(phoneDetails.Phone),
                  PhonesOld: arrayUnion(phoneDetails),
               })

               // update the website display
               neta.Phones.push(phoneDetails.Phone)

               // close modal
               setOpenModalPhone(false)

               setUploadingPhone(false)
               setUploadedPhone(true)
               setUploadingFailedPhone(false)
            } catch (error) {
               alert('Error adding Phone: ', error.message)
               console.log('Error adding Phone', error)
               setUploadingPhone(false)
               setUploadedPhone(false)
               setUploadingFailedPhone(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               weblinkDetails.CrBy = auth.currentUser.uid
               weblinkDetails.CrDt = new Date()
               weblinkDetails.CrIP = ip.data.ip

               const partyRef = doc(db, 'NetaProfiles', neta.id)
               await updateDoc(partyRef, {
                  WebLinks: arrayUnion(weblinkDetails.Weblink),
                  WebLinksOld: arrayUnion(weblinkDetails),
               })

               // update the website display
               neta.WebLinks.push(weblinkDetails.Weblink)

               // close modal
               // setOpenModalWebsite(false)
            } catch (error) {
               console.log('Error adding weblink: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Email modal
   const [openModalEmail, setOpenModalEmail] = useState(false)

   const handleOpenModalEmail = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            setOpenModalEmail(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalEmail = () => setOpenModalEmail(false)

   const handleAddEmail = async (emailDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisUser === neta.User_id) {
            try {
               emailDetails.CrBy = auth.currentUser.uid
               emailDetails.CrDt = new Date()
               emailDetails.CrIP = ip.data.ip

               const partyRef = doc(db, 'NetaProfiles', neta.id)
               await updateDoc(partyRef, {
                  Emails: arrayUnion(emailDetails.Email),
                  EmailsOld: arrayUnion(emailDetails),
               })

               // update the website display
               neta.Emails.push(emailDetails.Email)

               // close modal
               setOpenModalEmail(false)
            } catch (error) {
               console.log('Error adding email: ', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // const [foundIcon, setFoundIcon] = useState('')

   // function smSubstring(name) {
   //    if (name.substring(0, 25) === 'https://www.facebook.com/') {
   //       setFoundIcon('FacebookIcon')
   //    } else if (name.substring(0, 26) === 'https://www.instagram.com/') {
   //       setFoundIcon('InstagramIcon')
   //    } else if (name.substring(0, 25) === 'https://www.linkedin.com/') {
   //       setFoundIcon('LinkedInIcon')
   //    } else if (name.substring(0, 14) === 'https://x.com/') {
   //       setFoundIcon('XIcon')
   //    } else {
   //       setFoundIcon('')
   //    }
   // }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div>
            <div>
               <Helmet>
                  <title>{`Track Democracy - Leader - Neta - ${neta.Name}`}</title>
               </Helmet>
            </div>
            <Box
               sx={{
                  display: 'flex',
                  borderRadius: 1,
                  flexDirection: 'column',
                  px: 2,
               }}
            >
               <Table>
                  <tbody>
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           {(isSuper || isDataEditor) && (
                              <Button sx={{ my: 0.5 }} variant="contained">
                                 Block this profile
                              </Button>
                           )}
                        </TableCell>
                        <TableCell>
                           {thisUser === neta.User_id && (
                              <Box sx={{ justifyContent: 'end', display: 'flex' }}>
                                 <FormControl
                                    sx={{
                                       py: 0,
                                       px: 1,
                                       borderRadius: 1,
                                       backgroundColor: '#F1F1F1',
                                       ml: 'auto',
                                    }}
                                 >
                                    {/* <FormLabel id="demo-controlled-radio-buttons-group">
                                 Political Affiliation Type:
                              </FormLabel> */}
                                    <RadioGroup
                                       row
                                       aria-labelledby="demo-controlled-radio-buttons-group"
                                       name="PoliticalAffiliationType"
                                       value={selectedView}
                                       // style={{ display: 'flex', gap: '2rem' }}
                                       onChange={(e) => {
                                          handleView(e)
                                       }}
                                       sx={{
                                          backgroundColor: '#F1F1F1',
                                          borderRadius: 1,
                                          py: 0,
                                          '& .MuiSvgIcon-root': {
                                             fontSize: 15,
                                          },
                                          '& .MuiButtonBase-root': {
                                             py: 0,
                                          },
                                          '& MuiFormControlLabel-label': {
                                             fontSize: 13,
                                             lineHeight: 16,
                                             py: 0,
                                          },
                                       }}
                                    >
                                       <FormControlLabel
                                          value="Public View"
                                          control={<Radio size="small" />}
                                          label="Public View"
                                          sx={{ py: 0, fontSize: 15 }}
                                       />
                                       <FormControlLabel
                                          value="Edit View"
                                          control={<Radio size="small" />}
                                          label="Edit View"
                                          sx={{ py: 0, fontSize: 15 }}
                                       />
                                    </RadioGroup>
                                 </FormControl>
                              </Box>
                           )}
                        </TableCell>
                     </TableRow>

                     {thisUser === neta.User_id && selectedView === 'Edit View' && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box sx={{ mr: 1 }}>{neta.Name}</Box>

                                 {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editName"
                                       onClick={(e) => {
                                          handleOpenModalName(e)
                                       }}
                                       sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}

                     {thisUser === neta.User_id && selectedView === 'Edit View' && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name hindi:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box sx={{ mr: 1 }}>{neta.NameHindi}</Box>

                                 {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameHindi"
                                       onClick={(e) => {
                                          handleOpenModalNameHindi(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '30px',
                                          width: '30px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}

                     {thisUser === neta.User_id && selectedView === 'Edit View' && (
                        <TableRow className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name local:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box sx={{ mr: 1 }}>{neta.NameLocal}</Box>

                                 {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameLocal"
                                       onClick={(e) => {
                                          handleOpenModalNameLocal(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '30px',
                                          width: '30px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}

                     <TableRow className={myStyles.row_style}>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Other Names:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                 }}
                              >
                                 <Box>
                                    {neta.NameOthers &&
                                       neta.NameOthers.length > 0 &&
                                       neta.NameOthers.map((item, index) => <span key={index}>{item}, </span>)}
                                 </Box>
                                 <Box>
                                    {neta.NameOthersHindi &&
                                       neta.NameOthersHindi.length > 0 &&
                                       neta.NameOthersHindi.map((item, index) => <span key={index}>{item}, </span>)}
                                 </Box>
                                 <Box>
                                    {neta.NameOthers &&
                                       neta.NameOthersLocal.length > 0 &&
                                       neta.NameOthersLocal.map((item, index) => <span key={index}>{item}, </span>)}
                                 </Box>
                              </Box>
                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editName"
                                    onClick={(e) => {
                                       handleOpenModalNameOthers(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>

                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Birth date {'\n'} जन्म तिथि:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {neta.DoB !== '' && <Box>{moment(neta.DoB.toDate()).format('Do MMMM YYYY')}</Box>}

                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editDoB"
                                    onClick={(e) => {
                                       handleOpenModalDoB(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     {thisUser === neta.User_id && selectedView === 'Edit View' && (
                        <TableRow>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Image:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mt: 0.5,
                                 }}
                              >
                                 <Box>
                                    {sourceImage !== '' ? <img src={sourceImage} className={myStyles.imgX} /> : null}
                                 </Box>

                                 {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editImage"
                                       onClick={(e) => {
                                          handleOpenModalImage(e)
                                       }}
                                       sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Party:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    pt: 0.5,
                                 }}
                              >
                                 {neta.Party.PartyFlag && (
                                    <Link
                                       href={`/political-party/${neta.PartyName}/${neta.Party_id}`}
                                       sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       <Box>
                                          <img src={neta.Party.PartyFlag} className={myStyles.imgX} />
                                       </Box>
                                    </Link>
                                 )}
                                 <Box
                                    sx={{
                                       whiteSpace: 'pre-line',
                                    }}
                                 >
                                    {neta.Party.PartyName} {'\n'} {neta.Party.PartyNameHindi} {'\n'}{' '}
                                    {neta.Party.PartyNameLocal}
                                 </Box>
                              </Box>
                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editImage"
                                    onClick={(e) => {
                                       handleOpenModalParty(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>

                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Residence: {'\n'} निवास:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {neta.Address && neta.Address.length > 0 && (
                                 <Box>
                                    {neta.Address.map((item, index) => (
                                       <div key={index}>{item.Address}</div>
                                    ))}
                                 </Box>
                              )}

                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAddress"
                                    onClick={(e) => {
                                       handleOpenModalAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Offices: {'\n'} कार्यालय:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {neta.OfficeAddress && neta.OfficeAddress.length > 0 && (
                                 <Box>
                                    {neta.OfficeAddress.map((item, index) => (
                                       <div key={index}>{item.Address}</div>
                                    ))}
                                 </Box>
                              )}

                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editOfficeAddress"
                                    onClick={(e) => {
                                       handleOpenModalOfficeAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Phones {'\n'} दूरभाष:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {neta.Phones &&
                                    neta.Phones.length > 0 &&
                                    neta.Phones.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editPhone"
                                    onClick={(e) => {
                                       handleOpenModalPhone(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Web Links {'\n'} वेब लिंक:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>
                                 {neta.WebLinks &&
                                    neta.WebLinks.length > 0 &&
                                    neta.WebLinks.map((item, index) => (
                                       <div key={index}>
                                          {item &&
                                             (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                                <Link
                                                   underline="hover"
                                                   href={item}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                   {item.substring(0, 25) === 'https://www.facebook.com/' && (
                                                      <FacebookIcon />
                                                   )}
                                                   {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                                      <InstagramIcon />
                                                   )}
                                                   {item.substring(0, 25) === 'https://www.linkedin.com/' && (
                                                      <LinkedInIcon />
                                                   )}
                                                   {item.substring(0, 14) === 'https://x.com/' && <XIcon />}
                                                </Link>
                                             )}
                                          {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                             <Link
                                                underline="hover"
                                                href={`//${item}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                             >
                                                {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                                   <FacebookIcon />
                                                ) : item.substring(0, 26) === 'https://www.instagram.com/' ? (
                                                   <InstagramIcon />
                                                ) : item.substring(0, 25) === 'https://www.linkedin.com/' ? (
                                                   <LinkedInIcon />
                                                ) : item.substring(0, 14) === 'https://x.com/' ? (
                                                   <XIcon sx={{ color: '#000000' }} />
                                                ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                                   <YouTubeIcon />
                                                ) : (
                                                   item
                                                )}
                                             </Link>
                                          )}
                                       </div>
                                    ))}
                              </Box>

                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editWebsite"
                                    onClick={(e) => {
                                       handleOpenModalWebsite(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Emails {'\n'} ईमेल:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {neta.Emails &&
                                    neta.Emails.length > 0 &&
                                    neta.Emails.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {thisUser === neta.User_id && selectedView === 'Edit View' && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editEmail"
                                    onClick={(e) => {
                                       handleOpenModalEmail(e)
                                    }}
                                    sx={{ ml: 'auto', height: '30px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                  </tbody>
               </Table>
            </Box>
            <Box id="boxModals">
               <Modal
                  open={openModalName}
                  onClose={handleCloseModalName}
                  aria-labelledby="modal-modal-title-name"
                  aria-describedby="modal-modal-description-name"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-name"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Change name
                     </Typography>
                     <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                  </Box>
               </Modal>
               <Modal
                  open={openModalNameHindi}
                  onClose={handleCloseModalNameHindi}
                  aria-labelledby="modal-modal-title-Hindiname"
                  aria-describedby="modal-modal-description-Hindiname"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-hindiname"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add name in hindi
                     </Typography>
                     <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
                  </Box>
               </Modal>
               <Modal
                  open={openModalNameLocal}
                  onClose={handleCloseModalNameLocal}
                  aria-labelledby="modal-modal-title-localname"
                  aria-describedby="modal-modal-description-localname"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-localname"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add name in local language
                     </Typography>
                     <AddText
                        handleAddText={handleAddNameLocal}
                        labelName="Name (in local language)"
                        lastName={lastName}
                        uploadingName={uploadingName}
                        uploadedName={uploadedName}
                        uploadingFailedName={uploadingFailedName}
                     ></AddText>
                  </Box>
               </Modal>
               <Modal
                  open={openModalNameOthers}
                  onClose={handleCloseModalNameOthers}
                  aria-labelledby="modal-modal-title-nameOthers"
                  aria-describedby="modal-modal-description-nameOthers"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-nameOthers"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add other names: {'\n'} अन्य नाम जोड़ें:
                     </Typography>
                     <AddTextByLanguage handleAddText={handleAddNameOthers} labelName="Name"></AddTextByLanguage>
                  </Box>
               </Modal>
               <Modal
                  open={openModalDoB}
                  onClose={handleCloseModalDoB}
                  aria-labelledby="modal-modal-title-dob"
                  aria-describedby="modal-modal-description-dob"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-dob"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Date Of Birth {'\n'} <strong>जन्म तिथि जोड़ें</strong>
                     </Typography>
                     <AddDate labelName="Date of Birth / जन्म तिथि" handleAddDate={handleAddDoB}></AddDate>
                  </Box>
               </Modal>
               <Modal
                  open={openModalParty}
                  onClose={handleCloseModalParty}
                  aria-labelledby="modal-modal-title-party"
                  aria-describedby="modal-modal-description-party"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-party"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add / change Party {'\n'} <strong>पार्टी जोड़ें / बदलें</strong>
                     </Typography>
                     <AddPParty handleAddParty={handleAddParty}></AddPParty>
                  </Box>
               </Modal>
               <Modal
                  open={openModalPhone}
                  onClose={handleCloseModalPhone}
                  aria-labelledby="modal-modal-title-phones"
                  aria-describedby="modal-modal-description-phones"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-phones"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Phones:
                     </Typography>
                     <AddPhone
                        lastPhone={lastPhone}
                        handleAddPhone={handleAddPhone}
                        uploadingPhone={uploadingPhone}
                        uploadedPhone={uploadedPhone}
                        uploadingFailedPhone={uploadingFailedPhone}
                     ></AddPhone>
                  </Box>
               </Modal>
               <Modal
                  open={openModalImage}
                  onClose={handleCloseModalImage}
                  aria-labelledby="modal-modal-title-logo"
                  aria-describedby="modal-modal-description-logo"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-logo"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add image of Candidate
                     </Typography>
                     <AddImage handleAddImage={handleAddImage}></AddImage>
                  </Box>
               </Modal>
               <Modal
                  open={openModalEmail}
                  onClose={handleCloseModalEmail}
                  aria-labelledby="modal-modal-title-email"
                  aria-describedby="modal-modal-description-email"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-email"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add an email
                     </Typography>
                     <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
                  </Box>
               </Modal>
               <Modal
                  open={openModalWebsite}
                  onClose={handleCloseModalWebsite}
                  aria-labelledby="modal-modal-title-website"
                  aria-describedby="modal-modal-description-website"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-website"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add a web link
                     </Typography>
                     <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
                  </Box>
               </Modal>
               <Modal
                  open={openModalAddress}
                  onClose={handleCloseModalAddress}
                  aria-labelledby="modal-modal-title-headquarter"
                  aria-describedby="modal-modal-description-headquarter"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-headquarter"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Address
                     </Typography>
                     <AddAddress countryId={neta.Country_id} handleAddAddress={handleAddAddress}></AddAddress>
                  </Box>
               </Modal>
               <Modal
                  open={openModalOfficeAddress}
                  onClose={handleCloseModalOfficeAddress}
                  aria-labelledby="modal-modal-title-OfficeAddress"
                  aria-describedby="modal-modal-description-OfficeAddress"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-OfficeAddress"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Office Address
                     </Typography>
                     <AddAddress
                        countryId={neta.Country_id}
                        handleAddOfficeAddress={handleAddOfficeAddress}
                     ></AddAddress>
                  </Box>
               </Modal>
            </Box>
         </div>
      </LocalizationProvider>
   )
}

NetaBasicInfo.propTypes = {
   neta: PropTypes.object.isRequired,
   netaConstituencies: PropTypes.array.isRequired,
}

export default NetaBasicInfo
