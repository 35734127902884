import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle, Box } from '@mui/material'
import { Helmet } from 'react-helmet'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function ElecOrders({ props }) {
   const elec = props
   console.log('value from props from inside elec orders page: ', elec)
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Govrnment orders for ${elec.Name}`}</title>
            </Helmet>
         </div>
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               color: '#ffffff',
            }}
         >
            <Box>Official orders:</Box>
            <Box>आधिकारिक आदेश:</Box>
         </Box>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box>
               {elec.IsSpeculated && (
                  <Box>
                     <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                        <strong>
                           <u>Disclaimer:</u>
                        </strong>{' '}
                        The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                        display. Please wait for the actual announcement by the Election Commission of India.
                        <br />
                        <br />
                        <strong>
                           <u>अस्वीकरण:</u>
                        </strong>{' '}
                        इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया
                        भारत के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
                     </Alert>
                  </Box>
               )}
               {!elec.IsSpeculated && (
                  <Box>
                     <Box>
                        <Alert variant="outlined" severity="success" sx={{ mx: 10, mt: 5 }}>
                           List will be available soon.
                           <br />
                           <br />
                           सूची जल्द ही उपलब्ध होगी|
                        </Alert>
                     </Box>
                  </Box>
               )}
            </Box>
         )}
      </Box>
   )
}

ElecOrders.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecOrders
