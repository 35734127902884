import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Typography,
} from '@mui/material'
import { Transition } from '../../../Utils/MUITheme/MUITheme'

function NetaStatements({ neta, statements }) {
   const [openDialogCreatePetition, setOpenDialogCreatePetition] = React.useState(false)

   const handleOpenDialogCreatePetition = (e) => {
      e.preventDefault()

      setOpenDialogCreatePetition(true)
   }

   const handleCloseDialogCreatePetition = () => {
      setOpenDialogCreatePetition(false)
   }
   return (
      <div>
         <div>
            <Helmet>
               <title>{`Track Democracy - Statements of Netadate - ${neta.Name}`}</title>
            </Helmet>
         </div>
         <Box sx={{ mb: 0.5 }}>
            <Button
               onClick={(e) => {
                  handleOpenDialogCreatePetition(e)
               }}
               size="small"
               variant="outlined"
               sx={{ py: 0, px: 1, minWidth: 0, whiteSpace: 'pre-line' }}
            >
               List a statement / कथन सूचीबद्ध करें
            </Button>
         </Box>

         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               Statements / commitments: {'\n'} कथन / वचन:
            </Typography>
         </Box>
         <Box sx={{ m: 2 }}>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
            >
               <AlertTitle>
                  Leaders can list their statements and commitments here.
                  <br />
                  नेता यहां अपने बयान और प्रतिबद्धताएं सूचीबद्ध कर सकते हैं।
               </AlertTitle>
            </Alert>
         </Box>
         <Box sx={{ m: 2 }}>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
            >
               <AlertTitle sx={{ fontSize: 12 }}>
                  This facility will be available soon.
                  <br />
                  <br />
                  यह सुविधा शीघ्र ही उपलब्ध होगी |
               </AlertTitle>
            </Alert>
         </Box>
         <Box id="boxModals">
            <Dialog
               open={openDialogCreatePetition}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogCreatePetition}
               aria-describedby="alert-dialog-slide-su"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`एक कथन / वचन बनाएँ ${'\n'} Create a statement / commitment`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-su" sx={{ color: '#b69c0f' }}>
                     This facility will be available soon.
                     <br />
                     <br />
                     यह सुविधा शीघ्र ही उपलब्ध होगी |
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogCreatePetition}>Close</Button>
               </DialogActions>
            </Dialog>
         </Box>
      </div>
   )
}

NetaStatements.propTypes = {
   neta: PropTypes.object.isRequired,
   statements: PropTypes.array.isRequired,
}

export default NetaStatements
