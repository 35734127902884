import { Alert, Box, Typography } from '@mui/material'
import React from 'react'

function ConstitutionPreamble() {
   return (
      <Box>
         <Box
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               pl: 1,
            }}
         >
            <Typography variant="subtitle1" color="#FFFFFF">
               Preamble / प्रस्तावना:
            </Typography>
         </Box>
         <Box sx={{ mt: 0.5, px: 1 }}>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
            >
               This facility will be available soon.
               <br />
               यह सुविधा शीघ्र ही उपलब्ध होगी।
            </Alert>
         </Box>
      </Box>
   )
}

export default ConstitutionPreamble
