import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function ElecCandiConstiTransfer({
   selectedCandidate,
   constisList,
   uploadingConstiTransfer,
   uploadingFailedConstiTransfer,
   uploadedConstiTransfer,
   handleConstiTransfer,
}) {
   const [list, setList] = useState([])
   useEffect(() => {
      const listNew = constisList.filter((item) => item.Constituency_id !== selectedCandidate.Constituency_id)
      setList(listNew)
   }, [])

   const [constiDetails, setConstiDetails] = useState({
      Constituency_id: '',
      ConstituencyName: '',
      ConstituencyNameHindi: '',
      ConstituencyNameLocal: '',
   })
   const [selectedConsti, setSelectedConsti] = useState('')

   const handleChangeConsti = async (e) => {
      e.preventDefault()

      setSelectedConsti(e.target.value)
   }

   useEffect(() => {
      if (selectedConsti && selectedConsti !== '') {
         const consti = constisList.find((item) => item.Constituency_id === selectedConsti)

         setConstiDetails({
            Constituency_id: consti.Constituency_id,
            ConstituencyName: consti.Name,
            ConstituencyNameHindi: consti.NameHindi,
            ConstituencyNameLocal: consti.NameLocal,
         })
      } else {
         setConstiDetails({
            Constituency_id: '',
            ConstituencyName: '',
            ConstituencyNameHindi: '',
            ConstituencyNameLocal: '',
         })
      }
   }, [selectedConsti])

   const handleSubmitStatus = async (e) => {
      e.preventDefault()

      if (constiDetails.Constituency_id !== '') {
         handleConstiTransfer(constiDetails)
      }
   }
   return (
      <Box>
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               px: 1,
               mt: 0.5,
               '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '35ch' },
            }}
         >
            <Box sx={{ pt: 1 }}>
               <FormControl sx={{ ml: 1 }} size="small">
                  <InputLabel>Constituency / निर्वाचन क्षेत्र</InputLabel>
                  <Select
                     value={constiDetails.Constituency_id}
                     label="Constituency / निर्वाचन क्षेत्र"
                     onChange={(e) => {
                        handleChangeConsti(e)
                     }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {list &&
                        list.length > 0 &&
                        list.map((item) => (
                           <MenuItem key={item.Constituency_id} value={item.Constituency_id} sx={{ fontSize: 13 }}>
                              {item.Name} ({item.ConstituencyNumber}) - {item.NameHindi}{' '}
                              {item.NameLocal !== '' ? `-${item.NameLocal}` : null}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Box>
            <Box>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitStatus(e)
                  }}
                  disabled={constiDetails.Constituency_id === ''}
               >
                  Submit
               </Button>
            </Box>
            <Box>
               {uploadingConstiTransfer && (
                  <MyLoaderCircularGradient
                     title={'Transferring the candidate to another constituency ...'}
                  ></MyLoaderCircularGradient>
               )}
               {uploadedConstiTransfer && (
                  <Alert variant="outlined" severity="success">
                     Status updated successfully.
                  </Alert>
               )}
               {uploadingFailedConstiTransfer && (
                  <Alert variant="outlined" severity="error">
                     Error occured! Status could not be updated.
                  </Alert>
               )}
            </Box>
         </Box>
      </Box>
   )
}

ElecCandiConstiTransfer.propTypes = {
   selectedCandidate: PropTypes.object.isRequired,
   constisList: PropTypes.array.isRequired,
   handleConstiTransfer: PropTypes.func.isRequired,
   uploadingConstiTransfer: PropTypes.bool.isRequired,
   uploadedConstiTransfer: PropTypes.bool.isRequired,
   uploadingFailedConstiTransfer: PropTypes.bool.isRequired,
}

export default ElecCandiConstiTransfer
