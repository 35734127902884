import { configureStore } from '@reduxjs/toolkit'

import countriesReducer from './reducers/countries-slice'
import usersReducer from './reducers/users-slice'
import pPartiesReducer from './reducers/pParties-slice'

export default configureStore({
   reducer: {
      countries: countriesReducer,
      users: usersReducer,
      pParties: pPartiesReducer,
   },

   middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
         serializableCheck: false,
      })
   },
})
