import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db } from '../../FirebaseConfig'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Divider,
   FormControl,
   FormControlLabel,
   FormLabel,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Stack,
   Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import PageviewIcon from '@mui/icons-material/Pageview'

function HomeLocalLegis({ props }) {
   const individualCountry = props

   const [initialState, setInitialState] = useState('idle')
   const [statesList, setStatesList] = useState('')
   const [selectedState, setSelectedState] = useState('')

   const [divisionsList, setDivisionsList] = useState('')
   const [selectedDivision, setSelectedDivision] = useState('')
   const [showDivision, setShowDivision] = useState(false)
   const [showSearchDivision, setShowSearchDivision] = useState(false)

   const [districtsList, setDistrictsList] = useState('')
   const [districtsListReserve, setDistrictsListReserve] = useState('')
   const [selectedDistrict, setSelectedDistrict] = useState('')
   const [showDistrict, setShowDistrict] = useState(false)
   const [showSearchDistrict, setShowSearchDistrict] = useState(false)

   const [showRadio, setShowRadio] = useState(false)
   const [selectedRadio, setSelectedRadio] = useState(false)

   const [blocksList, setBlocksList] = useState('')
   const [selectedBlock, setSelectedBlock] = useState('')
   const [showBlock, setShowBlock] = useState(false)

   const [subDistrictsList, setSubDistrictsList] = useState('')
   const [selectedSubDistrict, setSelectedSubDistrict] = useState('')
   const [showSubDistrict, setShowSubDistrict] = useState(false)
   const [showSearchSubDistrict, setShowSearchSubDistrict] = useState(false)

   const [villagesList, setVillagesList] = useState('')
   const [selectedVillage, setSelectedVillage] = useState('')
   const [showVillage, setShowVillage] = useState(false)
   const [showSearchVillage, setShowSearchVillage] = useState(false)

   const [legisList, setLegisList] = useState('')

   const [textLevel, setTextLevel] = useState('')
   const [textName, setTextName] = useState('')

   const focusRef = useRef(null)

   useEffect(() => {
      if (initialState === 'idle') {
         const sortedListStates = [...individualCountry.States].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setStatesList(sortedListStates)
         setInitialState('success')
      }
   }, [])

   // regarding States

   const handleChangeState = (e) => {
      e.preventDefault()
      setSelectedState(e.target.value)

      setDivisionsList('')
      setDistrictsList('')
      setDistrictsListReserve('')
      setSubDistrictsList('')
      setVillagesList('')

      setShowDivision(false)
      setShowDistrict(false)
      setShowSubDistrict(false)
      setShowVillage(false)

      setLegisList('')

      setShowSearchDivision(false)
      setShowSearchDistrict(false)
      setShowSearchSubDistrict(false)
      setShowSearchVillage(false)

      setSelectedDivision('')
      setSelectedDistrict('')
      setSelectedSubDistrict('')
      setSelectedVillage('')

      setShowRadio(false)
      setSelectedRadio('')

      setBlocksList('')
      setShowBlock(false)
      setSelectedBlock('')
   }

   const [individualState, setIndividualState] = useState('')

   useEffect(() => {
      if (selectedState && selectedState !== '') {
         getIndividualState()
      } else {
         setIndividualState('')
      }
   }, [selectedState])

   const getIndividualState = async () => {
      const docRef = doc(db, 'States', selectedState)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualState(docSnap.data())
      } else {
         setIndividualState('')
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualState) {
         const sortedListDivisions = [...individualState.Divisions].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         const sortedListDistricts = [...individualState.Districts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setDivisionsList(sortedListDivisions)
         setDistrictsListReserve(sortedListDistricts)
         setDistrictsList(sortedListDistricts)

         if (individualState.DownLevel === 'Divisions') {
            setShowDivision(true)
         } else {
            setShowDistrict(true)
         }
      } else {
         setDivisionsList('')
         setDistrictsListReserve('')
         setDistrictsList('')
         setShowDivision(false)
         setShowDistrict(false)
      }
   }, [individualState])

   // regarding Divisions

   const handleChangeDivision = (e) => {
      e.preventDefault()
      setSelectedDivision(e.target.value)

      setDistrictsList('')
      setSubDistrictsList('')
      setVillagesList('')

      setShowDistrict(false)
      setShowSubDistrict(false)
      setShowVillage(false)

      setLegisList('')

      setShowSearchDivision(false)
      setShowSearchDistrict(false)
      setShowSearchSubDistrict(false)
      setShowSearchVillage(false)

      setSelectedDistrict('')
      setSelectedSubDistrict('')
      setSelectedVillage('')

      setShowRadio(false)
      setSelectedRadio('')

      setBlocksList('')
      setShowBlock(false)
      setSelectedBlock('')
   }

   useEffect(() => {
      if (selectedDivision) {
         setDistrictsList(districtsListReserve.filter((item) => item.Division_id === selectedDivision))
         setShowDistrict(true)
         setShowSearchDivision(true)
         getIndividualDivision()
      } else {
         setDistrictsList('')
         setShowDistrict(false)
         setShowSearchDivision(false)
         setIndividualDivision('')
      }
   }, [selectedDivision])

   const [individualDivision, setIndividualDivision] = useState('')

   const getIndividualDivision = async () => {
      const docRef = doc(db, 'Divisions', selectedDivision)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualDivision(docSnap.data())
      } else {
         setIndividualDivision('')
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualDivision) {
         const sortedListLegis = [...individualDivision.Legislatures].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegisList(sortedListLegis)
         setTextLevel('Division')
         setTextName(individualDivision.Name)
      } else {
         setLegisList('')
         setTextLevel('')
         setTextName('')
      }
   }, [individualDivision])

   const handleSearchDivision = (e) => {
      e.preventDefault()

      const sortedListLegis = [...individualDivision.Legislatures].sort((a, b) => {
         return parseInt(a.Priority) - parseInt(b.Priority)
      })
      setLegisList(sortedListLegis)
      setTextLevel('Division')
      setTextName(individualDivision.Name)
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   // regarding Districts

   const handleChangeDistrict = (e) => {
      e.preventDefault()
      setSelectedDistrict(e.target.value)

      setShowSubDistrict(false)
      setShowVillage(false)

      setSubDistrictsList('')
      setVillagesList('')

      setLegisList('')

      setShowSearchDistrict(false)
      setShowSearchSubDistrict(false)
      setShowSearchVillage(false)

      setSelectedSubDistrict('')
      setSelectedVillage('')

      setShowRadio(false)
      setSelectedRadio('')

      setBlocksList('')
      setShowBlock(false)
      setSelectedBlock('')
   }

   const [individualDistrict, setIndividualDistrict] = useState('')

   useEffect(() => {
      if (selectedDistrict) {
         getIndividualDistrict()
      } else {
         setIndividualDistrict('')
      }
   }, [selectedDistrict])

   const getIndividualDistrict = async () => {
      const docRef = doc(db, 'Districts', selectedDistrict)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualDistrict(docSnap.data())
      } else {
         setIndividualDistrict('')
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualDistrict) {
         const sortedListSubDistricts = [...individualDistrict.SubDistricts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setSubDistrictsList(sortedListSubDistricts)

         const sortedListBlocks = [...individualDistrict.Blocks].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setBlocksList(sortedListBlocks)

         const sortedListLegis = [...individualDistrict.Legislatures].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegisList(sortedListLegis)

         setTextLevel('District')
         setTextName(individualDistrict.Name)
         focusRef.current?.scrollIntoView({ behavior: 'smooth' })
         // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })

         setShowRadio(true)
      } else {
         setSubDistrictsList('')
         setBlocksList('')
         setLegisList('')
         setTextLevel('')
         setTextName('')
         setShowRadio(false)
      }
   }, [individualDistrict])

   const handleSearchDistrict = (e) => {
      e.preventDefault()

      const sortedListLegis = [...individualDistrict.Legislatures].sort((a, b) => {
         return parseInt(a.Priority) - parseInt(b.Priority)
      })
      setLegisList(sortedListLegis)
      setTextLevel('District')
      setTextName(individualDistrict.Name)
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   // regarding radio

   const handleChangeRadio = (e) => {
      e.preventDefault()

      setSelectedRadio(e.target.value)

      setShowSubDistrict(false)
      setShowSearchSubDistrict(false)
      setSelectedSubDistrict('')

      setShowVillage(false)
      setVillagesList('')
      setShowSearchVillage(false)
      setSelectedVillage('')

      setShowBlock(false)
      setSelectedBlock('')
   }

   useEffect(() => {
      setShowSearchDistrict(true)

      if (selectedRadio) {
         if (selectedRadio === 'SubDistrict') {
            setShowSubDistrict(true)
         } else if (selectedRadio === 'Block') {
            setShowBlock(true)
         }
      } else {
         setShowSubDistrict(false)
         setShowBlock(false)
      }
   }, [selectedRadio])

   // regarding Blocks

   const handleChangeBlock = (e) => {
      e.preventDefault()
      setSelectedBlock(e.target.value)
   }

   const [individualBlock, setIndividualBlock] = useState('')

   useEffect(() => {
      if (selectedBlock) {
         getIndividualBlock()
      } else {
         setIndividualBlock('')
      }
   }, [selectedBlock])

   const getIndividualBlock = async () => {
      const docRef = doc(db, 'Blocks', selectedBlock)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualBlock(docSnap.data())
      } else {
         setIndividualBlock('')
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualBlock) {
         const sortedListLegis = [...individualBlock.Legislatures].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegisList(sortedListLegis)

         setTextLevel('Block')
         setTextName(individualBlock.Name)
         focusRef.current?.scrollIntoView({ behavior: 'smooth' })
         // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
      } else {
         setLegisList('')
         setTextLevel('')
         setTextName('')
      }
   }, [individualBlock])

   // regarding Sub-Districts

   const handleChangeSubDistrict = (e) => {
      e.preventDefault()
      setSelectedSubDistrict(e.target.value)

      setShowVillage(false)
      setVillagesList('')

      setLegisList('')

      setShowSearchSubDistrict(false)
      setShowSearchVillage(false)

      setSelectedVillage('')
   }

   const [individualSubDistrict, setIndividualSubDistrict] = useState('')

   useEffect(() => {
      if (selectedSubDistrict) {
         getIndividualSubDistrict()
      } else {
         setIndividualSubDistrict('')
      }
   }, [selectedSubDistrict])

   const getIndividualSubDistrict = async () => {
      const docRef = doc(db, 'SubDistricts', selectedSubDistrict)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualSubDistrict(docSnap.data())
      } else {
         setIndividualSubDistrict('')
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualSubDistrict) {
         const sortedListVillages = [...individualSubDistrict.Villages].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setVillagesList(sortedListVillages)
         setShowVillage(true)
         setShowSearchSubDistrict(true)

         const sortedListLegis = [...individualSubDistrict.Legislatures].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegisList(sortedListLegis)
         setTextLevel('Sub-District')
         setTextName(individualSubDistrict.Name)
         focusRef.current?.scrollIntoView({ behavior: 'smooth' })
         // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
      } else {
         setVillagesList('')
         setShowVillage(false)
         setShowSearchSubDistrict(false)
      }
   }, [individualSubDistrict])

   const handleSearchSubDistrict = (e) => {
      e.preventDefault()

      const sortedListLegis = [...individualSubDistrict.Legislatures].sort((a, b) => {
         return parseInt(a.Priority) - parseInt(b.Priority)
      })
      setLegisList(sortedListLegis)
      setTextLevel('Sub-District')
      setTextName(individualSubDistrict.Name)
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   // regarding villages

   const handleChangeVillage = (e) => {
      e.preventDefault()
      setSelectedVillage(e.target.value)

      setLegisList('')
      setShowSearchVillage(false)
   }

   useEffect(() => {
      if (selectedVillage) {
         getIndividualVillage()
      } else {
         setLegisList('')
      }
   }, [selectedVillage])

   const [individualVillage, setIndividualVillage] = useState('')

   const getIndividualVillage = async () => {
      const docRef = doc(db, 'Villages', selectedVillage)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualVillage(docSnap.data())
      } else {
         console.log('No such document!')
         setIndividualVillage('')
      }
   }

   useEffect(() => {
      if (individualVillage) {
         const sortedListLegis = [...individualVillage.Legislatures].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegisList(sortedListLegis)
         setShowSearchVillage(true)
         setTextLevel('Village')
         setTextName(individualVillage.Name)
         focusRef.current?.scrollIntoView({ behavior: 'smooth' })
         // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
      } else {
         setLegisList('')
         setShowSearchVillage(false)
         setTextLevel('')
         setTextName('')
      }
   }, [individualVillage])

   const handleSearchVillage = (e) => {
      e.preventDefault()

      const sortedListLegis = [...individualVillage.Legislatures].sort((a, b) => {
         return parseInt(a.Priority) - parseInt(b.Priority)
      })
      setLegisList(sortedListLegis)
      setTextLevel('Village')
      setTextName(individualVillage.Name)
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   return (
      <Box sx={{ p: 0.5 }}>
         <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ my: 1, width: '100%', backgroundColor: '#ffffff' }} size="small">
               <InputLabel>Choose State / UT</InputLabel>
               <Select
                  id="xxx"
                  value={selectedState}
                  label="Choose State / UT"
                  onChange={(e) => {
                     handleChangeState(e)
                  }}
                  sx={{ padding: 0 }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {statesList &&
                     statesList.length > 0 &&
                     statesList.map((item) => (
                        <MenuItem key={item.State_id} value={item.State_id}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         </Box>

         {showDivision && (
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
               <FormControl sx={{ m: 1, width: '100%', backgroundColor: '#ffffff' }} size="small">
                  <InputLabel>Choose Division</InputLabel>
                  <Select
                     id="xxx"
                     value={selectedDivision}
                     label="Choose Division"
                     onChange={(e) => {
                        handleChangeDivision(e)
                     }}
                     sx={{ padding: 0 }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {divisionsList &&
                        divisionsList.length > 0 &&
                        divisionsList.map((item) => (
                           <MenuItem key={item.Division_id} value={item.Division_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               {showSearchDivision && (
                  <IconButton
                     aria-label="search-division"
                     sx={{ ml: 'auto', height: '30px', width: '30px' }}
                     onClick={(e) => {
                        handleSearchDivision(e)
                     }}
                  >
                     <PageviewIcon />
                  </IconButton>
               )}
            </Stack>
         )}

         {showDistrict && (
            <Stack direction="row" spacing={1} sx={{ my: 1, alignItems: 'center', justifyContent: 'center' }}>
               <FormControl sx={{ width: '100%', backgroundColor: '#ffffff' }} size="small">
                  <InputLabel>Choose District</InputLabel>
                  <Select
                     id="xxx"
                     value={selectedDistrict}
                     label="Choose District"
                     onChange={(e) => {
                        handleChangeDistrict(e)
                     }}
                     sx={{ padding: 0 }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {districtsList &&
                        districtsList.length > 0 &&
                        districtsList.map((item) => (
                           <MenuItem key={item.District_id} value={item.District_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               {showSearchDistrict && (
                  <IconButton
                     aria-label="search-district"
                     sx={{ ml: 'auto', height: '30px', width: '30px' }}
                     onClick={(e) => {
                        handleSearchDistrict(e)
                     }}
                  >
                     <PageviewIcon />
                  </IconButton>
               )}
            </Stack>
         )}

         {showRadio && (
            <div>
               <FormControl
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     border: 1,
                     borderColor: '#d6d6d6',
                     px: 1,
                     my: 1,
                     '& .MuiButtonBase-root.MuiRadio-root': {
                        py: 0,
                     },
                     '& .MuiTypography-root.MuiTypography-body1': {
                        fontSize: 13,
                     },
                     '& .MuiFormLabel-root': {
                        fontSize: 13,
                        backgroundColor: '#d6d6d6',
                     },
                  }}
               >
                  <FormLabel id="demo-controlled-radio-buttons-group">Please select one (कृपया एक चुनें):</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="DistrictDown"
                     value={selectedRadio}
                     onChange={(e) => {
                        handleChangeRadio(e)
                     }}
                  >
                     <FormControlLabel
                        value="SubDistrict"
                        control={<Radio size="small" />}
                        label="Sub-District (उप-जिला )"
                     />
                     <FormControlLabel value="Block" control={<Radio size="small" />} label="Block (ब्लॉक)" />
                  </RadioGroup>
               </FormControl>
            </div>
         )}

         {showBlock && (
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
               <FormControl sx={{ m: 1, width: '100%', backgroundColor: '#ffffff' }} size="small">
                  <InputLabel>Choose Block</InputLabel>
                  <Select
                     id="xxx"
                     value={selectedSubDistrict}
                     label="Choose Block"
                     onChange={(e) => {
                        handleChangeBlock(e)
                     }}
                     sx={{ padding: 0 }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {blocksList &&
                        blocksList.length > 0 &&
                        blocksList.map((item) => (
                           <MenuItem key={item.Block_id} value={item.Block_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Stack>
         )}

         {showSubDistrict && (
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
               <FormControl sx={{ m: 1, width: '100%', backgroundColor: '#ffffff' }} size="small">
                  <InputLabel>Choose Sub-District</InputLabel>
                  <Select
                     id="xxx"
                     value={selectedSubDistrict}
                     label="Choose Sub-District"
                     onChange={(e) => {
                        handleChangeSubDistrict(e)
                     }}
                     sx={{ padding: 0 }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {subDistrictsList &&
                        subDistrictsList.length > 0 &&
                        subDistrictsList.map((item) => (
                           <MenuItem key={item.SubDistrict_id} value={item.SubDistrict_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               {showSearchSubDistrict && (
                  <IconButton
                     aria-label="search-sub-district"
                     sx={{ ml: 'auto', height: '30px', width: '30px' }}
                     onClick={(e) => {
                        handleSearchSubDistrict(e)
                     }}
                  >
                     <PageviewIcon />
                  </IconButton>
               )}
            </Stack>
         )}

         {showVillage && (
            <Stack direction="row" spacing={1} sx={{ my: 1, alignItems: 'center', justifyContent: 'center' }}>
               <FormControl sx={{ width: '100%', backgroundColor: '#ffffff' }} size="small">
                  <InputLabel>Choose Village</InputLabel>
                  <Select
                     id="xxx"
                     value={selectedVillage}
                     label="Choose Village"
                     onChange={(e) => {
                        handleChangeVillage(e)
                     }}
                     sx={{ padding: 0 }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {villagesList &&
                        villagesList.length > 0 &&
                        villagesList.map((item) => (
                           <MenuItem key={item.Village} value={item.Village_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               {showSearchVillage && (
                  <IconButton
                     aria-label="search-sub-district"
                     sx={{ ml: 'auto', height: '30px', width: '30px' }}
                     onClick={(e) => {
                        handleSearchVillage(e)
                     }}
                  >
                     <PageviewIcon />
                  </IconButton>
               )}
            </Stack>
         )}
         {legisList && (
            <Typography component="div" sx={{ backgroundColor: '#a5ffc9', px: 1, mt: 1, fontSize: 13 }}>
               Bodies at level{' '}
               <Box
                  display="inline"
                  sx={{
                     backgroundColor: '#a5c9ff',
                     fontSize: 12,
                     color: '#000000',
                     px: 1,
                     fontWeight: 500,
                  }}
               >
                  {textLevel}
               </Box>
               <Box
                  fontWeight="fontWeightMedium"
                  display="inline"
                  sx={{
                     backgroundColor: '#ffb2b2',
                     fontSize: 12,
                     color: '#000000',
                     px: 1,
                     fontWeight: 500,
                  }}
               >
                  {textName}
               </Box>{' '}
               :
            </Typography>
         )}

         {legisList && legisList.length < 1 && (
            <div>
               <Alert
                  variant="outlined"
                  severity="warning"
                  sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle sx={{ fontSize: 12 }}>
                     To be available soon. <br /> शीघ्र उपलब्ध होगा |
                  </AlertTitle>
               </Alert>
            </div>
         )}

         <List dense ref={focusRef}>
            {legisList &&
               legisList.length > 0 &&
               legisList.map((item, index) => (
                  <Link
                     key={item.Legislature_id}
                     href={`/legislature/${item.Name}/${item.Legislature_id}`}
                     sx={{ textDecoration: 'none !important', px: 0 }}
                     rel="noreferrer"
                     target="_blank"
                  >
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemAvatar>
                           <Avatar alt="Travis Howard" src={item.Image} />
                        </ListItemAvatar>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                           primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                           primary={item.Name}
                           secondary={item.NameHindi}
                        />
                     </ListItem>
                     <Divider />
                  </Link>
               ))}
         </List>
      </Box>
   )
}

HomeLocalLegis.propTypes = {
   props: PropTypes.object.isRequired,
}

export default HomeLocalLegis
