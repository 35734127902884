// link to this code: https://www.30secondsofcode.org/js/s/string-case-conversion/

// other helpful links:
// https://www.geeksforgeeks.org/how-to-convert-string-to-camel-case-in-javascript/

// The camel case naming convention requires that the first letter of each word is capitalized,
// except for the first word. For example someName is camel case, but SomeName is not.
// This convention is used in JavaScript for naming variables, functions and classes.

export const toCamelCase = (str) => {
   const s =
      str &&
      str
         .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
         .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
         .join('')
   return s.slice(0, 1).toLowerCase() + s.slice(1)
}

// Pascal case is most often used in object-oriented languages like Java or C#.
// Pascal case strings have the first letter of each word capitalized.
// For example SomeName is Pascal case, but someName is not.

export const toPascalCase = (str) =>
   str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('')

// Kebab case is most often used in URL slugs. Kebab case strings are all lowercase,
// with words separated by hyphens. For example some-name is kebab case,
// but some-Name is not.

export const toKebabCase = (str) =>
   str &&
   str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('-')

// Snake case is most often used in languages such as Python or Ruby. Snake case
// strings are all lowercase, with words separated by underscores.
// For example some_name is snake case, but some_Name is not.

export const toSnakeCase = (str) =>
   str &&
   str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('_')

// Title case is most often used in titles or headings. Title case strings have the
// first letter of each word capitalized, with words separated by spaces.
// For example Some Name is title case, but Some name is not.

export const toTitleCase = (str) =>
   str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1))
      .join(' ')

// Finally, sentence case is most often used in sentences. Sentence case
// strings have their first letter capitalized, with words separated by spaces.
// For example Some name is sentence case, but some Name is not.

export const toSentenceCase = (str) => {
   const s = str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).join(' ')
   return s.slice(0, 1).toUpperCase() + s.slice(1)
}

// Convert to any case
// That was a lot of code snippets to go through and you might need more than one in
// your project. Let's see if we can combine them all into one function that
// expects a case as an argument and returns the converted string.

export const convertCase = (str, toCase = 'camel') => {
   if (!str) return ''

   const delimiter =
      toCase === 'snake' ? '_' : toCase === 'kebab' ? '-' : ['title', 'sentence'].includes(toCase) ? ' ' : ''

   const transform = ['camel', 'pascal'].includes(toCase)
      ? (x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase()
      : ['snake', 'kebab'].includes(toCase)
        ? (x) => x.toLowerCase()
        : toCase === 'title'
          ? (x) => x.slice(0, 1).toUpperCase() + x.slice(1)
          : (x) => x

   const finalTransform =
      toCase === 'camel'
         ? (x) => x.slice(0, 1).toLowerCase() + x.slice(1)
         : toCase === 'sentence'
           ? (x) => x.slice(0, 1).toUpperCase() + x.slice(1)
           : (x) => x

   const words = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)

   return finalTransform(words.map(transform).join(delimiter))
}

// https://www.geeksforgeeks.org/convert-string-to-title-case-in-javascript/

export function sentenceCase(str) {
   if (str === null || str === '') return false
   else str = str.toString()

   return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
   })
}

export function countWordsUsingReplace(str) {
   // Replace multiple spaces, newlines, and tabs with a single space
   const normalizedStr = str.replace(/\s+/g, ' ').trim()
   // Split the string by a single space and count the resulting words
   return normalizedStr === '' ? 0 : normalizedStr.split(' ').length
}
