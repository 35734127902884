import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ElecCandis.module.css'
import {
   Alert,
   Avatar,
   Box,
   Button,
   Divider,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'

import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'
import { stringAvatar80, stringAvatar80Single } from '../../../Utils/MUITheme/MUITheme'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import Switch from '@mui/material/Switch'

function AddCandiOnlineSupport({
   actionForSupport,
   candidatesListAccepted,
   uploadingOnlineSupport,
   uploadingFailedOnlineSupport,
   uploadedOnlineSupport,
   handleAddOnlineSupport,
   handleCancelOnlineSupport,
}) {
   const [onlineSupportDetails, setOnlineSupportDetails] = useState({
      Candidate_id: '',
      CandidateName: '',
      PartyName: '',
      PartyId: '',
      Alliances: '',
      OnlineSupport: '',
      CoreListId: '',
   })
   const [selectedCandi, setSelectedCandi] = useState('')
   const focusRef = useRef(null)

   useEffect(() => {
      console.log('on line vote details on change: ', onlineSupportDetails)
   }, [onlineSupportDetails])

   function handleOnlineSupportDetails(e, item) {
      e.preventDefault()

      setOnlineSupportDetails({
         Candidate_id: item.Candidate_id,
         CandidateName: item.CandidateName,
         PartyName: item.PartyName,
         PartyId: item.Party_id,
         Alliances: item.Alliances,
         OnlineSupport: item.OnlineSupport,
         CoreListId: item.CoreListId,
      })
      setSelectedCandi(item)
   }

   useEffect(() => {
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }, [selectedCandi])

   const handleSubmitOnlineSupport = async (e) => {
      e.preventDefault()

      if (onlineSupportDetails.Candidate_id !== '') {
         console.log('status Details before submit: ', onlineSupportDetails)

         handleAddOnlineSupport(onlineSupportDetails)
      }
   }

   const handleSubmitCancelOnlineSupport = async (e) => {
      e.preventDefault()

      setOnlineSupportDetails({
         Candidate_id: '',
         CandidateName: '',
         PartyName: '',
         PartyId: '',
         Alliances: '',
         OnlineSupport: '',
         CoreListId: '',
      })
      handleCancelOnlineSupport()
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            m: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            // '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <List dense>
            {candidatesListAccepted &&
               candidatesListAccepted.length > 0 &&
               candidatesListAccepted.map((item, index) => (
                  <div key={item.Candidate_id}>
                     <ListItem
                        sx={{ pl: 0, ml: 0 }}
                        key={item.Candidate_id}
                        secondaryAction={
                           <Box sx={{ ml: 'auto' }}>
                              <Switch
                                 checked={item.Candidate_id === onlineSupportDetails.Candidate_id}
                                 onChange={(e) => {
                                    handleOnlineSupportDetails(e, item)
                                 }}
                                 inputProps={{ 'aria-label': 'controlled' }}
                              />
                           </Box>
                        }
                     >
                        <ListItemAvatar>
                           {item.Name &&
                              (countWordsUsingReplace(item.Name) > 1 ? (
                                 <Avatar {...stringAvatar80(item.Name)} alt="Travis Howard" src={item.Image} />
                              ) : (
                                 <Avatar {...stringAvatar80Single(item.Name)} alt="Travis Howard" src={item.Image} />
                              ))}
                        </ListItemAvatar>

                        <ListItemText
                           sx={{ ml: 1 }}
                           secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           primary={
                              <>
                                 {item.Name !== 'Zznota' ? (
                                    <strong>{item.Name.toUpperCase()}</strong>
                                 ) : (
                                    <strong>NOTA</strong>
                                 )}

                                 {item.NameLocal && (
                                    <>
                                       <br />
                                       {item.NameLocal}
                                    </>
                                 )}
                                 {!item.NameLocal && (
                                    <>
                                       <br />
                                       {item.NameHindi}
                                    </>
                                 )}
                              </>
                           }
                           component={'span'}
                           secondary={
                              <>
                                 <>
                                    <img src={item.PartyFlag} className={styles.imgX3} />
                                    {item.PartySymbol ? (
                                       <img src={item.PartySymbol} className={styles.imgX3} />
                                    ) : (
                                       <img src={item.AllottedSymbol} className={styles.imgX3} />
                                    )}
                                    <br />
                                 </>
                                 <>
                                    {<strong> Party: </strong>}
                                    <Typography
                                       sx={{ display: 'inline' }}
                                       component="span"
                                       variant="body2"
                                       color="text.primary"
                                    >
                                       {item.PartyName}
                                    </Typography>
                                 </>
                              </>
                           }
                        />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
         </List>
         {selectedCandi !== '' && (
            <Box ref={focusRef} sx={{ mb: 1 }}>
               <Alert variant="outlined" severity="warning">
                  You have chosen to support <strong>{selectedCandi.Name}</strong>.
                  <br />
                  आपने <strong>{selectedCandi.NameHindi}</strong> का समर्थन करना चुना है |
               </Alert>
            </Box>
         )}
         {actionForSupport === 'attach and then accept the support' && (
            <Alert variant="outlined" severity="error" sx={{ color: 'Crimson' }}>
               <Typography>
                  Please express your support inside <strong>your constituency strictly</strong>.
               </Typography>
               <br />
               <Typography sx={{ color: '#4caf50', fontWeight: 600 }}>
                  Your user_id will be automatically attached to this constituency for next three years.
               </Typography>
               <br />
               <Typography>
                  कृपया <strong>अपने निर्वाचन क्षेत्र</strong> के अंदर ही अपना समर्थन प्रदर्शित करें।
               </Typography>
               <br />
               <Typography sx={{ color: '#4caf50', fontWeight: 600 }}>
                  आपकी user_id अगले तीन वर्षों के लिए स्वचालित रूप से इस निर्वाचन क्षेत्र से जुड़ जाएगी।
               </Typography>
            </Alert>
         )}

         <Box sx={{ mt: 1 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitOnlineSupport(e)
               }}
               disabled={!onlineSupportDetails.Candidate_id}
               sx={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: 1.2 }}
            >
               Submit Support {'\n'} समर्थन प्रस्तुत करें
            </Button>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="error"
               size="small"
               onClick={(e) => {
                  handleSubmitCancelOnlineSupport(e)
               }}
               sx={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: 1.2 }}
            >
               Cancel {'\n'} निरस्त करें
            </Button>
         </Box>
         <Box>
            {uploadingOnlineSupport && (
               <MyLoaderCircularGradient title={'Uploading your support vote ...'}></MyLoaderCircularGradient>
            )}
            {uploadedOnlineSupport && (
               <Alert variant="outlined" severity="success">
                  Your support updated successfully.
               </Alert>
            )}
            {uploadingFailedOnlineSupport && (
               <Alert variant="outlined" severity="error">
                  Error occured! Your support could not be updated.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

AddCandiOnlineSupport.propTypes = {
   actionForSupport: PropTypes.string.isRequired,
   candidatesListAccepted: PropTypes.object.isRequired,
   handleAddOnlineSupport: PropTypes.func.isRequired,
   handleCancelOnlineSupport: PropTypes.func.isRequired,
   uploadingOnlineSupport: PropTypes.bool.isRequired,
   uploadedOnlineSupport: PropTypes.bool.isRequired,
   uploadingFailedOnlineSupport: PropTypes.bool.isRequired,
}

export default AddCandiOnlineSupport
