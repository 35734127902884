import React from 'react'
import PropTypes from 'prop-types'
import myStyle from './Highlighter.module.css'

const HighLighter = ({ fullText, highlightText }) => {
   const parts = fullText.split(new RegExp(`(${highlightText})`, 'gi'))

   return (
      <>
         {' '}
         {parts.map((part, i) => {
            const highlightStyle = part.toLowerCase() === highlightText.toLowerCase() ? myStyle.highlightStyle : ''
            return (
               <span key={i} className={highlightStyle}>
                  {part}
               </span>
            )
         })}{' '}
      </>
   )
}

HighLighter.propTypes = {
   fullText: PropTypes.string.isRequired,
   highlightText: PropTypes.string.isRequired,
}

export default HighLighter
