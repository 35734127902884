import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'

import styles from './Constitution.module.css'
import { Box, Card, CardContent, CardMedia, Grid, Tabs, Typography } from '@mui/material'
import { TabContext } from '@mui/lab'

import SwipeableViews from 'react-swipeable-views'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import ConstitutionPreamble from '../ConstitutionPreamble/ConstitutionPreamble'
import ConstitutionCore from '../ConstitutionCore/ConstitutionCore'
import ConstitutionAmends from '../ConstitutionAmends/ConstitutionAmends'
import ConstitutionLinks from '../ConstitutionLinks/ConstitutionLinks'
import ConstitutionBasicInfo from '../ConstitutionBasicInfo/ConstitutionBasicInfo'

function Constitution() {
   const { countryName, countryId } = useParams()
   console.log('parameters from link: ', countryName, '-', countryId)

   const navigate = useNavigate()

   const theme = useTheme()
   const [value, setValue] = useState(0)

   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [constitution, setConstitution] = useState('')
   const [fetchStatus, setFetchStatus] = useState('idle')

   const fetchConstitution = async (countryId) => {
      try {
         const docRef = doc(db, 'Constitutions', '5vj7XfgUd7Oaz7ZBDLm5')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            setConstitution({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchConstitution(countryId)
      }
   }, [])
   return (
      <div>
         <div className={styles.main_div}>
            <Card
               sx={{
                  my: 1,
                  px: 2,
                  py: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundImage: 'linear-gradient(to right, #403b4a, #d6d6d6)',
               }}
            >
               <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
                  <CardContent sx={{ flex: '1 0 auto', fontWeight: 700 }}>
                     {constitution && (
                        <div>
                           <Typography variant="h5" color="#FFFFFF" component="div" sx={{ fontWeight: 600 }}>
                              {constitution.NameLocal}
                           </Typography>
                           <Typography variant="h5" color="#FFFFFF" component="div" sx={{ fontWeight: 600 }}>
                              {constitution.NameHindi}
                           </Typography>
                           <Typography component="div" variant="h5" sx={{ fontWeight: 700 }} color="#FFFFFF">
                              {constitution.Name}
                           </Typography>
                        </div>
                     )}
                  </CardContent>
               </Box>
               <CardMedia
                  component="img"
                  sx={{ maxWidth: 150, maxHeight: 70, borderRadius: 1, mr: 1, objectFit: 'contain' }}
                  image={
                     constitution.Image
                        ? constitution.Image
                        : 'https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                  }
                  alt={constitution.Name}
               />
            </Card>
            <TabContext value={value}>
               <div>
                  <Tabs
                     value={value}
                     onChange={handleChangeNavTab}
                     textColor="secondary"
                     indicatorColor="secondary"
                     aria-label="full width tabs example"
                     centered
                     // variant="scrollable"
                     // scrollButtons="auto"
                     sx={{
                        marginBottom: 1,
                        py: 0.5,
                        minHeight: 25,
                        backgroundColor: '#e7e9bb',
                     }}
                  >
                     <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                     <StyledTab1 label={`Preamble \n प्रस्तावना`} {...a11yProps(1)} />
                     <StyledTab1 label={`Constitution \n संविधान`} {...a11yProps(2)} />
                     <StyledTab1 label={`Amendments \n संशोधन`} {...a11yProps(3)} />
                     <StyledTab1 label={`Links \n लिंक`} {...a11yProps(4)} />
                  </Tabs>
               </div>
               <Grid container spacing={1} sx={{ mt: 1 }} id="vBoxGridContainer">
                  <Grid item xs={12} sm={8} id="vboxGrid1">
                     <Box>
                        <SwipeableViews
                           axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                           index={value}
                           onChangeIndex={handleChangeNavTabIndex}
                           id="swipeable"
                        >
                           <TabPanel2 value={value} index={0} dir={theme.direction}>
                              {constitution && constitution !== '' && <ConstitutionBasicInfo props={constitution} />}
                           </TabPanel2>
                           <TabPanel2 value={value} index={1} dir={theme.direction}>
                              {constitution && constitution !== '' && <ConstitutionPreamble props={constitution} />}
                           </TabPanel2>
                           <TabPanel2 value={value} index={2} dir={theme.direction}>
                              {constitution && constitution !== '' && <ConstitutionCore props={constitution} />}
                           </TabPanel2>
                           <TabPanel2 value={value} index={3} dir={theme.direction}>
                              {constitution && constitution !== '' && <ConstitutionAmends props={constitution} />}
                           </TabPanel2>
                           <TabPanel2 value={value} index={4} dir={theme.direction}>
                              {constitution && constitution !== '' && <ConstitutionLinks props={constitution} />}
                           </TabPanel2>
                        </SwipeableViews>
                     </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ backgroundColor: '#FFFFFF' }}></Grid>
               </Grid>
            </TabContext>
         </div>
      </div>
   )
}

export default Constitution
