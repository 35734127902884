import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import myStyles from './ElecManifestoes.module.css'

function AddElecManifesto({
   partiesList,
   lastManifesto,
   handleAddManifesto,
   uploadingManifesto,
   uploadedManifesto,
   uploadingFailedManifesto,
}) {
   /// /

   const [manifestoDetails, setManifestoDetails] = useState({
      Name: '',
      NameHindi: '',
      NameLocal: '',
      IssuedByParty_id: '',
      IssuedByPartyName: '',
      IssuedByPartyNameHindi: '',
      IssuedByPartyNameLocal: '',
      IssuedByPartyAbbreviation: '',
      IssuedByPartyFlag: '',
      IssuedByAlliance_id: '',
      IssuedByAllianceName: '',
      IssuedByAllianceNameHindi: '',
      IssuedByAllianceNameLocal: '',
      IssuedByAllianceAbbreviation: '',
      IssuedByAllianceLogo: '',
      SupportingParties: [],
      Election_id: '',
      ElectionName: '',
      ElectionNameHindi: '',
      ElectionNameLocal: '',
      Alliances: [],
      DateReleased: '',
      Image: '',
      ManifestoPages: [],
      WebLinks: [],
      Emails: [],
   })

   useEffect(() => {
      console.log('manifesto details on change: ', manifestoDetails)
   }, [manifestoDetails])

   function handleManifestoDetails(e) {
      setManifestoDetails({ ...manifestoDetails, [e.target.name]: e.target.value })
   }
   const [selectedPartyId, setSelectedPartyId] = useState('')

   function handleChangeParty(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedPartyId(e.target.value)

         const party = partiesList.find((item) => item.Party_id === e.target.value)
         setManifestoDetails({
            ...manifestoDetails,
            IssuedByParty_id: party.Party_id,
            IssuedByPartyName: party.Name,
            IssuedByPartyNameHindi: party.NameHindi,
            IssuedByPartyNameLocal: party.NameLocal,
            IssuedByPartyAbbreviation: party.Abbreviation,
            IssuedByPartyFlag: party.Flag,
         })
      } else {
         setSelectedPartyId('')
         setManifestoDetails({
            ...manifestoDetails,
            IssuedByParty_id: '',
            IssuedByPartyName: '',
            IssuedByPartyNameHindi: '',
            IssuedByPartyNameLocal: '',
            IssuedByPartyAbbreviation: '',
            IssuedByPartyFlag: '',
         })
      }
   }

   const [dateReleased, setDateReleased] = useState('')

   useEffect(() => {
      if (dateReleased) {
         setManifestoDetails({ ...manifestoDetails, DateReleased: dateReleased.$d })
      }
   }, [dateReleased])

   const handleSubmitManifesto = async (e) => {
      e.preventDefault()
      if (manifestoDetails.Name.length > 3) {
         console.log('manifestoDetails before submit: ', manifestoDetails)

         handleAddManifesto(manifestoDetails)

         setManifestoDetails({
            Name: '',
            NameHindi: '',
            NameLocal: '',
            IssuedByParty_id: '',
            IssuedByPartyName: '',
            IssuedByPartyNameHindi: '',
            IssuedByPartyNameLocal: '',
            IssuedByPartyAbbreviation: '',
            IssuedByPartyFlag: '',
            IssuedByAlliance_id: '',
            IssuedByAllianceName: '',
            IssuedByAllianceNameHindi: '',
            IssuedByAllianceNameLocal: '',
            IssuedByAllianceAbbreviation: '',
            IssuedByAllianceLogo: '',
            SupportingParties: [],
            Election_id: '',
            ElectionName: '',
            ElectionNameHindi: '',
            ElectionNameLocal: '',
            Alliances: [],
            DateReleased: '',
            Image: '',
            ManifestoPages: [],
            WebLinks: [],
            Emails: [],
         })
         setSelectedPartyId('')

         setSelectedImage(null)
         setImageUrl(null)
         refImageInput.current.value = ''
         // setSelectedFiledOrAnnounced('')
      }
   }

   const [selectedImage, setSelectedImage] = useState(null)
   const [imageUrl, setImageUrl] = useState(null)
   const refImageInput = useRef()

   function handleChangeImageInput(e) {
      if (e.target.files[0]) {
         setSelectedImage(e.target.files[0])
         // setImageUrl(URL.createObjectURL(e.target.files[0]))
      } else {
         setSelectedImage(null)
         setImageUrl(null)
      }
   }

   useEffect(() => {
      if (selectedImage) {
         setImageUrl(URL.createObjectURL(selectedImage))
         setManifestoDetails({
            ...manifestoDetails,
            Image: selectedImage,
         })
      }
   }, [selectedImage])

   function RemoveImage() {
      setSelectedImage(null)
      setImageUrl(null)
      refImageInput.current.value = ''
      setManifestoDetails({
         ...manifestoDetails,
         Image: '',
      })
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Box>
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel>Party</InputLabel>
                  <Select
                     value={selectedPartyId}
                     label="Party"
                     onChange={(e) => {
                        handleChangeParty(e)
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {partiesList &&
                        partiesList.map((item) => (
                           <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                              {item.Name} - <strong> {item.Abbreviation}</strong>
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Box>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleManifestoDetails(e)
                  }}
                  value={manifestoDetails.Name}
                  name="Name"
                  id="name"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleManifestoDetails(e)
                  }}
                  value={manifestoDetails.NameHindi}
                  name="NameHindi"
                  id="nameHindi"
                  label="Name in Hindi"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleManifestoDetails(e)
                  }}
                  value={manifestoDetails.NameLocal}
                  name="NameLocal"
                  id="nameLocal"
                  label="Name in Local Language"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <DatePicker
                  label="Date Of release"
                  name="DateReleased"
                  value={manifestoDetails.DateReleased != null ? dayjs(manifestoDetails.DateReleased) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateReleased(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div className={myStyles.flexRow}>
               <input
                  type="file"
                  id="select-image"
                  onChange={handleChangeImageInput}
                  accept="image/*, application/pdf"
                  ref={refImageInput}
               />
               {imageUrl && selectedImage && (
                  <Button variant="outlined" sx={{ textTransform: 'none', py: 0, minWidth: 0 }} onClick={RemoveImage}>
                     {' '}
                     Clear image
                  </Button>
               )}
            </div>
            {imageUrl && selectedImage && <img src={imageUrl} className={myStyles.imgX} />}

            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitManifesto(e)
                  }}
                  disabled={manifestoDetails.Name < 4}
               >
                  Submit
               </Button>
            </div>
            <Box>
               {uploadingManifesto && (
                  <MyLoaderCircularGradient title={'Listing the manifesto ...'}></MyLoaderCircularGradient>
               )}
               {uploadedManifesto && (
                  <Alert variant="outlined" severity="success">
                     <strong>{lastManifesto}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailedManifesto && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>{lastManifesto}</strong> could not be listed.
                  </Alert>
               )}
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddElecManifesto.propTypes = {
   partiesList: PropTypes.object.isRequired,
   lastManifesto: PropTypes.string.isRequired,
   handleAddManifesto: PropTypes.func.isRequired,
   uploadingManifesto: PropTypes.bool.isRequired,
   uploadedManifesto: PropTypes.bool.isRequired,
   uploadingFailedManifesto: PropTypes.bool.isRequired,
}

export default AddElecManifesto
