import React, { useState } from 'react'
import PropTypes from 'prop-types'

import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { Alert, Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

function EditSeat({ handleEditSeat, uploadingSeat, uploadedSeat, uploadingFailedSeat }) {
   //
   const [selectedSeatType, setSelectedSeatType] = useState([])

   const handleChangeSeatType = (e) => {
      //
      setSelectedSeatType(e.target.value)
   }
   const handleSubmitSeatType = async (e) => {
      e.preventDefault()

      console.log('seat type before submit: ', selectedSeatType)
      handleEditSeat(selectedSeatType)
      setSelectedSeatType('')
   }
   return (
      <Box>
         <Box sx={{ width: '45ch', pl: 2 }}>
            <FormControl
               sx={{
                  p: 0,
               }}
            >
               <FormLabel id="demo-controlled-radio-buttons-group">Reservation type:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="SeatType"
                  value={selectedSeatType}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handleChangeSeatType(e)
                  }}
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     py: 0,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="General - सामान्य"
                     control={<Radio size="small" />}
                     label="General - सामान्य"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="Scheduled Caste - अनुसूचित जाति"
                     control={<Radio size="small" />}
                     label="Scheduled Caste - अनुसूचित जाति"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="Scheduled Tribe - अनुसूचित जनजाति"
                     control={<Radio size="small" />}
                     label="Scheduled Tribe - अनुसूचित जनजाति"
                     sx={{ py: 0, fontSize: 15 }}
                  />
               </RadioGroup>
            </FormControl>
         </Box>
         <Box sx={{ pl: 2 }}>
            <Box sx={{ mb: 4 }}>
               {uploadingSeat && (
                  <MyLoaderCircularGradient title={'Changing the seat type ...'}></MyLoaderCircularGradient>
               )}
               {uploadedSeat && (
                  <Alert variant="outlined" severity="success">
                     <strong>selected constituencies</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailedSeat && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>Seat type</strong> could not be listed.
                  </Alert>
               )}
            </Box>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitSeatType(e)
               }}
               disabled={selectedSeatType.length < 1}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

EditSeat.propTypes = {
   handleEditSeat: PropTypes.func.isRequired,
   uploadingSeat: PropTypes.bool.isRequired,
   uploadedSeat: PropTypes.bool.isRequired,
   uploadingFailedSeat: PropTypes.bool.isRequired,
}

export default EditSeat
