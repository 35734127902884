import { Alert, Box, Divider, Link, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Helmet } from 'react-helmet'

function ConstituencyElections({ constituency, elections }) {
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Elections for ${constituency.Name}(${constituency.ConstituencyNumber}) - Constituency of ${constituency.LegislatureName}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               pl: 1,
            }}
         >
            Elections for this constituency / इस निर्वाचन क्षेत्र के लिए चुनाव:
         </Typography>
         <List dense sx={{ width: '100%', bgcolor: 'background.paper', my: 1, p: 0 }}>
            {elections.length > 0 &&
               elections.map((item) => (
                  <div key={item.Election_id}>
                     <ListItem
                        alignItems="flex-start"
                        key={item.Election_id}
                        // secondaryAction={
                        //    <Box sx={{ ml: 'auto' }}>
                        //       {isSuper && (
                        //          <IconButton
                        //             edge="end"
                        //             aria-label="delete"
                        //             onClick={(e) => {
                        //                handleDelete(
                        //                   e,
                        //                   item.Election_id,
                        //                   item.Legislature_id,
                        //                   item.LegislatureName,
                        //                   item.LegislatureNameHindi,
                        //                   item.LegislatureNameLocal,
                        //                   item.Name,
                        //                   item.NameHindi,
                        //                   item.NameLocal,
                        //                   item.NameAlt,
                        //                   item.DateStart,
                        //                   item.DateFinish,
                        //                   item.HouseNumber,
                        //                   item.IsSpeculated,
                        //                )
                        //             }}
                        //          >
                        //             <DeleteIcon />
                        //          </IconButton>
                        //       )}
                        //    </Box>
                        // }
                     >
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary=<Link
                              href={`/election/${item.Name}/${item.Election_id}`}
                              rel="noreferrer"
                              target="_blank"
                           >
                              {item.Name}
                           </Link>
                           secondary={
                              <React.Fragment>
                                 {item.NameHindi !== '' && (
                                    <>
                                       <Link
                                          href={`/election/${item.Name}/${item.Election_id}`}
                                          rel="noreferrer"
                                          target="_blank"
                                       >
                                          {item.NameHindi}
                                       </Link>
                                       <br />
                                    </>
                                 )}
                                 {item.NameLocal !== '' && (
                                    <>
                                       <Link
                                          href={`/election/${item.Name}/${item.Election_id}`}
                                          rel="noreferrer"
                                          target="_blank"
                                       >
                                          {item.NameLocal}
                                       </Link>
                                       <br />
                                    </>
                                 )}
                                 <>
                                    {' House Number / सदन संख्या: '}
                                    <Typography
                                       sx={{ display: 'inline' }}
                                       component="span"
                                       variant="body2"
                                       color="text.primary"
                                    >
                                       {item.HouseNumber}
                                    </Typography>
                                    <br />
                                 </>
                                 {' Poll start: '}
                                 <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                 >
                                    {moment(item.DateStart.toDate()).format('Do MMMM YYYY')}
                                 </Typography>

                                 {item.DateFinish !== '' && (
                                    <>
                                       <br />
                                       {' Poll end: '}
                                       <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                       >
                                          {moment(item.DateFinish.toDate()).format('Do MMMM YYYY')}
                                       </Typography>
                                    </>
                                 )}
                              </React.Fragment>
                           }
                        />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
         </List>
         {elections.length < 1 && (
            <Alert variant="outlined" severity="warning" sx={{ mx: 5, mt: 5 }}>
               No election for this body is available for display.
            </Alert>
         )}
      </Box>
   )
}

ConstituencyElections.propTypes = {
   constituency: PropTypes.object.isRequired,
   elections: PropTypes.array.isRequired,
}

export default ConstituencyElections
