import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import SwipeableViews from 'react-swipeable-views'
import { Tabs } from '@mui/material'
import HomeCentralJudiciary from './HomeCentralJudiciary'
import HomeStateJudiciary from './HomeStateJudiciary'
import HomeLocalJudiciary from './HomeLocalJudiciary'

import { StyledTab1, TabPanel1, a11yProps } from '../../Components/TabPanelSystem'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../redux/reducers/countries-slice'

// import Typography from '@mui/material/Typography'

export default function HomeTabPanelJudiciary() {
   const theme = useTheme()
   const [value, setValue] = useState(0)

   const thisCountry = useSelector(selectCountries)
   console.log('selected country: ', thisCountry.selectedCountryInformation)

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeIndex = (index) => {
      setValue(index)
   }

   const [initialState, setInitialState] = useState('idle')

   useEffect(() => {
      if (initialState === 'idle') {
         fetchCountry()
         setInitialState('success')
      }
   }, [])

   const [individualCountry, setIndividualCountry] = useState('')

   const fetchCountry = async () => {
      const docRef = doc(db, 'Countries', 'lsTDGRF8XHWnR3VjVuB4')
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualCountry(docSnap.data())
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   return (
      <Box sx={{ pb: 0.5 }}>
         <TabContext value={value}>
            <Box sx={{ width: '100%', mb: 1 }}>
               <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  sx={{
                     minHeight: 25,
                     width: '100%',
                     mr: 0,
                  }}
               >
                  <StyledTab1 label={`Central \n (केंद्र)`} {...a11yProps(0)} />
                  <StyledTab1 label={`State \n (राज्य)`} {...a11yProps(1)} />
                  <StyledTab1 label={`Local \n (स्थानीय)`} {...a11yProps(2)} />
               </Tabs>
            </Box>
            <SwipeableViews
               axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
               index={value}
               onChangeIndex={handleChangeIndex}
            >
               <TabPanel1 value={value} index={0} dir={theme.direction}>
                  {individualCountry && individualCountry !== '' && <HomeCentralJudiciary props={individualCountry} />}
               </TabPanel1>
               <TabPanel1 value={value} index={1} dir={theme.direction}>
                  {individualCountry && individualCountry !== '' && <HomeStateJudiciary props={individualCountry} />}
               </TabPanel1>
               <TabPanel1 value={value} index={2} dir={theme.direction}>
                  {individualCountry && individualCountry !== '' && <HomeLocalJudiciary props={individualCountry} />}
               </TabPanel1>
            </SwipeableViews>
         </TabContext>
      </Box>
   )
}
