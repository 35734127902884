import { Alert } from '@mui/material'
import React from 'react'

function HomeLocalExecutive() {
   return (
      <div>
         <Alert severity="success" sx={{ margin: 1, mt: 5, justifyContent: 'center', alignItems: 'center' }}>
            This facility will be available in future.
            <br />
            <br />
            यह सुविधा भविष्य में उपलब्ध होगी।
         </Alert>
      </div>
   )
}

export default HomeLocalExecutive
