import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Link, Modal, Table, TableCell, TableRow, Typography } from '@mui/material'
import myStyles from './PPAlliance.module.css'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../redux/reducers/users-slice'

import EditIcon from '@mui/icons-material/Edit'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'

import AddDate from '../../Components/Edits/AddDate'
import { auth, db, dbStorage } from '../../FirebaseConfig'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { sentenceCase } from '../../Utils/Conversions/CaseConversion'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { v4 } from 'uuid'
// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import AddImage from '../../Components/Edits/AddImage'
import AddAddress from '../../Components/Edits/AddAddress'
import AddPhone from '../../Components/Edits/AddPhone'
import AddWeblink from '../../Components/Edits/AddWeblink'
import AddEmail from '../../Components/Edits/AddEmail'
import AddText from '../../Components/Edits/AddText'
import AddTextRaw from '../../Components/Edits/AddTextRaw'
import { styleModalBox, styleModalTypography } from '../../Utils/MUITheme/MUITheme'

function PPAllianceBasicInfo({ pPAlliance }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [record, setRecord] = useState('')
   const [sourceLogo, setSourceLogo] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setRecord({
            Alliance_id: pPAlliance.id,
            Name: pPAlliance.Name,
            NameHindi: pPAlliance.NameHindi,
            NameLocal: pPAlliance.NameLocal,
            Abbreviation: pPAlliance.Abbreviation,
            IsActive: pPAlliance.IsActive,
            Party_id_s: pPAlliance.Party_id_s,
            FoundedDate: pPAlliance.FoundedDate,
            Logo: pPAlliance.Logo,
         })

         setFirstLoadStatus('success')

         console.log('ElecCandis - first use effect blank')
      }
   }, [])

   // related to FoundedDate modal

   const [openModalFoundedDate, setOpenModalFoundedDate] = useState(false)

   const handleOpenModalFoundedDate = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalFoundedDate(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalFoundedDate = () => setOpenModalFoundedDate(false)

   const handleAddFoundedDate = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (dateDetails.Date !== '') {
               const pPAllianceRef = doc(db, 'PPAlliances', pPAlliance.id)
               const pPAllianceBaseRef = doc(db, 'BaseLists', 'PPAlliancesBase')

               await updateDoc(pPAllianceRef, {
                  FoundedDate: dateDetails.Date,
               })

               const recordNew = { ...record, FoundedDate: dateDetails.Date }

               await updateDoc(pPAllianceBaseRef, {
                  Alliances: arrayRemove(record),
               })

               await updateDoc(pPAllianceBaseRef, {
                  Alliances: arrayUnion(recordNew),
               })

               // update the FoundedDate display
               pPAlliance.FoundedDate = dateDetails.Date
               setRecord({ ...record, FoundedDate: dateDetails.Date })
               // close the modal
               setOpenModalFoundedDate(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const pPAllianceRef = doc(db, 'PPAlliances', pPAlliance.id)
            const pPAllianceBaseRef = doc(db, 'BaseLists', 'PPAlliancesBase')

            await updateDoc(pPAllianceRef, {
               Name: textDetails.Text,
               NameOld: arrayUnion({
                  Name: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Name: textDetails.Text }

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayRemove(record),
            })

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayUnion(recordNew),
            })

            // update the name display
            pPAlliance.Name = textDetails.Text
            setRecord({ ...record, Name: textDetails.Text })
            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const pPAllianceRef = doc(db, 'PPAlliances', pPAlliance.id)
            const pPAllianceBaseRef = doc(db, 'BaseLists', 'PPAlliancesBase')

            await updateDoc(pPAllianceRef, {
               NameHindi: textDetails.Text,
               NameHindiOld: arrayUnion({
                  NameHindi: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameHindi: textDetails.Text }

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayRemove(record),
            })

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayUnion(recordNew),
            })

            // update the name display
            pPAlliance.NameHindi = textDetails.Text
            setRecord({ ...record, NameHindi: textDetails.Text })
            // close the modal
            setOpenModalNameHindi(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const pPAllianceRef = doc(db, 'PPAlliances', pPAlliance.id)
            const pPAllianceBaseRef = doc(db, 'BaseLists', 'PPAlliancesBase')

            await updateDoc(pPAllianceRef, {
               NameLocal: textDetails.Text,
               NameLocalOld: arrayUnion({
                  NameLocal: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameLocal: textDetails.Text }

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayRemove(record),
            })

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayUnion(recordNew),
            })

            // update the name display
            pPAlliance.NameLocal = textDetails.Text
            setRecord({ ...record, NameLocal: textDetails.Text })
            // close the modal
            setOpenModalNameLocal(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Abbreviation modal

   const [openModalAbbreviation, setOpenModalAbbreviation] = useState(false)

   const handleOpenModalAbbreviation = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAbbreviation(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAbbreviation = () => setOpenModalAbbreviation(false)

   const handleAddAbbreviation = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const pPAllianceRef = doc(db, 'PPAlliances', pPAlliance.id)
            const pPAllianceBaseRef = doc(db, 'BaseLists', 'PPAlliancesBase')

            await updateDoc(pPAllianceRef, {
               Abbreviation: textDetails.Text,
               AbbreviationOld: arrayUnion({
                  Abbreviation: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Abbreviation: textDetails.Text }

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayRemove(record),
            })

            await updateDoc(pPAllianceBaseRef, {
               Alliances: arrayUnion(recordNew),
            })

            // update the name display
            pPAlliance.Abbreviation = textDetails.Text
            setRecord({ ...record, Abbreviation: textDetails.Text })
            // close the modal
            setOpenModalAbbreviation(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Address modal

   const [openModalAddress, setOpenModalAddress] = useState(false)

   const handleOpenModalAddress = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAddress(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAddress = () => setOpenModalAddress(false)

   const handleAddAddress = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               addressDetails.State_id.length >= 3 &&
               addressDetails.Address.length >= 10 &&
               addressDetails.ValueRadio !== ''
            ) {
               ///
               addressDetails.CrBy = auth.currentUser.uid
               addressDetails.CrDt = new Date()
               addressDetails.CrIP = ip.data.ip
               console.log('stage 4 submit')

               addressDetails.Address = sentenceCase(addressDetails.Address)
               console.log('stage 5 submit')

               const pPAllianceRef = doc(db, 'PPAlliances', pPAlliance.id)

               if (addressDetails.ValueRadio === 'Current address') {
                  ///
                  await updateDoc(pPAllianceRef, {
                     Address: addressDetails,
                     AddressOld: arrayUnion(addressDetails),
                  })
                  console.log('stage 6 submit')
                  // update the address display
                  pPAlliance.Address.Address = addressDetails.Address
                  pPAlliance.Address.DistrictName = addressDetails.DistrictName
                  pPAlliance.Address.StateName = addressDetails.StateName
                  pPAlliance.Address.PinCode = addressDetails.PinCode
                  console.log('stage 7 submit')
               } else {
                  await updateDoc(pPAllianceRef, {
                     AddressOld: arrayUnion(addressDetails),
                  })
                  console.log('stage 8 submit')
               }

               // close modal
               setOpenModalAddress(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Logo modal
   const [openModalLogo, setOpenModalLogo] = useState(false)

   const handleOpenModalLogo = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalLogo(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalLogo = () => setOpenModalLogo(false)

   const handleAddImageLogo = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `PPAlliances/${pPAlliance.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     Logo: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const recordNew = { ...record, Logo: url }
                  console.log('test rrecord new: ', recordNew)
                  const allianceRef = doc(db, 'PPAlliances', pPAlliance.id)
                  const allianceBaseRef = doc(db, 'BaseLists', 'PPAlliancesBase')

                  if (imageDetails.ValueRadio === 'Current image') {
                     //
                     updateDoc(allianceRef, {
                        Logo: url,
                        LogoOld: arrayUnion(forOld),
                     })
                     console.log('test rrecord: ', record)
                     await updateDoc(allianceBaseRef, {
                        Alliances: arrayRemove(record),
                     })

                     await updateDoc(allianceBaseRef, {
                        Alliances: arrayUnion(recordNew),
                     })

                     // update the Logo display
                     pPAlliance.Logo = url
                     setRecord({ ...record, Logo: url })

                     setSourceLogo(url)
                  } else {
                     updateDoc(allianceRef, {
                        LogoOld: arrayUnion(forOld),
                     })
                  }
               })
            })

            // close modal
            setOpenModalLogo(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Phone modal
   const [openModalPhone, setOpenModalPhone] = useState(false)

   const handleOpenModalPhone = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalPhone(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalPhone = () => setOpenModalPhone(false)

   const [lastPhone, setLastPhone] = useState('')
   const [uploadingPhone, setUploadingPhone] = useState(false)
   const [uploadedPhone, setUploadedPhone] = useState(false)
   const [uploadingFailedPhone, setUploadingFailedPhone] = useState(false)

   const handleAddPhone = async (phoneDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            phoneDetails.CrBy = auth.currentUser.uid
            phoneDetails.CrDt = new Date()
            phoneDetails.CrIP = ip.data.ip

            setLastPhone(phoneDetails.Phone.toString())
            setUploadingPhone(true)
            try {
               const allianceRef = doc(db, 'PPAlliances', pPAlliance.id)
               await updateDoc(allianceRef, {
                  Phones: arrayUnion(phoneDetails.Phone),
                  PhonesOld: arrayUnion(phoneDetails),
               })

               // update the weblink display
               pPAlliance.Phones.push(phoneDetails.Phone)

               // close modal
               // setOpenModalPhone(false)

               setUploadingPhone(false)
               setUploadedPhone(true)
               setUploadingFailedPhone(false)
            } catch (error) {
               alert('Error adding Phone: ', error.message)
               console.log('Error adding Phone', error)
               setUploadingPhone(false)
               setUploadedPhone(false)
               setUploadingFailedPhone(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to WebLink modal
   const [openModalWebLink, setOpenModalWebLink] = useState(false)

   const handleOpenModalWebLink = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebLink(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebLink = () => setOpenModalWebLink(false)

   const handleAddWebLink = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            const allianceRef = doc(db, 'PPAlliances', pPAlliance.id)
            await updateDoc(allianceRef, {
               WebLinks: arrayUnion(weblinkDetails.Weblink),
               WebLinksOld: arrayUnion(weblinkDetails),
            })

            // update the weblink display
            pPAlliance.WebLinks.push(weblinkDetails.Weblink)

            // close modal
            // setOpenModalWebLink(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to WebLink modal
   const [openModalEmail, setOpenModalEmail] = useState(false)

   const handleOpenModalEmail = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalEmail(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalEmail = () => setOpenModalEmail(false)

   const handleAddEmail = async (emailDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            emailDetails.CrBy = auth.currentUser.uid
            emailDetails.CrDt = new Date()
            emailDetails.CrIP = ip.data.ip

            const allianceRef = doc(db, 'PPAlliances', pPAlliance.id)
            await updateDoc(allianceRef, {
               Emails: arrayUnion(emailDetails.Email),
               EmailsOld: arrayUnion(emailDetails),
            })

            // update the weblink display
            pPAlliance.Emails.push(emailDetails.Email)

            // close modal
            setOpenModalEmail(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               display: 'flex',
               borderRadius: 1,
               flexDirection: 'column',
               px: 2,
            }}
         >
            <Table>
               <tbody>
                  {(isDataEditor || isSuper) && (
                     <TableRow>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Name:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>{pPAlliance.Name}</Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editName"
                                    onClick={(e) => {
                                       handleOpenModalName(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalName}
                                 onClose={handleCloseModalName}
                                 aria-labelledby="modal-modal-title-name"
                                 aria-describedby="modal-modal-description-name"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-name"
                                       variant="h6"
                                       component="h6"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Change name
                                    </Typography>
                                    <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  )}
                  {(isDataEditor || isSuper) && (
                     <TableRow className={myStyles.row_style}>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Name hindi:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>{pPAlliance.NameHindi}</Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editNameHindi"
                                    onClick={(e) => {
                                       handleOpenModalNameHindi(e)
                                    }}
                                    sx={{
                                       ml: 'auto',
                                       height: '25px',
                                       width: '25px',
                                    }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalNameHindi}
                                 onClose={handleCloseModalNameHindi}
                                 aria-labelledby="modal-modal-title-Hindiname"
                                 aria-describedby="modal-modal-description-Hindiname"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-hindiname"
                                       variant="h6"
                                       component="h6"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add name in hindi
                                    </Typography>
                                    <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  )}
                  {(isDataEditor || isSuper) && (
                     <TableRow className={myStyles.row_style}>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Name local:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>{pPAlliance.NameLocal}</Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editNameLocal"
                                    onClick={(e) => {
                                       handleOpenModalNameLocal(e)
                                    }}
                                    sx={{
                                       ml: 'auto',
                                       height: '25px',
                                       width: '25px',
                                    }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalNameLocal}
                                 onClose={handleCloseModalNameLocal}
                                 aria-labelledby="modal-modal-title-localname"
                                 aria-describedby="modal-modal-description-localname"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-localname"
                                       variant="h6"
                                       component="h6"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add name in local language
                                    </Typography>
                                    <AddText
                                       handleAddText={handleAddNameLocal}
                                       labelName="Name (in local language)"
                                    ></AddText>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  )}
                  {(isDataEditor || isSuper) && (
                     <TableRow className={myStyles.row_style}>
                        <TableCell className={myStyles.cell_style_left} variant="head">
                           Abbreviation:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>{pPAlliance.Abbreviation}</Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAbbreviation"
                                    onClick={(e) => {
                                       handleOpenModalAbbreviation(e)
                                    }}
                                    sx={{
                                       ml: 'auto',
                                       height: '25px',
                                       width: '25px',
                                    }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}

                              <Modal
                                 open={openModalAbbreviation}
                                 onClose={handleCloseModalAbbreviation}
                                 aria-labelledby="modal-modal-title-abbreviation"
                                 aria-describedby="modal-modal-description-abbreviation"
                                 disableScrollLock
                              >
                                 <Box sx={styleModalBox}>
                                    <Typography
                                       id="modal-modal-title-abbreviation"
                                       variant="h6"
                                       component="h6"
                                       align="center"
                                       sx={styleModalTypography}
                                    >
                                       Add Abbreviation
                                    </Typography>
                                    <AddTextRaw
                                       handleAddTextRaw={handleAddAbbreviation}
                                       labelName="Abbreviation"
                                    ></AddTextRaw>
                                 </Box>
                              </Modal>
                           </Box>
                        </TableCell>
                     </TableRow>
                  )}
                  <TableRow>
                     <TableCell
                        sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                        className={myStyles.cell_style_left}
                        variant="head"
                     >
                        Founded on: {'\n'} स्थापित:
                     </TableCell>
                     <TableCell className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <Box>
                              {pPAlliance.FoundedDate !== '' && (
                                 <Box>{moment(pPAlliance.FoundedDate.toDate()).format('Do MMMM YYYY')}</Box>
                              )}
                           </Box>

                           {(isDataEditor || isSuper) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editFoundedDate"
                                 onClick={(e) => {
                                    handleOpenModalFoundedDate(e)
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}

                           <Modal
                              open={openModalFoundedDate}
                              onClose={handleCloseModalFoundedDate}
                              aria-labelledby="modal-modal-title-dob"
                              aria-describedby="modal-modal-description-dob"
                              disableScrollLock
                           >
                              <Box sx={styleModalBox}>
                                 <Typography
                                    id="modal-modal-title-dob"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    sx={styleModalTypography}
                                 >
                                    Add Founded Date
                                 </Typography>
                                 <AddDate
                                    labelName="Founded on / स्थापित"
                                    handleAddDate={handleAddFoundedDate}
                                 ></AddDate>
                              </Box>
                           </Modal>
                        </Box>
                     </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell
                        sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                        className={myStyles.cell_style_left}
                        variant="head"
                     >
                        Address {'\n'} पता:
                     </TableCell>
                     <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           {pPAlliance.Address && (
                              <Box>
                                 <div>{pPAlliance.Address.Address}</div>
                                 <span>
                                    {pPAlliance.Address.DistrictName} - {pPAlliance.Address.StateName} -{' '}
                                    {pPAlliance.Address.PinCode}
                                 </span>
                              </Box>
                           )}

                           {(isDataEditor || isSuper) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editAddress"
                                 onClick={(e) => {
                                    handleOpenModalAddress(e)
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}

                           <Modal
                              open={openModalAddress}
                              onClose={handleCloseModalAddress}
                              aria-labelledby="modal-modal-title-headquarter"
                              aria-describedby="modal-modal-description-headquarter"
                              disableScrollLock
                           >
                              <Box sx={styleModalBox}>
                                 <Typography
                                    id="modal-modal-title-headquarter"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    sx={styleModalTypography}
                                 >
                                    Add Address
                                 </Typography>
                                 <AddAddress
                                    countryId={pPAlliance.Country_id}
                                    handleAddAddress={handleAddAddress}
                                 ></AddAddress>
                              </Box>
                           </Modal>
                        </Box>
                     </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell
                        sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                        className={myStyles.cell_style_left}
                        variant="head"
                     >
                        Logo {'\n'} प्रतीक चिन्ह:
                     </TableCell>
                     <TableCell className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           {pPAlliance.Logo !== '' && (
                              <Box>
                                 <img src={pPAlliance.Logo} className={myStyles.imgX} />
                              </Box>
                           )}
                           {pPAlliance.Logo === '' && (
                              <Box>{sourceLogo !== '' ? <img src={sourceLogo} className={myStyles.imgX} /> : null}</Box>
                           )}
                           {(isDataEditor || isSuper) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editLogo"
                                 onClick={(e) => {
                                    handleOpenModalLogo(e)
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}

                           <Modal
                              open={openModalLogo}
                              onClose={handleCloseModalLogo}
                              aria-labelledby="modal-modal-title-logo"
                              aria-describedby="modal-modal-description-logo"
                              disableScrollLock
                           >
                              <Box sx={styleModalBox}>
                                 <Typography
                                    id="modal-modal-title-logo"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    sx={styleModalTypography}
                                 >
                                    Add Logo
                                 </Typography>
                                 <AddImage handleAddImage={handleAddImageLogo}></AddImage>
                              </Box>
                           </Modal>
                        </Box>
                     </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell
                        sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                        className={myStyles.cell_style_left}
                        variant="head"
                     >
                        Phones {'\n'} दूरभाष:
                     </TableCell>
                     <TableCell className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <Box>
                              {pPAlliance.Phones &&
                                 pPAlliance.Phones.length > 0 &&
                                 pPAlliance.Phones.map((item, index) => <div key={index}>{item}</div>)}
                           </Box>

                           {(isDataEditor || isSuper) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editPhone"
                                 onClick={(e) => {
                                    handleOpenModalPhone(e)
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}

                           <Modal
                              open={openModalPhone}
                              onClose={handleCloseModalPhone}
                              aria-labelledby="modal-modal-title-phones"
                              aria-describedby="modal-modal-description-phones"
                              disableScrollLock
                           >
                              <Box sx={styleModalBox}>
                                 <Typography
                                    id="modal-modal-title-phones"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    sx={styleModalTypography}
                                 >
                                    Add Phones:
                                 </Typography>
                                 <AddPhone
                                    lastPhone={lastPhone}
                                    handleAddPhone={handleAddPhone}
                                    uploadingPhone={uploadingPhone}
                                    uploadedPhone={uploadedPhone}
                                    uploadingFailedPhone={uploadingFailedPhone}
                                 ></AddPhone>
                              </Box>
                           </Modal>
                        </Box>
                     </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell
                        sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                        className={myStyles.cell_style_left}
                        variant="head"
                     >
                        Web Links {'\n'} वेब लिंक:
                     </TableCell>
                     <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Box>
                              {pPAlliance.WebLinks &&
                                 pPAlliance.WebLinks.length > 0 &&
                                 pPAlliance.WebLinks.map((item, index) => (
                                    <div key={index}>
                                       {item && (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                          <Link underline="hover" href={item} target="_blank" rel="noopener noreferrer">
                                             {item.substring(0, 25) === 'https://www.facebook.com/' && <FacebookIcon />}
                                             {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                                <InstagramIcon />
                                             )}
                                             {item.substring(0, 25) === 'https://www.linkedin.com/' && <LinkedInIcon />}
                                             {item.substring(0, 14) === 'https://x.com/' && <XIcon />}
                                          </Link>
                                       )}
                                       {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                          <Link
                                             underline="hover"
                                             href={`//${item}`}
                                             target="_blank"
                                             rel="noopener noreferrer"
                                          >
                                             {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                                <FacebookIcon />
                                             ) : item.substring(0, 26) === 'https://www.instagram.com/' ? (
                                                <InstagramIcon />
                                             ) : item.substring(0, 25) === 'https://www.linkedin.com/' ? (
                                                <LinkedInIcon />
                                             ) : item.substring(0, 14) === 'https://x.com/' ? (
                                                <XIcon sx={{ color: '#000000' }} />
                                             ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                                <YouTubeIcon />
                                             ) : (
                                                item
                                             )}
                                          </Link>
                                       )}
                                    </div>
                                 ))}
                           </Box>

                           {(isDataEditor || isSuper) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editWebLink"
                                 onClick={(e) => {
                                    handleOpenModalWebLink(e)
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}

                           <Modal
                              open={openModalWebLink}
                              onClose={handleCloseModalWebLink}
                              aria-labelledby="modal-modal-title-weblink"
                              aria-describedby="modal-modal-description-weblink"
                              disableScrollLock
                           >
                              <Box sx={styleModalBox}>
                                 <Typography
                                    id="modal-modal-title-weblink"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    sx={styleModalTypography}
                                 >
                                    Add a web link
                                 </Typography>
                                 <AddWeblink handleAddWeblink={handleAddWebLink}></AddWeblink>
                              </Box>
                           </Modal>
                        </Box>
                     </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell
                        sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                        className={myStyles.cell_style_left}
                        variant="head"
                     >
                        Emails {'\n'} ईमेल:
                     </TableCell>
                     <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <Box>
                              {pPAlliance.Emails &&
                                 pPAlliance.Emails.length > 0 &&
                                 pPAlliance.Emails.map((item, index) => <div key={index}>{item}</div>)}
                           </Box>

                           {(isDataEditor || isSuper) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editEmail"
                                 onClick={(e) => {
                                    handleOpenModalEmail(e)
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}

                           <Modal
                              open={openModalEmail}
                              onClose={handleCloseModalEmail}
                              aria-labelledby="modal-modal-title-email"
                              aria-describedby="modal-modal-description-email"
                              disableScrollLock
                           >
                              <Box sx={styleModalBox}>
                                 <Typography
                                    id="modal-modal-title-email"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    sx={styleModalTypography}
                                 >
                                    Add an email
                                 </Typography>
                                 <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
                              </Box>
                           </Modal>
                        </Box>
                     </TableCell>
                  </TableRow>
               </tbody>
            </Table>
         </Box>
      </LocalizationProvider>
   )
}

PPAllianceBasicInfo.propTypes = {
   pPAlliance: PropTypes.object.isRequired,
}

export default PPAllianceBasicInfo
