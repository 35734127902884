import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Divider, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import ReactPaginate from 'react-paginate'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import styles from '../../../Global.module.css'

function PPartiesUnrecognised({ props }) {
   const partiesBase = props

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [partiesList, setPartiesList] = useState([])

   const [sliceParties, setSliceParties] = useState([]) // State to hold parties
   // const [partiesCount, setPartiesCount] = useState([]) // State to hold parties
   const [loading, setLoading] = useState(false) // Loading state
   const [pageCount, setPageCount] = useState(0) // Total number of pages
   const [currentPage, setCurrentPage] = useState('') // Current page (0-indexed)
   const pageSize = 50 // Number of parties per page

   useEffect(() => {
      if (fetchStatus === 'idle') {
         const partiesListFiltered = partiesBase.filter(
            (party) => party.ECIStatus === 'Registered (Un-Recognised)' || party.ECIStatus === '',
         )
         setPartiesList(partiesListFiltered)

         // Get total number of parties
         const totalParties = partiesListFiltered.length
         // setPartiesCount(totalParties)
         console.log('stage 1: ', partiesListFiltered)
         setPageCount(Math.ceil(totalParties / pageSize)) // Set total number of pages
         // const currentPageData = GFG(partiesListFiltered, 1, pageSize)
         // console.log('stage 1: ', partiesListFiltered)
         // setSliceParties(currentPageData) // Set the current page parties
         setCurrentPage(0)

         setFetchStatus('success')
      }
   }, [])

   function GFG(array, thisPage, pageSize) {
      const startIndex = (thisPage - 1) * pageSize
      const endIndex = startIndex + pageSize
      return array.slice(startIndex, endIndex)
   }
   // const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
   //   const currentPage = 2;
   // const pageSize = 3
   // const currentPageData = GFG(data, currentPage, pageSize)
   // console.log(currentPageData)

   // Handle page click
   const handlePageClick = (data) => {
      setCurrentPage(data.selected) // React-paginate passes selected page (0-indexed)
   }

   useEffect(() => {
      if (currentPage !== '') {
         setLoading(true)

         const currentPageData = GFG(partiesList, currentPage + 1, pageSize)
         setSliceParties(currentPageData) // Set the current page parties
         setLoading(false)
      }
   }, [currentPage]) // get slice of parties when the currentPage changes

   return (
      <Box>
         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  pl: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.3,
               }}
            >
               Registered (Un-recognised) parties: {'\n'} पंजीकृत (मान्यता-रहित) दल:
            </Typography>
         </Box>
         <Box>
            {loading && loading === true ? (
               <MyLoaderCircularGradient title={'getting the list ...'}></MyLoaderCircularGradient>
            ) : (
               <PartiesDisplay parties={sliceParties} />
            )}

            <ReactPaginate
               previousLabel={'Previous'}
               nextLabel={'Next'}
               breakLabel={'...'}
               breakClassName={'break-me'}
               pageCount={pageCount} // Total number of pages
               marginPagesDisplayed={2} // How many pages to show at the beginning and end
               pageRangeDisplayed={2} // How many pages to show around the current page
               onPageChange={handlePageClick} // What happens when a page is clicked
               containerClassName={styles.pagination} // CSS class for the pagination container
               activeClassName={styles.active} // CSS class for the active page
            />
         </Box>
         {/* <Box
            sx={{
               px: 2,
            }}
         >
            <List dense>
               {partiesList.map &&
                  partiesList.map.length > 0 &&
                  partiesList.map((item, index) => (
                     <>
                        <ListItem key={item.id} sx={{ padding: 0 }}>
                           <ListItemAvatar>
                              <Link
                                 href={`/political-party/${item.Name}/${item.Party_id}`}
                                 sx={{ textDecoration: 'none !important', px: 0 }}
                                 target="_blank"
                                 rel="noopener"
                              >
                                 <Avatar alt="Travis Howard" src={item.Flag} />
                              </Link>
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left' }}
                              primary={
                                 <>
                                    <Link
                                       href={`/political-party/${item.Name}/${item.Party_id}`}
                                       sx={{ textDecoration: 'none !important', px: 0, mr: 1 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.Name}</strong>
                                    </Link>
                                    <strong>{item.Abbreviation}</strong>
                                 </>
                              }
                              secondary={
                                 <>
                                    {item.NameLocal && (
                                       <>
                                          {' '}
                                          <Link
                                             href={`/political-party/${item.Name}/${item.Party_id}`}
                                             sx={{ textDecoration: 'none !important', px: 0 }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             {item.NameLocal}
                                          </Link>
                                          <br />
                                       </>
                                    )}
                                    <Link
                                       href={`/political-party/${item.Name}/${item.Party_id}`}
                                       sx={{ textDecoration: 'none !important', px: 0 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.NameHindi}
                                    </Link>
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </>
                  ))}
            </List>
         </Box> */}
      </Box>
   )
}

const PartiesDisplay = ({ parties }) => {
   return (
      <List dense>
         {parties.map &&
            parties.map.length > 0 &&
            parties.map((item, index) => (
               <div key={item.id}>
                  <ListItem key={item.id} sx={{ padding: 0 }}>
                     <ListItemAvatar>
                        <Link
                           href={`/political-party/${item.Name}/${item.Party_id}`}
                           sx={{ textDecoration: 'none !important', px: 0 }}
                           target="_blank"
                           rel="noopener"
                        >
                           <Avatar alt="Travis Howard" src={item.Flag} />
                        </Link>
                     </ListItemAvatar>
                     <ListItemText
                        secondaryTypographyProps={{ align: 'left' }}
                        primary={
                           <>
                              <Link
                                 href={`/political-party/${item.Name}/${item.Party_id}`}
                                 sx={{ textDecoration: 'none !important', px: 0, mr: 1 }}
                                 target="_blank"
                                 rel="noopener"
                              >
                                 <strong>{item.Name}</strong>
                              </Link>
                              <strong>{item.Abbreviation}</strong>
                           </>
                        }
                        secondary={
                           <>
                              {item.NameLocal && (
                                 <>
                                    {' '}
                                    <Link
                                       href={`/political-party/${item.Name}/${item.Party_id}`}
                                       sx={{ textDecoration: 'none !important', px: 0 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.NameLocal}
                                    </Link>
                                    <br />
                                 </>
                              )}
                              <Link
                                 href={`/political-party/${item.Name}/${item.Party_id}`}
                                 sx={{ textDecoration: 'none !important', px: 0 }}
                                 target="_blank"
                                 rel="noopener"
                              >
                                 {item.NameHindi}
                              </Link>
                           </>
                        }
                     />
                  </ListItem>
                  <Divider />
               </div>
            ))}
      </List>
   )
}

PartiesDisplay.propTypes = {
   parties: PropTypes.array.isRequired,
}

PPartiesUnrecognised.propTypes = {
   props: PropTypes.array.isRequired,
}

export default PPartiesUnrecognised
