import {
   Avatar,
   Box,
   Divider,
   FormControl,
   FormControlLabel,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { countWordsUsingReplace } from '../../Utils/Conversions/CaseConversion'
import { stringAvatar80, stringAvatar80Single } from '../../Utils/MUITheme/MUITheme'
import { useSelector } from 'react-redux'
import { selectPParties } from '../../redux/reducers/pParties-slice'

function ConstituencyLeaders({ constituency, leaders }) {
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const pParties = useSelector(selectPParties)
   const [partiesList, setPartiesList] = useState([])
   const [leadersList, setLeadersList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         setPartiesList(pParties.pPartiesList)
         setLeadersList(leaders)

         setFirstLoadStatus('success')
         console.log('Consti leaders - first use effect blank')
      }
   }, [])

   const [selectedPoliticalAffiliationType, setSelectedPoliticalAffiliationType] = useState('')
   const [enablePartiesList, setEnablePartiesList] = useState(false)
   const [selectedPartyId, setSelectedPartyId] = useState('')

   function handlePoliticalAffiliationType(e) {
      e.preventDefault()

      setSelectedPoliticalAffiliationType(e.target.value)

      setSelectedPartyId('')

      switch (e.target.value) {
         case 'All':
            setEnablePartiesList(false)
            setLeadersList(leaders)
            break
         case 'Independent':
            setEnablePartiesList(false)
            setLeadersList(leaders.filter((thisItem) => thisItem.Party.PartyName === 'Independent'))
            break
         case 'PoliticalParty':
            setEnablePartiesList(true)

            break
      }
   }

   function handleChangeParty(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedPartyId(e.target.value)
         setLeadersList(leaders.filter((thisItem) => thisItem.Party.Party_id === e.target.value))
      } else {
         setSelectedPartyId('')
      }
   }

   const [searchText, setSearchText] = useState('')

   // const filteredPersons = leaders.filter((person) => {
   //    return (
   //       person.name.toLowerCase().includes(searchText.toLowerCase()) ||
   //       person.email.toLowerCase().includes(searchText.toLowerCase())
   //    )
   // })

   const handleChangeSearchText = (e) => {
      setSearchText(e.target.value)
   }

   useEffect(() => {
      if (searchText !== '') {
         const newList = leaders.filter((item) => item.Name.toLowerCase().includes(searchText.toLowerCase()))
         setLeadersList(newList)
      } else {
         setLeadersList(leaders)
      }
   }, [searchText])

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Leaders for ${constituency.Name}(${constituency.ConstituencyNumber}) - Constituency of ${constituency.LegislatureName}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               pl: 1,
            }}
         >
            Leaders for this constituency / इस निर्वाचन क्षेत्र के नेता:
         </Typography>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'row',
               borderRadius: 1,
               mx: 1,
               justifyContent: 'space-between',
            }}
         >
            <Box>
               <TextField
                  id="SearchName"
                  label="Search Name"
                  value={searchText}
                  onChange={(e) => {
                     handleChangeSearchText(e)
                  }}
                  onBlur={(event) => event}
                  margin="dense"
                  size="small"
               />
            </Box>
            <Box id="sort" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1 }}>
               <Box sx={{ display: 'inline', ml: 'auto', mr: 1, color: '#795548' }}>filter by:</Box>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     borderRadius: 1,
                     my: 1,
                     backgroundColor: '#F1F1F1',
                  }}
               >
                  <FormControl
                     sx={{
                        py: 0,
                        px: 1,
                        mt: 0.5,
                     }}
                  >
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="PoliticalAffiliationType"
                        value={selectedPoliticalAffiliationType}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handlePoliticalAffiliationType(e)
                        }}
                        sx={{
                           backgroundColor: '#ffffff',
                           borderRadius: 1,
                           px: 1,
                           py: 0,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 13,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        <FormControlLabel
                           value="All"
                           control={<Radio size="small" />}
                           label="All"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Independent"
                           control={<Radio size="small" />}
                           label="Independent"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="PoliticalParty"
                           control={<Radio size="small" />}
                           label="Political Party"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ m: 1 }} size="small" disabled={!enablePartiesList}>
                     <InputLabel>Party</InputLabel>
                     <Select
                        value={selectedPartyId}
                        label="Party"
                        onChange={(e) => {
                           handleChangeParty(e)
                        }}
                        sx={{ backgroundColor: '#ffffff' }}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {partiesList &&
                           partiesList.map((item) => (
                              <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                                 {item.Name} - <strong> {item.Abbreviation}</strong>
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Box>
            </Box>
         </Box>
         <Box>
            <List dense sx={{ width: '98%' }}>
               {leadersList &&
                  leadersList.length > 0 &&
                  leadersList.map((item, index) => (
                     <div key={item.NetaProfile_id}>
                        <ListItem sx={{ pl: 0, ml: 1 }} key={item.NetaProfile_id}>
                           <Link
                              href={`/leader/${item.Name}/${item.NetaProfile_id}`}
                              sx={{ textDecoration: 'none !important' }}
                              target="_blank"
                              rel="noopener"
                           >
                              <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                 <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                       color: 'text.primary',
                                       display: 'inline',
                                       fontWeight: 600,
                                       mb: 1,
                                    }}
                                 >
                                    {index + 1}.
                                 </Typography>
                                 {item.Name &&
                                    (countWordsUsingReplace(item.Name) > 1 ? (
                                       <Avatar {...stringAvatar80(item.Name)} alt="Travis Howard" src={item.Image} />
                                    ) : (
                                       <Avatar
                                          {...stringAvatar80Single(item.Name)}
                                          alt="Travis Howard"
                                          src={item.Image}
                                       />
                                    ))}
                              </ListItemAvatar>
                           </Link>

                           <ListItemText
                              sx={{ ml: 1 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                              primary={
                                 <>
                                    <Link
                                       // color="inherit"
                                       underline="hover"
                                       href={`/leader/${item.Name}/${item.NetaProfile_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.Name.toUpperCase()}</strong>
                                    </Link>{' '}
                                    -{' '}
                                    {item.NameLocal && (
                                       <Link
                                          href={`/leader/${item.Name}/${item.NetaProfile_id}`}
                                          sx={{ textDecoration: 'none !important' }}
                                          target="_blank"
                                          rel="noopener"
                                       >
                                          {item.NameLocal}
                                       </Link>
                                    )}
                                    {'  '}-{' '}
                                    <Link
                                       underline="none"
                                       href={`/leader/${item.Name}/${item.NetaProfile_id}`}
                                       color="warning"
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                                       {item.NameHindi}
                                       {'  '}
                                    </Link>
                                 </>
                              }
                              secondary={
                                 <>
                                    <Link
                                       href={`/political-party/${item.Party.PartyName}/${item.Party.Party_id}`}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <Box
                                          component="img"
                                          sx={{
                                             height: 50,
                                          }}
                                          src={item.Party.PartyFlag}
                                       />
                                    </Link>

                                    <>
                                       {<strong> Party: </strong>}
                                       {item.PartyName !== 'Independent' && (
                                          <Typography
                                             sx={{ display: 'inline' }}
                                             component="span"
                                             variant="body2"
                                             color="text.primary"
                                          >
                                             <Link
                                                href={`/political-party/${item.Party.PartyName}/${item.Party.Party_id}`}
                                                underline="none"
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                {item.Party.PartyName}
                                             </Link>
                                          </Typography>
                                       )}
                                       {item.Party.PartyName === 'Independent' && (
                                          <Typography
                                             sx={{ display: 'inline' }}
                                             component="span"
                                             variant="body2"
                                             color="text.primary"
                                          >
                                             {item.Party.PartyName}
                                          </Typography>
                                       )}
                                    </>
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
         </Box>
      </Box>
   )
}

ConstituencyLeaders.propTypes = {
   constituency: PropTypes.object.isRequired,
   leaders: PropTypes.array.isRequired,
}

export default ConstituencyLeaders
