import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Button,
   Divider,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddAllianceParty from './AddAllianceParty'
import { styleModalBox, styleModalTypography } from '../../Utils/MUITheme/MUITheme'

function PPAllianceParties({ pPAlliance, handleAllianceDetails }) {
   //

   const user = useSelector(selectUsers)
   // const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('ElecCandis - first use effect entered')

      if (user.currentUser !== null) {
         console.log('ElecCandis - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('ElecCandis - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [alliancePartiesList, setAlliancePartiesList] = useState([])
   const [partiesList, setPartiesList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         const list = pPAlliance.Parties
         let sortedList = []
         sortedList = [...list].sort((a, b) => {
            if (b.PartyName > a.PartyName) {
               return -1
            } else if (b.PartyName < a.PartyName) {
               return 1
            } else {
               return 0
            }
         })
         setAlliancePartiesList(sortedList)

         setFirstLoadStatus('success')
         console.log('Alliance parties - first use effect blank')
      }
   }, [])

   const [openModalAddAllianceParties, setOpenModalAddAllianceParties] = useState(false)

   const handleOpenModalAddAllianceParties = async (e) => {
      e.preventDefault()

      if (partiesList.length > 0) {
         setOpenModalAddAllianceParties(true)
      } else {
         /// fetch parties list
         const docRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            const sortedList = [...docSnap.data().Parties].sort((a, b) => {
               if (b.Name > a.Name) {
                  return -1
               } else if (b.Name < a.Name) {
                  return 1
               } else {
                  return 0
               }
            })
            setPartiesList(sortedList)
            setOpenModalAddAllianceParties(true)
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document for parties!')
         }
      }
   }

   const handleCloseModalAddAllianceParties = () => setOpenModalAddAllianceParties(false)

   const [lastAllianceParty, setLastAllianceParty] = useState('')
   const [uploadingAllianceParties, setUploadingAllianceParties] = useState(false)
   const [uploadedAllianceParties, setUploadedAllianceParties] = useState(false)
   const [uploadingFailedAllianceParties, setUploadingFailedAllianceParties] = useState(false)

   const handleAddAllianceParties = async (alliancePartyDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (alliancePartyDetails.PartyName.length > 2) {
               setUploadingAllianceParties(true)

               setLastAllianceParty(alliancePartyDetails.PartyName)
               console.log('alliancePartyDetails at start: ', alliancePartyDetails)
               console.log('alliancePartyList at start: ', alliancePartiesList)
               // console.log('alliancePartiesListRootLocal at start: ', alliancePartiesListRootLocal)

               const recordOld = {
                  Alliance_id: pPAlliance.id,
                  Name: pPAlliance.Name,
                  NameHindi: pPAlliance.NameHindi,
                  NameLocal: pPAlliance.NameLocal,
                  Abbreviation: pPAlliance.Abbreviation,
                  IsActive: pPAlliance.IsActive,
                  Party_id_s: pPAlliance.Party_id_s,
                  FoundedDate: pPAlliance.FoundedDate,
                  Logo: pPAlliance.Logo,
               }
               console.log('old record stage 0 record old', recordOld)

               const listParties = [...pPAlliance.Parties, alliancePartyDetails]
               const listPartyIDs = [...pPAlliance.Party_id_s, alliancePartyDetails.Party_id]

               const recordNew = {
                  Alliance_id: pPAlliance.id,
                  Name: pPAlliance.Name,
                  NameHindi: pPAlliance.NameHindi,
                  NameLocal: pPAlliance.NameLocal,
                  Abbreviation: pPAlliance.Abbreviation,
                  IsActive: pPAlliance.IsActive,
                  Party_id_s: listPartyIDs,
                  FoundedDate: pPAlliance.FoundedDate,
                  Logo: pPAlliance.Logo,
               }

               try {
                  const docRefUpdateAlliance = doc(db, 'PPAlliances', pPAlliance.id)
                  if (!pPAlliance.Party_id_s.includes(alliancePartyDetails.Party_id)) {
                     await updateDoc(docRefUpdateAlliance, {
                        Parties: arrayUnion(alliancePartyDetails),
                        Party_id_s: arrayUnion(alliancePartyDetails.Party_id),
                     })

                     const docRefUpdateAllianceBase = doc(db, 'BaseLists', 'PPAlliancesBase')
                     await updateDoc(docRefUpdateAllianceBase, {
                        Alliances: arrayRemove(recordOld),
                     })

                     console.log('new record stage 1', recordNew)

                     await updateDoc(docRefUpdateAllianceBase, {
                        Alliances: arrayUnion(recordNew),
                     })
                     console.log('new record stage 3')
                     console.log('alliancePartyList at end: ', alliancePartiesList)
                     // handleAlliancePartiesListRootUpdate([...alliancePartiesList, alliancePartyDetails])
                     // setAlliancePartiesList([...alliancePartiesList, alliancePartyDetails])

                     setAlliancePartiesList(listParties)
                     handleAllianceDetails({ ...pPAlliance, Parties: listParties, Party_id_s: listPartyIDs })
                  }

                  setUploadingAllianceParties(false)
                  setUploadedAllianceParties(true)
                  setUploadingFailedAllianceParties(false)
               } catch (error) {
                  alert('Error adding party: ', error.message)
                  console.log('Error adding party: ', error)
                  setUploadingAllianceParties(false)
                  setUploadedAllianceParties(false)
                  setUploadingFailedAllianceParties(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDeleteAllianceParty = async (
      e,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisPartySymbol,
      thisPartyECIStatus,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this candidate?')) {
               try {
                  //
                  const partyRecord = {
                     Party_id: thisPartyId,
                     PartyName: thisPartyName,
                     PartyNameHindi: thisPartyNameHindi,
                     PartyNameLocal: thisPartyNameLocal,
                     PartyAbbreviation: thisPartyAbbreviation,
                     PartyFlag: thisPartyFlag,
                     PartySymbol: thisPartySymbol,
                     PartyECIStatus: thisPartyECIStatus,
                  }

                  const recordAllianceOld = {
                     Alliance_id: pPAlliance.id,
                     Name: pPAlliance.Name,
                     NameHindi: pPAlliance.NameHindi,
                     NameLocal: pPAlliance.NameLocal,
                     Abbreviation: pPAlliance.Abbreviation,
                     IsActive: pPAlliance.IsActive,
                     Party_id_s: pPAlliance.Party_id_s,
                     FoundedDate: pPAlliance.FoundedDate,
                     Logo: pPAlliance.Logo,
                  }

                  const listParties = alliancePartiesList.filter((item) => item.Party_id !== thisPartyId)
                  const listPartyIDs = []
                  for (let i = 0; i < listParties.length; i++) {
                     listPartyIDs.push(listParties[i].Party_id)
                  }

                  const recordAllianceNew = {
                     Alliance_id: pPAlliance.id,
                     Name: pPAlliance.Name,
                     NameHindi: pPAlliance.NameHindi,
                     NameLocal: pPAlliance.NameLocal,
                     Abbreviation: pPAlliance.Abbreviation,
                     IsActive: pPAlliance.IsActive,
                     Party_id_s: listPartyIDs,
                     FoundedDate: pPAlliance.FoundedDate,
                     Logo: pPAlliance.Logo,
                  }

                  const docRefUpdateAlliance = doc(db, 'PPAlliances', pPAlliance.id)
                  await updateDoc(docRefUpdateAlliance, {
                     Parties: arrayRemove(partyRecord),
                     Party_id_s: arrayRemove(thisPartyId),
                  })

                  const docRefUpdateAllianceBase = doc(db, 'BaseLists', 'PPAlliancesBase')
                  await updateDoc(docRefUpdateAllianceBase, {
                     Alliances: arrayRemove(recordAllianceOld),
                  })
                  await updateDoc(docRefUpdateAllianceBase, {
                     Alliances: arrayUnion(recordAllianceNew),
                  })

                  setAlliancePartiesList(listParties)
                  handleAllianceDetails({ ...pPAlliance, Parties: listParties, Party_id_s: listPartyIDs })
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   return (
      <Box>
         {(isSuper || isDataEditor) && (
            <Box>
               <Button
                  sx={{ py: 0, px: 1, minWidth: 0 }}
                  onClick={(e) => {
                     handleOpenModalAddAllianceParties(e)
                  }}
               >
                  Add Parties
               </Button>
            </Box>
         )}
         <Box>
            <List dense>
               {alliancePartiesList &&
                  alliancePartiesList.length > 0 &&
                  alliancePartiesList.map((item, index) => (
                     <div key={item.Party_id}>
                        <ListItem
                           sx={{ pl: 0, ml: 0 }}
                           key={item.Party_id}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleDeleteAllianceParty(
                                             e,
                                             item.Party_id,
                                             item.PartyName,
                                             item.PartyNameHindi,
                                             item.PartyNameLocal,
                                             item.PartyAbbreviation,
                                             item.PartyFlag,
                                             item.PartySymbol,
                                             item.PartyECIStatus,
                                          )
                                       }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <Link
                              href={`/political-party/${item.PartyName}/${item.Party_id}`}
                              sx={{ textDecoration: 'none !important' }}
                              target="_blank"
                              rel="noopener"
                           >
                              <ListItemAvatar>
                                 <Avatar alt="Travis Howard" src={item.PartyFlag} />
                              </ListItemAvatar>
                           </Link>

                           <ListItemText
                              sx={{ ml: 1 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                              primary={
                                 <>
                                    <Link
                                       // color="inherit"
                                       underline="hover"
                                       href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                       sx={{ textDecoration: 'none !important', mr: 1 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.PartyName}</strong>
                                    </Link>
                                    <strong>{item.PartyAbbreviation}</strong>
                                 </>
                              }
                              component={'span'}
                              secondary={
                                 <>
                                    {item.PartyNameLocal && (
                                       <>
                                          <Link
                                             underline="hover"
                                             href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             {item.PartyNameLocal}
                                          </Link>

                                          <br />
                                       </>
                                    )}
                                    <>
                                       <Link
                                          href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                          sx={{ textDecoration: 'none !important' }}
                                          target="_blank"
                                          rel="noopener"
                                       >
                                          {item.PartyNameHindi}
                                       </Link>

                                       <br />
                                    </>
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
            {alliancePartiesList && alliancePartiesList.length < 1 && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        List of parties for{' '}
                        <strong>
                           <u>{pPAlliance.Name}</u>
                        </strong>{' '}
                        to be available soon. <br />{' '}
                        <strong>
                           <u>{pPAlliance.NameHindi}</u>
                        </strong>{' '}
                        के लिए राजनीतिक दल की सूची शीघ्र ही उपलब्ध होगी |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}
         </Box>
         <Box id="boxModals">
            <Modal
               open={openModalAddAllianceParties}
               onClose={handleCloseModalAddAllianceParties}
               aria-labelledby="modal-modal-title-addAllianceParties"
               aria-describedby="modal-modal-description-addAllianceParties"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addAllianceParties"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add parties for {pPAlliance.Name}
                  </Typography>
                  <AddAllianceParty
                     partiesList={partiesList}
                     lastAllianceParty={lastAllianceParty}
                     uploadingAllianceParties={uploadingAllianceParties}
                     uploadedAllianceParties={uploadedAllianceParties}
                     uploadingFailedAllianceParties={uploadingFailedAllianceParties}
                     handleAddAllianceParties={handleAddAllianceParties}
                  ></AddAllianceParty>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

PPAllianceParties.propTypes = {
   pPAlliance: PropTypes.object.isRequired,
   handleAllianceDetails: PropTypes.func.isRequired,
}

export default PPAllianceParties
