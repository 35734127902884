import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function AddToDateAndReason({ labelName, purpose, handleAddDate }) {
   //
   const [dateDetails, setDateDetails] = useState({
      ToDate: '',
      Reason: '',
   })
   useEffect(() => {
      console.log('Date details on change of its value: ', dateDetails)
   }, [dateDetails])

   const [selectedReason, setSelectedReason] = useState('')

   function handleReason(e) {
      e.preventDefault()

      setSelectedReason(e.target.value)
      setDateDetails({
         ...dateDetails,
         Reason: e.target.value,
      })
   }

   function handleSubmitDate(e) {
      e.preventDefault()

      if (dateDetails.ToDate === '') {
         alert('Please add some date.')
         return
      }

      if (dateDetails.Reason === '') {
         alert('Please select a reason.')
         return
      }

      handleAddDate(dateDetails)

      reset()
   }

   function reset() {
      setDateDetails({
         ToDate: '',
         Reason: '',
      })
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ m: 2 }}>
            <Box>
               <DatePicker
                  label={labelName}
                  name="Date"
                  value={dateDetails.ToDate != null ? dayjs(dateDetails.ToDate) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) =>
                     setDateDetails({
                        ...dateDetails,
                        ToDate: new Date(newValue),
                     })
                  }
                  size="small"
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, my: 1 }}>
               {purpose === 'member' && (
                  <FormControl
                     sx={{
                        py: 0,
                        px: 1,
                     }}
                  >
                     <FormLabel id="demo-controlled-radio-buttons-group">Reason:</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="Reason"
                        value={selectedReason}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handleReason(e)
                        }}
                        sx={{
                           backgroundColor: '#ffffff',
                           borderRadius: 1,
                           py: 0,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 13,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        <FormControlLabel
                           value="Death / मृत्यु"
                           control={<Radio size="small" />}
                           label="Death / मृत्यु"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Resigned / त्यागपत्र दे दिया"
                           control={<Radio size="small" />}
                           label="Resigned / त्यागपत्र दे दिया"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Disqualified / अयोग्‍य घोषित"
                           control={<Radio size="small" />}
                           label="Disqualified / अयोग्‍य घोषित"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Term Completed / कार्यकाल पूर्ण"
                           control={<Radio size="small" />}
                           label="Term Completed / कार्यकाल पूर्ण"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Dissolved / भंग किया गया"
                           control={<Radio size="small" />}
                           label="Dissolved / भंग किया गया"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     </RadioGroup>
                  </FormControl>
               )}
               {purpose === 'legislature' && (
                  <FormControl
                     sx={{
                        py: 0,
                        px: 1,
                     }}
                  >
                     <FormLabel id="demo-controlled-radio-buttons-group">Reason:</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="Reason"
                        value={selectedReason}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handleReason(e)
                        }}
                        sx={{
                           backgroundColor: '#ffffff',
                           borderRadius: 1,
                           py: 0,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 13,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        <FormControlLabel
                           value="Term Completed / कार्यकाल पूर्ण"
                           control={<Radio size="small" />}
                           label="Term Completed / कार्यकाल पूर्ण"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Dissolved / भंग किया गया"
                           control={<Radio size="small" />}
                           label="Dissolved / भंग किया गया"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     </RadioGroup>
                  </FormControl>
               )}
            </Box>
            <div className={myStyles.text_align_center}>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  margin="dense"
                  sx={{ my: 1 }}
                  onClick={(e) => {
                     handleSubmitDate(e)
                  }}
                  disabled={dateDetails.ToDate === '' || dateDetails.Reason === ''}
               >
                  Submit
               </Button>
            </div>
         </Box>
      </LocalizationProvider>
   )
}

AddToDateAndReason.propTypes = {
   labelName: PropTypes.string.isRequired,
   purpose: PropTypes.string.isRequired,
   handleAddDate: PropTypes.func.isRequired,
}

export default AddToDateAndReason
