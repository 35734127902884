import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Box,
   Button,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
} from '@mui/material'

function AddPParty({ partiesList, handleAddParty }) {
   const [pPartyDetails, setPPartyDetails] = useState({
      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyAbbreviation: '',
      PartyFlag: '',
      PartySymbol: '',
   })

   useEffect(() => {
      console.log('pPartydate details on change: ', pPartyDetails)
   }, [pPartyDetails])

   const [selectedPoliticalAffiliationType, setSelectedPoliticalAffiliationType] = useState('')
   const [enablePartiesDropdown, setEnablePartiesDropdown] = useState(false)
   const [selectedPartyId, setSelectedPartyId] = useState('')

   function handlePoliticalAffiliationType(e) {
      e.preventDefault()

      setSelectedPoliticalAffiliationType(e.target.value)

      setSelectedPartyId('')
      setEnablePartiesDropdown(false)
      setPPartyDetails({
         Party_id: '',
         PartyName: '',
         PartyNameHindi: '',
         PartyNameLocal: '',
         PartyAbbreviation: '',
         PartyFlag: '',
         PartySymbol: '',
      })

      if (e.target.value === 'PoliticalParty') {
         setEnablePartiesDropdown(true)
      } else {
         setEnablePartiesDropdown(false)
         setPPartyDetails({
            ...pPartyDetails,
            PartyName: 'Independent',
            PartyNameHindi: 'निर्दलीय',
         })
      }
   }

   function handleChangeParty(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedPartyId(e.target.value)
         setEnablePartiesDropdown(true)

         const party = partiesList.find((item) => item.Party_id === e.target.value)
         console.log('party flag', party.Flag)
         console.log('party name', party.Name)
         setPPartyDetails({
            ...pPartyDetails,
            Party_id: party.Party_id,
            PartyName: party.Name,
            PartyNameHindi: party.NameHindi,
            PartyNameLocal: party.NameLocal,
            PartyAbbreviation: party.Abbreviation,
            PartyFlag: party.Flag,
            PartySymbol: party.Symbol,
         })
      } else {
         setSelectedPartyId('')
         setEnablePartiesDropdown(false)
         setPPartyDetails({
            ...pPartyDetails,
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
         })
      }
   }

   const handleSubmitParty = async (e) => {
      e.preventDefault()
      if (selectedPoliticalAffiliationType !== '') {
         // proceed
         if (selectedPoliticalAffiliationType === 'PoliticalParty' && selectedPartyId === '') {
            // just ignore
         } else {
            // proceed
            console.log('partyDetails before submit: ', pPartyDetails)

            handleAddParty(pPartyDetails)

            setPPartyDetails({
               Party_id: '',
               PartyName: '',
               PartyNameHindi: '',
               PartyNameLocal: '',
               PartyAbbreviation: '',
               PartyFlag: '',
               PartySymbol: '',
            })
            setSelectedPoliticalAffiliationType('')
            setSelectedPartyId('')
            setEnablePartiesDropdown(false)
         }
      } else {
         // just ignore
      }
   }
   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, m: 2 }}>
         <FormControl
            sx={{
               py: 0,
               px: 1,
            }}
         >
            <FormLabel id="demo-controlled-radio-buttons-group">Political Affiliation Type:</FormLabel>
            <RadioGroup
               row
               aria-labelledby="demo-controlled-radio-buttons-group"
               name="PoliticalAffiliationType"
               value={selectedPoliticalAffiliationType}
               // style={{ display: 'flex', gap: '2rem' }}
               onChange={(e) => {
                  handlePoliticalAffiliationType(e)
               }}
               sx={{
                  backgroundColor: '#ffffff',
                  borderRadius: 1,
                  py: 0,
                  '& .MuiSvgIcon-root': {
                     fontSize: 15,
                  },
                  '& .MuiButtonBase-root': {
                     py: 0,
                  },
                  '& MuiFormControlLabel-label': {
                     fontSize: 13,
                     lineHeight: 16,
                     py: 0,
                  },
               }}
            >
               <FormControlLabel
                  value="Independent"
                  control={<Radio size="small" />}
                  label="Independent"
                  sx={{ py: 0, fontSize: 15 }}
               />
               <FormControlLabel
                  value="PoliticalParty"
                  control={<Radio size="small" />}
                  label="Political Party"
                  sx={{ py: 0, fontSize: 15 }}
               />
            </RadioGroup>
         </FormControl>
         <FormControl sx={{ m: 1 }} size="small" disabled={!enablePartiesDropdown}>
            <InputLabel>Party</InputLabel>
            <Select
               value={selectedPartyId}
               label="Party"
               onChange={(e) => {
                  handleChangeParty(e)
               }}
               sx={{ backgroundColor: '#ffffff' }}
            >
               <MenuItem value="">
                  <em>None</em>
               </MenuItem>
               {partiesList &&
                  partiesList.map((item) => (
                     <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                        {item.Name} - <strong> {item.Abbreviation}</strong>
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
         <Box>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitParty(e)
               }}
               disabled={
                  selectedPoliticalAffiliationType === '' ||
                  (selectedPoliticalAffiliationType === 'PoliticalParty' && selectedPartyId === '')
               }
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

AddPParty.propTypes = {
   partiesList: PropTypes.array.isRequired,
   handleAddParty: PropTypes.func.isRequired,
}

export default AddPParty
