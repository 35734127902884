import React from 'react'
import PropTypes from 'prop-types'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Alert, AlertTitle, Box } from '@mui/material'
import moment from 'moment'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function Activities({ isUserLoggedIn, userProfile, supports }) {
   let fullName = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ minHeight: 500 }}>
            {navigator.onLine && isUserLoggedIn === '' && (
               <div>
                  <Alert variant="outlined" severity="warning" sx={{ margin: 5 }}>
                     <AlertTitle>... Loading !</AlertTitle>
                  </Alert>
               </div>
            )}
            {!navigator.onLine && (
               <div>
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle>
                        You are offline !
                        <CloudOffIcon sx={{ ml: 5 }} />
                     </AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && isUserLoggedIn === 'out' && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{
                        margin: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >
                     <AlertTitle>Please log in! {'\n'} कृपया लॉगिन करें!</AlertTitle>
                     You are not logged in the site. {'\n'} आप साइट पर लॉग इन नहीं हैं.
                  </Alert>
               </Box>
            )}
            {navigator.onLine && isUserLoggedIn === 'in' && (
               <Box>
                  <Box sx={{ fontWeight: 600, textAlign: 'center', mb: 1 }}>Welcome ... {fullName}!</Box>
                  {supports &&
                     supports.length > 0 &&
                     supports.map((item, index) => (
                        <Box key={index} sx={{ backgroundColor: '#F1F1F1', p: 1, ml: 2 }}>
                           <ArrowForwardIosIcon fontSize="small" sx={{ mr: 1, p: 0 }} />
                           You supported <strong>{item.PartyName}</strong>
                           {item.PartyFlag && (
                              <Box component="img" src={item.PartyFlag} sx={{ height: '25px', mx: 1 }} />
                           )}
                           on <strong>{item.CrDt && moment(item.CrDt.toDate()).format('Do MMM YYYY')}</strong>.
                        </Box>
                     ))}
                  {supports && supports.length < 1 && (
                     <Alert
                        variant="outlined"
                        severity="warning"
                        sx={{
                           margin: 5,
                           alignItems: 'center',
                           justifyContent: 'center',
                           whiteSpace: 'pre-line',
                           textAlign: 'center',
                        }}
                     >
                        <AlertTitle>
                           You have not yet done any activity! {'\n'} आपने अभी तक कोई गतिविधि नहीं की है!
                        </AlertTitle>
                     </Alert>
                  )}
                  {!supports && (
                     <Alert
                        variant="outlined"
                        severity="warning"
                        sx={{
                           margin: 5,
                           alignItems: 'center',
                           justifyContent: 'center',
                           whiteSpace: 'pre-line',
                           textAlign: 'center',
                        }}
                     >
                        <AlertTitle>
                           You have not yet done any activity! {'\n'} आपने अभी तक कोई गतिविधि नहीं की है!
                        </AlertTitle>
                     </Alert>
                  )}
               </Box>
            )}
         </Box>
      </LocalizationProvider>
   )
}

Activities.propTypes = {
   isUserLoggedIn: PropTypes.string.isRequired,
   userProfile: PropTypes.object.isRequired,
   supports: PropTypes.array.isRequired,
}

export default Activities
