import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { Avatar, Box, Divider, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'

function PPartiesState({ props }) {
   const partiesBase = props
   const user = useSelector(selectUsers)
   const ip = user.ip

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [partiesList, setPartiesList] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         const partiesListFiltered = partiesBase.filter((party) => party.ECIStatus === 'State')

         // const sortedListParties = partiesListFiltered.sort((a, b) => {
         //    if (b.Name > a.Name) {
         //       return -1
         //    } else if (b.Name < a.Name) {
         //       return 1
         //    } else {
         //       return 0
         //    }
         // })
         setPartiesList(partiesListFiltered)

         setFetchStatus('success')
      }
   }, [])

   console.log(partiesBase, ip)
   return (
      <Box>
         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  pl: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               State level parties: {'\n'} राज्य स्तर के दल:
            </Typography>
         </Box>
         <Box
            sx={{
               px: 2,
            }}
         >
            <List dense>
               {partiesList.map &&
                  partiesList.map.length > 0 &&
                  partiesList.map((item, index) => (
                     <>
                        <ListItem key={item.id} sx={{ padding: 0 }}>
                           <ListItemAvatar>
                              <Link
                                 href={`/political-party/${item.Name}/${item.Party_id}`}
                                 sx={{ textDecoration: 'none !important', px: 0 }}
                                 target="_blank"
                                 rel="noopener"
                              >
                                 <Avatar alt="Travis Howard" src={item.Flag} />
                              </Link>
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left' }}
                              primary={
                                 <>
                                    <Link
                                       href={`/political-party/${item.Name}/${item.Party_id}`}
                                       sx={{ textDecoration: 'none !important', px: 0, mr: 1 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.Name}</strong>
                                    </Link>
                                    <strong>{item.Abbreviation}</strong>
                                 </>
                              }
                              secondary={
                                 <>
                                    {item.NameLocal && (
                                       <>
                                          {' '}
                                          <Link
                                             href={`/political-party/${item.Name}/${item.Party_id}`}
                                             sx={{ textDecoration: 'none !important', px: 0 }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             {item.NameLocal}
                                          </Link>
                                          <br />
                                       </>
                                    )}
                                    <Link
                                       href={`/political-party/${item.Name}/${item.Party_id}`}
                                       sx={{ textDecoration: 'none !important', px: 0 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.NameHindi}
                                    </Link>
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </>
                  ))}
            </List>
         </Box>
      </Box>
   )
}

PPartiesState.propTypes = {
   props: PropTypes.array.isRequired,
}

export default PPartiesState
